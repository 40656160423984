import React, { useState } from "react";
import Button from "../../ButtonUI";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import Spinner from "../../Spinner";
import Container from "../../DynamicForms/Container";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  setPatientAdded: Function;
  constId: string;
  patientId: string;
  gender: string;
}

const AddRequestModal: React.FC<Props> = ({
  openModal,
  setOpenModal,
  constId,
  patientId,
  gender,
  setPatientAdded,
}) => {
  const [loading, setLoading] = useState(false);
const [showUploadProg, setUpoloadProg] = useState(false);

  return loading ? (
    <Spinner />
  ) : (
    <div
      className={` ${
        showUploadProg ? "" : "fixed"
      } inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter`}
    >
      <div
        className={` ${
          showUploadProg
            ? "absolute bottom-0 left-0 z-40 uploadProgress "
            : "absolute  inset-y-0 right-0  form-add-patient transition transition ease-in duration-700  z-40 transition transition ease-in duration-700 patient-modal-info"
        }`}
      >
        {showUploadProg ? (
          ""
        ) : (
          <Button
            status="icon-secondary"
            className={"absolute right-1 top-1"}
            onClick={() => setOpenModal(false)}
          >
            <Close />
          </Button>
        )}

        {!showUploadProg ? (
          <h1 className=" title flexCenter text-center">
            Nouvelle demande de téléexpertise{" "}
          </h1>
        ) : (
          <></>
        )}

       {!showUploadProg && <div className="bg-yellow-100 border-l-4 border-yellow-600 p-4 rounded-lg shadow-lg mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-6 w-6 text-yellow-600"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path d="M14 2l-6 6h12l-6-6zM4 6v14a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2z"></path>
              </svg>
            </div>
            <div className="ml-3">
              <div className="flex items-center">
                <svg
                  className="h-4 w-4 text-yellow-600 mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 478.125 478.125"
                  fill="currentColor"
                >
                  <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <g>
                        <circle cx="239.904" cy="314.721" r="35.878" />
                        <path d="M256.657,127.525h-31.9c-10.557,0-19.125,8.645-19.125,19.125v101.975c0,10.48,8.645,19.125,19.125,19.125h31.9     c10.48,0,19.125-8.645,19.125-19.125V146.65C275.782,136.17,267.138,127.525,256.657,127.525z" />
                        <path d="M239.062,0C106.947,0,0,106.947,0,239.062s106.947,239.062,239.062,239.062c132.115,0,239.062-106.947,239.062-239.062     S371.178,0,239.062,0z M239.292,409.734c-94.171,0-170.595-76.348-170.595-170.596c0-94.248,76.347-170.595,170.595-170.595     s170.595,76.347,170.595,170.595C409.887,333.387,333.464,409.734,239.292,409.734z" />
                      </g>
                    </g>
                  </g>
                </svg>
                <p className="ml-1 text-sm leading-5 font-medium text-yellow-800">
                  Attention
                </p>
              </div>
              <p className="mt-1 text-sm leading-5 text-yellow-700">
                Ne pas utiliser la téléexpertise pour côter la consultation en
                utilisant de manière indue le code QZQP001 de dermatoscopie.
              </p>
            </div>
          </div>
        </div>}

        <div className="flexCenter">
          <Container
            setPatientAdded={setPatientAdded}
            gender={gender}
            constId={constId}
            patientId={patientId}
            setOpenModal={setOpenModal}
            setUpoloadProg={() => setUpoloadProg(true)}
            setUpoloadProgFalse={() => setUpoloadProg(false)}
          />
        </div>
      </div>
    </div>
  );
};
export default AddRequestModal;
