import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import Button from "../ButtonUI";

import { colorAvatar, tabsR } from "./fakeData";
import { statusLabel } from "../../helper/demandeStatus";
import moment from "../../helper/moment";
import { userAtom } from "../../state/auth";
import { refreshAtom } from "../../state/refreshPatientInfo";
import { User } from "../../types/user";

import Spinner from "../Spinner";
import AddRequestModal from "./modal/AddRequestTelex";
import SharedRequest from "../SharedRequest";
import AddPatient from "../../pages/MyPatients/addPatient";

import telex from "../../assets/images/dermatologista.png";
import birthday from "../../assets/images/birthday.png";
import phone from "../../assets/images/phone.png";
import mail from "../../assets/images/mail.png";
import home from "../../assets/images/home.png";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patienta.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patienta.svg";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
interface IProps {
  openModal: Boolean;
  setOpenModal: Function;
  updatePatientData: Function;
  message?: String;
  type?: String;
  url?: string;
  patientData?: any;
  antecedentsList?: [] | any;
  antecedentsChrirugicalList?: [] | any;
  setDocumentsList?: [] | any;
  documentsList?: [] | any;
  consultaionPage?: boolean;
  colorIndex?: number;
  setPatientAdded: Function;
  setSelectedPatient: Function;
  setPatientData: Function;
  setOpenModalInfoPatient: Function;
}

const PatientInfo: React.FC<IProps> = ({
  patientData,
  setOpenModal,
  consultaionPage,
  colorIndex,
  updatePatientData,
  setPatientAdded,
  setSelectedPatient,
  setPatientData,
  setOpenModalInfoPatient,
}) => {
  const [constId, setConstId] = useState("6203ded2169b5137f453363d");
  const [openTab, setOpenTab] = useState(1);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalRequest, setModalRequest] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [display, setDisplay] = useState(true);
  const [user] = useAtom<User | any>(userAtom);
  const [refreshPatientInfo] = useAtom(refreshAtom);
  const [tabs, setTabs] = useState(tabsR);
  const [editMode, setEditMode] = useState(false);
  const colorStatus = (status, delai) => {
    switch (status) {
      case "IN_PROGRESS":
        if (moment(delai).diff(moment(), "hours") > 1) {
          return "rgba(52, 211, 153)";
        } else {
          return "rgb(244 ,142 ,154)";
        }

      case "RESPONSE":
        return "rgba(52, 211, 153)";
      default:
        if (moment(delai).diff(moment(), "hours") > 1) {
          return "rgba(98 145 240)";
        } else {
          return "rgb(244 ,142 ,154)";
        }
    }
  };
  useEffect(() => {}, [openTab, refreshPatientInfo?.state]);

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      {patientData?._id ? (
        <div className="absolute  inset-y-0 right-0 z-40  patient-modal-info">
          <div
            className={`sub-container ${
              display ? "overflow-hidden" : "max-h-content"
            }`}
          >
            <div className="flex header justify-between 	">
              <div className="infos flex w-full justify-between mr-2 mt-3 ">
                <div className="flex sub-infos ">
                  <div
                    className="photo"
                    style={{
                      backgroundColor:
                        colorAvatar[(colorIndex || 1) % colorAvatar.length],
                    }}
                  >
                    {patientData?.gender === "male" ? (
                      <ManPatient />
                    ) : (
                      <WomanPatient />
                    )}
                  </div>

                  <div className="details ">
                    <div className="flex justify-between">
                      <div className="">
                        <p className="font-bold text-lg capitalize">
                          {patientData.firstName} {patientData.lastName}
                        </p>
                        <div className="flex birthPatient">
                          <div className="sub-birth flexCenter img">
                            <img src={birthday} alt="birthday" />
                          </div>
                          <div className="">
                            <p>
                              {moment(patientData.birthday).format("L")} (
                              {moment(Date.now()).year() -
                                moment(patientData.birthday).year()}{" "}
                              ans)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between flex-wrap  phonemail">
                      <div className="flex">
                        <div className="flexCenter img">
                          <img
                            src={phone}
                            alt="phone"
                            width="15.5px"
                            height="15.5px"
                          />
                        </div>
                        <div>{patientData.phone}</div>
                      </div>
                      <div className="flex mail">
                        <div className="flexCenter img">
                          <img
                            src={mail}
                            alt="mail"
                            width="15.5px"
                            height="15.5px"
                          />
                        </div>
                        <div>
                          {patientData.email ? patientData.email : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex">
                        <div className="flexCenter img">
                          <img
                            src={home}
                            alt="home"
                            width="14.64px"
                            height="14.64px"
                          />
                        </div>
                        <div>
                          {patientData.adresse ? patientData.adresse : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {!consultaionPage && (
                    <Button
                      status="icon-secondary"
                      onClick={() => setOpenModal(false)}
                      className="absolute top-1 right-1 "
                    >
                      <Close className="absolute top-1 right-1 " />
                    </Button>
                  )}
                  <div className=" flex flex-col mt-2 justify-between">
                    <span
                      className="w-fit h-fit  icon-secondary cursor-pointer rounded-xl p-2 mr-3"
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      <img
                        src={require(`../../assets/images/pen.png`).default}
                        alt="katomi"
                        className="w-6 h-6 "
                      />
                      <p className="-mt-1 text-xs font-bold pt-1"> Editer</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={`patient-tabs ${!display ? "stretch" : null}`}>
              <div className="tabs flex items-center justify-between	">
                {tabs.map((tab) => {
                  return (
                    <div
                      className={
                        openTab === tab.id
                          ? "tab selected flexCenter"
                          : "tab flexCenter cursor-pointer"
                      }
                      onClick={() => {
                        setOpenTab(tab.id);
                        if (tab.id === 0 || tab.id === 3) {
                          setDisplay(true);
                        } else if (tab.id === 1 || tab.id === 2) {
                          setDisplay(true);
                        }
                      }}
                    >
                      {tab.name}
                    </div>
                  );
                })}
              </div>
              <div></div>
            </div>
            <div className="relative tabs-content">
              {openTab !== 3 ? (
                <div></div>
              ) : openTab === 3 ? undefined : (
                <div></div>
              )}
              <div className="tabs-container">
                <div className="tab-content tab-space tabs-liste">
                  <div
                    className={classNames(
                      "item",
                      openTab === 0 ? "block" : "hidden"
                    )}
                    id=""
                  >
                    <div className="sub-item">
                      <div className="title-container">
                        <p className="title">Actions Rapide Patient</p>
                        <span className="card-subtitle">
                          Inscrit depuis :{" "}
                          {moment(patientData.createdAt).format("L")}
                        </span>
                      </div>
                      <div className="flex values-patient">
                        <div className="flexCenter sub-values"></div>
                        <div className="flexCenter sub-values"></div>
                      </div>
                    </div>
                  </div>
                  <div className={openTab === 1 ? "block" : "hidden"} id="">
                    <div className="flex flex-col">
                      {user.type !== "medecinResponsable" && (
                        <div className="my-3">
                          <Button
                            status="secondary"
                            className=""
                            width={"146px"}
                            height={"35px"}
                            onClick={() => {
                              setModalAdd(true);
                            }}
                          >
                            Téléexpertise
                          </Button>
                        </div>
                      )}

                      {user.type === "medecin" ? (
                        <>
                          <ul className="flex flex-col">
                            {patientData.demandes.filter(function (e) {
                              return (
                                e.docReq === user._id ||
                                patientData?.authorizedDoctors?.includes(
                                  user._id
                                )
                              );
                            }).length > 0 ? (
                              <>
                                {patientData.demandes
                                  .filter(function (e) {
                                    return (
                                      e.docReq === user._id ||
                                      patientData?.authorizedDoctors?.includes(
                                        user._id
                                      )
                                    );
                                  })
                                  .reverse()
                                  .map((d) => (
                                    <div
                                      className="card-specialist  p-3 space-x-3 max-w-max shadow relative"
                                      onClick={() => {
                                        setSelectedRequest(d);
                                        setModalRequest(true);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="flex">
                                        <div
                                          className="rounded-lg h-14 w-14 flexCenter mx-5 my-auto"
                                          style={{
                                            backgroundColor:
                                              colorAvatar[
                                                Math.floor(
                                                  Math.random() *
                                                    colorAvatar.length
                                                )
                                              ],
                                            opacity: 0.7,
                                          }}
                                        >
                                          {d.type ===
                                          "Dermatose non tumorale" ? (
                                            <img
                                              src={telex}
                                              alt="telex"
                                              width={"45px"}
                                              height={"45px"}
                                            />
                                          ) : (
                                            <img
                                              src={telex}
                                              alt="telex"
                                              width={"45px"}
                                              height={"45px"}
                                            />
                                          )}
                                        </div>
                                        <div className="details">
                                          <div className="flex-grow">
                                            <p className="card-title">
                                              Demande de téléexpertise en
                                              {" " + d.specialite}
                                            </p>
                                            <p className="font-bold text-gray-700">
                                              {" "}
                                              Type :{" " + d.type}
                                            </p>
                                            <p className=" w-64 font-bold text-sm    ">
                                              {statusLabel(
                                                d.status,
                                                d.delaiDate,
                                                user?.type,
                                                d.priseEncharge
                                              )}
                                            </p>
                                            <span
                                              className={`rounded-full w-3 h-3 absolute right-2 top-2  `}
                                              style={{
                                                backgroundColor: colorStatus(
                                                  d.status,
                                                  d.delaiDate
                                                ),
                                              }}
                                            ></span>
                                            <p className="card-sub-title text-xs absolute right-2 m-2 mt-0">
                                              {" le "}
                                              {moment(d.createdAt).format(
                                                "LL à HH:mm"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex space-x-3"></div>
                                    </div>
                                  ))}
                              </>
                            ) : (
                              <div className="flexCenter w-full anEmptyBox">
                                <p className="card-subtitle">
                                  Ce patient n'a aucune demande de
                                  téléexpertise.
                                </p>
                              </div>
                            )}
                          </ul>
                        </>
                      ) : (
                        <div className="container flex flex-col mx-auto w-full  ">
                          <ul className="flex flex-col">
                            {patientData.demandes.length > 0 ? (
                              <>
                                {patientData.demandes
                                  .filter(function (e) {
                                    return e;
                                  })
                                  .reverse()
                                  .map((d) => (
                                    <div
                                      className="card-specialist  p-3 space-x-3 max-w-max shadow relative"
                                      onClick={() => {
                                        setSelectedRequest(d);
                                        setModalRequest(true);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="flex">
                                        <div
                                          className="rounded-lg h-14 w-14 flexCenter mx-5 my-auto"
                                          style={{
                                            backgroundColor:
                                              colorAvatar[
                                                Math.floor(
                                                  Math.random() *
                                                    colorAvatar.length
                                                )
                                              ],
                                            opacity: 0.7,
                                          }}
                                        >
                                          {d.type ===
                                          "Dermatose non tumorale" ? (
                                            <img
                                              src={telex}
                                              alt="telex"
                                              width={"45px"}
                                              height={"45px"}
                                            />
                                          ) : (
                                            <img
                                              src={telex}
                                              alt="telex"
                                              width={"45px"}
                                              height={"45px"}
                                            />
                                          )}
                                        </div>
                                        <div className="details">
                                          <div className="flex-grow">
                                            <p className="card-title">
                                              Demande de téléexpertise en
                                              {" " + d.specialite}
                                            </p>
                                            <p className="font-bold text-gray-700">
                                              {" "}
                                              Type :{" " + d.type}
                                            </p>
                                            <p className=" w-64 font-bold text-sm    ">
                                              {statusLabel(
                                                d.status,
                                                d.delaiDate,
                                                user?.type,
                                                d.priseEncharge
                                              )}
                                            </p>
                                            <span
                                              className={`rounded-full w-3 h-3 absolute right-2 top-2  `}
                                              style={{
                                                backgroundColor: colorStatus(
                                                  d.status,
                                                  d.delaiDate
                                                ),
                                              }}
                                            ></span>
                                            <p className="card-sub-title text-xs absolute right-2 m-2 mt-0">
                                              {" le "}
                                              {moment(d.createdAt).format(
                                                "LL à HH:mm"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex space-x-3"></div>
                                    </div>
                                  ))}
                              </>
                            ) : (
                              <div className="flexCenter w-full anEmptyBox">
                                <p className="card-subtitle">
                                  Ce patient n'a aucune demande de
                                  téléexpertise.
                                </p>
                              </div>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={openTab === 5 ? "block" : "hidden"} id="">
                    <div className="flexCenter w-full anEmptyBox">
                      <p className="card-subtitle">
                        Ce patient n'a aucun historique.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute  inset-y-0 right-0 z-40  transition ease-in duration-700 patient-modal-info">
          <Spinner />
        </div>
      )}

      {modalAdd && (
        <AddRequestModal
          patientId={patientData._id}
          openModal={modalAdd}
          constId={constId}
          setPatientAdded={setPatientAdded}
          setOpenModal={setModalAdd}
          gender={patientData.gender}
        />
      )}
      {editMode && (
        <AddPatient
          editMode={true}
          openModal={editMode}
          setOpenModal={setEditMode}
          setPatientAdded={setPatientAdded}
          setSelectedPatient={setSelectedPatient}
          setPatientData={setPatientData}
          patientData={patientData}
          setOpenModalInfoPatient={() => console.log("")}
        />
      )}
      {modalRequest && (
        <SharedRequest
          demandeData={selectedRequest}
          setOpenModal={setModalRequest}
          colorIndex={0}
          consultaionPage={false}
        />
      )}
    </div>
  );
};

export default PatientInfo;
