import React, { useEffect, useState } from "react";
import Button from "../../components/ButtonUI";
import Input from "../../components/Input";
import "../Signin/stylesSignin.scss";
import "./styles.scss";
import * as Yup from "yup";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import { inscriptionDoctor } from "../../api/medecin";
import history from "../../history";
import makeToast from "../../components/Snackbar";
import { FormikWizard } from "../../components/FormikWizard";
import StripePaymentMethod from "../../components/StripePaymentMethod";
import { logout } from "../../api/auth";
import validationSchema from "./validation";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as Download } from "../../assets/illustrations/6421887411543238876.svg";
import Signup from "./sinUp";
import { createCustomerId } from "../../api/stripe";
import { ReactComponent as Pay } from "../../assets/illustrations/pay.svg";
import ConditionPolitique from "./ConditionPolitique";
import { retriveGroup } from "../../api/groups";
import Spinner from "../../components/Spinner";
interface Props {
  openModal: boolean;
  setOpenModal: Function;
  setOpenModalAddPatient: Function;
}

export const SpecialRegistre: React.FC<Props> = ({}) => {
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = useState(true);
  const [groupData, setGroupData] = useState("") as any;
  const ProgressBar = (progress: number) => {
    const progressPercentage = (100 / 3) * (progress + 1);

    return (
      <div className=" h-2 w-full bg-gray-300 border rounded-md">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={`h-full ${
            progressPercentage < 75 ? "bg-indigo-500" : "bg-green-300"
          }`}
        ></div>
      </div>
    );
  };
  //extract to global function
  const handleLogout = async () => {
    try {
      await logout(user);
      window.location.href = "/";
      localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      localStorage.clear();
      console.error(error);
    }
  };

  const createCustomerIdReq = async (values) => {
    try {
      const res = await createCustomerId(values);
      if (res) {
        return res.data.stripeCustomerId;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // extract to class components 3 steps
  // declare intial value in constants file
  const { groupId } = useParams() as any;
  async function retriveGroupMyAPI() {
    const res = await retriveGroup(groupId);
    setGroupData(res?.response);
    setLoading(false);
    return await res;
  }
  useEffect(() => {
    retriveGroupMyAPI();
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <div className="doctor-signup flexColCenter min-h-screen flex justify-center">
      <div className=" z-40 top-8 flexColCenter firstModal2 ">
        <h2>Bienvenue sur AvisDoc 👋 </h2>
        <div className="m-2 w-full">
          <ul className="stepper" data-mdb-stepper="stepper">
            <FormikWizard
              initialValues={{
                politique: "",
                condition: "",
                QZQP001: "",
                subscriptionId: "",
                stripeSubscriptionId: "",
                email: "",
                firstName: "",
                lastName: "",
                type: "",
                password: "",
                passwordConfirmation: "",
                customerId: "",
                numCom: "",
                numRPPS: "",
                city: "",
                address: "",
                codePostale: "",
                phone: "",
                speciality: "",
                gender: "",
                birthday: "",
                country: "France",
                newPassword: false,
                departement: "",
                groupId: groupId,
                freeAccount:
                  groupData?.freeRegistration !== true ? false : true,
              }}
              onSubmit={async (values) => {
                const { politique, condition, QZQP001 } = values;
                
                values.stripeSubscriptionId = values.subscriptionId;
                values.agreedToCGU = condition === "oui";
                values.agreedToConfidentialityPolicy = politique === "oui";
                values.agreedToDermatoscopyPolicy = QZQP001 === "oui";
                if (values.subscriptionId === "") {
                  makeToast(
                    "error",
                    "Échec de l'enregistrement de l'abonnement !"
                  );
                } else {
                  const res = await inscriptionDoctor(values);
                  if (res) {
                    history.push("/login");
                    makeToast("success", "Vous êtes inscrit avec succès ! ");
                  } else {
                    makeToast(
                      "error",
                      "L'inscription a échoué. Vérifiez vos informations"
                    );
                  }
                }
              }}
              validateOnNext
              activeStepIndex={0}
              steps={[
                {
                  component: ({
                    errors,
                    values,
                    handleChange,
                    setFieldValue,
                  }) => (
                    <>
                      <div className="stepper-content text-justify text-sm mb-4 ">
                        En vous inscrivant, vous reconnaissez avoir pris
                        connaissance des Conditions Générales d’Utilisation et
                        de la Politique de protection des données personnelles
                        de Avisdoc et en accepter les termes.<br></br> Avisdoc,
                        responsable du traitement, a besoin de traiter vos
                        données pour créer et administrer votre compte dans le
                        cadre de votre usage de sa plateforme de téléexpertise,
                        pour assurer son bon fonctionnement et sa sécurité, pour
                        réaliser des enquêtes de satisfaction, des statistiques,
                        des études et autres actions de promotion sur
                        l’utilisation de ses services et pour répondre à vos
                        demandes. Tous les champs doivent obligatoirement être
                        remplis, faute de quoi nous ne serions pas en mesure de
                        satisfaire votre demande. La création de votre compte et
                        l’utilisation de la plateforme sont basées sur votre
                        consentement, ou sur les intérêts légitimes d’Avisdoc ou
                        sur le respect de ses obligations légales. Selon les
                        conditions applicables, vous pouvez modifier et accéder
                        à vos données, demander leur effacement ou leur
                        portabilité, exercer votre droit d’opposition ou de
                        limitation du traitement, et définir des directives
                        quant au sort de vos données, directement grâce aux
                        paramètres de votre compte ou par courriel à{" "}
                        <i className="font-bold">contact@avisdoc.co</i>.
                        <br></br> Lorsqu’il est la base légale applicable, vous
                        pouvez retirer à tout moment votre consentement au
                        traitement de vos données. Pour plus d’information, nous
                        vous invitons à consulter notre Politique de protection
                        des données personnelles.
                      </div>
                      <div className="flex flexCenter space-x-5  my-2 mx-4 ">
                        <Link
                          className="flex  text-indigo-500 space-x-1   text-xs  "
                          to={"./avisdocConditions.pdf"}
                          target="_parent"
                          download="avisdocConditions.pdf"
                        >
                          <Download className="w-4 text-indigo-600 " />
                          <p>Conditions générales d’utilisation</p>
                        </Link>

                        <Link
                          className=" flex space-x-1 text-indigo-500  text-xs  "
                          to={"./avisdocPolitique.pdf"}
                          target="_blank"
                          download="avisdocPolitique.pdf"
                        >
                          <Download className="w-4 text-indigo-600   " />
                          <p>
                            {" "}
                            Politique de protection des données personnelles
                          </p>
                        </Link>
                      </div>
                      <Input
                        name="politique"
                        type="checkbox"
                        onChange={(e) => {
                          if (values.politique === "oui") {
                            setFieldValue("politique", "");
                          } else {
                            setFieldValue("politique", e.target.value);
                          }
                        }}
                        value="oui"
                        labelText="Je déclare avoir pris connaissance de la Politique de protection des données personnelles et accepte que mes données  soient collectées et traitées par Avisdoc selon ses termes. "
                        checked={values.politique === "oui"}
                        labelProps={{
                          className: "check2 text-sm m-2",
                        }}
                        stylesDiv={""}
                      />
                      <Input
                        name="condition"
                        type="checkbox"
                        onChange={(e) => {
                          if (values.condition === "oui") {
                            setFieldValue("condition", "");
                          } else {
                            setFieldValue("condition", e.target.value);
                          }
                        }}
                        value="oui"
                        labelText="Je déclare avoir pris connaissance et accepter les Conditions Générales d’Utilisation. "
                        checked={values.condition === "oui"}
                        labelProps={{
                          className: "check2 text-sm  m-2 mb-3",
                        }}
                        stylesDiv={""}
                      />
                      <Input
                        name="QZQP001"
                        type="checkbox"
                        onChange={(e) => {
                          if (values.QZQP001 === "oui") {
                            setFieldValue("QZQP001", "");
                          } else {
                            setFieldValue("QZQP001", e.target.value);
                          }
                        }}
                        value="oui"
                        labelText="Je m'engage à ne pas utiliser la téléexpertise pour surcôter mes actes en utilisant de manière indue le code QZQP001 de dermatoscopie."
                        checked={values.QZQP001 === "oui"}
                        labelProps={{
                          className: "check2 text-sm  m-2 mb-3",
                        }}
                        stylesDiv={""}
                      />
                    </>
                  ),
                  validationSchema: Yup.object().shape({
                    condition: Yup.string().required("required"),
                    politique: Yup.string().required("required"),
                    QZQP001: Yup.string().required("required"),
                  }),
                },
                {
                  component: ({
                    errors,
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    touched,
                  }) => (
                    <Signup
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  ),
                  validationSchema: validationSchema,
                },

                {
                  component: ({
                    errors,
                    values,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                  }) =>
                    values.subscriptionId === "" ? (
                      groupData?.freeRegistration !== true ? (
                        <>
                          <StripePaymentMethod
                            setFieldValue={setFieldValue}
                            auth={false}
                            values={values}
                            handleSubmitGlobal={handleSubmit}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <Pay
                            className="border-4 border-green-300 bg-gray-100 rounded-full p-5 mx-auto my-2"
                            width={200}
                            height={200}
                          />
                          <p className="flexCenter font-bold text-lg text-green-500 ">
                            Dispense de paiement{" "}
                          </p>
                        </>
                      )
                    ) : (
                      <div>
                        <Pay
                          className="border-4 border-green-300 bg-gray-100 rounded-full p-5 mx-auto my-2"
                          width={200}
                          height={200}
                        />
                        <p className="flexCenter font-bold text-lg text-green-500 ">
                          Paiement réussi
                        </p>
                      </div>
                    ),
                  validationSchema: Yup.object().shape({
                    subscriptionId: Yup.string().required("required"),
                  }),
                },
              ]}
            >
              {({
                renderComponent,
                handlePrev,
                handleNext,
                isNextDisabled,
                isPrevDisabled,
                isLastStep,
                currentStepIndex,
                values,
                setErrors,
                setFieldValue,
              }) => (
                <>
                  <div className="wrapper option-1 option-1-1">
                    {currentStepIndex
                      ? ProgressBar(currentStepIndex)
                      : ProgressBar(0)}
                    {}
                    <ol className="c-stepper">
                      <li className="c-stepper__item">
                        <h3
                          className={`c-stepper__title ${
                            currentStepIndex == 0
                              ? "text-indigo-500"
                              : "text-gray-300"
                          }`}
                        >
                          CGU et RGPD
                        </h3>
                        <p className="c-stepper__desc mb-3"></p>
                      </li>
                      <li className="c-stepper__item">
                        <h3
                          className={`c-stepper__title ${
                            currentStepIndex == 1
                              ? "text-indigo-500"
                              : "text-gray-300"
                          }`}
                        >
                          Informations personnelles
                        </h3>
                        <p className="c-stepper__desc mb-3"></p>
                      </li>
                      <li className="c-stepper__item">
                        <h3
                          className={`c-stepper__title ${
                            currentStepIndex == 2
                              ? "text-indigo-500"
                              : "text-gray-300"
                          }`}
                        >
                          Abonnement
                        </h3>
                        <p className="c-stepper__desc"></p>
                      </li>
                    </ol>
                    {renderComponent()}
                  </div>

                  <Button
                    status="primary"
                    className="float-right flexCenter mx-5 my-5"
                    width="100px"
                    height="50px"
                    type="button"
                    onClick={
                      currentStepIndex === 1
                        ? async () => {
                            const CustomerId = await createCustomerIdReq({
                              email: values.email,
                              lastName: values.lastName,
                              firstName: values.firstName,
                            });
                            if (CustomerId && CustomerId !== null) {
                              setFieldValue("stripeCustomerId", CustomerId);
                              handleNext();
                            } else if (CustomerId === null) {
                              makeToast(
                                "error",
                                "Email d'utilisateur existe déjà. Vérifiez vos informations !"
                              );
                              setErrors({ email: "email already Used" });
                            }
                          }
                        : currentStepIndex === 2
                        ? async () => {
                            const res = await inscriptionDoctor(values);
                            if (res) {
                              history.push("/login");
                              makeToast(
                                "success",
                                "Vous êtes inscrit avec succès ! "
                              );
                            } else {
                              makeToast(
                                "error",
                                "L'inscription a échoué. Vérifiez vos informations"
                              );
                            }
                          }
                        : handleNext
                    }
                    disabled={
                      isNextDisabled &&
                      (currentStepIndex !== 2 ||
                        (currentStepIndex === 2 && !groupData.freeRegistration))
                    }
                  >
                    {" "}
                    {isLastStep ? "Terminer" : "Suivant "}{" "}
                  </Button>

                  {!isPrevDisabled && (
                    <>
                      <Button
                        status="primary"
                        className="float-right flexCenter mx- 5 my-5"
                        width="100px"
                        height="50px"
                        type="button"
                        onClick={handlePrev}
                        hidden={isPrevDisabled}
                      >
                        Précédent
                      </Button>
                    </>
                  )}

                  <Button
                    status="secondary"
                    className="float-left flexCenter my-5"
                    width="100px"
                    height="50px"
                    type="button"
                    onClick={handleLogout}
                  >
                    Annuler
                  </Button>
                </>
              )}
            </FormikWizard>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SpecialRegistre;
