import React, { useState } from "react";
import "./styles.scss";
import LeaveForm from "./tabs/Leave";
import Presentiel from "./tabs/Presentiel";

export const tabsHeader = [
  {
    id: 1,
    name: "Vacations",
  },

  {
    id: 2,
    name: "Congés",
  },
];

interface Props {
  closeModal: any;
  agendaConfig: any[];
  tabIndex?: number | null | undefined;
}

const DAYS_IN_WEEK = [
  {
    day: "Lundi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "",
  },
  {
    day: "Mardi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "",
  },
  {
    day: "Mercredi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "",
  },
  {
    day: "Jeudi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "",
  },
  {
    day: "Vendredi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "",
  },
  {
    day: "Samedi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "",
  },
  {
    day: "Dimanche",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "",
  },
];

const AgendaConfigForm: React.FC<Props> = ({
  closeModal,
  agendaConfig,
  tabIndex,
}) => {
  const dataMap = () => {
    const DAYS_MAPPED = DAYS_IN_WEEK.map((d) => {
      agendaConfig.map((ag) => {
        if (ag.day === d.day) {
          d.active = true;
          d.timeRange.start = ag.rnageOfTime.start;
          d.timeRange.end = ag.rnageOfTime.end;
          d.nbreqMax = ag.nbrReqMax;
        }
      });
      return d;
    });
    return DAYS_MAPPED;
  };
  const [openTabHeader, setOpenTabHeader] = useState(1);

  React.useEffect(() => {
    if (tabIndex) setOpenTabHeader(tabIndex);
  }, [tabIndex]);

  return (
    <div className="agenda-config-form flex flex-col items-center w-full">
      <h1>Configuration de votre agenda</h1>
      <div className="tabs-Container mt-8">
        <div className="patient-tabs ">
          <div className="tabs flex items-center justify-between">
            {tabsHeader.map((tab) => {
              return (
                <div
                  className={
                    openTabHeader === tab.id
                      ? "atab selected flexCenter cursor-pointer "
                      : "atab flexCenter cursor-pointer "
                  }
                  onClick={() => setOpenTabHeader(tab.id)}
                >
                  {tab.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full">
        {openTabHeader === 1 && (
          <div className="flexColCenter mt-4">
            <Presentiel closeModal={closeModal} dataToMap={dataMap()} />
          </div>
        )}
        {openTabHeader === 2 && (
          <div className="flexCenter ">
            <LeaveForm closeModal={closeModal} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AgendaConfigForm;
