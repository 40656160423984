import { useAtom } from "jotai";
import { verifyResetToken } from "../../api/auth";
import { userAtom } from "../../state/auth";
import "./styles.scss";
import logo from "../../assets/Logo.png";
import makeToast from "../../components/Snackbar";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import Sad from "../../assets/images/Sad0.png";

const ConfirmForgetPassword: React.FC = () => {
  const params: any = useParams();
  const history: any = useHistory();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useAtom(userAtom);
  const { token, userId } = params;
  const fnverifyResetToken = async () => {
    try {
      const response = await verifyResetToken({
        tokenStr: token,
        tokenId: userId,
      });
      if (response && response.data && response.data.user) {
        setUser(response.data.user);
      } else {
        setLoading(false);
        setUser(null);
        makeToast("error", "Lien expiré 😔");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      makeToast("error", "Lien expiré 😔");
    }
  };
  useEffect(() => {
    fnverifyResetToken();
  }, []);
  return !loading ? (
    <div className="login min-h-screen flex justify-center forgetPassword">
      <div className="auth-login-Container mt-4">
        <h1 className="h1 title flexRowCenter"></h1>
        <div className="formLogin">
          <div className="">
            <div className="logo-bloc flexRowCenter">
              <img src={logo} alt="logo" />
            </div>
            <br />
            <span className="item block  py-4  overflow-y-auto rounded-b-2xl h-auto">
              <span className="text-gray-500 text-6xl block">
                <span>
                  <img src={Sad} />
                </span>
                <span className=" flexCenter text-gray-500 text-xl">
                  Lien expiré !
                </span>
              </span>
            </span>

            <div className="create-Account ">
              <p className="check-account"></p>
              <p
                className=" cursor-pointer flexCenter"
                onClick={() => history.push("/forgetPassword")}
              >
                Réinitialisez de nouveau votre mot de passe
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ConfirmForgetPassword;
