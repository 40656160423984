export const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];
export const sexe = ["man", "women"];
export const tabsR = [
  {
    id: 1,
    name: "Status",
  },
  {
    id: 2,
    name: "Patients",
  },
  {
    id: 4,
    name: "Demandes",
  },
  {
    id: 5,
    name: "Historique",
  },
  {
    id: 6,
    name: "Identification",
  },
  // { id: 5, name: "Historique" },
];

export const tabsT = [
  {
    id: 1,
    name: "Status",
  },
  { id: 3, name: "Disponibilités" },
  {
    id: 4,
    name: "Demandes",
  },
  {
    id: 5,
    name: "Historique",
  },
  {
    id: 6,
    name: "Identification",
  },

];

export const data = [
  {
    id: 1,
    name: "Taille:",
    value: "156",
    unite: "cm",
  },
  {
    id: 2,
    name: "Poids:",
    value: "56",
    unite: "kg",
  },
  {
    id: 3,
    name: "IMC:",
    value: "21.4",
  },
];
export const Ipouls = [
  {
    id: 1,
    name: "Valeur:",
    value: "56",
    unite: "/min",
  },
  {
    id: 2,
    name: "Ra pouls/T°C:",
    value: "37",
    unite: "°C",
  },
  {
    id: 3,
    value: "21.4",
    unite: "/min",
  },
];
export const temps = [
  {
    id: 1,
    name: "T°C:",
    value: "36.5",
    unite: "°C",
  },
  {
    id: 2,
    name: "Prise à:",
    value: "10:00",
    unite: "heures",
  },
];

export const taches = [
  {
    id: 1,
    img: "pen",
    isIcone: false,
    title: "Compte rendu",
    subTitle: "Mise à jour le 5 juillet 2021 à 17:00",
    details: "Dermatose non tumorale",
    to: "Rediger",
  },
  {
    id: 2,
    icone: "capsule",
    isIcone: false,
    title: "Compte rendu",
    subTitle: "Mise à jour le 5 juillet 2021 à 17:00",
    details: "Dermatose non tumorale",
    to: "Prescrire",
  },
  {
    id: 2,
    icone: "agenda",
    isIcone: false,
    title: "Rendez-vous",
    subTitle: "Mise à jour le 5 juillet 2021 à 17:00",
    details: "Dermatose non tumorale",
    to: "Modifier",
  },
];

export const compteRendu = [
  {
    id: 1,
    title: "Consultation du:",
    date: "12 Juilliet 2021",
    subTitle: "Mise à jour le 5 juillet 2021 à 17:00",
    details: "Dermatose non tumorale",
  },
  {
    id: 2,
    title: "Consultation du:",
    date: "12 Juilliet 2021",
    subTitle: "Mise à jour le 5 juillet 2021 à 17:00",
    details: "Dermatose non tumorale",
  },
];
export const TEnCours = [
  {
    id: 1,
    title: "Insuline ",
    subTitle: "Mise à jour le 12 juillet 2021 à 17:00",
    details: "Dermatose non tumorale",
    to: "+  Ajouter une observation",
  },
  {
    id: 2,
    title: "Quercetine anhydre",
    subTitle: "Mise à jour le 12 juillet 2021 à 17:00",
    to: "+  Ajouter une observation",
  },
];
export const TAncien = [
  {
    id: 1,
    title: "Insuline ",
    subTitle: "Mise à jour le 12 juillet 2021 à 17:00",
    nombreObservation: 2,
    to: "Ajouter",
  },
  {
    id: 2,
    title: "Quercetine anhydre",
    subTitle: "Mise à jour le 12 juillet 2021 à 17:00",
    nombreObservation: 5,
    to: "Ajouter",
  },
];
export const antecedentsMedicaux = [
  {
    id: 1,
    title: "Allérgie Médicamenteuse ",
    subTitle: "Mise à jour le 12 juillet 2021 à 17:00",
    nombreObservation: 2,
    to: "+  Ajouter une observation",
    medicament: ["Ibuprophène"],
  },
  {
    id: 2,
    title: "Antécédent :",
    titleHelper: "Diabète",
    subTitle: "Mise à jour le 12 juillet 2021 à 17:00",
    nombreObservation: 2,
    medicament: ["Pénicilline", "Paracétamol"],
  },
];
export const antecedentsChirurgicaux = [
  {
    id: 1,
    title: "Antécédents :",
    titleHelper: "Chirurgie Générale",
    subTitle: "Mise à jour le 12 juillet 2021 à 17:00",
    medicament: ["Pénicilline", "Paracétamol"],
    to: "+  Ajouter une observation",
  },
  {
    id: 2,
    title: "Antécédents :",
    titleHelper: "Diabète",
    subTitle: "Mise à jour le 12 juillet 2021 à 17:00",
    nombreObservation: 5,
    medicament: ["Pénicilline", "Paracétamol"],
  },
];
export const images = [
  "https://img-19.ccm2.net/cI8qqj-finfDcmx6jMK6Vr-krEw=/1500x/smart/b829396acc244fd484c5ddcdcb2b08f3/ccmcms-commentcamarche/20494859.jpg",
  "https://www.publicdomainpictures.net/pictures/320000/velka/background-image.png",
  "https://www.nature.com/immersive/d41586-021-00095-y/assets/3TP4N718ac/2021-01-xx_jan-iom_tree-of-life_sh-1080x1440.jpeg",
  "https://static.lexpress.fr/medias_11326/w_2048%2Ch_1146%2Cc_crop%2Cx_0%2Cy_119/w_640%2Ch_360%2Cc_fill%2Cg_north/v1486983788/maladie-bouillote-croix-rouge-lentreprise_5799331.jpg",
];
