import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import RecursiveContainer from "./RecursiveContainer";
import Button from "../ButtonUI";
import {
  formConfigDermatose,
  formConfigTummerCutane,
} from "../../config/formConfig";
import Compressor from "compressorjs";
import Input from "../Input";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import { refreshAtom } from "../../state/refreshPatientInfo";
import { useDropzone } from "react-dropzone";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import { remove } from "lodash";
import Select from "../Select";
import makeToast from "../Snackbar";
import {
  addDocsByPatient2,
  addDemandByReqDoctor,
  getAllAvailableExperts,
  assignDemandeToExpert,
} from "../../api/medecin";
import { getYupSchemaFromMetaData } from "./yupSchemaCreator";
import Modal from "../ModalUI";
import _ from "lodash";
import alert from "../../assets/images/alert.png";
import "./styles.scss";
import { retriveGroup } from "../../api/groups";
const specialites = [{ label: "Dermatologie", value: "dermatologie" }];
const category = [
  { label: "Dermatose non tumorale", value: "Dermatose non tumorale" },
  { label: "Tumeurs cutanees", value: "Tumeurs cutanees" },
];
export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (
    obj,
    prefix = "",
    result: any[] = []
  ) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

const RequestForm: React.FC<any> = ({
  openModal,
  setOpenModal,
  constId,
  patientId,
  gender,
  setPatientAdded,
  setUpoloadProg,
  setUpoloadProgFalse,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalPatientHasDemandInProg, setModalPatientHasDemandInProg] =
    useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [refreshPatientInfo, setRefreshPatientInfo] = useAtom(refreshAtom);
  const [files, setFiles] = useState<any>([]);
  const [takePic, setTakePic] = useState(true);
  const [progress, setProgress] = useState(0);
  const [uploadToast, setUploadToast] = useState(false);
  const [draft, setDraft] = useState(false);
  const [groupData, setGroupData] = useState("") as any;
  const [teleexpertList, setTeleexpertList] = useState<any[]>([]);
  const [previousDoctor, setPreviousDoctor] = useState<any[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  // const [loadingCompressor, setLoadingCompressor] = useState(false);
  const [confirmationData, setConfirmationData] = useState<{
    values: any;
    formConfig: any;
  }>({
    values: null,
    formConfig: null,
  });

  const format = ({ size }: Blob) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const calculate = (t: number) => {
    return (
      Math.round((performance.now() - t + Number.EPSILON) * 100) / 100 + "ms"
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/jpeg,image/jpg,image/webp",
    onDropAccepted: () => {
      // setLoadingCompressor(true);
    },
    onDrop: (acceptedFiles) => {
      let pics: any[] = [];

      acceptedFiles.map((file, i) => {
        const t0 = performance.now();
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 2048,
          maxHeight: 2048,

          success(blob) {
            console.log("compressorjs", format(blob), calculate(t0));
            pics.push(
              Object.assign(blob, {
                preview: URL.createObjectURL(blob),
              })
            );
            setFiles([...pics]);
            if (i + 1 === acceptedFiles.length) {
              // console.log("compressorjs xx");
              // setLoadingCompressor(false);
            }
          },
        });
      }) as any;
    },
  });

  const thumbs = files.map((file) => (
    <div className="thumb" key={file?.preview as any}>
      <div className="thumbInner">
        <button
          className="absolute icon-secondary2"
          onClick={() => {
            setFiles(
              remove([...files], function (n) {
                return n.preview !== file.preview;
              })
            );
          }}
        >
          <Close />
        </button>
        <img src={file.preview} alt="img drop" className="img" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      const response = await getAllAvailableExperts({
        userId: user?._id,
        patientId: patientId,
      });
      setTeleexpertList(response?.filterListOfAvailableDoctor);
      setPreviousDoctor(
        response?.ListPreviousDoctors
          ? response?.ListPreviousDoctors.map((el) => ({
              ...el,
              label:
                el.lastName +
                " " +
                el.firstName +
                " " +
                "(" +
                "a précédemment traité une demande sur ce même patient" +
                ")",
              value: el._id,
            }))
          : []
      );
    }

    fetchMyAPI();
  }, []);

  async function retriveGroupMyAPI() {
    setLoading(true);
    const res = await retriveGroup(user?.groupId);
    setGroupData(res?.response);
    setLoading(false);
    return await res;
  }
  useEffect(() => {
    retriveGroupMyAPI();
  }, []);

  const formik = useFormik({
    initialValues: {
      specialite: "",
      type: "",
      constId: constId,
      withPhotos: true,
      doctor_id: user?._id,
      patientId: patientId,
      priseEncharge: false,
      files: files,
      isDraft: draft,
      forceIt: false,
      expertId: "",
      withConsentement: false,
    },

    onSubmit: async (values) => {
      try {
        let formConfig = {};

        if (values.type === "Dermatose non tumorale") {
          formConfig = formConfigDermatose;
        } else if (values.type === "Tumeurs cutanees") {
          formConfig = formConfigTummerCutane;
        }

        values.files = files;
        values.isDraft = draft;
        if (values.files && values.files.length === 0) {
          values.withPhotos = false;
        }

        setLoading(true);
        if (values.withPhotos) {
          setUpoloadProg();
          setUploadToast(true);
        }
        if (user?.type == "medecinResponsable") {
          const response = await addDocsByPatient2(
            values,
            formConfig,
            (event: { loaded: number; total: number }) => {
              setProgress(Math.round((100 * event.loaded) / event.total));

              return event.loaded;
            }
          );
          if (response.status === "Success") {
            setDraft(false);
            setLoading(false);
            setPatientAdded(true);
            makeToast("success", response.message);
            setRefreshPatientInfo({ state: !refreshPatientInfo?.state });
            setOpenModal(false);
          } else if (response.status === "info") {
            setDraft(false);
            setUpoloadProgFalse();
            setUploadToast(false);
            setModalPatientHasDemandInProg(true);
            makeToast("info", response.message);
            setLoading(false);
          } else {
            setLoading(false);
            makeToast("error", response.message);
            setOpenModal(false);
          }
        } else if (user?.type == "medecin" && _.isEmpty(teleexpertList)) {
          const response = await addDocsByPatient2(
            values,
            formConfig,
            (event: { loaded: number; total: number }) => {
              setProgress(Math.round((100 * event.loaded) / event.total));

              return event.loaded;
            }
          );
          if (response.status === "Success") {
            setDraft(false);
            setLoading(false);
            setPatientAdded(true);
            makeToast("success", response.message);
            setRefreshPatientInfo({ state: !refreshPatientInfo?.state });
            setOpenModal(false);
          } else if (response.status === "info") {
            setDraft(false);
            setUpoloadProgFalse();
            setUploadToast(false);
            setModalPatientHasDemandInProg(true);
            makeToast("info", response.message);
            setLoading(false);
          } else {
            setLoading(false);
            makeToast("error", response.message);
            setOpenModal(false);
          }
        } else if (
          user?.type == "medecin" &&
          !_.isEmpty(teleexpertList) &&
          !formik.values.expertId
        ) {
          if (
            !formik.values.expertId &&
            !formik.values.forceIt &&
            !formik.values.isDraft
          ) {
            setConfirmationData({ values, formConfig });
            setLoading(false);
            setUpoloadProgFalse();
            setUploadToast(false);
            setShowConfirmation(true);
          } else {
            const response = await addDocsByPatient2(
              values,
              formConfig,
              (event: { loaded: number; total: number }) => {
                setProgress(Math.round((100 * event.loaded) / event.total));

                return event.loaded;
              }
            );
            if (response.status === "Success") {
              setDraft(false);
              setLoading(false);
              setPatientAdded(true);
              makeToast("success", response.message);
              setRefreshPatientInfo({ state: !refreshPatientInfo?.state });
              setOpenModal(false);
            } else if (response.status === "info") {
              setDraft(false);
              setUpoloadProgFalse();
              setUploadToast(false);
              setModalPatientHasDemandInProg(true);
              makeToast("info", response.message);
              setLoading(false);
            } else {
              setLoading(false);
              makeToast("error", response.message);
              setOpenModal(false);
            }
          }
        } else if (
          user?.type == "medecin" &&
          !_.isEmpty(teleexpertList) &&
          formik.values.expertId
        ) {
          const response = await addDemandByReqDoctor(values, formConfig);
          if (values.withPhotos) {
            setUpoloadProg();
            setUploadToast(true);
          }
          if (response.status === "Success") {
            setDraft(false);
            setLoading(false);
            setPatientAdded(true);
            makeToast("success", response.message);
            setRefreshPatientInfo({ state: !refreshPatientInfo?.state });
            setOpenModal(false);
          } else if (response.status === "info") {
            setDraft(false);
            makeToast("info", response.message);
            setLoading(false);
            setUpoloadProgFalse();
            setUploadToast(false);
            setModalPatientHasDemandInProg(true);
          } else {
            setLoading(false);
            makeToast("error", response.message);
            setOpenModal(false);
          }
        } else {
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        makeToast(
          "error",
          "Vous n'êtes pas autorisé à transmettre cette demande de téléexpertise! "
        );
        setOpenModal(false);
      }
    },

    validationSchema:
      !draft && getYupSchemaFromMetaData(formConfigDermatose, [], []),
  });
  const formik2 = useFormik({
    initialValues: {
      specialite: "dermatologie",
      type: "Tumeurs cutanees",
      constId: constId,
      withPhotos: true,
      doctor_id: user?._id,
      patientId: patientId,
      priseEncharge: false,
      files: files,
      isDraft: draft,
      forceIt: false,
      expertId: "",
      withConsentement: false,
    },
    onSubmit: async (values) => {
      try {
        let formConfig = {};

        if (values.type === "Dermatose non tumorale") {
          formConfig = formConfigDermatose;
        } else if (values.type === "Tumeurs cutanees") {
          formConfig = formConfigTummerCutane;
        }

        values.files = files;
        values.isDraft = draft;
        if (values.files && values.files.length === 0) {
          values.withPhotos = false;
        }
        setLoading(true);
        if (values.withPhotos) {
          setUpoloadProg();
          setUploadToast(true);
        }
        if (user?.type == "medecinResponsable") {
          const response = await addDocsByPatient2(
            values,
            formConfig,
            (event: { loaded: number; total: number }) => {
              setProgress(Math.round((100 * event.loaded) / event.total));

              return event.loaded;
            }
          );
          if (response.status === "Success") {
            setDraft(false);
            setLoading(false);
            setPatientAdded(true);
            makeToast("success", response.message);
            setRefreshPatientInfo({ state: !refreshPatientInfo?.state });
            setOpenModal(false);
          } else if (response.status === "info") {
            setDraft(false);
            setUpoloadProgFalse();
            setUploadToast(false);
            setModalPatientHasDemandInProg(true);
            makeToast("info", response.message);
            setLoading(false);
          } else {
            setLoading(false);
            makeToast("error", response.message);
            setOpenModal(false);
          }
        } else if (user?.type == "medecin" && _.isEmpty(teleexpertList)) {
          const response = await addDocsByPatient2(
            values,
            formConfig,
            (event: { loaded: number; total: number }) => {
              setProgress(Math.round((100 * event.loaded) / event.total));

              return event.loaded;
            }
          );
          if (response.status === "Success") {
            setDraft(false);
            setLoading(false);
            setPatientAdded(true);
            makeToast("success", response.message);
            setRefreshPatientInfo({ state: !refreshPatientInfo?.state });
            setOpenModal(false);
          } else if (response.status === "info") {
            setDraft(false);
            setUpoloadProgFalse();
            setUploadToast(false);
            setModalPatientHasDemandInProg(true);
            makeToast("info", response.message);
            setLoading(false);
          } else {
            setLoading(false);
            makeToast("error", response.message);
            setOpenModal(false);
          }
        } else if (
          user?.type == "medecin" &&
          !_.isEmpty(teleexpertList) &&
          !formik2.values.expertId
        ) {
          if (
            !formik2.values.expertId &&
            !formik2.values.forceIt &&
            !formik2.values.isDraft
          ) {
            setConfirmationData({ values, formConfig });
            setLoading(false);
            setUpoloadProgFalse();
            setUploadToast(false);
            setShowConfirmation(true);
          } else {
            const response = await addDocsByPatient2(
              values,
              formConfig,
              (event: { loaded: number; total: number }) => {
                setProgress(Math.round((100 * event.loaded) / event.total));

                return event.loaded;
              }
            );
            if (response.status === "Success") {
              setDraft(false);
              setLoading(false);
              setPatientAdded(true);
              makeToast("success", response.message);
              setRefreshPatientInfo({ state: !refreshPatientInfo?.state });
              setOpenModal(false);
            } else if (response.status === "info") {
              setDraft(false);
              setUpoloadProgFalse();
              setUploadToast(false);
              setModalPatientHasDemandInProg(true);
              makeToast("info", response.message);
              setLoading(false);
            } else {
              setLoading(false);
              makeToast("error", response.message);
              setOpenModal(false);
            }
          }
        } else if (
          user?.type == "medecin" &&
          !_.isEmpty(teleexpertList) &&
          formik2.values.expertId
        ) {
          const response = await addDemandByReqDoctor(values, formConfig);
          // const response = await addDocsByPatient2(
          //   values,
          //   formConfig,
          //   (event: { loaded: number; total: number }) => {
          //     setProgress(Math.round((100 * event.loaded) / event.total));
          //     return event.loaded;
          //   }
          // );
          if (response.status === "Success") {
            setDraft(false);
            setLoading(false);
            setPatientAdded(true);
            makeToast("success", response.message);
            setRefreshPatientInfo({ state: !refreshPatientInfo?.state });
            setOpenModal(false);
          } else if (response.status === "info") {
            setDraft(false);
            setUpoloadProgFalse();
            setUploadToast(false);
            setModalPatientHasDemandInProg(true);
            makeToast("info", response.message);
            setLoading(false);
          } else {
            setLoading(false);
            makeToast("error", response.message);
            setOpenModal(false);
          }
        } else {
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        makeToast(
          "error",
          "Vous n'êtes pas autorisé à transmettre cette demande de téléexpertise! "
        );
        setOpenModal(false);
      }
    },

    validationSchema: !draft
      ? getYupSchemaFromMetaData(formConfigTummerCutane, [], [])
      : null,
  });

  console.log("🚀 ~ file: Container.tsx:80 ~ draft:", draft);

  return !uploadToast ? (
    <>
      <form
        className="w-3/4 max-w-lg mt-4 space-y-4"
        onSubmit={
          formik.values.type === "Dermatose non tumorale"
            ? formik.handleSubmit
            : formik2.handleSubmit
        }
      >
        <div className="flex flex-col w-full text-base">
          Sélectionner la spécialité correspondant à votre demande :
          <Select
            value={formik.values.specialite}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            id="specialite"
            text={"Sélectionner  une spécialité"}
            className="relative mb-2 mt-2"
            options={specialites}
            required={true}
            style={{ cursor: "pointer" }}
          ></Select>
          {formik.values.specialite === "dermatologie" && (
            <>
              Sélectionner le type de lésion correspondant :
              <Select
                value={formik.values.type}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                id="type"
                text={"Type de lésion"}
                className="relative mb-2 mt-2"
                options={category}
                required={true}
                style={{ cursor: "pointer" }}
              ></Select>
            </>
          )}
        </div>

        {formik.values.specialite === "dermatologie" &&
          formik.values.type === "Dermatose non tumorale" && (
            <>
              <RecursiveContainer
                genre={gender}
                config={formConfigDermatose}
                formik={formik}
              />

              {takePic && (
                <section
                  style={{
                    cursor: "pointer",
                    marginLeft: "-20px",
                  }}
                  className="addDoc"
                >
                  <div
                    {...getRootProps({
                      className: "dropzone flexCenter",
                    })}
                  >
                    <input {...getInputProps()} />
                    {/* {loadingCompressor ? (
                      <p>Chargement d'images...</p>
                    ) : ( */}
                      <p>
                        Glissez et déposez les images ou cliquez ici.<br></br>
                        <span className="text-sm text-gray-400">
                          Formats acceptés (.png , .jpg , jpeg , .webp)
                        </span>
                      </p>
                    {/* )} */}
                  </div>
                  <aside className="thumbsContainer gap-4 mb-5">{thumbs}</aside>
                </section>
              )}
              {files && files.length === 0 && (
                <Input
                  id="withPhotos"
                  value={`${formik.values.withPhotos}`}
                  type={"checkbox"}
                  className="m-1"
                  labelText={"Ajouter les photos plus tard"}
                  onChange={() => {
                    setTakePic(!takePic);
                    formik.values.withPhotos = !takePic;
                  }}
                  onBlur={formik.handleBlur}
                  style={{ cursor: "pointer" }}
                />
              )}

              <Input
                id="withConsentement"
                value={`${formik.values.withConsentement}`}
                type={"checkbox"}
                className="m-1"
                labelText={"J'ai bien le consentement du patient"}
                onChange={(e) => {
                  formik.setFieldValue("withConsentement", e.target.checked);
                }}
                onBlur={formik.handleBlur}
                style={{ cursor: "pointer" }}
              />

              {(user?.type === "medecin" ||
                user?.type === "medecinResponsable") &&
              _.isEmpty(teleexpertList) ? (
                <>
                  <img
                    src={alert}
                    alt="alerte"
                    className="absolute top-35 left-8"
                    width="40"
                  />
                  <p
                    style={{
                      fontSize: "17px",
                      color: "#4565f6",
                      textAlign: "justify",
                    }}
                  >
                    {user?.groupId ? (
                      <>
                        {" "}
                        Aucun expert n'est disponible dans votre groupe, votre
                        demande sera transmise à un médecin expert de notre
                        réseau.
                      </>
                    ) : (
                      <>
                        {" "}
                        Aucun expert n'est disponible dans votre département,
                        votre demande sera transmise à un médecin expert de
                        notre réseau.
                      </>
                    )}
                  </p>
                </>
              ) : (
                <div className="flex flex-col w-full">
                  <Select
                    id="expertId"
                    value={formik.values.expertId}
                    onChange={formik.handleChange}
                    text={"Sélectionner un téléexpert"}
                    className="relative mb-2 mt-2"
                    //disabled={_.isEmpty(teleexpertList)}
                    options={
                      _.isEmpty(previousDoctor)
                        ? teleexpertList?.map((e) => ({
                            value: e._id,
                            label:
                              e.lastName +
                              " " +
                              e.firstName +
                              " " +
                              "(" +
                              e.departement +
                              ")",
                          }))
                        : teleexpertList
                            ?.map((e) => ({
                              value: e._id,
                              label:
                                e.lastName +
                                " " +
                                e.firstName +
                                " " +
                                "(" +
                                e.departement +
                                ")",
                            }))
                            .concat(previousDoctor)
                    }
                  ></Select>
                  {formik.values.expertId ? null : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={alert} alt="alerte" width="40" />
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#4565f6",
                          textAlign: "justify",
                        }}
                      >
                        {user?.groupId ? (
                          groupData?.doctorId?.length === 1 ? (
                            <>
                              Aucun expert de votre département n'a été
                              sélectionné, votre demande sera transmise à un
                              médecin expert de notre réseau national.{" "}
                            </>
                          ) : (
                            <>
                              Aucun expert de votre groupe n'a été sélectionné,
                              votre demande sera transmise à un médecin expert
                              de notre réseau national.
                            </>
                          )
                        ) : (
                          <>
                            Aucun expert de votre département n'a été
                            sélectionné, votre demande sera transmise à un
                            médecin expert de notre réseau national.
                          </>
                        )}
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="relative mt-2  ml-2 mr-2 ">
                <Button
                  status="primary"
                  className="float-right flexCenter mb-5"
                  width="200px"
                  height="50px"
                  disabled={
                    !formik.isValid ||
                    formik.values.withConsentement == false ||
                    (takePic && files.length == 0) ||
                    loading
                  }
                >
                  {loading && !draft ? (
                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                  ) : (
                    "Envoyer la demande"
                  )}
                </Button>

                <Button
                  status="secondary"
                  className=" flexCenter mb-5"
                  width="200px"
                  height="50px"
                  type="submit"
                  disabled={loading}
                  onClick={() => setDraft(true)}
                >
                  {loading && draft ? (
                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                  ) : (
                    "Enregistrer brouillon "
                  )}
                </Button>
              </div>
            </>
          )}
        {formik.values.specialite === "dermatologie" &&
          formik.values.type === "Tumeurs cutanees" && (
            <>
              <RecursiveContainer
                genre={gender}
                config={formConfigTummerCutane}
                formik={formik2}
              />

              {takePic && (
                <section
                  className=" addDoc"
                  style={{ cursor: "pointer", marginLeft: "-20px" }}
                >
                  <div
                    {...getRootProps({
                      className: "dropzone flexCenter",
                    })}
                  >
                    <input {...getInputProps()} />
                    {/* {loadingCompressor ? (
                      <p>Chargement...</p>
                    ) : ( */}
                      <p>
                        Glissez et déposez les images ou cliquez ici.<br></br>
                        <span className="text-sm text-gray-400">
                          Formats acceptés (.png , .jpg , jpeg , .webp)
                        </span>
                      </p>
                    {/* )} */}
                  </div>
                  <aside className="thumbsContainer gap-4 mb-5">{thumbs}</aside>
                </section>
              )}
              {files && files.length === 0 && (
                <Input
                  id="withPhotos"
                  value={`${formik2.values.withPhotos}`}
                  type={"checkbox"}
                  className="m-1"
                  labelText={"Ajouter les photos plus tard"}
                  onChange={() => {
                    setTakePic(!takePic);
                    formik2.values.withPhotos = !takePic;
                  }}
                  onBlur={formik.handleBlur}
                />
              )}
              <Input
                id="withConsentement"
                value={`${formik2.values.withConsentement}`}
                type={"checkbox"}
                className="m-1"
                labelText={"J'ai bien le consentement du patient"}
                onChange={(e) => {
                  formik2.setFieldValue("withConsentement", e.target.checked);
                }}
                onBlur={formik2.handleBlur}
                style={{ cursor: "pointer" }}
              />
              {(user?.type === "medecin" ||
                user?.type === "medecinResponsable") &&
              _.isEmpty(teleexpertList) ? (
                <>
                  <img
                    src={alert}
                    alt="alerte"
                    className="absolute top-35 left-8"
                    width="40"
                  />
                  <p
                    style={{
                      fontSize: "17px",
                      color: "#4565f6",
                      textAlign: "justify",
                    }}
                  >
                    {user?.groupId ? (
                      <>
                        {" "}
                        Aucun expert n'est disponible dans votre groupe, votre
                        demande sera transmise à un médecin expert de notre
                        réseau.
                      </>
                    ) : (
                      <>
                        {" "}
                        Aucun expert n'est disponible dans votre département,
                        votre demande sera transmise à un médecin expert de
                        notre réseau.
                      </>
                    )}
                  </p>
                </>
              ) : (
                <div className="flex flex-col w-full">
                  <Select
                    id="expertId"
                    value={formik2.values.expertId}
                    onChange={formik2.handleChange}
                    text={"Sélectionner  un téléexpert"}
                    className="relative mb-2 mt-2"
                    //disabled={_.isEmpty(teleexpertList)}
                    options={
                      _.isEmpty(previousDoctor)
                        ? teleexpertList?.map((e) => ({
                            value: e._id,
                            label:
                              e.lastName +
                              " " +
                              e.firstName +
                              " " +
                              "(" +
                              e.departement +
                              ")",
                          }))
                        : teleexpertList
                            ?.map((e) => ({
                              value: e._id,
                              label:
                                e.lastName +
                                " " +
                                e.firstName +
                                " " +
                                "(" +
                                e.departement +
                                ")",
                            }))
                            .concat(previousDoctor)
                    }
                    //required={true}
                  ></Select>
                  {formik2.values.expertId ? null : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={alert} alt="alerte" width="40" />
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#4565f6",
                          textAlign: "justify",
                        }}
                      >
                        {user?.groupId ? (
                          groupData?.doctorId?.length === 1 ? (
                            <>
                              Aucun expert de votre département n'a été
                              sélectionné, votre demande sera transmise à un
                              médecin expert de notre réseau national.{" "}
                            </>
                          ) : (
                            <>
                              Aucun expert de votre groupe n'a été sélectionné,
                              votre demande sera transmise à un médecin expert
                              de notre réseau national.
                            </>
                          )
                        ) : (
                          <>
                            Aucun expert de votre département n'a été
                            sélectionné, votre demande sera transmise à un
                            médecin expert de notre réseau national.
                          </>
                        )}
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="relative mt-2  ml-2 mr-2 ">
                <Button
                  status="primary"
                  className="float-right flexCenter mb-5"
                  width="200px"
                  height="50px"
                  disabled={
                    !draft &&
                    (!formik2.isValid ||
                      formik2.values.withConsentement == false ||
                      (takePic && files.length == 0) ||
                      loading)
                  }
                >
                  {loading && !draft ? (
                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                  ) : (
                    "Envoyer la demande"
                  )}
                </Button>
                <div className=" buttom-0">
                  <Button
                    status="secondary"
                    className=" flexCenter mb-5"
                    width="200px"
                    height="50px"
                    type="submit"
                    disabled={loading}
                    onClick={() => {
                      setDraft(true);
                    }}
                  >
                    {loading && draft ? (
                      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                    ) : (
                      "Enregistrer brouillon "
                    )}
                  </Button>
                </div>
              </div>
            </>
          )}
      </form>

      {
        <Modal
          showModal={showConfirmation}
          closeModal={() => setShowConfirmation(false)}
        >
          <div className="p-3">
            <h3 className="text-black text-center">
              Vous n'avez sélectionné aucun expert sur la liste. Êtes-vous sûr
              de vouloir continuer?
            </h3>
            <div className="flexCenter mt-6 buttons gap-x-6">
              <Button
                status="secondary"
                width={"119px"}
                height={"50px"}
                onClick={() => setShowConfirmation(false)}
              >
                Annuler
              </Button>
              <Button
                status="secondary"
                width={"119px"}
                height={"50px"}
                onClick={() => {
                  addDocsByPatient2(
                    confirmationData.values,
                    confirmationData.formConfig,
                    (event: { loaded: number; total: number }) => {
                      setProgress(
                        Math.round((100 * event.loaded) / event.total)
                      );
                      return event.loaded;
                    }
                  )
                    .then((response) => {
                      console.log(response);
                      if (response.status === "Success") {
                        setDraft(false);
                        setLoading(false);
                        setPatientAdded(true);
                        makeToast("success", response.message);
                        setRefreshPatientInfo({
                          state: !refreshPatientInfo?.state,
                        });
                        setOpenModal(false);
                      } else if (response.status === "info") {
                        setDraft(false);
                        setUpoloadProgFalse();
                        setUploadToast(false);
                        setModalPatientHasDemandInProg(true);
                        setShowConfirmation(false);
                        makeToast("info", response.message);
                        setLoading(false);
                      } else {
                        setLoading(false);
                        makeToast("error", response.message);
                        setOpenModal(false);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }}
              >
                Confirmer
              </Button>
            </div>
          </div>
        </Modal>
      }

      <Modal
        showModal={modalPatientHasDemandInProg}
        closeModal={() => setModalPatientHasDemandInProg(false)}
      >
        <div className="p-3 ">
          <h3 className="flexCenter text-black">
            Ce patient a déjà une demande de téléexpertise en cours. <br></br>{" "}
          </h3>
          <h3 className="flexCenter text-black">
            Souhaitez-vous confirmer l'envoi de cette nouvelle demande de
            téléexpertise?
          </h3>
          <div className="flexCenter mt-6 buttons gap-x-6">
            <Button
              status="secondary"
              width={"119px"}
              height={"50px"}
              onClick={() => setModalPatientHasDemandInProg(false)}
            >
              Annuler
            </Button>
            <Button
              status="secondary"
              type="button"
              width={"119px"}
              height={"50px"}
              onClick={() => {
                if (formik.values.type === "Dermatose non tumorale") {
                  formik.setFieldValue("forceIt", true);
                  formik.handleSubmit();
                } else if (formik.values.type === "Tumeurs cutanees") {
                  formik2.setFieldValue("forceIt", true);
                  formik2.handleSubmit();
                } else {
                }
              }}
            >
              Confirmer
            </Button>
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <div className="w-full px-6  ">
      <div className="font-bold text-gray-700 ">
        Téléchargement d'images en cours...
      </div>
      <div className="w-full  bg-gray-300 rounded-full ">
        <div
          className={`${
            progress == 0 ? "" : "bg-Blue-Primary"
          } text-xs font-medium text-white text-center px-0.5 leading-none rounded-full`}
          style={{ width: `${progress}%` }}
        >
          {progress}%
        </div>
      </div>
    </div>
  );
};

export default RequestForm;
