import React, { useEffect, useState } from "react";
import { loadImage } from "../../api/medecin";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import Button from "../ButtonUI";
import Spinner from "../Spinner";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface IProps {
  imageData: object | any;
  closeModal: Function;
  patientId: string;
}

const ImageModal: React.FC<IProps> = ({ imageData, closeModal }) => {
  const { url, type, fileInfo } = imageData;
  const { specialite, patientId, filePath } = fileInfo;
  const [parsedImage, setParsedImage] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(true);
  const parseImage = () => ({
    original: loadImage(specialite, patientId, "lossless", filePath),
  });
  const parseImagePreview = () => ({
    original: loadImage(specialite, patientId, "preview", filePath),
  });

  useEffect(() => {
    setParsedImage(parseImage());

    if (parsedImage) {
    }
  }, [imageData]);

  return (
    <div className="relative">
      {parsedImage ? (
        <>
          <div className="absolute top-0 right-0 z-50 ">
            <Button status="icon-secondary" onClick={() => closeModal()}>
              <Close />
            </Button>
          </div>
          <div className="row  d-flex justify-content-center py-12 px-20">
            <div className="col-lg-8 text-center text-dark ">
              <TransformWrapper
                doubleClick={{ disabled: false, mode: "zoomIn" }}
                // initialScale={1}
                // initialPositionX={1}
                // initialPositionY={1}
              >
                <TransformComponent
                  wrapperStyle={
                    loader
                      ? {
                          background: "#f5f5f9",
                          borderRadius: "10px",
                          cursor: "zoom-in",
                        }
                      : {
                          border: "1px solid gray",
                          background: "#f5f5f9",
                          borderRadius: "10px",
                          cursor: "zoom-in",
                        }
                  }
                >
                  {" "}
                  <div style={{ display: loader ? "block" : "none" }}>
                    <Spinner />
                  </div>
                  <img
                    src={parsedImage.original}
                    onError={() => setParsedImage(parseImagePreview())}
                    alt="katomi"
                    loading="lazy"
                    className="rounded-lg"
                    onLoad={() => setLoader(false)}
                  />
                </TransformComponent>
              </TransformWrapper>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="relative w-full  ">
            <Spinner />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageModal;
