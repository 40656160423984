import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from "react";
import classNames from "classnames";

import "./styles.scss";
import Icon from "../Icon";

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  error?: string;
  labelText?: string;
  inlineLabel?: boolean;
  onfocus?: boolean;
  labelProps?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  stylesDiv?: string;
  icon?: string;
  iconColor?: string;
  image?: string;
  isIcon?: boolean;
};

const Input: React.FC<Props> = ({
  error,
  labelText,
  labelProps,
  inlineLabel,
  stylesDiv,
  icon,
  iconColor,
  image,
  isIcon,
  ...props
}) => {
  const { className: labelClassName, ...restOfLabelProps } = labelProps!;
  const { className: inputClassName, ...inputProps } = props;

  switch (props.type) {
    case "FullBackgroundCheckbox":
      return (
        <label className="container-checkbox">
          {labelText && labelText}
          <input type="checkbox" />
          <span className="checkmark"></span>
        </label>
      );
    case "switcher":
      return (
        <label className="switch">
          <input type="checkbox" />
          <span className="slider round"></span>
        </label>
      );

    default:
      return (
        <>
          <div
            className={
              props.type === "radio" || props.type === "checkbox"
                ? `flex  flex-row-reverse items-center justify-center max-w-max ${stylesDiv}`
                : icon
                ? `iconClass  `
                : image
                ? `imgClass `
                : ``
            }
          >
            {labelText ? (
              <label
                className={classNames(
                  `flex items-center text-base`,
                  props.type === "radio" && "disabled:text-gray-300",
                  labelClassName
                )}
                htmlFor={inputProps.id}
                {...restOfLabelProps}
              >
                {labelText}
              </label>
            ) : null}

            {icon &&
              (isIcon ? (
                <Icon
                  name={icon}
                  isIcon={true}
                  color={iconColor}
                  width={15}
                  height={15}
                />
              ) : (
                <Icon
                  name={icon}
                  isIcon={false}
                  color={iconColor}
                  width={15}
                  height={15}
                />
              ))}
            {image ? (
              <div>
                <img
                  src={require(`../../assets/images/${image}.png`).default}
                  alt="katomi"
                />
              </div>
            ) : null}

            <input
              className={classNames(
                `${error ? "ring-2 ring-red-100 " : ""} flex-grow ${
                  props.type === "checkbox"
                    ? "flexCenter checkbox"
                    : props.type === "radio"
                    ? "h-5 w-5 flexCenter radio"
                    : "h-12 px-5"
                } ${props.type === "file" ? "" : "border border-gray-200"}`,
                inputClassName
              )}
              style={{ border: error && "border 1px  " }}
              {...inputProps}
            />
          </div>
        </>
      );
  }
};

Input.defaultProps = {
  labelProps: {},
};

export default Input;
