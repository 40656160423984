import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import EndConsultationModal from "./EndConsultationModal";
import Modal from "../../components/ModalUI";
import Button from "../../components/ButtonUI";
import SharedRequest from "../../components/SharedRequest";
import Spinner from "../../components/Spinner";
import makeToast from "../../components/Snackbar";
import Input from "../../components/Input";

import "./styles.scss";
import interdit from "../../assets/images/exclamation-mark.png";

import { retriveDemande2, seenByExpert, sendResponse } from "../../api/medecin";
import moment from "../../helper/moment";

const Consultation = () => {
  const params: any = useParams();
  const history: any = useHistory();
  const { idDemande } = params;
  const [quit, setQuit] = useState(false);

  const [loading, setLoading] = useState(false);
  const [demandeDetails, setDemandeDetails] = useState<any>();
  const [autoSaveAt, setAutoSaveAt] = useState(Date.now());
  const [endConsultationModal, setEndConsultationModal] = useState(false);
  const [editorState, setEditorState] = useState<any>();
  const [condition, setCondition] = useState<any>({ c: "", nbrJrs: "" });
  const [urgence, setUrgence] = useState<boolean>(false);
  const conditions = [
    { c: "Consultation dermatologique non indispensable", nbrJrs: "" },
    { c: "Consultation dermatologique   ", nbrJrs: "" },
    { c: "Exérèse indispensable  ", nbrJrs: "" },
  ];
  const onEditorStateChange = async (newState) => {
    setEditorState(newState);

    setAutoSaveAt(Date.now());
    //TODO localStorge draft
    try {
    } catch (error) {
      console.log("error", error);
    }
  };

  const sendDemandeResponse = async () => {
    if (demandeDetails) {
      await sendResponse(idDemande, {
        response: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        urgence: urgence,
        recommendation: condition,
      });
    }
  };

  const seenByExp = async () => {
    if (demandeDetails) {
      if (demandeDetails.status === "IN_PROGRESS") {
        await seenByExpert(idDemande, demandeDetails._id);
      }
    }
  };
  const _getDemandeById = async () => {
    setLoading(true);
    try {
      const response = await retriveDemande2(idDemande);
      setLoading(false);
      setDemandeDetails(response?.data.demande);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    _getDemandeById();
  }, []);

  useEffect(() => {
    // if (demandeDetails) _getPatientData();
    if (demandeDetails && demandeDetails.status === "RESPONSE") {
      makeToast("error", "Demande déjà traitée");

      history.push("/");
    }
  }, [demandeDetails]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="grid grid-cols-2 divide-x">
        {demandeDetails ? (
          <>
            <div className="h-screen">
              <div className="flex justify-between">
                <div>
                  <h2>Demande de téléexpertise en Dermatologie </h2>
                  {moment(demandeDetails?.createdAt).format("DD MMMM yyyy")}
                  {demandeDetails?.type && (
                    <span className="box ml-4">{demandeDetails?.type}</span>
                  )}
                </div>
                <div className="flexCenter space-x-5 mx-2">
                  <Button
                    width="119px"
                    height="50px"
                    onClick={() => {
                      setEndConsultationModal(true);
                      console.log("urge", urgence);
                      console.log("cond", condition);
                      setQuit(false);
                      //repondre
                    }}
                    disabled={
                      !editorState ||
                      (editorState &&
                        draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                        ).length < 9)
                    }
                  >
                    Répondre
                  </Button>
                </div>
                <div className="flexCenter space-x-5 mx-2">
                  <Button
                    width="119px"
                    height="50px"
                    status="secondary"
                    onClick={() => {
                      setQuit(true);
                      setEndConsultationModal(true);
                    }}
                  >
                    Quitter
                  </Button>
                </div>
              </div>
              <div className="mt-5 py-4 mb-3 inputax  relative bg-white shadow-md     h-42   px-4 mb-6 rounded-lg ">
                <span className="capitalize font-bold text-md">
                  Recommandation
                </span>
                {conditions.map((el, i) => {
                  return (
                    <>
                      {" "}
                      <Input
                        name={"cond" + i}
                        type="checkbox"
                        onChange={() => {
                          setCondition(el);
                        }}
                        value={el.c}
                        labelText={el.c}
                        labelProps={{
                          className: `check text-sm ${
                            condition?.c === el.c
                              ? " font-bold  m-2"
                              : "text-gray-400 m-2"
                          } `,
                        }}
                        checked={condition?.c === el.c}
                        stylesDiv={""}
                      />
                      {condition.c === el.c &&
                        condition.c !==
                          "Consultation dermatologique non indispensable" && (
                          <div className="relative  flex items-center ">
                            <label className="mx-1 -mt-1   text-gray-400">
                              Dans un délai de
                            </label>
                            <Input
                              id="codePostale"
                              placeholder="Nombre des jours"
                              type="number"
                              className="mb-2.5 rounded-lg"
                              iconColor="white"
                              isIcon={false}
                              onBlur={(e) =>
                                setCondition({
                                  c: condition.c,
                                  nbrJrs: e.target.value,
                                })
                              }
                            />
                          </div>
                        )}
                    </>
                  );
                })}
              </div>
              <div
                className={`inputax  relative h-12 border  bg-white shadow-md  rounded-lg     w-full flex gap-x-5  mb-2.5`}
              >
                <img
                  src={interdit}
                  alt="info"
                  className=" relative left-3 top-3 "
                  width="20"
                  style={{ height: "20px" }}
                />{" "}
                <p className={`relative py-3 text-sm font-bold`}> Urgence</p>
                <Input
                  name="urgence"
                  type="checkbox"
                  onChange={() => {
                    setUrgence(!urgence);
                  }}
                  labelText={""}
                  labelProps={{
                    className: `check text-sm ${
                      urgence ? "" : "text-gray-400 m-2"
                    } `,
                  }}
                  checked={urgence}
                  stylesDiv={""}
                />
              </div>
              <div className="mt-5 mb-3 bg-white rounded-lg h-fit shadow-md ">
                <span className="  capitalize font-bold text-md"></span>
                <Editor
                  editorState={editorState}
                  wrapperClassName="consultation-editor"
                  editorClassName="consultation-editor "
                  onEditorStateChange={onEditorStateChange}
                  placeholder="Rédiger votre avis ici ..."
                  editorStyle={{ height: "388px", padding: "8px" }}
                />
              </div>
              <div className="text-center">
                <span className="card-subtitle">
                  Enregistré automatiquement à{" "}
                  {moment(autoSaveAt).format("hh:mm")}✓
                </span>
              </div>
            </div>
            <SharedRequest
              demandeData={demandeDetails}
              colorIndex={0}
              consultaionPage={true}
              setOpenModal={() => console.log("")}
            />
          </>
        ) : (
          <Spinner />
        )}

        <Modal
          showModal={endConsultationModal}
          closeModal={() => setEndConsultationModal(false)}
        >
          <EndConsultationModal
            closeModal={() => setEndConsultationModal(false)}
            idConsultation={idDemande}
            openPrescriptionModal={() => sendDemandeResponse()}
            seenByExpert={() => seenByExp()}
            quit={quit}
          />
        </Modal>
      </div>
    </>
  );
};

export default Consultation;
