import { Redirect, RouteProps } from "react-router";
import Signin from "../pages/Signin";
import ForgetPassword from "../pages/ForgetPassword";
import ConfirmForgetPassword from "../pages/ForgetPassword/confirmForgetPwd";
import Signup from "../pages/signup";
import SpecialRegistre from "../pages/signup/SpecialRegistre";

const guestRoutes: RouteProps[] = [
  {
    path: "/login",
    component: Signin,
    exact: true,
  },
  {
    path: "/login",
    component: Signin,
    exact: true,
  },
  {
    path: "/forgetPassword",
    component: ForgetPassword,
    exact: true,
  },
  {
    path: "/resetPassword/:userId/:token",
    component: ConfirmForgetPassword,
    exact: true,
  },
  {
    path: "/register",
    component: Signup,
    exact: true,
  },
  {
    path: "/register/:groupId",
    component: SpecialRegistre,
    exact: true,
  },
  { render: () => <Redirect to="/login" /> },
];

export default guestRoutes;
