import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  TextareaHTMLAttributes,
} from "react";
import classNames from "classnames";

import "./styles.scss";
import Icon from "../Icon";

type Props = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  error?: string;
  labelText?: string;
  inlineLabel?: boolean;
  onfocus?: boolean;
  labelProps?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  stylesDiv?: string;
  cols?: number;
};

const Input: React.FC<Props> = ({
  error,
  labelText,
  labelProps,
  inlineLabel,
  stylesDiv,
  cols,
  ...props
}) => {
  const { className: labelClassName, ...restOfLabelProps } = labelProps!;
  const { className: inputClassName, ...inputProps } = props;

  return (
    <div className={`stylingTextAreaFormulaire ${stylesDiv}`}>
      {labelText ? (
        <label
          className={classNames(
            `flex items-center`,
            labelClassName,
            error ? "text-red-500" : null
          )}
          htmlFor={inputProps.id}
          {...restOfLabelProps}
        >
          {labelText}
        </label>
      ) : null}
      <textarea
        className={classNames(
          `flex-grow w-full p-4 rounded-md	`,
          inputClassName,
          error ? "text-red-500 border-red-300" : null
        )}
        cols={cols ? cols : 3}
        {...inputProps}
      />
    </div>
  );
};

Input.defaultProps = {
  labelProps: {},
};

export default Input;
