import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import moment from "../../helper/moment";
import Icon from "../Icon";
import Input from "../Input";
import { useAtom } from "jotai";
import MiniCard from "../MiniCardUI";
import { logout } from "../../api/auth";
import { colorAvatarAtom } from "../../state/constants";
import { userAtom } from "../../state/auth";
import { screenBreakpoints } from "../../config/theme";

import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";

import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import EmojiSmileSick from "../../assets/images/smileSick.png";
import EmojiCamera from "../../assets/images/camera.png";
import EmojiMoney from "../../assets/images/money.png";

import "./styles.scss";

const SideBarDoctor: React.FC = () => {
  const [user] = useAtom(userAtom);
  const [patientName, setPatientName] = useState("");
  const [colorAvatar] = useAtom<any>(colorAvatarAtom);
  const [path, setPath] = useState(window.location.pathname);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [menuToggleIsActive, setMenuToggleIsActive] = useState(
    window.innerWidth <= screenBreakpoints.md
  );
  const [forceMenuOpen, setForceMenuOpen] = useState(false);

  const swipeEventsHandlers = useSwipeable({
    onSwipedLeft: () => {
      setForceMenuOpen(false);
    },
    onSwipedRight: () => {
      setForceMenuOpen(true);
    },
  });

  const specialistStat = [
    { name: "patients", score: "37", emoji: EmojiSmileSick, direction: "col" },
    {
      name: "revenu total",
      score: "1560 €",
      emoji: EmojiMoney,
      direction: "col",
    },
    {
      name: "télé-consultations",
      score: "41",
      emoji: EmojiCamera,
      direction: "row",
    },
  ];

  const extraMenu = () => {
    switch (path) {
      case "/specialist":
      case "/mes-patients":
        return (
          <div className="mt-2 w-full">
            <div>
              <div className="flex items-center space-x-2">
                <span className="link uppercase">quelques chiffres</span>
                <Icon isIcon={true} name={"reglage-haut_droite"} />
              </div>

              <span className="card-subtitle -mt-2 mb-2 block">
                depuis le 1er {moment().format("MMMM")}
              </span>
            </div>
            <div className="flex flex-wrap">
              {specialistStat.map(({ name, score, emoji, direction }) => (
                <MiniCard
                  name={name}
                  score={score}
                  emoji={emoji}
                  direction={direction}
                />
              ))}
            </div>
          </div>
        );

      case "/agenda":
        return <div className="border border-blue-500 h-60 w-full"></div>;
      default:
        return;
    }
  };

  const menus = [
    {
      icon: "patient-tableau",
      text: "Demandes",
      url: "/demandes",
    },

    {
      icon: "monCompte",
      text: "Mon compte",
      url: "/profile",
    },
  ];

  const handleLogout = async () => {
    try {
      await logout(user);
      setPath("/");
      window.location.href = "/";
      localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      localStorage.clear();
      console.error(error);
    }
  };
  const imageBaseUrl = `${process.env.REACT_APP_KATOMI_API_URL}/v${process.env.REACT_APP_KATOMI_API_VERSION}`;
  
  useEffect(() => {
    setPath(window.location.pathname);

    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
      setMenuToggleIsActive(window.innerWidth <= screenBreakpoints.md);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    console.log("[debug] sidebar - ", { menuToggleIsActive });
  }, [menuToggleIsActive]);

  useEffect(() => {
    console.log("[debug] sidebar - ", { screenWidth });
  }, [screenWidth]);

  return (
    <div className="sidebar-doctor sidebar">
      <div
        className={`swipe-events-container ${
          !menuToggleIsActive || !forceMenuOpen ? "" : "swipeable-fullscreen"
        }`}
        {...swipeEventsHandlers}
      />
      <div
        className={`sidebar-container absolute transform md:relative md:translate-x-0 transition duration-200 ease-in-out ${
          menuToggleIsActive ? "mobile-sidebar" : "bg-transparent"
        } ${menuToggleIsActive && !forceMenuOpen ? "-translate-x-full" : ""}`}
      >
        <div className="relative">
          {/* <!-- SIDEBAR --> */}

          {/* mobile menu */}
          <div className="flex justify-between md:hidden absolute mobile-menu-burger">
            <button
              className="p-4 focus:outline-none"
              onClick={() => {
                setForceMenuOpen(!forceMenuOpen);
              }}
            >
              <Icon isIcon={true} name={forceMenuOpen ? "close" : "menu"} />
            </button>
          </div>
          {/* mobile menu END */}

          <div className="flex items-center space-x-3">
            {user ? (
              <div className="avatar-doctor ">
                {/* {user.picture !== null && (
                <img
                  src={`${imageBaseUrl}/${user?.picture}`}
                  alt="doctor avatar"
                  className="rounded-lg w-full h-full"
                />
              )}

              {user.picture === null && ( */}
                <div
                  className="avatar-doctor"
                  style={{ backgroundColor: colorAvatar }}
                >
                  {user?.gender === "female" ? (
                    <WomanPatient className=" rounded-lg w-full h-full -mt-2" />
                  ) : (
                    <ManPatient className=" rounded-lg w-full h-full -mt-2" />
                  )}
                </div>
                {/* )} */}
              </div>
            ) : (
              <div
                className="avatar-doctor"
                style={{ backgroundColor: colorAvatar }}
              >
                <ManPatient className="-mt-2" />
              </div>
            )}

            <div>
              <span className="menu">
                {user?.firstName} {user?.lastName}
              </span>
              <p className="docter-center">{user?.speciality}</p>
            </div>
          </div>

          <div className="relative flex w-full flex-wrap items-stretch mb-3 mt-6 ">
            <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
              {/* <IconSearch /> */}
            </span>
            {/* <Input
            type="text"
            placeholder="Rechercher patient"
            width="w-full"
            onChange={(e) => setPatientName(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter")
                history.push({ pathname: "/mes-patients", state: patientName });
            }}
            className="rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm outline-none focus:border-blue-500  border-blue-100 w-full pl-10"
          /> */}
          </div>

          <nav className="mt-3">
            {menus.map((menu) => {
              return (
                <div className={path === menu.url ? "selected-menu" : ""}>
                  <Link
                    to={menu.url}
                    className="flex items-center space-x-3 py-3"
                    onClick={() => setPath(menu.url)}
                  >
                    <div className="w-12 h-12 flexCenter">
                      <Icon isIcon={true} name={menu.icon} />
                    </div>
                    <span className="my-auto menu">{menu.text}</span>
                  </Link>
                </div>
              );
            })}
            <div className={path === "/logout" ? "selected-menu" : ""}>
              <span
                // to={"/"}
                className="flex items-center space-x-3 py-3 cursor-pointer"
                onClick={handleLogout}
              >
                <div className="w-12 h-12 flexCenter">
                  <img
                    src={require("../../assets/images/logout.png").default}
                    width="22"
                    alt="logout"
                  />
                </div>
                <span className="my-auto menu">Se déconnecter</span>
              </span>
            </div>

            {/* {extraMenu()} */}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SideBarDoctor;
