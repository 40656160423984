import { useHistory } from "react-router-dom";
import Button from "../../components/ButtonUI";
import makeToast from "../../components/Snackbar";
interface Props {
  closeModal: any;
  openPrescriptionModal: any;
  seenByExpert: any;
  idConsultation: string;
  quit: boolean;
}

const EndConsultationModal = (props: Props) => {
  const history: any = useHistory();
  const { closeModal, openPrescriptionModal, seenByExpert, quit } = props;
  return (
    <div className="modal-end-consultation flexCenter flex-col space-y-5">
      {quit ? (
        <div>
          <h2 style={{ textAlign: "center" }}>
            Êtes-vous sûr de vouloir quitter sans répondre à cette demande ?{" "}
          </h2>
          <div className="flexCenterBtn space-x-10 mt-6">
            <Button
              status="secondary"
              width="146.6px"
              height="50px"
              onClick={async () => {
                await seenByExpert();
                closeModal();
                history.push("/");
              }}
            >
              Confirmer
            </Button>

            <Button
              status="secondary"
              width="167px"
              height="50px"
              onClick={async () => {
                closeModal();
              }}
            >
              Annuler
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <h2 style={{ textAlign: "center" }}>Transférer votre réponse </h2>
          <div className="flexCenterBtn space-x-10 mt-6">
            <Button
              status="secondary"
              width="100px"
              height="50px"
              onClick={async () => {
                closeModal();
                await openPrescriptionModal();
                makeToast("success", "Avis envoyé avec succès");
                history.push("/");
              }}
            >
              Confirmer
            </Button>

            <Button
              status="secondary"
              width="100px"
              height="50px"
              onClick={async () => {
                closeModal();
              }}
            >
              Annuler
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EndConsultationModal;
