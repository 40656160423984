import React from "react";
import { freehDoctor } from "../../api/medecin";
import Button from "../../components/ButtonUI";
import makeToast from "../Snackbar";
import "./styles.scss";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  setOpenModalAddDoctor: Function;
  doctorData: any;
  setActive: Function;
  closeModal: Function;
  free: Boolean;
}

export const ConfirmModalFree: React.FC<Props> = ({
  openModal,
  setOpenModal,
  setOpenModalAddDoctor,
  doctorData,
  setActive,
  closeModal,
  free,
}) => {
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute z-40 top-1/3 flexColCenter confirmAddPatientX">
        {free ? (
          <>
            {" "}
            <h2 className="text-md">
              Êtes-vous sûr de vouloir exonérer le Dr. {doctorData.lastName} de
              ses frais de paiement ?
            </h2>
            <span className="my-2 p-2">
              {" "}
              Cette opération annulera l'abonnement actif de ce médecin.
            </span>
          </>
        ) : (
          <>
            <h2 className="text-md">
              Êtes-vous sûr de vouloir annuler la dispense de paiement du Dr.{" "}
              {doctorData.lastName}?
            </h2>
            <span className="my-2 p-2">
              {" "}
              Cette opération entraînera une interdiction d'accès à la
              plateforme web et mobile de ce médecin afin de souscrire à un
              abonnement.
            </span>
          </>
        )}

        <div className="flexCenter mt-6 buttons">
          <Button
            status="secondary"
            width={"119px"}
            height={"50px"}
            onClick={() => {
              setActive(doctorData.freeAccount);
              setOpenModal(false);
            }}
          >
            Annuler
          </Button>
          <Button
            status="secondary"
            width={"119px"}
            height={"50px"}
            onClick={() => {
              const res = freehDoctor(doctorData);
              makeToast(
                "success",
                "Changement du méthode de paiement est effectué avec succès."
              );
              setOpenModal(false);

              closeModal(true);
            }}
          >
            Confirmer
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmModalFree;
