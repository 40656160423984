import { useEffect, useState } from "react";
import moment from "../../../../helper/moment";
import Icon from "../../../../components/Icon";
import { ITreatements } from "../../../../types/patient";


const Traitements = ({ patientData }) => {
  const [treatementEnCours, setTreatementEnCours] = useState<ITreatements[]>(
    []
  );
  const [treatementsAncien, setTreatementsAncien] = useState<ITreatements[]>(
    []
  );
  const [, setTypeObservation] = useState({
    typeObservation: "",
    idObservation: "",
  });
  const [, setObservationModal] = useState(false);
  const [, setShowObservationsModal] = useState(false);
  const [, setSelectedObservationId] = useState("");

  useEffect(() => {
    if (patientData?.treatements) {
      let tCours = [] as any;
      let tAncien = [] as any;
      patientData?.treatements.forEach((e) => {
        if (e.traitement === "Traitement en cours") {
          tCours.push(e);
        } else {
          tAncien.push(e);
        }
      });
      setTreatementEnCours(tCours);
      setTreatementsAncien(tAncien);
    }
  }, [patientData]);
  return (
    <div className="share-folder-traitement">
      <div className="flex justify-between ">
        <div className="mb-3">
          <h3>En cours</h3>
        </div>
      </div>
      <div className="flex flex-wrap gap-5">
        {treatementEnCours && treatementEnCours.length > 0 ? (
          treatementEnCours.map((traitement) => (
            <div className="card-traitement">
              <div className="flex justify-between items-center">
                <div className="flex card-title">
                  <p>{traitement.type}</p>
                </div>
                <div>
                  <Icon name="troisPoints" isIcon={true} />
                </div>
              </div>
              <p className="card-subtitle">
                Mise à jour le
                <strong>{` ${moment(traitement.updatedAt).format(
                  "LL"
                )} à ${moment(traitement.updatedAt).format("HH:mm")}`}</strong>
              </p>
              <div className="flex">
                {traitement["medicament"] &&
                  traitement["medicament"].map((medic) => {
                    return (
                      <div className="max-w-max flex">
                        <Icon
                          name="capsule"
                          isIcon={false}
                          width={14}
                          height={16}
                        />
                        <p>{medic}</p>
                      </div>
                    );
                  })}
              </div>
              <p className="addObservation menu flexCenter traitementbtn blue-text">
                {traitement.observationsDoctor.length === 0 ? (
                  <p
                    className="addObservation menu flexCenter cursor-pointer"
                    onClick={() => {
                      setSelectedObservationId(traitement._id);
                      setObservationModal(true);
                    }}
                  >
                    + Ajouter une observation
                  </p>
                ) : (
                  <div className="flexCenter">
                    <div
                      className="menu addObservation"
                      onClick={() => {
                        setTypeObservation({
                          typeObservation: "treatements",
                          idObservation: traitement._id,
                        });
                        setShowObservationsModal(true);
                      }}
                    >
                      {traitement.observationsDoctor.length} observations
                    </div>
                    <div
                      className="menu ml-6 addObservation cursor-pointer"
                      onClick={() => {
                        setSelectedObservationId(traitement._id);
                        setObservationModal(true);
                      }}
                    >
                      Ajouter
                    </div>
                  </div>
                )}
              </p>
            </div>
          ))
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">
              Ce patient n'as aucun tratiement en cours
            </p>
          </div>
        )}
      </div>
      <div className="flex justify-between">
        <div className="my-5">
          <h3>Ancien</h3>
        </div>

      </div>
      <div className="taches flex">
        {treatementsAncien && treatementsAncien.length > 0 ? (
          treatementsAncien.map((traitement) => {
            return (
              <div className="card-traitement">
                <div className="flex justify-between items-center">
                  <div className="flex card-title">
                    <p>{traitement.type}</p>
                  </div>
                  <div>
                    <Icon name="troisPoints" isIcon={true} />
                  </div>
                </div>
                <p className="card-subtitle">
                  Mise à jour le
                  <strong>{` ${moment(traitement.updatedAt).format(
                    "LL"
                  )} à ${moment(traitement.updatedAt).format(
                    "HH:mm"
                  )}`}</strong>
                </p>
                <div className="flex">
                  {traitement["medicament"] &&
                    traitement["medicament"].map((medic) => {
                      return (
                        <div className="max-w-max flex">
                          <Icon
                            name="capsule"
                            isIcon={false}
                            width={14}
                            height={16}
                          />
                          <p>{medic}</p>
                        </div>
                      );
                    })}
                </div>
                <p className="addObservation menu flexCenter traitementbtn blue-text">
                  {traitement.observationsDoctor.length &&
                  traitement.observationsDoctor.length === 0 ? (
                    <p
                      className="addObservation menu flexCenter"
                      onClick={() => {
                        setSelectedObservationId(traitement._id);
                        setObservationModal(true);
                      }}
                    >
                      + Ajouter une observation
                    </p>
                  ) : (
                    <div className="flexCenter">
                      <div
                        className="menu addObservation"
                        onClick={() => {
                          setTypeObservation({
                            typeObservation: "treatements",
                            idObservation: traitement._id,
                          });
                          setShowObservationsModal(true);
                        }}
                      >
                        {traitement.observationsDoctor.length} observations
                      </div>
                      <div
                        className="menu ml-6 addObservation"
                        onClick={() => {
                          setSelectedObservationId(traitement._id);
                          setObservationModal(true);
                        }}
                      >
                        Ajouter
                      </div>
                    </div>
                  )}
                </p>
              </div>
            );
          })
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">
              Ce patient n'as aucun ancien tratiement
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Traitements;
