import { useEffect, useState } from "react";
import moment from "moment";
import { colorAvatar } from "../../../components/SharedDoctorDetails/fakeData";
import { ReactComponent as WomanPatient } from "../../../assets/illustrations/calendar-svgrepo-com.svg";
import { ReactComponent as Time } from "../../../assets/illustrations/timer-svgrepo-com.svg";
import Sad from "../../../assets/images/Sad0.png";
import Icon from "../../../components/Icon";
import alert from "../../../assets/images/alert.png";
import Tooltip from "../../../components/toolTip";
import _ from "lodash";
interface Props {
  data: any;
  setShowAgendaModal: Function;
  day: any;
}
const DayPlan = (props: Props) => {
  const [day, setDay] = useState(props.day);

  return (
    <>
      <div className="flexCenter my-14 flex rounded-lg p-3 mb-4 font-medium">
        <div className=" w-2/3 h-full flex-none text-center ">
          <div className="block rounded-2xl overflow-x-hidden  text-center shadow-lg">
            <div
              className="flexCenter relative  text-white h-24  py-1"
              style={{ backgroundColor: "#4565f4" }}
            >
              <div className=" absolute right-0  top-0 rounded-bl-3xl border shadow-lg  cursor-pointer -mx-2 -my-2">
                <Icon
                  isIcon={true}
                  style={{ opacity: 0.5 }}
                  name="close"
                  onClick={() => {
                    props.setShowAgendaModal("");
                  }}
                />
              </div>

              <button
                type="button"
                className="text-white rounded-md py-2 absolute -left-2   hover:text-gray-100  px-3  mx-2"
                onClick={() =>
                  setDay(moment(day, "dddd").subtract("day", 1).format("dddd"))
                }
              >
                <div className="flex flex-row align-middle">
                  <svg
                    className="w-6 transition duration-700 ease-in-out transform hover:-translate-x-2 shadow-lg rounded-lg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p className="ml-2 text-sm">
                    {moment(day, "dddd").subtract("day", 1).format("dddd")}
                  </p>
                </div>
              </button>
              <div className="">
                <span className="text-xl font-bold  leading-tight capitalize mx-2 w-2/3">
                  {day === moment().format("dddd") ? "Aujourd'hui" : day}
                </span>
                <div className=" text-center  pt-1 pb-2">
                  <span className="text-xs bg-white text-indigo-700 rounded-lg px-2 py-1 border border-white shadow-lg ">
                    {props.data[day].length > 0
                      ? props.data[day].length > 1
                        ? props.data[day].length + " experts disponibles"
                        : props.data[day].length + " expert disponible"
                      : `Pas d'expert pour ${day}`}
                  </span>
                </div>
              </div>

              <button
                type="button"
                className="text-white rounded-md py-2 absolute -right-2 px-3 mx-2   hover:text-gray-100  "
                onClick={() =>
                  setDay(moment(day, "dddd").add("day", 1).format("dddd"))
                }
              >
                <div className=" flex flex-row align-middle   ">
                  <span className="mr-2 text-sm">
                    {moment(day, "dddd").add("day", 1).format("dddd")}
                  </span>
                  <svg
                    className="w-6 transition duration-700 ease-in-out transform hover:translate-x-2 shadow-lg rounded-lg "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </button>
            </div>

            {props.data[day].length > 0 ? (
              <>
                <div className="overflow-y-auto h-96 item block bg-gray-50 py-6 rounded-b-2xl ">
                  {props.data[day].map((d) => (
                    <div className="card-specialistd mx-2 shadow-md">
                      <div className="flex">
                        <div
                          className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
                          style={{
                            backgroundColor:
                              colorAvatar[
                                Math.floor(Math.random() * colorAvatar.length)
                              ],
                          }}
                        >
                          {true ? <WomanPatient /> : <WomanPatient />}
                        </div>
                        <div className="details">
                          <div className="flex-grow">
                            <p className="card-title w-64">
                              Dr.{" "}
                              {" " +
                                d?.doctorName?.lastName +
                                " " +
                                d?.doctorName?.firstName}
                              {d?.leave &&
                                d?.leave?.map((l) => {
                                  let isTodayLeave = false;

                                  if (!l?.notAgreedToReceiveDemand) {
                                    if (
                                      moment()
                                        .day(day !== "dimanche" ? day : 7)
                                        .isBetween(
                                          moment(l?.startDate),
                                          moment(l?.endDate)
                                        )
                                    ) {
                                      isTodayLeave = true;
                                    }
                                  } else {
                                    if (
                                      moment()
                                        .day(day !== "dimanche" ? day : 7)
                                        .isBetween(
                                          moment(l?.startDate).subtract(
                                            2,
                                            "days"
                                          ),
                                          moment(l?.endDate)
                                        )
                                    ) {
                                      isTodayLeave = true;
                                    }
                                  }

                                  if (isTodayLeave) {
                                    return (
                                      <div className="flex justify-center py-1">
                                        <img
                                          src={alert}
                                          alt="alerte"
                                          width="25"
                                        />
                                        <p
                                          key={l?.id}
                                          className="card-subtitle"
                                        >
                                          En congé
                                        </p>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                            </p>
                          </div>
                          <span className="flex flexCenter">
                            {" "}
                            <Time className="w-4  mr-2" />{" "}
                            <p className="card-subtitle  ">
                              {d?.rnage?.start + " - " + d?.rnage?.end}
                            </p>
                          </span>
                        </div>
                      </div>

                      <div className="flex space-x-3"></div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <span className="item block bg-gray-50 py-4  overflow-y-auto rounded-b-2xl h-96">
                <span className="text-gray-500 text-6xl block">
                  {" "}
                  <span className="text-gray-500 text-xl">
                    {" "}
                    Pas de disponibilité pour {day} !
                  </span>
                  <span>
                    <img src={Sad} />
                  </span>
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DayPlan;
