import React from "react";
import moment from "../../../helper/moment";

import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patienta.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patienta.svg";
import "./styles.scss";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

interface IPatientDetails {
  updatedAt: Date;
  demandes: any;
  firstName: string;
  lastName: string;
  _id: string;
  date: Date;
  type: string;
  gender: string;
  dateDerniereDemande: Date;
  motifs: string;
}

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  patientDetails: IPatientDetails;
  openModal: boolean;
  setOpenModal: Function;
  setSelectedPatient: Function;
  setChatModal: Function;
  selectedPatient: string;
  setColorIndex: Function;
  i: number;
};

const CardPatient: React.FC<Props> = ({
  patientDetails,
  openModal,
  setOpenModal,
  setSelectedPatient,
  selectedPatient,
  setChatModal,
  setColorIndex,
  i,
}) => {
  return (
    <div
      className={`card-patient justify-between bg-white  ${
        selectedPatient === patientDetails?._id && "selectedPatientDoctor"
      }`}
      key={i}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        setSelectedPatient(patientDetails?._id);
        setOpenModal(!openModal);
        setColorIndex(i);
      }}
    >
      <div className="flex patient-info">
        <div
          className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
          style={{
            backgroundColor: colorAvatar[i % colorAvatar.length],
          }}
        >
          {patientDetails.gender === "male" ? <ManPatient /> : <WomanPatient />}
        </div>
        <div className="details">
          <div className="flex-grow ">
            <p className="card-title capitalize">
              {patientDetails.firstName} {patientDetails.lastName}
            </p>
            {patientDetails.dateDerniereDemande ? (
              <p className="card-subtitle card-subtitlex font-bold">
                Dernière demande de téléexpertise le{" "}
                {moment(patientDetails.dateDerniereDemande).format(
                  "DD/MM/YYYY à HH:mm"
                )}{" "}
              </p>
            ) : (
              <p className="card-subtitle card-subtitlex"></p>
            )}
            {patientDetails.motifs ? (
              <span className="box  shadow-md">{patientDetails.motifs} </span>
            ) : (
              <p className="card-subtitle card-subtitlex"></p>
            )}

          </div>
        </div>
      </div>
      <div className="patient-icons">
      </div>
    </div>
  );
};

export default CardPatient;
