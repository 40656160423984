import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { logout } from "../../api/auth";
import { addOrModifyProfileInfo } from "../../api/medecin";
import { cancelSubscription, verifyingSubscription } from "../../api/stripe";
import Button from "../../components/ButtonUI";
import { FormikWizard } from "../../components/FormikWizard";
import Icon from "../../components/Icon";
import Modal from "../../components/ModalUI";
import StripePaymentMethod from "../../components/StripePaymentMethod";
import { userAtom } from "../../state/auth";
import { User } from "../../types/user";
import * as Yup from "yup";
import { ReactComponent as Pay } from "../../assets/illustrations/pay.svg";

const reqProducts = [
  {
    priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_REQ_T1,
    price: "10",
    limit: true,
    name: "Abonnement standard",
  },
  {
    priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_REQ_T2,
    price: "20",
    limit: false,
    name: "Abonnement illimité",
  },
];
const expertProducts = [
  {
    priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_EXP_T1,
    price: "20",
    limit: true,
    name: "Abonnement standard",
  },
  {
    priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_EXP_T2,
    price: "40",
    limit: false,
    name: "Abonnement illimité",
  },
];
const surgProducts = [
  {
    priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_SRG,
    price: "100",
    limit: false,
    name: "Abonnement standard",
  },
];

const Subscription: React.FC<User | any> = ({ userProps }) => {
  const [subId, setSubId] = useState();
  const [user, setUser] = useAtom(userAtom);
  const [openModal, setOpenModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const handleLogout = async () => {
    try {
      await logout(user);

      window.location.href = "/";
      localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      localStorage.clear();
      console.error(error);
    }
  };
  const renderComponent = () => {
    let name = "";
    let price = "";
    let limit: boolean = false;

    switch (userProps.type) {
      case "medecin":
        reqProducts.map((p) => {
          if (p.priceId === subId) {
            name = p.name;
            price = p.price;
            limit = p.limit;
          }
        });
        break;

      case "medecinTeleexpert":
        expertProducts.map((p) => {
          if (p.priceId === subId) {
            name = p.name;
            price = p.price;
            limit = p.limit;
          }
        });
        break;

      case "medecinChirurgien":
        surgProducts.map((p) => {
          if (p.priceId === subId) {
            name = p.name;
            price = p.price;
            limit = p.limit;
          }
        });
        break;
    }
    return { name: name, price: price, limit: limit };
  };

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      const sub = await verifyingSubscription({
        subscriptionId: userProps.stripeSubscriptionId,
        userId: userProps._id,
      });
      if (sub) {
        setSubId(sub.planId !== "canceled" ? sub.planId : undefined);
      }
    }
    if (!user?.freeAccount) {
      fetchMyAPI();
    }
  });
  return (
    <div className="cursus">
      <div className="headerContainer flex items-center relative">
        <Icon isIcon={true} name="tab-invoice" className="ml-5 w-8 h-8" />
        <p className="header">Abonnement</p>
        {!user?.freeAccount && (
          <p
            className="absolute right-0	modifier"
            onClick={() => setOpenModal(true)}
          >
            Modifier
          </p>
        )}
      </div>
      <div className="cursusContainer">
        {subId ? (
          <div className="flex">
            <div className="images"></div>
            <div className="details">
              <div className="flex items-center space-x-2">
                <p className="typeDiplome">{renderComponent().name} </p>

                <p className="anneeDiplome">{renderComponent().price} € HT</p>
              </div>
              {renderComponent().limit ? (
                <>
                  <p className="university my-2 ml-6 font-bold">
                    <span className="absolute my-1.5 -ml-4 w-1.5 h-1.5 bg-gray-500 rounded"></span>
                    Jusqu'à 10 demandes de téléexpertise
                  </p>
                  {user?.type === "medecin" && (
                    <p className="university my-2 ml-6 font-bold">
                      <span className="absolute my-1.5 -ml-4 w-1.5 h-1.5 bg-gray-500 rounded"></span>
                      Jusqu'à 10 patients
                    </p>
                  )}
                </>
              ) : (
                <>
                  <p className="university my-2 ml-6 font-bold">
                    <span className="absolute my-1.5 -ml-4 w-1.5 h-1.5 bg-gray-500 rounded"></span>
                    Nombre illimité de demandes de téléexpertise
                  </p>
                  {user?.type === "medecin" && (
                    <p className="university my-2 ml-6 font-bold">
                      <span className="absolute my-1.5 -ml-4 w-1.5 h-1.5 bg-gray-500 rounded"></span>
                      Nombre illimité de patients
                    </p>
                  )}
                </>
              )}
              <ul>
                {" "}
                <li>
                  <p className="university my-2 ml-6 font-bold">
                    <span className="absolute my-1.5 -ml-4 w-1.5 h-1.5 bg-gray-500 rounded"></span>
                    Accès Web et Mobile
                  </p>
                </li>
                <li>
                  <p className="university my-2 ml-6 font-bold">
                    <span className="absolute my-1.5 -ml-4 w-1.5 h-1.5 bg-gray-500 rounded"></span>
                    Support Technique
                  </p>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <p className="card-subtitle flexCenter mt-20">
            {!user?.freeAccount
              ? "Vous ne disposez pas d'un abonnement active pour le moment ."
              : "Vous bénéficiez d'une exonération de paiement."}
          </p>
        )}
      </div>
      {openModal && (
        <Modal showModal={openModal}>
          <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden  bg-opacity-modal flexCenter ">
            <div className="absolute z-40 top-8 flexColCenter firstModal ">
              <h2></h2>
              <div className="m-2 w-full">
                <ul className="stepper" data-mdb-stepper="stepper">
                  <FormikWizard
                    initialValues={{
                      subscriptionId: "",
                      email: user?.email,
                    }}
                    onSubmit={async (values) => {
                      const { subscriptionId } = values;
                      if (user?.type !== "medecinResponsable") {
                        const updateProfile = await addOrModifyProfileInfo({
                          stripeSubscriptionId: subscriptionId,
                        });
                        if (updateProfile) {
                          setUser(updateProfile);
                          setOpenModal(false);
                        }
                      }
                    }}
                    validateOnNext
                    activeStepIndex={0}
                    steps={[
                      {
                        component: ({
                          errors,
                          values,
                          handleChange,
                          setFieldValue,
                          handleSubmit,
                        }) =>
                          values.subscriptionId === "" ? (
                            <>
                              <StripePaymentMethod
                                setFieldValue={setFieldValue}
                                auth={true}
                                values={values}
                                handleSubmitGlobal={handleSubmit}
                              />
                            </>
                          ) : (
                            <div>
                              <Pay
                                className="border-4 border-green-300 bg-gray-100 rounded-full p-5 mx-auto my-2"
                                width={200}
                                height={200}
                              />
                              <p className="flexCenter font-bold text-lg text-green-500 ">
                                Paiement réussi
                              </p>
                            </div>
                          ),
                        validationSchema: Yup.object().shape({
                          subscriptionId: Yup.string().required("required"),
                        }),
                      },
                    ]}
                  >
                    {({
                      renderComponent,
                      handlePrev,
                      handleNext,
                      isNextDisabled,
                      isPrevDisabled,
                      isLastStep,
                      currentStepIndex,
                      values,
                    }) => (
                      <>
                        <div className="wrapper option-1 option-1-1">
                          {}
                          <ol className="c-stepper">
                            <li className="c-stepper__item">
                              <h3
                                className={`c-stepper__title ${
                                  currentStepIndex == 0
                                    ? "text-indigo-500"
                                    : "text-gray-300"
                                }`}
                              >
                                Sélectionner votre plan d'abonnement 💵
                              </h3>
                              <p className="c-stepper__desc mb-3"></p>
                            </li>
                          </ol>
                          {renderComponent()}
                        </div>

                        {isPrevDisabled && (
                          <Button
                            status="secondary"
                            className="float-right flexCenter text-red-400 mx- 5 my-5 px-4 border-red-400"
                            width="auto"
                            height="50px"
                            type="button"
                            onClick={() => setCancelModal(true)}
                            hidden={isPrevDisabled}
                          >
                            Annuler l'abonnement
                          </Button>
                        )}

                        <Button
                          status="secondary"
                          className="float-left flexCenter my-5"
                          width="100px"
                          height="50px"
                          type="button"
                          onClick={() => setOpenModal(false)}
                        >
                          Quitter
                        </Button>
                      </>
                    )}
                  </FormikWizard>
                </ul>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {cancelModal && (
        <Modal showModal={cancelModal}>
          <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
            <div className="absolute z-40 top-1/3 flexColCenter confirmAddPatient">
              <h2>
                Êtes-vous sûr de vouloir annuler votre abonnement actuel ?{" "}
              </h2>
              <span className="my-2 p-2">
                Cette désactivation entraîne une déconnexion immédiate et une
                restriction d'utilisation.
              </span>
              <div className="flexCenter mt-6 buttons">
                <Button
                  status="secondary"
                  width={"119px"}
                  height={"50px"}
                  onClick={() => setCancelModal(!cancelModal)}
                >
                  Annuler
                </Button>
                <Button
                  status="secondary"
                  width={"119px"}
                  height={"50px"}
                  onClick={async () => {
                    try {
                      const zx = await cancelSubscription({
                        subscriptionId: userProps.stripeSubscriptionId,
                        userId: user?._id,
                      });
                      const res = await zx;
                      if (res) {
                        handleLogout();
                      }
                    } catch (error) {
                      Promise.reject(error);
                    }
                  }}
                >
                  Confirmer
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Subscription;
