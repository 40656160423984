import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Formik } from "formik";

import Input from "../../components/Input";
import Button from "../../components/ButtonUI";
import makeToast from "../../components/Snackbar";

import { addDoctor } from "../../api/medecin";
import validationSchema from "./validateSchema";
import { userAtom } from "../../state/auth";

import info from "../../assets/icons/icon-patient.svg";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import "./styles.scss";
import SelectComp from "../../components/Select";
import ListDepartement from "../../config/constants/departement.json";
import { getAllGroups } from "../../api/groups";

interface IProps {
  openModal: Boolean;
  setOpenModal: Function;
  message?: String;
  type?: String;
  url?: string;
  setDoctorAdded: boolean | any;
  setSelectedDoctor: Function;
  setDoctorData: Function;
  setOpenModalInfoDoctor: Function;
}

const typeOptionGroup = [
  { label: "Requérant", value: "medecin" },
  { label: " Médecin Expert Dermatologue", value: "medecinTeleexpert" },
  {
    label: " Médecin Chirurgien ",
    value: "medecinChirurgien",
  },
];
const typeRequrent = [
  { label: "Médecin", value: "medecinRequrent" },
  { label: "Sage-femme", value: "sageFemme" },
  {
    label: "Infirmier(e)",
    value: "infirmier",
  },
];
const AddDoctor: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  setDoctorAdded,
  setSelectedDoctor,
  setDoctorData,
  setOpenModalInfoDoctor,
}) => {
  const [user, setUser] = useAtom(userAtom);
  const [freeAccount, setFreeAccount] = useState(false);
  const [groupList, setGroupList] = useState<any[]>([]);

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      const response = await getAllGroups();
      setGroupList(response);
    }

    fetchMyAPI();
  }, []);
  const buttonOnSubmit = async (values) => {
    try {
      if (values.type === "medecinTeleexpert1") {
        values.type = "medecinTeleexpert";
      }
      const {
        email,
        lastName,
        firstName,
        birthday,
        gender,
        codePostale,
        phone,
        adresse,
        numCom,
        numRPPS,
        type,
        groupId,
      } = values;

      if (
        email === "" ||
        lastName === "" ||
        firstName === "" ||
        gender === "" ||
        birthday === "" ||
        phone === "" ||
        adresse === "" ||
        codePostale === "" ||
        numCom === "" ||
        numRPPS === "" ||
        type === ""
      ) {
        makeToast("error", "Veuillez remplir les champs obligatoires");
      } else {
        values.freeAccount = freeAccount;
        const response = await addDoctor(values);

        if (response) {
          setDoctorAdded(true);
          setOpenModal(!openModal);
          setSelectedDoctor(response.data.id);

          setDoctorData(response.doctor);
          setUser({ ...user, ...response.doctor });
          setOpenModalInfoDoctor(true);
        }
      }
    } catch (error) {
      makeToast("error", "Ce médecin existe déjà.");
      console.log(`Error: ${JSON.stringify(error)}`);
    }
  };
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute  inset-y-0 right-0  form-add-patient  z-40 transition transition ease-in duration-700 patient-modal-info">
        <Button
          status="icon-secondary"
          className="absolute top-1 right-1"
          onClick={() => setOpenModal(!openModal)}
        >
          <Close />
        </Button>
        <h1 className="title flexCenter">Nouvel utilisateur</h1>
        <div className="form-container flexCenter">
          <Formik
            initialValues={{
              email: "",
              password: "",
              lastName: "",
              firstName: "",
              birthday: "",
              gender: "",
              particularites: [],
              phone: "",
              adresse: "",
              numCom: "",
              numRPPS: "",
              medecin: "",
              type: "",
              requrentType: "",
              groupId: "",
              codePostale: "",
              departement: "",
              freeAccount: false,
            }}
            onSubmit={async (values) => {
              try {
                console.log("Submited");
              } catch (error) {
                console.log(`Error: ${JSON.stringify(error)}`);
              }
            }}
            validationSchema={validationSchema}
          >
            {(props) => {
              const {
                values,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isValid,
                touched,
              } = props;

              return (
                <>
                  <form
                    onSubmit={handleSubmit}
                    className="form-login flexCenter flex-col fixingColor"
                  >
                    <div className="relative">
                      <Input
                        id="lastName"
                        placeholder="Nom"
                        type="text"
                        error={touched.lastName ? errors.lastName : undefined}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="custom-Input mb-2.5"
                        inlineLabel={false}
                        onfocus={true}
                        icon="user"
                        iconColor="white"
                        isIcon={true}
                      />
                      {values.lastName === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="relative">
                      <Input
                        id="firstName"
                        placeholder="Prénom"
                        type="text"
                        error={touched.firstName ? errors.firstName : undefined}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="custom-Input mb-2.5"
                        inlineLabel={false}
                        onfocus={true}
                        icon="user"
                        iconColor="white"
                        isIcon={true}
                      />
                      {values.firstName === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>
                    <div
                      className={`inputax  relative h-12 border ${
                        touched.phone && !values.gender
                          ? "ring-2 ring-red-100"
                          : ""
                      }   border border-gray-200  w-full flex gap-x-5  mb-2.5`}
                    >
                      <img
                        src={info}
                        alt="info"
                        className=" relative left-3 top-3 "
                        width="20"
                        style={{ height: "24px" }}
                      />{" "}
                      <p
                        className={
                          values.gender
                            ? `relative py-3 text-sm `
                            : `relative py-3 text-sm text-gray-400`
                        }
                      >
                        Sexe
                      </p>
                      {values.gender === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                      <Input
                        name="gender"
                        type="checkbox"
                        onChange={() => {
                          setFieldValue("gender", "female");
                        }}
                        value="female"
                        labelText="Féminin"
                        checked={values.gender === "female"}
                        labelProps={{
                          className: "check text-sm text-gray-400 m-2",
                        }}
                        stylesDiv={""}
                      />
                      <Input
                        name="gender"
                        type="checkbox"
                        onChange={() => {
                          setFieldValue("gender", "male");
                        }}
                        value="male"
                        labelText="Masculin"
                        labelProps={{
                          className: "check text-sm text-gray-400 m-2",
                        }}
                        checked={values.gender === "male"}
                        stylesDiv={""}
                      />
                    </div>

                    <div className="relative">
                      <Input
                        id="phone"
                        placeholder="Numéro de téléphone"
                        type="text"
                        error={touched.phone ? errors.phone : undefined}
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2.5"
                        image="phone-white"
                      />
                      {values.phone === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="relative">
                      <Input
                        id="adresse"
                        placeholder="Adresse postale"
                        type="text"
                        error={touched.adresse ? errors.adresse : undefined}
                        value={values.adresse}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2.5"
                        icon="home"
                        iconColor="white"
                        isIcon={true}
                      />
                      {values.adresse === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="relative">
                      <Input
                        id="codePostale"
                        placeholder="Code postal"
                        type="number"
                        error={
                          touched.codePostale ? errors.codePostale : undefined
                        }
                        value={values.codePostale}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2.5"
                        icon="home"
                        iconColor="white"
                        isIcon={true}
                      />
                      {values.codePostale === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="relative">
                      <SelectComp
                        id="departement"
                        value={values.departement}
                        options={ListDepartement}
                        onChange={handleChange}
                        onBlur={() => handleBlur}
                        text="Sélectionner le département"
                        className=" flex-grow h-12 px-5 border border-gray-200 mb-2.5"
                        style={{ width: "417px" }}
                      />
                      {/* {values.codePostale === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )} */}
                    </div>
                    <div className="relative">
                      <SelectComp
                        id="groupId"
                        value={values.groupId}
                        options={groupList?.map((e) => ({
                          value: e._id,
                          label: e.name,
                        }))}
                        onChange={handleChange}
                        onBlur={() => handleBlur}
                        text="Sélectionner un groupe"
                        className=" flex-grow h-12 px-5 border border-gray-200 mb-2.5"
                        style={{ width: "417px" }}
                      />
                      {/* {values.codePostale === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )} */}
                    </div>
                    <div className="relative">
                      <Input
                        id="email"
                        placeholder="Adresse mail"
                        type="email"
                        error={touched.email ? errors.email : undefined}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${
                          touched.email && values.gender
                            ? " border border-gray-200"
                            : "border-red-200"
                        } `}
                        image="mail-white"
                      />
                      {values.email === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>

                    <div className="flex flex-row gap-x-8 mb-2.5"></div>
                    <div className="relative">
                      <Input
                        id="numCom"
                        placeholder="N° ADELI"
                        type="text"
                        error={touched.numCom ? errors.numCom : undefined}
                        value={values.numCom}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2.5"
                        image="securite-sociale"
                      />
                      {values.numCom === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                      {touched.numCom && (
                        <i className="flexCenter text-xs mb-2 -mt-1 ">
                          {errors.numCom}
                        </i>
                      )}
                    </div>
                    <div className="relative">
                      <Input
                        id="numRPPS"
                        placeholder="N° RPPS"
                        type="text"
                        error={touched.numRPPS ? errors.numRPPS : undefined}
                        value={values.numRPPS}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2.5"
                        image="securite-sociale"
                      />
                      {values.numRPPS === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                      {touched.numRPPS && (
                        <i className="flexCenter text-xs mb-2 -mt-1 ">
                          {errors.numRPPS}
                        </i>
                      )}
                    </div>

                    <div className="relative">
                      <label className="text-sm text-gray-400">
                        Date de naissance{" "}
                      </label>
                      <Input
                        id="birthday"
                        placeholder="Date de naissance (JJ/MM/YYYY)"
                        type="date"
                        error={touched.birthday ? errors.birthday : undefined}
                        value={values.birthday}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="custom-Input mb-8"
                        inlineLabel={false}
                        onfocus={true}
                        image="cake"
                      />
                      {values.birthday === "" && (
                        <span className="absolute top-6 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>

                    <div
                      className={`inputax  relative ${
                        touched.birthday && !values.type
                          ? "ring-2 ring-red-100 rounded-md"
                          : ""
                      } flexCenter w-full -mx-8  h-32 border border-gray-200 m-2 p-2 mb-6`}
                    >
                      <p
                        className={
                          values.type
                            ? `mx-8 py-3 text-sm `
                            : ` mx-8 py-3 text-sm text-gray-400`
                        }
                      >
                        Type d'utilisateur
                      </p>
                      {values.type === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}

                      <div className="flex-col pt-4">
                        {typeOptionGroup.map((t, i) => (
                          <>
                            {" "}
                            <Input
                              name="type"
                              type="checkbox"
                              onChange={() => {
                                setFieldValue("type", t.value);
                              }}
                              key={i}
                              value={t.value}
                              labelText={t.label}
                              checked={values.type === t.value}
                              labelProps={{ className: "check" }}
                              stylesDiv={"inputTest"}
                            />
                          </>
                        ))}
                      </div>
                    </div>
                    <div className="relative">
                      {values.type === "medecin" && (
                        <SelectComp
                          id="requrentType"
                          value={values.requrentType}
                          options={typeRequrent}
                          onChange={handleChange}
                          onBlur={() => handleBlur}
                          text="Sélectionner le type de requérant"
                          className=" flex-grow h-12 px-5 border border-gray-200 mb-2.5"
                          style={{ width: "417px" }}
                        />
                      )}
                      {/* {values.requrentType === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )} */}
                    </div>
                    <div
                      className={`inputax  relative flexCenter w-full -mx-8  h-32 border border-gray-200 m-2 p-2 mb-6`}
                    >
                      <p className={` mx-8  text-sm text-gray-400`}>
                        Dispense de paiement
                      </p>
                      <div className="flex-col ">
                        <Input
                          id="freeAccount"
                          // value={`${draft}`}
                          type={"checkbox"}
                          className="mx-1"
                          labelText={""}
                          onChange={() => {
                            setFreeAccount(!freeAccount);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>

                    <div className="flexCenter">
                      <Button
                        status="primary"
                        className="btn mb-2.5"
                        type="submit"
                        disabled={isSubmitting || !isValid}
                        onClick={() => buttonOnSubmit(values)}
                      >
                        Enregistrer
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddDoctor;
