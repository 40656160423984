import React, { useState, useEffect } from "react";
import Button from "../../../components/ButtonUI";
// import { MDBBtn } from "mdbreact";
import moment from "../../../helper/moment";
import { ReactComponent as ThreeDots } from "../../../assets/icons/icon-troisPoints.svg";
import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patient.svg";
import img from "../../../assets/images/icons8-website-locked-64.png";
import "./styles.scss";

import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import { User } from "../../../types/user";
import makeToast from "../../../components/Snackbar";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

interface IDoctorDetails {
  updatedAt: Date;
  createdAt: Date;
  firstName: string;
  lastName: string;
  _id: string;
  date: Date;
  type: string;
  gender: string;
  activated: Boolean;
}

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  doctorDetails: any;
  openModal: boolean;
  setOpenModal: Function;
  setSelectedDoctor: Function;

  selectedDoctor: string;

  setColorIndex: Function;
  i: number;
};

const CardDoctor: React.FC<Props> = ({
  doctorDetails,
  openModal,
  setOpenModal,
  setSelectedDoctor,
  selectedDoctor,
  setColorIndex,
  i,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [user, setUser] = useAtom<User | any>(userAtom);
  const [tog, setTog] = useState(doctorDetails.activated);
  const digitsOnly = (value) => /^\d+$/.test(value);
  const verifNumRpps = (num, numType) => {
    switch (numType) {
      case "RPPS":
        if (num && num.length === 11 && digitsOnly(num)) return false;
        else return true;

      case "ADELI":
        if (num && num.length === 9) return false;
        else return true;
    }
  };
  const requrentRoles = {
    ["medecinRequrent"]: "Médecin Requérant",
    ["sageFemme"]: "Sage-femme",
    ["infirmier"]: "Infirmier(e)",
  };
  const rolesLabels = {
    ["medecinResponsable"]: "Médecin Référent",
    ["medecinTeleexpert"]: "Médecin Expert",
    ["medecinChirurgien"]: "Médecin Chirurgien",
  };

  return (
    <div
      className={`card-patient justify-between bg-white  ${
        selectedDoctor === doctorDetails?._id
      }`}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        setSelectedDoctor(doctorDetails?._id);
        setOpenModal(!openModal);
        setColorIndex(i);
      }}
    >
      <div className="flex patient-info ">
        <div
          className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
          style={{
            backgroundColor: colorAvatar[i % colorAvatar.length],
            cursor: "pointer",
          }}
        >
          {doctorDetails?.gender === "male" ? <ManPatient /> : <WomanPatient />}
        </div>
        <div className="details" style={{ cursor: "pointer" }}>
          <div className="flex-grow ">
            <p className="card-title capitalize" style={{ cursor: "pointer" }}>
              {doctorDetails.firstName} {doctorDetails.lastName}{" "}
              <span className="right-0 top-0">
                {(verifNumRpps(doctorDetails?.numCom, "ADELI") ||
                  verifNumRpps(doctorDetails?.numRPPS, "RPPS")) &&
                  "⚠️"}
              </span>
            </p>
            <p className="card-subtitle">
              Dernières activités le{" "}
              <span>
                {moment(doctorDetails.updatedAt).format("DD MMMM YYYY à HH:MM")}
              </span>
            </p>

            <span className="box">
              {doctorDetails.type === "medecin"
                ? (rolesLabels["medecin"] =
                    doctorDetails.requrentType &&
                    requrentRoles[doctorDetails.requrentType]
                      ? requrentRoles[doctorDetails.requrentType]
                      : "Médecin Requérant")
                : rolesLabels[doctorDetails.type]}{" "}
            </span>
          </div>
        </div>
      </div>

      <div className="patient-icons">
        <Button
          status="icon-secondary"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedDoctor(doctorDetails?._id);
          }}
        >
          {" "}
          <ThreeDots />
          {!doctorDetails.activated && (
            <span>
              <img className="w-6 mt-6 mx-3 " src={img} />{" "}
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default CardDoctor;
