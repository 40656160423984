import React, { useState } from "react";
import Button from "../../../components/ButtonUI";
import "./styles.scss";
import numAdliSchema from "./schemas/numAdli";
import { addOrModifyNumAdli } from "../../../api/medecin";
import { Formik } from "formik";
import { User } from "../../../types/user";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import makeToast from "../../../components/Snackbar";
import Input from "../../../components/Input";
import ConfirmationModal from "./ConfirmationModal";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
}

export const AddNumAdeli: React.FC<Props> = ({ openModal, setOpenModal }) => {
  const [user, setUser] = useAtom(userAtom);
  const [confirmModal, setConfirmModal] = useState(false);

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd">
        <Formik
          initialValues={{
            numCom: "" || user?.numCom,
            numRPPS: "" || user?.numRPPS,
          }}
          onSubmit={async (values) => {
            try {
              const newUser = await addOrModifyNumAdli({ ...values });
              const { numCom,numRPPS } = newUser;

              setUser({
                ...user,
                numCom:numCom,
                numRPPS:numRPPS,
              } as User);
              setOpenModal(!openModal);
              if (newUser.id) {
                makeToast("success", "N° RPPS / ADELI ajoutée avec succès.");

              } else {
                makeToast("error", "Ajout du N° RPPS / ADELI  a échouher.");
              }
            } catch (err) {
              console.log("[error] add Patient -on Submit :", err);
            }
          }}
          validationSchema={numAdliSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <div
              
                className="flex-auto py-7 px-8 min-w-full"
              >
                <div className="flex flex-wrap items-baseline">
                  <h1 className="mb-10">N° ADELI / RPPS</h1>
                </div>
                <div className="flex justify-center items-center gap-5  my-2">
                  <span>N° ADELI</span>
                  <Input
                    id="numCom"
                    placeholder="N° ADELI"
                    value={values.numCom}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                    
                  />
                </div>
                {errors.numCom && (
                  <span className="flexCenter m-2 text-gray-400 text-sm">
                    {" "}
                    {errors.numCom}
                  </span>
                )}
                <div className="flex justify-center items-center gap-5 my-2 ">
                  <span>N° RPPS </span>
                  <Input
                    id="numRPPS"
                    placeholder="N° RPPS "
                    value={values.numRPPS}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                   
                  />
                </div>
                {errors.numRPPS && (
                  <span className="flexCenter m-2 text-gray-400 text-sm">
                    {" "}
                    {errors.numRPPS}
                  </span>
                )}

                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-left my-6 btn-delete"
                  onClick={() => setOpenModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-right my-6"
                  onClick={()=>setConfirmModal(true)}
                >
                  Valider
                </Button>
            {
              confirmModal &&     <ConfirmationModal
              openModal={confirmModal}
              setOpenModal={setConfirmModal}
              setOpenModalAddDoctor={handleSubmit}
            />
            }
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddNumAdeli;
