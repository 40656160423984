import { CalendarOptions } from "@fullcalendar/common";

export default function getCalendarOptions(
  locale,
  isResponsable,
  nbrExperts,
  scrollToTime,
  onClickAddEvent,
  onClickConfigureAgenda
): CalendarOptions {
  const customButtons = {
    addEvent: {
      text: "Configurer agenda",
      click: onClickAddEvent,
    },
    agendaSetup: {
      text: "Disponibilité générale",
      click: onClickConfigureAgenda,
    },
  };
  return {
    initialView: "timeGridWeek",
    locale,
    nowIndicator: true,
    allDaySlot: false,
    contentHeight: window.innerHeight - 200,
    scrollTimeReset: false,
    scrollTime: scrollToTime,
    customButtons: customButtons,
    headerToolbar: {
      left: "",

      center: "title",
      right: isResponsable ? "agendaSetup" : "addEvent",
    },
    slotLabelFormat: {
      hour: "2-digit",
      minute: "2-digit",
      meridiem: "short",
    },
    slotDuration: "01:00:00",
    slotLabelInterval: "01:30",
    views: {
      timeGridWeek: {
        titleFormat: { year: "numeric", month: "long" },
        dayHeaderFormat: { weekday: "long" },
      },
    },
    viewDidMount: () => {
      if (!document) return;

      const fcBtnGroupElCollection =
        document.getElementsByClassName("fc-button-group") || [];
      if (fcBtnGroupElCollection.length > 0) {
        const viewChangeBtnBgElement = document.createElement("div");
        viewChangeBtnBgElement.classList.add("fc-views-button-group-bg");
        fcBtnGroupElCollection[0].appendChild(viewChangeBtnBgElement);
      }

      const fcConfigAgendaBtnElCollection =
        document.getElementsByClassName("fc-configureAgenda-button") || [];
      if (fcConfigAgendaBtnElCollection.length > 0) {
        fcConfigAgendaBtnElCollection[0].innerHTML = `<i class="fas fa-cog"></i>`;
      }
    },
    dayHeaderContent: ({ date, text, isToday }) => {
      return {
        html: `
            <p class="fc-custom-dayheader-weekday">
              ${text}
            </p>
            <p class="fc-custom-dayheader-dayofmonth${
              isToday ? " fc-custom-dayheader-dayofmonth-istoday" : ""
            }">
                ${date.getDate().toString().padStart(2, "0")}
            </p>
            `,
      };
    },
    nowIndicatorContent: ({ isAxis, date }) => {
      if (!document || !isAxis) return;

      const customNowIndicatorLineEl = document.createElement("div");
      customNowIndicatorLineEl.id = "now-tag";
      customNowIndicatorLineEl.classList.add(
        "fc-timegrid-custom-now-indicator-line"
      );

      const fcMediaScreenElCollection =
        document.getElementsByClassName("fc-media-screen") || [];
      if (fcMediaScreenElCollection.length > 0) {
        customNowIndicatorLineEl.style.width =
          fcMediaScreenElCollection[0].clientWidth + "px";
      }

      const customNowIndicatorTagWrpEl = document.createElement("div");
      customNowIndicatorTagWrpEl.classList.add(
        "fc-timegrid-custom-now-indicator-tag-wrapper"
      );

      const customNowIndicatorTagTxtEl = document.createElement("span");
      customNowIndicatorTagTxtEl.classList.add(
        "fc-timegrid-custom-now-indicator-tag-text"
      );

      customNowIndicatorTagTxtEl.innerText = `${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;

      customNowIndicatorTagWrpEl.appendChild(customNowIndicatorTagTxtEl);
      customNowIndicatorLineEl.appendChild(customNowIndicatorTagWrpEl);
      return { domNodes: [customNowIndicatorLineEl] };
    },
  };
}
