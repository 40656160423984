import React, { useState } from "react";
import Icon from "../../components/Icon";
import { User } from "../../types/user";
import AddCursus from "./modal/AddCursus";

const cursus = [
  {
    type: "Diplome",
    annee: "2006",
    university: "Faculté de médecine de Marseille",
  },
  {
    type: "Diplome",
    annee: "2003",
    university: "Faculté de médecine de Marseille",
  },
  {
    type: "Diplome",
    annee: "1999",
    university: "Faculté de médecine de Marseille",
  },
];

const Cursus: React.FC<User | any> = ({ userProps }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="cursus">
      <div className="headerContainer flex items-center relative">
        <Icon
          isIcon={true}
          name="hat"
          transform="scale(1.1)"
          className="ml-5"
        />
        <p className="header">CURSUS</p>
        <p
          className="absolute right-0	modifier"
          onClick={() => setOpenModal(true)}
        >
          Modifier
        </p>
      </div>
      <div className="cursusContainer">
        {userProps.studiesHistory?.length ? (
          userProps.studiesHistory.map((d, index) => {
            return (
              <div className="flex">
                <div className="images">
                  <Icon isIcon={true} name="hat" />

                  {index !== cursus.length - 1 ? (
                    <Icon
                      name="monCompte-cursus"
                      width={0}
                      height={33}
                      isIcon={true}
                    />
                  ) : (
                    <Icon
                      name="monCompte-cursus"
                      width={0}
                      height={0}
                      isIcon={true}
                    />
                  )}
                </div>
                <div className="details">
                  <div className="flex items-center space-x-2">
                    <p className="typeDiplome">{d.diplome}</p>
                    <span className="w-1 h-1 bg-black rounded"></span>
                    <p className="anneeDiplome">{d.year}</p>
                  </div>
                  <p className="university">{d.university}</p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="card-subtitle flexCenter mt-20">
            {" "}
            Vous n'avez pas encore ajouté cursus universitaire
          </p>
        )}
      </div>
      {openModal && (
        <AddCursus
          openModal={openModal}
          setOpenModal={setOpenModal}
          doctorId={userProps._id}
        />
      )}
    </div>
  );
};

export default Cursus;
