import axios from 'axios';
import { KATOMI_API_ENDPOINT } from "../config/api"
export const baseURL = KATOMI_API_ENDPOINT

const Client = axios.create({
  baseURL
});

export const config = (token: string | null) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};
export const config2 = (token: string | null) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent: { loaded: any; }) => console.log(progressEvent.loaded),
  };
};
// POST action
const post = async (url: string, data: any) => {
  try {
    const token = localStorage.getItem('token');
    let response;
    if (token)
      response = await Client.post(url, data, config(token));
    else
      response = await Client.post(url, data);

    return response;
  } catch (err) {
    console.error({ err });

  }
};
// POST action
const post2 = async (url: string, data: any,onUploadProgress: any) => {
  try {
    const token = localStorage.getItem('token');
    let response;
    if (token) {
      response = await Client.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
    }
    else
      response = await Client.post(url, data);

    return response;
  } catch (err) {
    console.error({ err });

  }
};


// GET action
const get = async (url: string) => {
  try {
    const token = localStorage.getItem('token');
    let response;
    if (token)
      response = await Client.get(url, config(token));
    else
      response = await Client.get(url, config(token));
    return response.data;
  } catch (err) {
    console.error({ err });

  }
};

// PATCH action
const patch = async (url: string, data: any) => {
  try {
    const token = localStorage.getItem('token');
    let response;
    if (token)
      response = await Client.patch(url, data, config(token));
    else
      response = await Client.patch(url, data);
    return response.data;
  } catch (err) {
    console.error({ err });

  }
};
const update = async (url: string, data: any) => {
  try {
    const token = localStorage.getItem('token');
    let response;
    if (token)
      response = await Client.put(url, data, config(token));
    else
      response = await Client.put(url, data);
    return response.data;
  } catch (err) {
    console.error({ err });

  }
};

// DELETE action
const remove = async (url: string) => {
  try {
    const token = localStorage.getItem('token');
    let response;
    if (token)
      response = await Client.delete(url, config(token));
    else
      response = await Client.delete(url);
    return response.data;
  } catch (err) {
    console.error({ err });

  }
};

export { get, post, remove, patch, update, post2 };


