import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { addPaymentMethod } from "../../api/stripe";

import "./styles.scss";
import { useState } from "react";
import makeToast from "../Snackbar";

const StripePaymentMethodForm = ({ onSave }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [sendLoad, setSendLoad] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    try {
      setSendLoad(true);
      setDisabled(true);
      let { error, paymentMethod } = await stripe!.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)!,
      });

      if (error) throw error;

      const resp = await addPaymentMethod(paymentMethod);
      console.log("[debug] payment method - ", { resp });

      setSendLoad(false);
      makeToast("success", "Moyen de paiement modifié avec succès. ");

      onSave();
    } catch (error) {
      console.error(error);
      makeToast("error", "Veuillez vérifier vos informations. ");
      setSendLoad(false);
      setDisabled(false);
    }
  };

  return (
    <div id="stripe-payment-method">
      <>
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                color: "#424770",
                fontFamily: '"Ubuntu", sans-serif',
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
        <button
          className="buttonPay"
          type="submit"
          disabled={!stripe || !elements || disabled}
          onClick={handleSubmit}
        >
          {" "}
          {!sendLoad ? (
            "Enregistrer"
          ) : (
            <p className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 self-center border-purple-500 mx-auto"></p>
          )}
        </button>
      </>
    </div>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!, {
  locale: "fr",
});

const StripePaymentMethodChange = ({ onSave }) => (
  <Elements stripe={stripePromise}>
    <StripePaymentMethodForm onSave={onSave} />
  </Elements>
);

export default StripePaymentMethodChange;
