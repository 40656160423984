import { Link, useHistory } from "react-router-dom";
import Button from "../../components/ButtonUI";
import makeToast from "../../components/Snackbar";
import Input from "../Input";
interface Props {
  closeModal: any;
  openPrescriptionModal: any;
  seenByExpert: any;
  demandId: string;
  quit: boolean;
  formikAcceptPriseEnCharge: any;
}

const PriseEnCharge = (props: Props) => {
  const history: any = useHistory();

  const {
    closeModal,
    demandId,
    openPrescriptionModal,
    seenByExpert,
    quit,
    formikAcceptPriseEnCharge,
  } = props;
  return (
    <div className="modal-end-consultation  flex-col p-2 space-y-5">
      
      
        <div className="inline-flex items-center text-md text-gray-500 m-1 p-2 leading-none ">
          <div className="relative  mt-5">
            <label className="absolute -top-5 text-gray-400 text-sm ">
              Date de prise en charge :
            </label>
            <Input
              id="datePriseEnCharge"
              error={
                formikAcceptPriseEnCharge.touched.datePriseEnCharge
                  ? formikAcceptPriseEnCharge.errors.datePriseEnCharge
                  : undefined
              }
              value={formikAcceptPriseEnCharge.values.datePriseEnCharge}
              type="date"
              onChange={formikAcceptPriseEnCharge.handleChange}
              className="custom-Input mb-2"
              inlineLabel={false}
              onfocus={true}
              image=""
            />

            {true && (
              <span className="absolute top-0 bottom-0 right-0">
                <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                  ✯
                </span>
              </span>
            )}
          </div>
        </div>
        <div className="inline-flex items-center text-md text-gray-500 m-1 p-2 leading-none ">
          <div className="relative  mt-5">
            <label className="absolute -top-5 text-gray-400 text-sm ">
              Lieu :
            </label>
            <Input
              id="lieuPriseEnCharge"
              placeholder=""
              type="text"
              error={
                formikAcceptPriseEnCharge.touched.lieuPriseEnCharge
                  ? formikAcceptPriseEnCharge.errors.lieuPriseEnCharge
                  : undefined
              }
              value={formikAcceptPriseEnCharge.values.lieuPriseEnCharge}
              className="custom-Input mb-2"
              inlineLabel={false}
              onfocus={true}
              onChange={formikAcceptPriseEnCharge.handleChange}
              image=""
            />

            {true && (
              <span className="absolute top-0 bottom-0 right-0">
                <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                  ✯
                </span>
              </span>
            )}
          </div>
        </div>
        <div className="mt-2 flexCenter  space-x-10">
          
        {formikAcceptPriseEnCharge.isValid ?<Button
            status="secondary"
            width="146.6px"
            height="50px"
            onClick={async () => {
              await formikAcceptPriseEnCharge.handleSubmit();
            }}
            disabled={formikAcceptPriseEnCharge.isSubmitting}
          >
            Confirmer
         
          </Button> : <></> } 
     

          <Button
            status="secondary"
            width="167px"
            height="50px"
            onClick={async () => {
              closeModal();
            }}
          >
            Annuler
          </Button>
        </div>
    
    </div>
  );
};

export default PriseEnCharge;
