import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { verifyingSubscription } from "../../api/stripe";
import { userAtom } from "../../state/auth";
import Button from "../ButtonUI";
import Spinner from "../Spinner";

type Props = {
  userType: any;
  setPriceId: Function;
};
const PricingCard: React.FC<Props> = ({ userType, setPriceId }) => {
  const [user, setUser] = useAtom(userAtom);
  const [selctedProduct, setSelctedProduct] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [subId, setSubId] = useState();
  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      setLoading(true);
      {
        try {
          const sub = await verifyingSubscription({
            subscriptionId: user?.stripeSubscriptionId,
            userId: user?._id,
          });
          console.log(sub);

          if (sub) {
            setSubId(sub.planId);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          Promise.reject(error);
        }
      }
    }
    if (
      user &&
      user?.stripeSubscriptionId &&
      user?.stripeSubscriptionId !== null
    ) {
      fetchMyAPI();
    }
  }, []);

  const reqProducts = [
    {
      priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_REQ_T1,
      price: "10",
      limit: true,
    },
    {
      priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_REQ_T2,
      price: "20",
      limit: false,
    },
  ].filter(function (value) {
    return value.priceId !== subId;
  });

  const expertProducts = [
    {
      priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_EXP_T1,
      price: "20",
      limit: true,
    },
    {
      priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_EXP_T2,
      price: "40",
      limit: false,
    },
  ].filter(function (value) {
    return value.priceId !== subId;
  });
  const surgProducts = [
    {
      priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_SRG,
      price: "100",
      limit: false,
    },
  ];
  const CardByUser = () => {
    const type = userType ? userType : user?.type;
    switch (type) {
      case "medecin":
        return (
          <div className="flex gap-x-5">
            {reqProducts.map((p, i) => (
              <div
                className={
                  selctedProduct === p.priceId
                    ? `cursor-pointer card text-center w-1/2  border shadow-2xl border-indigo-700 border-8`
                    : `cursor-pointer card text-center w-1/2  border border-gray-400 `
                }
                onClick={() => {
                  if (subId && subId === p.priceId) {
                  } else {
                    setSelctedProduct(p.priceId);
                    setPriceId(p.priceId);
                  }
                }}
              >
                <div className="title">
                  <i className="fa fa-rocket" aria-hidden="true"></i>
                  <h2>Mensuel</h2>
                </div>
                <div className="price">
                  <h4>
                    <sup>€</sup>
                    {p.price}
                    <sub>HT</sub>
                  </h4>
                </div>
                <div className="option">
                  <ul>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {p.limit
                        ? "Jusqu'à  10 patients"
                        : "Nombre illimité de patients"}
                    </li>
                    <li>
                      <i
                        className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                        aria-hidden="true"
                      ></i>
                      {p.limit
                        ? "Jusqu'à  10 demandes de téléexpertise"
                        : "Nombre illimité de demandes de téléexpertise"}
                    </li>
                    <li>
                      <i
                        className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                        aria-hidden="true"
                      ></i>{" "}
                      Accès Web et Mobile
                    </li>
                    <li>
                      <i
                        className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                        aria-hidden="true"
                      ></i>{" "}
                      Support Technique
                    </li>
                  </ul>
                </div>

                {subId && subId !== "canceled" && (
                  <p className=" border border-blue-600 rounded-full text-blue-600 p-2">
                    {" "}
                    {process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_REQ_T1 ===
                    subId
                      ? "Mise à niveau "
                      : "Rétrograder votre abonnement"}
                  </p>
                )}
              </div>
            ))}
          </div>
        );

      case "medecinTeleexpert":
        return (
          <div className="flex gap-x-5">
            {expertProducts.map((p, i) => (
              <div
                className={
                  selctedProduct === p.priceId
                    ? `cursor-pointer card text-center w-1/2  border shadow-2xl border-indigo-700 border-8`
                    : `cursor-pointer card text-center w-1/2  border border-gray-400 `
                }
                onClick={() => {
                  if (subId && subId === p.priceId && i == 1) {
                    return;
                  } else {
                    setSelctedProduct(p.priceId);
                    setPriceId(p.priceId);
                  }
                }}
              >
                <div className="title">
                  <i className="fa fa-rocket" aria-hidden="true"></i>
                  <h2>Mensuel</h2>
                </div>
                <div className="price">
                  <h4>
                    <sup>€</sup>
                    {p.price}
                    <sub>HT</sub>
                  </h4>
                </div>
                <div className="option">
                  <ul>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {p.limit
                        ? "Jusqu'à  10 patients"
                        : "Nombre illimité de patients"}
                    </li>
                    <li>
                      <i
                        className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                        aria-hidden="true"
                      ></i>
                      {p.limit
                        ? "Jusqu'à  10 demandes de téléexpertise"
                        : "Nombre illimité de demandes de téléexpertise"}
                    </li>
                    <li>
                      <i
                        className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                        aria-hidden="true"
                      ></i>{" "}
                      Accès Web et Mobile
                    </li>
                    <li>
                      <i
                        className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                        aria-hidden="true"
                      ></i>{" "}
                      Support Technique
                    </li>
                  </ul>
                </div>

                {subId && (
                  <p className=" border border-blue-600 rounded-full text-blue-600 p-2">
                    {" "}
                    {process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_EXP_T1 ===
                    subId
                      ? "Mise à niveau "
                      : "Rétrograder votre abonnement"}
                  </p>
                )}
              </div>
            ))}
          </div>
        );

      case "medecinChirurgien":
        return (
          <div className="flex gap-x-5">
            {surgProducts.map((p, i) => (
              <div
                className={
                  selctedProduct === p.priceId
                    ? `cursor-pointer card text-center w-1/2  border shadow-2xl border-indigo-700 border-8`
                    : `cursor-pointer card text-center w-1/2  border border-gray-400 `
                }
                onClick={() => {
                  if (!(subId && subId === p.priceId && i == 0)) {
                    setSelctedProduct(p.priceId);
                    setPriceId(p.priceId);
                  }
                }}
              >
                <div className="title">
                  <i className="fa fa-rocket" aria-hidden="true"></i>
                  <h2>Mensuel</h2>
                </div>
                <div className="price">
                  <h4>
                    <sup>€</sup>
                    {p.price}
                    <sub>HT</sub>
                  </h4>
                </div>
                <div className="option">
                  <ul>
                    {/* <li>
                <i className="fa fa-check" aria-hidden="true"></i> {p.limit ? "Jusqu'à  10 patients" : "Nombre illimité de patients"}
              </li> */}
                    <li>
                      <i
                        className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                        aria-hidden="true"
                      ></i>
                      {p.limit
                        ? "Jusqu'à  10 demandes de téléexpertise"
                        : "Nombre illimité de demandes de téléexpertise"}
                    </li>
                    <li>
                      <i
                        className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                        aria-hidden="true"
                      ></i>{" "}
                      Accès Web et Mobile
                    </li>
                    <li>
                      <i
                        className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                        aria-hidden="true"
                      ></i>{" "}
                      Support Technique
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        );
    }
  };
  return loading ? <Spinner /> : <div>{CardByUser()}</div>;
};

export default PricingCard;
