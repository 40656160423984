import React, { useState } from "react";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import fcLocaleFR from "@fullcalendar/core/locales/fr";
import moment from "moment";
import AgendaConfigForm from "../../../components/AgendaConfigForm";
import Modal from "../../../components/ModalUI";
import { userAtom } from "../../../state/auth";
import Spinner from "../../../components/Spinner";
import getCalendarOptions from "./calendarOptions";
import CalendarEvent from "./CalendarEvent";
import "./styles.scss";
import ICalendarEvent from "../../../types/ICalendarEvent";
import Icon from "../../../components/Icon";
import { getAgendaConfig } from "../../../api/medecin";
import { getAllMyLeaves } from "../../../api/medecin";

import DayPlan from "./DayComp";

const AgendaKatomi: React.FC = () => {
  const [showAgendaModal, setShowAgendaModal] = React.useState("") as any;
  const [agendaConfig, setAgendaConfig] = useState<any[]>([]);
  const [leaves, setLeaves] = useState([]) as any;
  const [eventsList, setEventsList] = React.useState<ICalendarEvent[]>([]);
  const [user] = useAtom(userAtom);
  const [nbravaibleDoctors, setNbrAvaibleDoctors] = useState(0);
  const [selectedDay, setSelectedDay] = useState(moment().format("dddd"));
  const [weekConfig, setWeekConfig] = useState<any>();
  const fcRef = React.useRef<any>(null);
  const isResponsable = user?.type === "medecinResponsable" ? true : false;
  const now = moment();
  const calendarScrollToTime =
    now.hours() > 2
      ? now.subtract(90, "minutes").format("HH:mm:ss")
      : now.format("HH:mm:ss");

  const calendarOptions = getCalendarOptions(
    fcLocaleFR,
    isResponsable,
    nbravaibleDoctors,
    calendarScrollToTime,
    () => setShowAgendaModal("newEvent"),
    () => setShowAgendaModal("agendaSetup")
  );

  const loadAvailabilities = async () => {
    try {
      // TODO
      // get agenda-config data
      // map data to ICalendarEvent
      // setEventsList()
      let leaves;
      if (!isResponsable)
        leaves = await getAllMyLeaves({ doctorId: user?._id });

      const { agendaConfig, avaibleDoctors, deo } = await getAgendaConfig(
        user?._id
      );
      if (agendaConfig) setAgendaConfig(agendaConfig);

      setWeekConfig(deo);

      const startOfTheWeek = moment().startOf("week");
      const weekMap = {
        Lundi: startOfTheWeek.clone(),
        Mardi: startOfTheWeek.clone().add(1, "d"),
        Mercredi: startOfTheWeek.clone().add(2, "d"),
        Jeudi: startOfTheWeek.clone().add(3, "d"),
        Vendredi: startOfTheWeek.clone().add(4, "d"),
        Samedi: startOfTheWeek.clone().add(5, "d"),
        Dimanche: startOfTheWeek.clone().add(6, "d"),
      };

      const conge = leaves?.data ? leaves.data.leave : [];

      const calendarEventsIndisponibility: [] = conge.map((item) => {
        return {
          start: item.startDate,
          end: item.endDate,
          testIndisponibility: true,
        };
      });

      const calendarEvents: ICalendarEvent[] = agendaConfig.map((item) => {
        if (item.rnageOfTime.start === "" && item.rnageOfTime.end === "") {
          item.rnageOfTime.start = "00:01";
          item.rnageOfTime.end = "00:01";
        }
        const [startTimeHours, startTimeMinutes] =
          item.rnageOfTime.start.split(":");
        const [endTimeHours, endTimeMinutes] = item.rnageOfTime.end.split(":");

        const testConge = conge.filter((el) => {
          return (
            moment(
              weekMap[item.day]
                .hours(startTimeHours)
                .minutes(startTimeMinutes)
                .toISOString(true)
            ).isBetween(el.startDate, el.endDate) &&
            moment(
              weekMap[item.day]
                .hours(endTimeHours)
                .minutes(endTimeMinutes)
                .toISOString(true)
            ).isBetween(el.startDate, el.endDate)
          );
        });

        if (isEmpty(testConge))
          return {
            start: weekMap[item.day]
              .hours(startTimeHours)
              .minutes(startTimeMinutes)
              .toISOString(true),
            end: weekMap[item.day]
              .hours(endTimeHours)
              .minutes(endTimeMinutes)
              .toISOString(true),
            testIndisponibility: false,
          };
        else return {};
      });

      if (!isResponsable)
        // TODO define responsable
        setEventsList([...calendarEvents, ...calendarEventsIndisponibility]);
      else setEventsList(calendarEvents);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    loadAvailabilities();
    setSelectedDay(moment().format("dddd"));
  }, [user, showAgendaModal]);

  return (
    <section id="agenda" className="flex">
      <div className="w-full relative">
        <FullCalendar
          ref={fcRef}
          plugins={[dayGridPlugin, timeGridPlugin]}
          themeSystem="bootstrap"
          eventContent={CalendarEvent}
          events={eventsList}
          {...calendarOptions}
          eventClick={async (e) => {
            setSelectedDay(
              moment(e.event._instance?.range.start).format("dddd")
            );
            if (isResponsable) {
              setShowAgendaModal("agendaSetup");
            }
          }}
        />
        {false && (
          <div className="absolute inset-0 bg-opacity-agenda">
            <Spinner />
          </div>
        )}
      </div>

      {showAgendaModal === "newEvent" ? (
        <Modal
          showModal={showAgendaModal.length > 0}
          slidemodal={!isResponsable}
          closeModal={() => {
            // When close modal reset component
            setShowAgendaModal("");
          }}
        >
          <div>
            <div className="icon-secondary cursor-pointer float-right mt-4 mr-2">
              <Icon
                isIcon={true}
                name="close"
                onClick={() => {
                  setShowAgendaModal("");
                }}
                style={{ marginTop: "6px" }}
              />
            </div>
            <AgendaConfigForm
              closeModal={() => {
                setShowAgendaModal("");
              }}
              agendaConfig={agendaConfig}
            />{" "}
          </div>
        </Modal>
      ) : showAgendaModal === "agendaSetup" ? (
        <>
          {showAgendaModal.length > 0 ? (
            <>
              <div
                className={`fixed inset-0 z-50 outline-none focus:outline-none  bg-opacity-modal  ${" flex justify-center items-center"}`}
              >
                <div
                  className={`relative w-2/4 h-full  my-6 ml-auto  ${"m-auto"}`}
                >
                  <div className=" h-full">
                    {" "}
                    <DayPlan
                      data={weekConfig}
                      setShowAgendaModal={setShowAgendaModal}
                      day={selectedDay}
                    />
                  </div>
                </div>
                {/* </div> */}
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </>
      ) : null}
    </section>
  );
};

export default AgendaKatomi;
