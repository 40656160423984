import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import Fuse from "fuse.js";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  getDemandeByChirugienPaginated,
  getDemandeByDoctors,
  getDemandeByDoctorsPaginated,
  getDemandeByRequrentPaginated,
  getDemandeByTeleexpertPaginated,
} from "../../api/medecin";
import { userAtom } from "../../state/auth";
import { refreshAtomRequest } from "../../state/refreshRequestList";
import TasksContainer from "../../components/MyRequests/TasksContainer";
import Input from "../../components/Input";
import DraftTask from "../../components/MyRequests/DraftTask";
import Button from "../../components/ButtonUI";

import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import { ReactComponent as Draft } from "../../assets/illustrations/Edraft.svg";
import { ReactComponent as Demandes } from "../../assets/illustrations/demandes.svg";
import "./styles/main.css";
import "./styles.scss";

const MyRequests = () => {
  const typingTimeoutRef = useRef(null) as any;
  const [user] = useAtom(userAtom);
  const [demandesEncours, setDemandesEncours] = useState([]) as any;
  const [demandesWaiting, setDemandesWaiting] = useState([]) as any;
  const [demandesDone, setDemandesDone] = useState([]) as any;
  const [demandesDraft, setDemandesDraft] = useState([]) as any;
  const [demandesDoneChirugien, setDemandesDoneChirugien] = useState([]) as any;
  const [demandesEncoursChirugien, setDemandesEncoursChirugien] = useState(
    []
  ) as any;
  const [refresh] = useAtom(refreshAtomRequest);
  const [update, setUpdate] = useState(false);
  const [strFilter, setStrFilter] = React.useState<string>("");
  const [openTab, setOpenTab] = React.useState(1);
  const [currentPageDone, setCurrentPageDone] = useState(1);
  const [currentPageNotYet, setCurrentPageNotYet] = useState(1);
  const [currentPageDraft, setCurrentPageDraft] = useState(1);
  const [currentPageInProgress, setCurrentPageInProgress] = useState(1);
  const [indexDone, setIndexDone] = useState(2);
  const [indexDoneChirugien, setIndexDoneChirugien] = useState(2);
  const [indexWaiting, setIndexWaiting] = useState(2);
  const [indexEncours, setIndexEncours] = useState(2);
  const [indexEncoursChirugien, setIndexEncoursChirugien] = useState(2);
  const [indexDraft, setIndexDraft] = useState(2);
  const [totalCountsEnCours, setTotalCountsEnCours] = useState(0) as any;
  const [totalCountsDraft, setTotalCountsDraft] = useState(0) as any;
  const [totalCountsWaiting, setTotalCountsWaiting] = useState(0) as any;
  const [totalCountsDone, setTotalCountsDone] = useState(0) as any;
  const [hasMoreEncours, setHasMoreEncours] = useState(true);
  const [hasMoreEncoursChirugien, setHasMoreEncoursChirugien] = useState(true);
  const [hasMoreWaiting, setHasMoreWaiting] = useState(true);
  const [hasMoreDone, setHasMoreDone] = useState(true);
  const [hasMoreDoneChirugien, setHasMoreDoneChirugien] = useState(true);
  const [hasMoreDraft, setHasMoreDraft] = useState(true);
  const [limit] = useState(10);
  const done = ["RESPONSE", "SEEN_BY_REQ"];
  const notYet = ["WAIT_FOR_IMGS", "NOT_ASSIGNED", "DELAI_EXPIRE"];
  const InProgress = ["IN_PROGRESS", "SEEN_BY_EXPERT"];
  const [title, setTitle] = useState(
    user?.type === "medecin" ? "En Cours" : "Récemment Attribuées"
  );
  const titleClass = classNames("p-1", "rounded-sm", {
    "border-b-4": false,
    "border-indigo-500": true,
  });
  const [textSearch, setTextSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    typingTimeoutRef.current = setTimeout(() => {
      if (searchValue.length >= 1) {
        setDemandesEncours([]);
        setDemandesWaiting([]);
        setDemandesDone([]);
        setDemandesDraft([]);
        setDemandesDoneChirugien([]);
        setDemandesEncoursChirugien([]);
        setTextSearch(searchValue);
      } else {
        clearSearch();
      }
    }, 1000);

    return () => clearTimeout(typingTimeoutRef.current);
  }, [searchValue]);

  const clearSearch = () => {
    setSearchValue("");
    setTextSearch("");
  };

  const onSearch = (e) => {
    const value = e.target.value.toLowerCase().trim();
    setSearchValue(value);
    if (value === "") {
      window.location.reload();
    }
  };
  // const onSearch = (e) => {
  //   const value: string = e.target.value.toLowerCase().trim();
  //   clearTimeout(typingTimeoutRef.current);
  //   setSearchValue(value);
  //   typingTimeoutRef.current = setTimeout(() => {
  //     if (value.length >= 1) {
  //       setTextSearch(value);
  //     } else {
  //       setTextSearch("");
  //     }
  //   }, 1000);
  // };
  // useEffect(() => {
  //   return () => {
  //     clearTimeout(typingTimeoutRef.current);
  //   };
  // }, []);

  const demandesData = async () => {
    try {
      let chirugienDoneResponse,
        chirugienInProgressResponse,
        doneResponse,
        notYetResponse,
        inProgressResponse,
        draftResponse;

      if (user?.type === "medecinChirurgien") {
        chirugienDoneResponse = await getDemandeByChirugienPaginated(
          currentPageDone,
          limit,
          true,
          textSearch
        );
        chirugienInProgressResponse = await getDemandeByChirugienPaginated(
          currentPageInProgress,
          limit,
          false,
          textSearch
        );
      } else if (user?.type === "medecinTeleexpert") {
        doneResponse = await getDemandeByTeleexpertPaginated(
          currentPageDone,
          limit,
          done,
          textSearch
        );
        inProgressResponse = await getDemandeByTeleexpertPaginated(
          currentPageInProgress,
          limit,
          "INPROGRESS",
          textSearch
        );
        notYetResponse = await getDemandeByTeleexpertPaginated(
          currentPageNotYet,
          limit,
          "NOTYET",
          textSearch
        );
      } else if (user?.type === "medecin") {
        doneResponse = await getDemandeByRequrentPaginated(
          currentPageDone,
          limit,
          done,
          textSearch
        );
        inProgressResponse = await getDemandeByRequrentPaginated(
          currentPageInProgress,
          limit,
          "INPROGRESS",
          textSearch
        );
        notYetResponse = await getDemandeByRequrentPaginated(
          currentPageNotYet,
          limit,
          "NOTYET",
          textSearch
        );
        draftResponse = await getDemandeByRequrentPaginated(
          currentPageDraft,
          limit,
          "DRAFT",
          textSearch
        );
      } else {
        doneResponse = await getDemandeByDoctorsPaginated(
          currentPageDone,
          limit,
          done,
          textSearch
        );
        notYetResponse = await getDemandeByDoctorsPaginated(
          currentPageNotYet,
          limit,
          "NOTYET",
          textSearch
        );
        inProgressResponse = await getDemandeByDoctorsPaginated(
          currentPageInProgress,
          limit,
          "INPROGRESS",
          textSearch
        );
      }
      // Handle "chirugienDoneResponse" requests
      if (chirugienDoneResponse && chirugienDoneResponse.dones.length > 0) {
        if (chirugienDoneResponse.dones.length < limit) {
          setHasMoreDoneChirugien(false);
        }
        setDemandesDoneChirugien(chirugienDoneResponse.dones);
        setTotalCountsDone(chirugienDoneResponse?.doneCount);
      } else {
        setHasMoreDoneChirugien(false);
      }
      // Handle "chirugienInProgressResponse" requests
      if (
        chirugienInProgressResponse &&
        chirugienInProgressResponse.InProgress.length > 0
      ) {
        if (chirugienInProgressResponse.InProgress.length < limit) {
          setHasMoreEncoursChirugien(false);
        }
        setDemandesEncoursChirugien(chirugienInProgressResponse.InProgress);
        setTotalCountsEnCours(chirugienInProgressResponse?.inProgressCount);
      } else {
        setHasMoreEncoursChirugien(false);
      }
      // Handle "Draft" requests
      if (draftResponse && draftResponse.Draft.length > 0) {
        if (draftResponse.Draft.length < limit) {
          setHasMoreDraft(false);
        }
        setDemandesDraft(draftResponse.Draft);
        setTotalCountsDraft(draftResponse?.counts?.draft);
      } else {
        setHasMoreDraft(false);
      }
      // Handle "Done" requests
      if (doneResponse && doneResponse.dones.length > 0) {
        if (doneResponse.dones.length < limit) {
          setHasMoreDone(false);
        }
        setDemandesDone(doneResponse.dones);
        setTotalCountsDone(doneResponse?.counts?.dones);
      } else {
        setHasMoreDone(false);
      }

      // Handle "Not Yet" requests
      if (notYetResponse && notYetResponse.notYet.length > 0) {
        if (notYetResponse.notYet.length < limit) {
          setHasMoreWaiting(false);
        }
        setDemandesWaiting(notYetResponse.notYet);
        setTotalCountsWaiting(notYetResponse?.counts?.notYet);
      } else {
        setHasMoreWaiting(false);
      }

      // Handle "In Progress" requests
      if (inProgressResponse && inProgressResponse.InProgress.length > 0) {
        if (inProgressResponse.InProgress.length < limit) {
          setHasMoreEncours(false);
        }
        setDemandesEncours(inProgressResponse.InProgress);

        setTotalCountsEnCours(inProgressResponse?.counts?.InProgress);
      } else {
        setHasMoreEncours(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreDataDone = async () => {
    try {
      let res;
      if (user?.type === "medecin") {
        res = await getDemandeByRequrentPaginated(
          indexDone,
          limit,
          done,
          textSearch
        );
      } else if (user?.type === "medecinTeleexpert") {
        res = await getDemandeByTeleexpertPaginated(
          indexDone,
          limit,
          done,
          textSearch
        );
      } else {
        res = await getDemandeByDoctorsPaginated(
          indexDone,
          limit,
          done,
          textSearch
        );
      }
      if (res) {
        setDemandesDone((prevItems) => {
          return [...prevItems, ...res.dones];
        });

        res.dones.length > 0 ? setHasMoreDone(true) : setHasMoreDone(false);
      }
      setIndexDone((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchMoreDataChirugienDone = async () => {
    try {
      const res = await getDemandeByChirugienPaginated(
        indexDoneChirugien,
        limit,
        true,
        textSearch
      );

      if (res) {
        setDemandesDoneChirugien((prevItems) => {
          return [...prevItems, ...res.dones];
        });
        res.dones.length > 0
          ? setHasMoreDoneChirugien(true)
          : setHasMoreDoneChirugien(false);
      }
      setIndexDoneChirugien((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchMoreDataNotYet = async () => {
    try {
      let res;
      if (user?.type === "medecin") {
        res = await getDemandeByRequrentPaginated(
          indexWaiting,
          limit,
          "NOTYET",
          textSearch
        );
      } else if (user?.type === "medecinTeleexpert") {
        res = await getDemandeByTeleexpertPaginated(
          indexWaiting,
          limit,
          "NOTYET",
          textSearch
        );
      } else {
        res = await getDemandeByDoctorsPaginated(
          indexWaiting,
          limit,
          "NOTYET",
          textSearch
        );
      }
      if (res) {
        setDemandesWaiting((prevItems) => {
          return [...prevItems, ...res.notYet];
        });
        console.log("demandes", res.notYet);
        res.notYet.length > 0
          ? setHasMoreWaiting(true)
          : setHasMoreWaiting(false);
      }
      setIndexWaiting((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreDataInProgress = async () => {
    try {
      let res;
      if (user?.type === "medecin") {
        res = await getDemandeByRequrentPaginated(
          indexEncours,
          limit,
          "INPROGRESS",
          textSearch
        );
      } else if (user?.type === "medecinTeleexpert") {
        res = await getDemandeByTeleexpertPaginated(
          indexEncours,
          limit,
          "INPROGRESS",
          textSearch
        );
      } else {
        res = await getDemandeByDoctorsPaginated(
          indexEncours,
          limit,
          "INPROGRESS",
          textSearch
        );
      }
      if (res) {
        setDemandesEncours((prevItems) => [...prevItems, ...res.InProgress]);
        res.InProgress.length > 0
          ? setHasMoreEncours(true)
          : setHasMoreEncours(false);
      }
      setIndexEncours((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreDataChirugienInProgress = async () => {
    try {
      const res = await getDemandeByChirugienPaginated(
        indexEncoursChirugien,
        limit,
        false,
        textSearch
      );
      if (res) {
        setDemandesEncoursChirugien((prevItems) => [
          ...prevItems,
          ...res.InProgress,
        ]);
        res.InProgress.length > 0
          ? setHasMoreEncoursChirugien(true)
          : setHasMoreEncoursChirugien(false);
      }
      setIndexEncoursChirugien((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreDataDraft = async () => {
    try {
      const res = await getDemandeByRequrentPaginated(
        indexDraft,
        limit,
        "DRAFT",
        textSearch
      );
      if (res) {
        setDemandesDraft((prevItems) => [...prevItems, ...res.Draft]);
        res.Draft.length > 0 ? setHasMoreDraft(true) : setHasMoreDraft(false);
      }
      setIndexDraft((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    demandesData();
    console.log(refresh);
  }, [
    textSearch,
    refresh?.state,
    currentPageDone,
    currentPageNotYet,
    currentPageInProgress,
    currentPageDraft,
  ]);
  const handleKeyDown = (event) => {
    const value: string = event.target.value.toLowerCase().trim();
    if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
      if (value.length >= 1) {
        setDemandesEncours([]);
        setDemandesWaiting([]);
        setDemandesDone([]);
        setDemandesDraft([]);
        setDemandesDoneChirugien([]);
        setDemandesEncoursChirugien([]);
        setTextSearch(value);
        setSearchValue(value);
      } else {
        setSearchValue("");
        setTextSearch("");
      }
    }
  };

  return (
    <>
      <div className="relative flex w-full flex-wrap mx-4 ">
        <span className="z-10 h-full leading-snug font-normal  text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
          <IconSearch />
        </span>
        <Input
          value={searchValue}
          type="text"
          placeholder={
            user?.type === "medecinTeleexpert"
              ? "Rechercher"
              : "Rechercher par patient"
          }
          width="w-full"
          onKeyDown={handleKeyDown}
          onChange={onSearch}
          className="rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white  text-sm outline-none focus:border-blue-500  border-blue-100 w-full pl-10"
        />
      </div>
      <div className={openTab === 1 ? "block" : "hidden relative"}>
        {user?.type === "medecin" && (
          <div className="-mb-px mx-4 last:mr-0 flex-auto w-32 text-center my-2 absolute right-0 top-auto">
            <Button
              status="secondary"
              className={"btnAddPatient text-sm  text-indigo-600 -ml-10 "}
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              // href="#link2"
              role="tablist"
            >
              Brouillons
            </Button>
          </div>
        )}
        {user?.type === "medecinChirurgien" ? (
          <div className=" h-screen mx-3 h-min-screen flex w-full">
            <TasksContainer
              setUpdate={setUpdate}
              title={title}
              titleBorder
              userType={user?.type}
              tasks={demandesEncoursChirugien}
              fetchMoreData={fetchMoreDataChirugienInProgress}
              hasMore={hasMoreEncoursChirugien}
              id="scrollableEncoursChirugienDiv"
              totalCounts={totalCountsEnCours}
            />
            <TasksContainer
              setUpdate={() => setUpdate}
              title="Terminées"
              tasks={demandesDoneChirugien}
              userType={user?.type}
              fetchMoreData={fetchMoreDataChirugienDone}
              hasMore={hasMoreDoneChirugien}
              id="scrollableDoneChirugienDiv"
              totalCounts={totalCountsDone}
            />
          </div>
        ) : (
          <div className=" h-screen mx-3 h-min-screen   flex  ">
            <TasksContainer
              setUpdate={setUpdate}
              title={title}
              titleBorder
              tasks={demandesEncours}
              userType={user?.type}
              fetchMoreData={fetchMoreDataInProgress}
              hasMore={hasMoreEncours}
              id="scrollableEncoursDiv"
              totalCounts={totalCountsEnCours}
            />
            <TasksContainer
              setUpdate={() => setUpdate}
              title="En Attente"
              tasks={demandesWaiting}
              userType={user?.type}
              fetchMoreData={fetchMoreDataNotYet}
              hasMore={hasMoreWaiting}
              id="scrollableWaitingDiv"
              totalCounts={totalCountsWaiting}
            />
            <TasksContainer
              setUpdate={() => setUpdate}
              title="Terminées"
              tasks={demandesDone}
              userType={user?.type}
              fetchMoreData={fetchMoreDataDone}
              hasMore={hasMoreDone}
              id="scrollableDoneDiv"
              totalCounts={totalCountsDone}
            />
          </div>
        )}
      </div>
      <div className={openTab === 2 ? "block" : "hidden relative"}>
        <div className="-mb-px mx-4 last:mr-0 flex-auto w-32 text-center my-2 absolute right-0 top-auto ">
          <Button
            status="secondary"
            className={"btnAddPatient text-sm  text-indigo-600 -ml-10"}
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
            }}
            data-toggle="tab"
            // href="#link2"
            role="tablist"
          >
            Demandes
          </Button>
        </div>

        <div className=" h-screen mx-3 h-min-screen flex w-full">
          <div
            className={`flex mt-10   gap-2 flex-col ${
              user?.type === "medecinChirurgien" ? "w-1/2" : "w-1/3"
            }  h-full`}
          >
            <div className="flex items-center ">
              <h4 className={titleClass}>{"Brouillons"}</h4>
              <span className="p-1 text-gray-500">
                ({totalCountsDraft || demandesDraft.length})
              </span>
            </div>
            <div
              className="p-2 overflow-y-scroll    h-full "
              style={{ height: "100%", overflowY: "scroll" }}
              id="scrollableDraftDiv"
            >
              <InfiniteScroll
                dataLength={demandesDraft?.length}
                next={fetchMoreDataDraft}
                hasMore={hasMoreDraft}
                loader={<h4>Chargement en cours...</h4>}
                scrollableTarget="scrollableDraftDiv"
              >
                <div className="flex flex-col p-2 ">
                  {demandesDraft.map((task: any, i) => (
                    <DraftTask key={i} {...task} />
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyRequests;
