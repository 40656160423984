import { atom } from "jotai";
import type { Stripe } from "stripe";
import {
  getInvoices,
  getDefaultPaymentMethod,
} from "../api/stripe";

export const invoicesListAtom = atom<Stripe.Invoice[]>([]);
export const invoicesListIsLoadingAtom = atom<boolean>(false);

export const defaultPaymentMethodAtom = atom<Stripe.PaymentMethod | null>(null);
export const defaultPaymentMethodIsLoadingAtom = atom<boolean>(false);

export const asyncSetInvoicesListAtom = atom(null, async (_, set) => {
  set(invoicesListIsLoadingAtom, true);

  try {
    const response = await getInvoices();
    if (!response.data)
      throw new Error("no invoices data received from server.");

    set(invoicesListAtom, response.data);
  } catch (error) {
    console.error(error);
  }

  set(invoicesListIsLoadingAtom, false);
});

export const asyncSetDefaultPaymentMethodAtom = atom(null, async (_, set) => {
  set(defaultPaymentMethodIsLoadingAtom, true);

  try {
    const data = await getDefaultPaymentMethod();
    if (!data)
      throw new Error("no default payment method data received from server.");

    set(defaultPaymentMethodAtom, data);
  } catch (error) {
    console.error(error);
  }

  set(defaultPaymentMethodIsLoadingAtom, false);
});
