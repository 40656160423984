import * as Yup from "yup";


export default Yup.object({
  cursus: Yup.array()
  .of(
    Yup.object().shape({
        diplome: Yup.string().required("Required"),
        university: Yup.string().required("Required"),
        year: Yup.string().required("Required"),
    })
  )
});
