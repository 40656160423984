import { Redirect, RouteProps } from "react-router";
import Profile from "../pages/Profile";
import MyRequests from "../pages/MyRequests";



const medecinChirurgienRoutes: RouteProps[] = [
  {
    path: "/demandes",
    component: MyRequests,
    exact: true,

  },
  {
    path: "/profile",
    component: Profile,
    exact: true,
  },
  { 
    render: () => <Redirect to="/demandes" /> },
];


export default medecinChirurgienRoutes;
