import { useState } from "react";
import { FormikValues } from "formik";
import _ from "lodash";
import Input from "../../components/FloatInput";
import SelectComp from "../../components/Select";
import ListSpecilaities from "../../config/constants/specialties.json";
import CountryWithPhoneSuffix from "../../config/constants/pays.json";
import "./stylesSignin.scss";
import voir from "../../assets/images/voir.png";
import ListDepartement from "../../config/constants/departement.json";

const dropdownSexeOptions = [
  { value: "male", label: "Homme" },
  { value: "female", label: "Femme" },
];
const typeOptionGroup = [
  { label: "Requérant", value: "medecin" },
  { label: " Médecin Expert Dermatologue", value: "medecinTeleexpert" },
  {
    label: " Médecin Chirurgien ",
    value: "medecinChirurgien",
  },
];
const typeRequrent = [
  { label: "Médecin", value: "medecinRequrent" },
  { label: "Sage-femme", value: "sageFemme" },
  {
    label: "Infirmier(e)",
    value: "infirmier",
  },
];
interface IProps {
  setFieldValue: Function;
  handleBlur: Function;
  handleChange: Function;
  values: FormikValues;
  errors: any;
  touched: any;
}
const Signup: React.FC<IProps> = ({
  setFieldValue,
  handleBlur,
  values,
  errors,
  touched,
}) => {
  const [passwordFormType1, setPasswordFormType1] = useState("password");
  const [passwordFormType2, setPasswordFormType2] = useState("password");

  return (
    <>
      <form className="w-full min-w-full">
        <div className="flex justify-between flex-wrap  ">
          <div className="relative">
            {" "}
            <Input
              id="lastName"
              labelText="Saisissez votre nom"
              placeholder="Saisissez votre nom"
              type="text"
              value={values.lastName}
              onChange={(e) => setFieldValue("lastName", e.target.value)}
              onBlur={(e) => setFieldValue("lastName", e.target.value)}
              style={{ width: "361px" }}
            />
            {errors.lastName && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.lastName === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="relative">
            {" "}
            <Input
              id="firstName"
              labelText="Saisissez votre prénom"
              placeholder="Saisissez votre prénom"
              type="text"
              value={values.firstName}
              onChange={(e) => setFieldValue("firstName", e.target.value)}
              onBlur={() => handleBlur}
              style={{ width: "361px" }}
            />
            {errors.firstName && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.firstName === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>

          <div className="relative">
            <Input
              id="email"
              labelText="Saisissez votre email"
              placeholder="Saisissez votre email"
              type="text"
              value={values.email}
              onChange={(e) => setFieldValue("email", e.target.value)}
              onBlur={() => handleBlur}
              style={{ width: "361px" }}
            />
            {errors.email && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.email === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>
        </div>

        <div className="flex justify-between flex-wrap">
          <div className="relative">
            <Input
              id="password"
              labelText="Mot de passe"
              placeholder="********"
              value={values.password}
              type={passwordFormType1}
              className="pr-10"
              onChange={(e) => setFieldValue("password", e.target.value)}
              style={{ width: "361px" }}
            />

            <img
              src={voir}
              alt="voir"
              className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setPasswordFormType1(
                  passwordFormType1 === "password" ? "text" : "password"
                );
              }}
            />
            {errors.password && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.password === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs m-1  leading-none   rounded-full">
                    {" "}
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>

          <div className="relative">
            <Input
              id="passwordConfirmation"
              labelText="Confirmer votre mot de passe"
              placeholder="********"
              type={passwordFormType2}
              className="pr-10"
              onChange={(e) =>
                setFieldValue("passwordConfirmation", e.target.value)
              }
              value={values.passwordConfirmation}
              onBlur={() => handleBlur}
              style={{ width: "361px" }}
            />
            <img
              src={voir}
              alt="voir"
              className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setPasswordFormType2(
                  passwordFormType2 === "password" ? "text" : "password"
                );
              }}
            />
            {errors.passwordConfirmation && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.passwordConfirmation === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs m-1  leading-none   rounded-full">
                    {" "}
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>
          {values.password !== "" && (
            <i className="text-xs w-64 ">{errors.password}</i>
          )}
          <i className="text-xs w-64 ">{errors.passwordConfirmation}</i>
        </div>
        <div className="flex justify-between flex-wrap">
          <div className="relative">
            <SelectComp
              id="gender"
              value={values.gender}
              options={dropdownSexeOptions}
              onChange={(e) => setFieldValue("gender", e.target.value)}
              onBlur={() => handleBlur}
              text="Saisissez votre sexe"
              className="my-2.5 "
              style={{ width: "361px" }}
            />
            {errors.gender && (
              <span className="absolute top-2 bottom-0 right-0 ">
                {values.gender === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>

          <div className="relative">
            <SelectComp
              id="speciality"
              value={values.speciality}
              options={ListSpecilaities}
              onChange={(e) => setFieldValue("speciality", e.target.value)}
              onBlur={() => handleBlur}
              text="Saisissez votre specialitié"
              style={{ width: "361px" }}
              className="my-2.5"
            />
            {errors.speciality && (
              <span className="absolute top-3 bottom-0 right-0 ">
                {values.speciality === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>
        </div>

        <div className="flex justify-between flex-wrap">
          <div className="relative">
            <SelectComp
              id="type"
              value={values.type}
              options={typeOptionGroup.map((el) => ({
                value: el.value,
                label: el.label,
              }))}
              onChange={(e) => setFieldValue("type", e.target.value)}
              onBlur={() => handleBlur}
              text="Sélectionner votre postion"
              className="my-2.5 "
              style={{ width: "361px" }}
            />
            {errors.type && (
              <span className="absolute top-3 bottom-0 right-0 ">
                {values.type === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="relative">
            {values.type === "medecin" && (
              <SelectComp
                id="requrentType"
                value={values.requrentType}
                options={typeRequrent}
                onChange={(e) => setFieldValue("requrentType", e.target.value)}
                onBlur={() => handleBlur}
                text="Sélectionner le type de requérant"
                className="my-2.5 "
                style={{ width: "361px" }}
              />
            )}
          </div>

          <div className="relative">
            <SelectComp
              id="country"
              value={values.country}
              options={CountryWithPhoneSuffix.map((el) => ({
                value: el.name,
                label: el.name,
              }))}
              onChange={(e) => setFieldValue("country", e.target.value)}
              onBlur={() => handleBlur}
              text="Saisissez votre pays"
              className="my-2.5 "
              style={{ width: "361px" }}
              disabled
            />
            {errors.country && (
              <span className="absolute top-3 bottom-0 right-0 ">
                {values.country === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="relative">
            <Input
              id="adresse"
              labelText="Saisissez votre adresse professionnelle"
              placeholder="Saisissez votre adresse professionnelle"
              type="text"
              value={values.adresse}
              onChange={(e) => setFieldValue("adresse", e.target.value)}
              onBlur={() => handleBlur}
              style={{ width: "361px" }}
              className="mb-1.5 "
            />
            {errors.adresse && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.adresse === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="relative">
            <Input
              id="codePostale"
              labelText="Saisissez votre code postal"
              placeholder="Saisissez votre code postal"
              type="number"
              value={values.codePostale}
              onChange={(e) => setFieldValue("codePostale", e.target.value)}
              onBlur={() => handleBlur}
              style={{ width: "361px" }}
            />
            {errors.codePostale && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.codePostale === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="relative">
            <SelectComp
              id="departement"
              value={values.departement}
              options={ListDepartement}
              onChange={(e) => setFieldValue("departement", e.target.value)}
              onBlur={() => handleBlur}
              text="Sélectionner votre département"
              className="mb-4"
              style={{ width: "361px" }}
            />
            {/* {errors.country && (
              <span className="absolute top-3 bottom-0 right-0 ">
                {values.country === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )} */}
          </div>
        </div>

        <div className="flex justify-between	flex-wrap">
          <div className="relative">
            <Input
              id="phone"
              labelText="Saisissez  votre numéro de téléphone"
              placeholder="Saisissez votre numéro de téléphone"
              type="text"
              value={values.phone}
              onChange={(e) => setFieldValue("phone", e.target.value)}
              onBlur={() => handleBlur}
              style={{ width: "361px" }}
            />
            {errors.phone && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.phone === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="relative">
            <Input
              id="birthday"
              type="date"
              value={values.birthday}
              onChange={(e) => setFieldValue("birthday", e.target.value)}
              onBlur={(e) => setFieldValue("birthday", e.target.value)}
              className="custom-Input mb-2"
              inlineLabel={false}
              onfocus={true}
              style={{ width: "361px" }}
            />

            <span className="absolute top-0 bottom-0 right-5">
              <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                <label className="text-sm text-gray-400">
                  Date de naissance{" "}
                </label>
              </span>
            </span>
            {errors.birthday && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.birthday === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="relative mb-2">
            <Input
              id="numCom"
              labelText="Votre numéro d'inscription Adeli"
              placeholder="Votre numéro d'inscription Adeli"
              type="text"
              value={values.numCom}
              onChange={(e) => setFieldValue("numCom", e.target.value)}
              onBlur={() => handleBlur}
              style={{ width: "361px" }}
            />
            {errors.numCom && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.numCom === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
            {<i className="flexCenter text-xs mb-2 -mt-1 ">{errors.numCom}</i>}
          </div>
          <div className="relative">
            {" "}
            <Input
              id="numRPPS"
              labelText="Votre numéro RPPS"
              placeholder="Votre numéro RPPS"
              type="text"
              value={values.numRPPS}
              onChange={(e) => setFieldValue("numRPPS", e.target.value)}
              onBlur={() => handleBlur}
              style={{ width: "361px" }}
            />
            {errors.numRPPS && (
              <span className="absolute top-0 bottom-0 right-0 ">
                {values.numRPPS === "" ? (
                  <span className="flex items-center w-2 h-2 subpixel-antialiased   text-xs my-1 mx-2 leading-none   rounded-full">
                    ✯{" "}
                  </span>
                ) : (
                  <span className="flex items-center w-4 h-3 subpixel-antialiased rounded-bl-md   bg-red-200   leading-none rounded-tr-md ">
                    {" "}
                  </span>
                )}
              </span>
            )}
            {<i className="flexCenter text-xs mb-2 -mt-1 ">{errors.numRPPS}</i>}
          </div>
          <p className="flex flexCenter border rounded-xl pr-2">
            {" "}
            <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs h-full leading-none text-gray-400  bg-gray-100 rounded">
              💡
            </span>
            <p className="text-indigo-500  text-xs">✯ Champ obligatoire </p>
          </p>
        </div>
      </form>
    </>
  );
};

export default Signup;
