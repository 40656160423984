import fr from "date-fns/locale/fr";
import { Formik } from "formik";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { addLeave, updateLeave } from "../../api/medecin";
import makeToast from "../../components/Snackbar";
import { userAtom } from "../../state/auth";
import Button from "../ButtonUI";
import ConfirmModal from "./ConfirmModalEdit";
import ToggleSwitch from "../ToggleSwitch2/ToggleSwitch";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "./styles.scss";

registerLocale("fr", fr);
setDefaultLocale("fr");

registerLocale("fr", fr);
setDefaultLocale("fr");

interface IProps {
  setLeaveAdded: boolean | any;
  setSelectedLeave: Function;
  setLeaveData: Function;
  setEditMode: Function;
  editMode: boolean;
  selectedItem: any;
  leaveData: {
    _id: string;
    startDate: string;
    endDate: string;
    notAgreedToReceiveDemand?: Boolean;
  };
  setRefreshLeave: Function;
  setShow: Function;
}

const AddLeave: React.FC<IProps> = ({
  setLeaveAdded,
  setSelectedLeave,
  leaveData,
  setLeaveData,
  selectedItem,
  editMode,
  setRefreshLeave,
  setShow,
  setEditMode,
}) => {
  const [user] = useAtom(userAtom);

  const [confirmEditModal, setConfirmEditModal] = useState(false);
  //const [show, setShow] = useState(false);
  const handleAddLeave = async (values) => {
    try {
      const { startDate, endDate } = values;
      if (startDate === "" || endDate === "") {
        makeToast("error", "Veuillez remplir les champs  obligatoires");
      } else {
        if (startDate <= endDate) {
          const response = await addLeave({
            ...values,
            doctorId: user?._id,
          });

          if (response.request.status === 200) {
            setLeaveAdded(true);
            setSelectedLeave(response.data._id);
            setLeaveData(response.data.leave);
            makeToast("success", "Congé créé avec succès.");
            setRefreshLeave((x) => !x);
            setShow((x) => !x);
          } else if (response.request.status === 202) {
            makeToast("error", response.data.response);
          }
        } else if (startDate > endDate) {
          makeToast(
            "error",
            "La date de fin doit être supérieure à la date de début."
          );
        }
      }
    } catch (error) {
      makeToast("error", "Ce congé existe déjà.");
      console.log(error);
    }
  };

  const handleEditLeave = async (values) => {
    try {
      const { startDate, endDate, notAgreedToReceiveDemand } = values;

      if (startDate === "" || endDate === "") {
        makeToast("error", "Veuillez remplir les champs obligatoires");
      } else {
        if (startDate <= endDate) {
          const response = await updateLeave(
            {
              ...values,
              doctorId: user?._id,
            },
            selectedItem?._id
          );
          if (response.leave) {
            setLeaveAdded(true);
            setSelectedLeave(response.leave._id);
            setLeaveData(response.leave);
            makeToast(
              "success",
              "Mise à jour des données du congé effectuée avec succès."
            );
            setEditMode(false);
            setRefreshLeave((x) => !x);
            setShow((x) => !x);
          }
        } else if (startDate > endDate) {
          makeToast(
            "error",
            "La date de fin doit être supérieure à la date de début."
          );
        }
      }
    } catch (error) {
      makeToast("error", "Ce congé existe déjà.");
      console.log(error);
    }
  };
  return (
    <Formik
      initialValues={
        !editMode
          ? {
              startDate: "",
              endDate: "",
              notAgreedToReceiveDemand: true,
            }
          : {
              startDate: new Date(selectedItem?.startDate),
              endDate: new Date(selectedItem?.endDate),
              notAgreedToReceiveDemand: selectedItem?.notAgreedToReceiveDemand,
            }
      }
      onSubmit={async (values) => {
        try {
          console.log("Submited", values);
        } catch (error) {
          console.log("error", error);
        }
      }}
    >
      {(props) => {
        const {
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,
        } = props;
        return (
          //!show && (
          <form className="tabsContent mx-8" onSubmit={handleSubmit}>
            <div className="flex-parent-element mt-3">
              <div className="flex-child-element -ml-2 ">
                <label
                  className=" text-black text-base font-bold"
                  style={{ width: "168px" }}
                >
                  Date de début :
                </label>
                <DatePicker
                  id="startDate"
                  locale="fr"
                  className="custom-Input  border boder-gray-300  w-40 ml-2 h-9 -mt-1 rounded-lg text-center"
                  selected={values.startDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(x) => setFieldValue("startDate", x)}
                  placeholderText="JJ/MM/AAAA"
                  autoComplete="off"
                  showYearDropdown
                />
              </div>

              <div className="flex-child-element -ml-10">
                <label
                  className=" text-black text-base font-bold"
                  style={{ width: "125px" }}
                >
                  Date de fin :
                </label>
                <DatePicker
                  id="endDate"
                  locale="fr"
                  className="custom-Input  border boder-gray-300  w-40 ml-2 h-9 -mt-1 rounded-lg text-center"
                  selected={values.endDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(x) => setFieldValue("endDate", x)}
                  placeholderText="JJ/MM/AAAA"
                  autoComplete="off"
                  showYearDropdown
                />
              </div>
            </div>
            <div className="flexCenter mt-2">
              <ToggleSwitch
                id="notAgreedToReceiveDemand"
                //disabled={!switcher}
                small
                checked={values.notAgreedToReceiveDemand}
                onChange={(x) => setFieldValue("notAgreedToReceiveDemand", x)}
              />
              <p
                style={{
                  fontSize: "16px",
                  color: "#000000",
                  textAlign: "justify",
                  marginTop: "22px",
                }}
              >
                {" "}
                Je ne souhaite pas reçevoir des demandes de téléexpertise 48
                heures avant la date de mon congé.
              </p>
            </div>
            <div className="flexCenter mt-12 mb-2">
              <Button
                status="primary"
                type="submit"
                width="170px"
                height="50px"
                onClick={() => {
                  editMode ? setConfirmEditModal(true) : handleAddLeave(values);
                  //setShow(true);
                }}
              >
                Ajouter
              </Button>
            </div>
            {editMode && confirmEditModal && (
              <ConfirmModal
                action={() => handleEditLeave(values)}
                setOpenModal={setConfirmEditModal}
              />
            )}
          </form>
        );
        //);
      }}
    </Formik>
  );
};
export default AddLeave;
