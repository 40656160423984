import { useEffect, useState } from "react";

import _ from "lodash";
import moment from "../../../../helper/moment";
import Button from "../../../../components/ButtonUI";
import Modal from "../../../../components/ModalUI";
import Spinner from "../../../../components/Spinner";
import Checkbox from "../../../../components/Checkbox";
import Select from "../../../../components/Select";
import Antecedents from "./Antecedents";
import Traitements from "./Traitements";

const ShareFolderModal = ({
  getPatientById,
  user,
  specialistDetails,
  socket,
  generateNewShareFolder,
  setPreviewReportModal,
}) => {
  const [patients, setPatients] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [checkedAllImages, setCheckedAllImages] = useState<boolean>(false);
  const [typeShareFolder, setTypeShareFolder] = useState<String>("");
  const [patientsNameList, setPatientsNameList] = useState<any>([]);
  const [reports, setReports] = useState<any>([]);
  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [patientsName, setPatientName] = useState<String>("");
  const [patientDetails, setPatientDetails] = useState<any>();

  const [imageToShare, setImageToShare] = useState<any>([]);
  const [reportToShare, setReportToShare] = useState<any>([]);
  const [prescriptionToShare, setPrescriptionToShare] = useState<any>([]);

  useEffect(() => {
    async function _getPatientById() {
      setLoading(true);
      const response = await getPatientById(user._id);
      setPatients(response?.myPatients?.patients);
      setLoading(false);
    }
    _getPatientById();
  }, []);

  useEffect(() => {
    if (patients && patients.length > 0) {
      setPatientsNameList(
        patients.map((el) => ({
          name: el.firstName + "" + el.lastName,
          id: el._id,
        }))
      );
    }
  }, [patients]);

  useEffect(() => {
    if (patientDetails) {
      setPrescriptions([]);
      setReports([]);
      if (
        patientDetails.consultations &&
        patientDetails.consultations.length > 0
      ) {
        const tempreports = [] as any;
        const tempPrescriptions = [] as any;
        patientDetails.consultations.map((el) => {
          if (el.rapport) {
            tempreports.push({ ...el, id: _.uniqueId("report_") });
          }
          if (el.prescriptions && el.prescriptions.length > 0) {
            tempPrescriptions.push({ ...el, id: _.uniqueId("prescription_") });
          }
        });
        setPrescriptions(tempPrescriptions);
        setReports(tempreports);
      }
    }
  }, [patientDetails]);

  const handleShareFolder = async () => {
    const sharedFolder = {
      patient: patientDetails?._id,
      sharedBy: user._id,
      SharedTo: specialistDetails._id,
      prescriptions: prescriptionToShare,
      reports: reportToShare,
      images: imageToShare,
    };
    generateNewShareFolder(sharedFolder);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="shared-folder  w-full h-full m-2 flex flex-col pr-3">
      <div className="flex flex-col justify-end h-full m-3 space-y-3">
        <div className="h-full">
          <h2>Demande de téléexpertise</h2>
          <div className="flex justify-between items-center pr-2">
            <span className="courant-text">
              Vous souhaitez partager des documents concernant :
            </span>
            <div className="select-wrapper w-1/3 ">
              <Select
                options={patientsNameList?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))}
                text="Patient"
                setText={true}
                className="w-full rounded-full px-3 text-gray-400 "
                onChange={(e) => {
                  setPatientName(e.target.value);
                  setPatientDetails(
                    patients?.find((el) => el._id === e.target.value)
                  );
                }}
              />
            </div>
          </div>
          {patientsName && (
            <>
              <div className="flex items-center space-x-7 mt-10">
                <span className="courant-text max-w-max">
                  Vous souhaitez partager :
                </span>
                <div className="flex flex-1 justify-between">
                  <span
                    className={`box-share-forder ${
                      typeShareFolder === "Documents" && " box-selected"
                    }`}
                    onClick={() => {
                      setTypeShareFolder("Documents");
                    }}
                  >
                    Documents
                  </span>
                  <span
                    className={`box-share-forder ${
                      typeShareFolder === "Antécédents" && " box-selected"
                    }`}
                    onClick={() => {
                      setTypeShareFolder("Antécédents");
                    }}
                  >
                    Antécédents
                  </span>
                  <span
                    className={`box-share-forder ${
                      typeShareFolder === "Traitement" && " box-selected"
                    }`}
                    onClick={() => {
                      setTypeShareFolder("Traitement");
                    }}
                  >
                    Traitement
                  </span>
                </div>
              </div>
              {typeShareFolder === "Documents" && (
                <>
                  {patientDetails?.documents &&
                    patientDetails?.documents.length > 0 && (
                      <div className="mt-10">
                        <div className="flex justify-between items-center">
                          <h3>IMAGES</h3>
                          <Button
                            status="secondary"
                            height="40px"
                            className="min-w-max px-3"
                            onClick={(e) => {
                              e.preventDefault();
                              if (!checkedAllImages) {
                                const tempImageToShare =
                                  patientDetails?.documents?.map((el) => {
                                    if (
                                      el.metadata &&
                                      el.metadata.slice(0, 5) === "image"
                                    ) {
                                      return el._id;
                                    }
                                  });
                                setImageToShare(tempImageToShare);
                              } else setImageToShare([]);
                              setCheckedAllImages(!checkedAllImages);
                            }}
                          >
                            <div className="flex items-center space-x-1">
                              <div className="">
                                <label className="container-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={
                                      patientDetails?.documents?.map((el) => {
                                        if (
                                          el.metadata &&
                                          el.metadata.slice(0, 5) === "image"
                                        ) {
                                          return el._id;
                                        }
                                      }).length === imageToShare?.length
                                    }
                                    onChange={(e) => {
                                      e.preventDefault();
                                      if (!checkedAllImages) {
                                        const tempImageToShare =
                                          patientDetails?.documents?.map(
                                            (el) => {
                                              if (
                                                el.metadata &&
                                                el.metadata.slice(0, 5) ===
                                                  "image"
                                              ) {
                                                return el._id;
                                              }
                                            }
                                          );
                                        setImageToShare(tempImageToShare);
                                      } else setImageToShare([]);

                                      setCheckedAllImages(!checkedAllImages);
                                    }}
                                  />
                                  <span
                                    className="checkmark"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (!checkedAllImages) {
                                        const tempImageToShare =
                                          patientDetails?.documents?.map(
                                            (el) => {
                                              if (
                                                el.metadata &&
                                                el.metadata.slice(0, 5) ===
                                                  "image"
                                              ) {
                                                return el._id;
                                              }
                                            }
                                          );
                                        setImageToShare(tempImageToShare);
                                      } else setImageToShare([]);

                                      setCheckedAllImages(!checkedAllImages);
                                    }}
                                  ></span>
                                </label>
                              </div>
                              <span>Tout séléctionner</span>
                            </div>
                          </Button>
                        </div>
                        <div className="flexCenter flex-wrap gap-4 mt-7">
                          {patientDetails?.documents?.map((el) => {
                            if (
                              el.metadata &&
                              el.metadata.slice(0, 5) === "image"
                            ) {
                              return (
                                <div className="relative" key={el._id}>
                                  <img
                                    src={
                                      process.env.REACT_APP_KATOMI_API_URL +
                                      el.filePath
                                    }
                                    alt="patient-folder"
                                    className="rounded-lg"
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                    }}
                                  />
                                  <div className="absolute top-2 right-2 ">
                                    <label className="container-checkbox ">
                                      <input
                                        type="checkbox"
                                        checked={imageToShare.includes(el._id)}
                                        onChange={() => {
                                          imageToShare &&
                                          imageToShare.length > 0 &&
                                          imageToShare.includes(el._id)
                                            ? setImageToShare(
                                                imageToShare
                                                  .slice()
                                                  .filter(
                                                    (elm) => elm !== el._id
                                                  )
                                              )
                                            : setImageToShare([
                                                ...imageToShare,
                                                el._id,
                                              ]);
                                        }}
                                      />
                                      <span
                                        className="checkmark"
                                        onClick={() => {
                                          imageToShare &&
                                          imageToShare.length > 0 &&
                                          imageToShare.includes(el._id)
                                            ? setImageToShare(
                                                imageToShare
                                                  .slice()
                                                  .filter(
                                                    (elm) => elm !== el._id
                                                  )
                                              )
                                            : setImageToShare([
                                                ...imageToShare,
                                                el._id,
                                              ]);
                                        }}
                                      ></span>
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    )}
                  {/* COMPTE RENDU */}
                  {reports && reports.length > 0 && (
                    <div className="mt-10">
                      <div className="flex justify-between">
                        <h3 className="uppercase">Compte rendu</h3>
                        {/* <Button
                          status="secondary"
                          height="40px"
                          className="min-w-max px-3"
                        >
                          <span>+ Ajouter</span>
                        </Button> */}
                      </div>
                      <div className="flex flex-wrap justify-between mt-7 gap-5">
                        {reports.map((el) => (
                          <div
                            className="relative report-card shadow-md"
                            onClick={() => {
                              setPreviewReportModal({ status: true, ...el });
                            }}
                            key={el._id}
                          >
                            <span className="card-title block">
                              Consultation du :
                            </span>
                            <span className="block font-normal">
                              {moment(el.dateConsultation).format(
                                "DD MMMM YYYY"
                              )}
                            </span>
                            <span className="block updated-report">
                              Consultation du : Mise à jour le{" "}
                              {moment(el.updatedAt).format(
                                "DD MMMM YYYY à HH:mm"
                              )}
                            </span>
                            <div className="box max-w-max ">{el.motif}</div>
                            <div className="absolute top-5 right-5">
                              <label className="container-checkbox ">
                                <input
                                  type="checkbox"
                                  checked={reportToShare.some(
                                    (elm) => elm.id === el.id
                                  )}
                                />
                                <span
                                  className="checkmark"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if (
                                      _.isArray(reportToShare) &&
                                      reportToShare.some(
                                        (elm) => elm.id === el.id
                                      )
                                    ) {
                                      const tempReportToShare =
                                        reportToShare.filter(
                                          (elm) => elm.id !== el.id
                                        );
                                      setReportToShare(tempReportToShare);
                                    } else
                                      setReportToShare([...reportToShare, el]);
                                  }}
                                ></span>
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* PRESCRIPTION */}
                  {prescriptions && prescriptions.length > 0 && (
                    <div className="mt-10">
                      <div className="flex justify-between">
                        <h3>Prescription</h3>
                        {/* <Button
                          status="secondary"
                          height="40px"
                          className="min-w-max px-3"
                        >
                          <span>+ Ajouter</span>
                        </Button> */}
                      </div>
                      <div className="flex flex-wrap justify-between mt-7 gap-5">
                        {prescriptions.map((el) => (
                          <div
                            className="relative report-card shadow-md"
                            key={el._id}
                          >
                            <span className="card-title block">
                              Consultation du :
                            </span>
                            <span className="block font-normal">
                              {moment(el.dateConsultation).format(
                                "DD MMMM YYYY"
                              )}
                            </span>
                            <span className="block updated-report">
                              Consultation du : Mise à jour le{" "}
                              {moment(el.updatedAt).format(
                                "DD MMMM YYYY à HH:mm"
                              )}
                            </span>
                            <div className="box max-w-max ">{el.motif}</div>
                            <div className="absolute top-5 right-5">
                              <label className="container-checkbox ">
                                <input
                                  type="checkbox"
                                  checked={prescriptionToShare.some(
                                    (elm) => elm.id === el.id
                                  )}
                                  onChange={(e) => {
                                    if (
                                      _.isArray(prescriptionToShare) &&
                                      prescriptionToShare.some(
                                        (elm) => elm.id === el.id
                                      )
                                    ) {
                                      const tempPrescriptionToShare =
                                        prescriptionToShare.filter(
                                          (elm) => elm.id !== el.id
                                        );
                                      setPrescriptionToShare(
                                        tempPrescriptionToShare
                                      );
                                    } else {
                                      setPrescriptionToShare([
                                        ...prescriptionToShare,
                                        el,
                                      ]);
                                    }
                                  }}
                                />
                                <span
                                  className="checkmark"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      _.isArray(prescriptionToShare) &&
                                      prescriptionToShare.some(
                                        (elm) => elm.id === el.id
                                      )
                                    ) {
                                      const tempPrescriptionToShare =
                                        prescriptionToShare.filter(
                                          (elm) => elm.id !== el.id
                                        );
                                      setPrescriptionToShare(
                                        tempPrescriptionToShare
                                      );
                                    } else {
                                      setPrescriptionToShare([
                                        ...prescriptionToShare,
                                        el,
                                      ]);
                                    }
                                  }}
                                ></span>
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {((patientDetails?.documents &&
                    patientDetails?.documents.length > 0) ||
                    (reports && reports.length > 0) ||
                    (prescriptions && prescriptions.length > 0)) && (
                    <Button
                      className="py-3 my-5 w-full"
                      onClick={handleShareFolder}
                    >
                      Partager
                    </Button>
                  )}
                </>
              )}
              {typeShareFolder === "Traitement" && (
                <Traitements patientData={patientDetails} />
              )}
              {typeShareFolder === "Antécédents" && (
                <Antecedents patientData={patientDetails} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareFolderModal;
