import React, { useState } from "react";
import Button from "../../ButtonUI";
import "../styles.scss";
import TimePicker from "react-time-picker";

import makeToast from "../../Snackbar";
import { getAgendaConfig, setAgendaConfiguration } from "../../../api/medecin";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import Tooltip from "../../toolTip";

interface IRes {
  doctor_id: string;
  agendaConfig: any;
}
const DAYS_IN_WEEK = [
  {
    day: "Lundi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "1",
  },
  {
    day: "Mardi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "1",
  },
  {
    day: "Mercredi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "1",
  },
  {
    day: "Jeudi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "1",
  },
  {
    day: "Vendredi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "1",
  },
  {
    day: "Samedi",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "1",
  },
  {
    day: "Dimanche",
    active: false,
    timeRange: { start: "09:00", end: "18:00" },
    nbreqMax: "1",
  },
];

interface Props {
  closeModal: any;
  dataToMap: any[];
}

const Presentiel = (props: Props) => {
  const { closeModal, dataToMap } = props;
  const [loadingBtn, setLoadingBtn] = React.useState<boolean>(false);
  const [temp, setTemp] = useState(dataToMap);
  const [data] = useState<IRes>();
  const [user] = useAtom(userAtom);

  const [selectedInput, setSelectedInput] = useState("");
  // const [officeWorkingHours, setOfficeWorkingHours] = useState<{
  //   start: any;
  //   end: any;
  // }>({
  //   start: "09:00",
  //   end: "18:00",
  // });
  const [start, setStart] = useState("09:00");
  const [end, setEnd] = useState("18:00");

  const [nbrReqMax, setNbrReqMax] = useState("");

  const dataToPayload = () => {
    const payload = {
      doctor_id: user?._id,
      agendaConfig: temp
        .filter((e) => e.active)
        .map((e) => {
          return {
            day: e.day,
            rnageOfTime: e.timeRange,
            nbrReqMax: e.nbreqMax,
          };
        }),
      nbrMaxReq: 7,
    };

    return payload;
  };

  const updateTemp = async (t) => {
    let cTemp = [...temp];
    const newTemp = cTemp.map((e) => {
      if (t.day === e.day) {
        e.active = !e.active;
        //e.timeRange = officeWorkingHours;
        e.nbreqMax = "1";
      }

      return e;
    });
    return newTemp;
  };
  const restTemp = async (active: boolean) => {
    let cTemp = [...temp];
    const newTemp = cTemp.map((e) => {
      e.active = active;
      //e.timeRange = officeWorkingHours;
      e.nbreqMax = nbrReqMax;
      return e;
    });

    return newTemp;
  };

  const submitTimeSlots = async () => {
    try {
      setLoadingBtn(true);

      const res = await setAgendaConfiguration(dataToPayload());

      setLoadingBtn(false);
      closeModal();
    } catch (error) {
      setLoadingBtn(false);
      makeToast(
        "error",
        "Quelque chose s'est mal passé. Veuillez réessayer plus tard"
      );
      console.error(error);
    }
  };

  function handleChange(e, el) {
    console.log("element", el);
    const nbreqMax = e.target.value;
    setTemp(
      temp.map((elm) => (elm.day === el.day ? { ...elm, nbreqMax } : elm))
    );
  }

  function handleStartTimerChange(start, t) {
    setTemp(
      temp.map((elm) =>
        elm.day === t.day
          ? { ...elm, timeRange: { start, end: elm.timeRange.end } }
          : elm
      )
    );
  }

  function handleEndTimerChange(end, t) {
    setTemp(
      temp.map((elm) =>
        elm.day === t.day
          ? { ...elm, timeRange: { start: elm.timeRange.start, end } }
          : elm
      )
    );
  }

  return (
    <>
      <div
        style={{
          minWidth: "352px",
          display: "flex",
        }}
        className="justify-between"
      >
        <div
          style={{
            fontSize: "16px",
            color: "#4565f6",
          }}
        >
          Jour
        </div>
        <Tooltip tooltipText="Nombre de téléexpertise par jour" position="top">
          <div
            style={{
              fontSize: "16px",
              color: "#4565f6",
              marginRight: "15px",
            }}
          >
            Nombre
          </div>
        </Tooltip>

        <div
          style={{
            fontSize: "16px",
            color: "#4565f6",
            marginRight: "26px",
          }}
        >
          Plage Horaire{" "}
        </div>
      </div>
      <div className="ml-40">
        {/*<div>
          <Button
            style={{
              //marginRight: "320px",
              width: "100px",
              height: "32px",
              marginTop: "13px",
            }}
            status="secondary"
            onClick={async () => {
              setTemp(await restTemp(true));
            }}
          >
            7 j /7
          </Button>
          </div>*/}
        <div className="workingDays flex flex-wrap gap-4">
          {temp.map((t) => {
            return (
              <div className="flex-parent-element ">
                <div
                  className="flex-child-element w-4/12"
                  style={{
                    minWidth: "100px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    setTemp(await updateTemp(t));
                  }}
                  aria-checked={t.active}
                >
                  <p>{t.day}</p>
                </div>{" "}
                <input
                  className="flex-child-element w-8/12"
                  style={{
                    height: "44px",
                    borderRadius: "5px",
                    borderColor: "rgba(166, 169, 200, 0.22)",
                    width: "63px",
                    textAlign: "center",
                    color: "rgb(69, 101, 246)",
                  }}
                  type="number"
                  placeholder="1"
                  disabled={!t.active}
                  value={t.nbreqMax}
                  onChange={(e) => handleChange(e, t)}
                />{" "}
                <p
                  style={{
                    marginTop: "8px",
                    marginRight: "10px",
                    marginLeft: "27px",
                    fontSize: "16px",
                  }}
                >
                  du:{" "}
                </p>
                <div
                  className="flex-child-element sub-values w-8/12"
                  style={{
                    borderRadius: "5px",
                    borderColor: "rgba(166, 169, 200, 0.22)",
                  }}
                >
                  <TimePicker
                    onChange={(start) => handleStartTimerChange(start, t)}
                    format="HH:mm"
                    value={t.timeRange.start}
                    disabled={!t.active}
                    //value={officeWorkingHours.start}
                    // onClockClose={() => {
                    //   setSelectedInput("");
                    // }}
                    onClockOpen={() => {}}
                  />
                </div>
                <p
                  className="px-3"
                  style={{
                    marginTop: "8px",
                    fontSize: "16px",
                  }}
                >
                  à:{" "}
                </p>
                <div
                  className="flex-child-element sub-values w-8/12"
                  style={{
                    borderRadius: "5px",
                    borderColor: "rgba(166, 169, 200, 0.22)",
                  }}
                >
                  <TimePicker
                    onChange={(end) => handleEndTimerChange(end, t)}
                    format="HH:mm"
                    value={t.timeRange.end}
                    disabled={!t.active}
                    // onClockClose={() => {
                    //   setSelectedInput("");
                    // }}
                    onClockOpen={() => {}}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="plageHoraire flexCenter gap-x-2">
        <p>Plage Horaire du </p>
        <div
          className="flexCenter sub-values"
          onClick={(e) => {
            setSelectedInput("TemperatureTime");
          }}
          style={{ cursor: "pointer" }}
        >
          {selectedInput === "TemperatureTime" ? (
            <TimePicker
              onChange={(e) => {
                if (e !== null)
                  setOfficeWorkingHours({
                    ...officeWorkingHours,
                    start: e,
                  });
                setTemp(
                  temp.map((e1) => {
                    if (e1.active)
                      e1.timeRange = {
                        ...officeWorkingHours,
                        start: e,
                      };
                    return e1;
                  })
                );
              }}
              format="HH:mm"
              value={officeWorkingHours.start}
              onClockClose={() => {
                setSelectedInput("");
              }}
              onClockOpen={() => {}}
            />
          ) : (
            <p className="value flexCenter cursor-pointer">
              {officeWorkingHours.start !== 0
                ? officeWorkingHours.start
                : "00:00"}
            </p>
          )}
        </div>
        <p>à :</p>
        <div
          className="flexCenter sub-values"
          onClick={(e) => {
            setSelectedInput("TemperatureTime");
          }}
        >
          {selectedInput === "TemperatureTime" ? (
            <TimePicker
              onChange={(e) => {
                if (e !== null)
                  setOfficeWorkingHours({
                    ...officeWorkingHours,
                    end: e,
                  });
                setTemp(
                  temp.map((e1) => {
                    if (e1.active)
                      e1.timeRange = {
                        ...officeWorkingHours,
                        end: e,
                      };
                    return e1;
                  })
                );
              }}
              format="HH:mm"
              value={officeWorkingHours.end}
              onClockClose={() => {
                setSelectedInput("");
              }}
              onClockOpen={() => {}}
            />
          ) : (
            <p className="value flexCenter cursor-pointer">
              {officeWorkingHours.end !== 0 ? officeWorkingHours.end : "00:00"}
            </p>
          )}
        </div>
      </div> */}
      <div>
        {/* {temp.filter((e) => e.active).length > 0 && (
          <div
            style={{
              fontSize: "18px",
              color: "#4565f6",
              fontWeight: "lighter",
              marginTop: "10px",
            }}
          >
            Nombre des téléExpertises /j
          </div>
        )} */}
      </div>
      <div className="flexCenterBtn my-10">
        <Button
          status="secondary"
          width="170px"
          height="50px"
          onClick={async () => {
            setTemp(await restTemp(false));
          }}
        >
          Réinitialiser
        </Button>

        <Button
          status="primary"
          width="170px"
          height="50px"
          onClick={submitTimeSlots}
          disabled={loadingBtn}
        >
          <div>
            {loadingBtn && (
              <div className="absolute h-full w-full flexCenter">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            )}
            Enregistrer
          </div>
        </Button>
      </div>
    </>
  );
};

export default Presentiel;
