import { Switch, Route } from "react-router-dom";
import LayoutResponsable from "../../layouts/LayoutResponsable";
import LayoutTeleexpert from "../../layouts/LayoutTeleexpert";
import LayoutMedecin from "../../layouts/LayoutMedecin";
import { UserRole } from "../../types/user";
import routes from "../../routes";
import ChangePass from "../../pages/Signin/changePassWordModal";
import { useState, useEffect } from "react";
import LayoutChirurgien from "../../layouts/LayoutChirurgien";
import SubscriptionModal from "../../pages/Profile/modal/SubscriptionModal";
import { updatePosition } from "../../api/auth";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import _ from "lodash";

type Props = {
  role: UserRole;
  newPassword: any;
  subscription: boolean;
};

const Navigator: React.FC<Props> = ({ role, newPassword, subscription }) => {
  const [modalChangePass, setModalChangePass] = useState<boolean>(true);
  const [modalSubcription, setModalSubcription] = useState<boolean>(true);
  const [user, setUser] = useAtom(userAtom);

  const [currentPosition, setCurrentPosition] = useState({
    latitude: "",
    longitude: "",
  }) as any;

  function getPosition(position) {
    setCurrentPosition({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  }

  useEffect(() => {
    //if (!_.isEmpty(currentPosition))
    async function fetchMyAPI(): Promise<void> {
      if (navigator.geolocation && user && !_.isEmpty(currentPosition)) {
        navigator.geolocation.getCurrentPosition(getPosition);
        await updatePosition(
          { userId: user?._id },
          { position: currentPosition }
        );
      } else {
        console.log("Geolocalisation API is not supported");
      }
    }
    fetchMyAPI();
  }, [currentPosition]);
  const Routes = () =>
    newPassword === true ? (
      <>
        <Switch>
          {routes(role).map((routeProps, index) => (
            <Route key={index} {...routeProps} />
          ))}
        </Switch>
        {modalChangePass && newPassword && (
          <ChangePass
            openModal={modalChangePass}
            setOpenModal={setModalChangePass}
            setOpenModalAddPatient={() => console.log()}
          />
        )}
      </>
    ) : modalSubcription && subscription && role !== "medecinResponsable" ? (
      <SubscriptionModal setOpenModal={setModalSubcription} />
    ) : (
      <Switch>
        {routes(role).map((routeProps, index) => (
          <Route key={index} {...routeProps} />
        ))}
      </Switch>
    );

  switch (role) {
    case UserRole.Guest:
      return Routes();
    case UserRole.RESPONSABLE:
      return <LayoutResponsable>{Routes()}</LayoutResponsable>;
    case UserRole.TELEEXPERT:
      return <LayoutTeleexpert>{Routes()}</LayoutTeleexpert>;
    case UserRole.MEDECIN:
      return <LayoutMedecin>{Routes()}</LayoutMedecin>;
    case UserRole.CHIRURGIEN:
      return <LayoutChirurgien>{Routes()}</LayoutChirurgien>;
    default:
      return Routes();
  }
};

export default Navigator;
