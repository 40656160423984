import React, { useState } from "react";


import moment from "./moment";



export const statusLabel = (reqStatus, delai,type,priseEncharge) => {
  if (priseEncharge){
    return "Prise en charge"
  } 
  else { 
    switch (reqStatus) {
      case "IN_PROGRESS":
        if (type === "medecinResponsable") {
          if (moment(delai).diff(moment(), "minutes") >= 1440) {
            return "Affectée à un expert";
          } else {
            return "Rappel envoyé à l'expert";
          }
        } else if (type === "medecinTeleexpert") {
          if (moment(delai).diff(moment(), "minutes") >= 1440) {
            return "Demande récemment attribuée";
          } else {
            return "Demande attribuée pas encore traitée";
          }
        } else {
          if (moment(delai).diff(moment(), "minutes") >= 1440) {
            return "Affectée à un expert";
          } else {
            return "Rappel envoyé à l'expert";
          }
        }
   
      case "NOT_ASSIGNED":
        if (type === "medecinResponsable") {
          if (moment(delai).diff(moment(), "hours") > 0) {
            return "En attente d'affectation";
          } else {
            return "Expiration du délai";
          }
        } else if (type === "medecinTeleexpert") {
          if (moment(delai).diff(moment(), "hours") > 0) {
            return "";
          } else {
            return "";
          }
        } else {
          if (moment(delai).diff(moment(), "hours") > 0) {
            return "En attente d'affectation";
          } else {
            return "Pas encore affectée par le responsable";
          }
        }

      case "WAIT_FOR_IMGS":
        if (type === "medecinResponsable") {
          if (moment(delai).diff(moment(), "hours") > 0) {
            return "En attente de photos";
          } else {
            return "En attente de photos";
          }
        }  else {
          if (moment(delai).diff(moment(), "hours") > 0) {
            return "En attente de photos";
          } else {
            return "En attente de photos";
          }
        }
        break;
   
      case "RESPONSE":
        if (type === "medecinChirurgien"){
          return "Prise en charge"
        }
        if (type === "medecinResponsable") {
          if (moment(delai).diff(moment(), "hours") > 0) {
            return "Demande traitée"
          } else {
            return "Demande traitée"
          }
        } else if (type === "medecinTeleexpert") {
          if (moment(delai).diff(moment(), "hours") > 0) {
            return "Réponse envoyée"
          } else {
            return "Réponse envoyée"
          }
        } else {
          if (moment(delai).diff(moment(), "hours") > 0) {
            return "Réponse reçue"
          } else {
            return "Réponse reçue"
          }
        }
        break;
        case "SEEN_BY_EXPERT":
          if (type === "medecinResponsable") {
            if (moment(delai).diff(moment(), "minutes") >= 1440) {
              return "Demande vue par l'expert";
            } else {
              return "Rappel envoyé à l'expert 👁️";
            }
          } else if (type === "medecinTeleexpert") {
            if (moment(delai).diff(moment(), "minutes") >= 1440) {
              return "En attente de votre réponse";
            } else {
              return "En attente de votre réponse (premier rappel)";
            }
          } else {
            if (moment(delai).diff(moment(), "minutes") >= 1440) {
              return "Demande vue par l'expert";
            } else {
              return "Rappel envoyé à l'expert 👁️";
            }
          }
          break;
      default:
        break;
    }}
  };