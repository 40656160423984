import axios from "axios";
import { values } from "lodash";
import { get, update, post, remove, post2 } from "../helper/apiMethods";
import { KATOMI_API_ENDPOINT } from "../config/api";
import makeToast from "../components/Snackbar";

export const addGroup = async (values) => {
  try {
    const res = await post("/groups/addGroup", values);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllGroups = async () => {
  try {
    const res = await get("/groups/ListofGroups");
    return res.data ? res.data : false;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retriveGroup = async (groupId) => {
  try {
    const res = await get(`/groups/${groupId}`);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteGroup = async (id) => {
  try {
    const response = await remove(`/groups/delete/${id}`);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateGroup = async (values, id) => {
  try {
    const res = update(`/groups/update/${id}`, values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateGroupDoctor = async (userId, payload) => {
  try {
    const response = await update(`/groups/${userId}`, {
      groupId: payload.groupId,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
