import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import Icon from "../Icon";
import { useAtom } from "jotai";
import { logout } from "../../api/auth";
import { colorAvatarAtom } from "../../state/constants";
import { userAtom } from "../../state/auth";
import { screenBreakpoints } from "../../config/theme";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { getUnreadMsg } from "../../api/messages";
import ChatModal from "../SharedChat";
import { refreshAtomNotification } from "../../state/refreshStateNotifications";

import "./styles.scss";
import _ from "lodash";

const SideBarDoctor: React.FC = () => {
  const [user, setUser] = useAtom(userAtom) as any;
  const [colorAvatar] = useAtom<any>(colorAvatarAtom);
  const [path, setPath] = useState(window.location.pathname);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [notification, setNotifications] = useState([]) as any;
  const [chatModal, setChatModal] = useState();
  const [refreshUnReadMsg, setRefreshUnReadMsg] = useAtom(
    refreshAtomNotification
  );
  const [menuToggleIsActive, setMenuToggleIsActive] = useState(
    window.innerWidth <= screenBreakpoints.md
  );
  const [forceMenuOpen, setForceMenuOpen] = useState(false);
  const swipeEventsHandlers = useSwipeable({
    onSwipedLeft: () => {
      setForceMenuOpen(false);
    },
    onSwipedRight: () => {
      setForceMenuOpen(true);
    },
  });

  const menus = [
    {
      icon: "agenda",
      text: "Disponibilités",
      url: "/disponibilités",
    },
    {
      icon: "patient-tableau",
      text: "Demandes",
      url: "/demandes",
    },

    {
      icon: "monCompte",
      text: "Mon compte",
      url: "/profile",
    },
  ];

  const handleLogout = async () => {
    try {
      await logout(user);
      setPath("/");
      window.location.href = "/";
      localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      localStorage.clear();
      console.error(error);
    }
  };
  const _getUnreadMsg = async () => {
    try {
      const response = await getUnreadMsg();
      setNotifications(_.uniqBy(response, "sentFrom._id"));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    _getUnreadMsg();
  }, [ChatModal, refreshUnReadMsg]);

  useEffect(() => {
    setPath(window.location.pathname);

    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
      setMenuToggleIsActive(window.innerWidth <= screenBreakpoints.md);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    console.log("[debug] sidebar - ", { menuToggleIsActive });
  }, [menuToggleIsActive]);

  useEffect(() => {
    console.log("[debug] sidebar - ", { screenWidth });
  }, [screenWidth]);
  //console.log("----->", !_.isEmpty(notification));
  return (
    <>
      <ChatModal
        chatWithId={chatModal}
        showModal={!!chatModal}
        closeChatModal={() => setChatModal(undefined)}
      />
      <div className="sidebar-doctor sidebar">
        <div
          className={`swipe-events-container ${
            !menuToggleIsActive || !forceMenuOpen ? "" : "swipeable-fullscreen"
          }`}
          {...swipeEventsHandlers}
        />
        <div
          className={`sidebar-container absolute transform md:relative md:translate-x-0 transition duration-200 ease-in-out ${
            menuToggleIsActive ? "mobile-sidebar" : "bg-transparent"
          } ${menuToggleIsActive && !forceMenuOpen ? "-translate-x-full" : ""}`}
        >
          <div className="relative">
            <div className="flex justify-between md:hidden absolute mobile-menu-burger">
              <button
                className="p-4 focus:outline-none"
                onClick={() => {
                  setForceMenuOpen(!forceMenuOpen);
                }}
              >
                <Icon isIcon={true} name={forceMenuOpen ? "close" : "menu"} />
              </button>
            </div>

            <div className="flex items-center space-x-3 relative notification">
              {user ? (
                <div className="avatar-doctor relative">
                  <div
                    className="avatar-doctor"
                    style={{ backgroundColor: colorAvatar }}
                  >
                    {user?.gender === "female" ? (
                      <WomanPatient className=" rounded-lg w-full h-full -mt-2" />
                    ) : (
                      <ManPatient className=" rounded-lg w-full h-full -mt-2" />
                    )}
                    {!_.isEmpty(notification) && (
                      <Icon
                        isIcon={true}
                        className="absolute -right-4 -top-3"
                        name="notif-deconnexion"
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="avatar-doctor relative"
                  style={{ backgroundColor: colorAvatar }}
                >
                  <ManPatient className="-mt-2" />
                </div>
              )}

              <div>
                <span className="menu">
                  {user?.firstName} {user?.lastName}
                </span>
                <p className="docter-center">{user?.speciality}</p>
                {!_.isEmpty(notification) && (
                  <p className="notif-text">{"Nouveau message"}</p>
                )}
              </div>
              <div className="w-64 max-h-96 bg-white absolute top-7 overflow-auto rounded-2xl  notification-list">
                {!_.isEmpty(notification) &&
                  notification.map((el) => (
                    <div
                      className="flex space-x-3 items-center p-2 hover:bg-blue-100 cursor-pointer"
                      onClick={() => setChatModal(el.chatroom)}
                      key={el._id}
                    >
                      <p className="w-14 h-10  rounded-full bg-blue-500 flexCenter text-white text-bold">
                        {el.sentFrom.lastName[0].toUpperCase() +
                          el.sentFrom.firstName[0].toUpperCase()}
                      </p>
                      <p>
                        {el.sentFrom.lastName + " " + el.sentFrom.firstName}{" "}
                        vous a envoyé un message
                      </p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="relative flex w-full flex-wrap items-stretch mb-3 mt-6 ">
              <span className="z-10 h-full leading-snug font-normal  text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1"></span>
            </div>

            <nav className="mt-3">
              {menus.map((menu) => {
                return (
                  <div className={path === menu.url ? "selected-menu" : ""}>
                    <Link
                      to={menu.url}
                      className="flex items-center space-x-3 py-3"
                      onClick={() => setPath(menu.url)}
                    >
                      <div className="w-12 h-12 flexCenter">
                        <Icon isIcon={true} name={menu.icon} />
                      </div>
                      <span className="my-auto menu">{menu.text}</span>
                    </Link>
                  </div>
                );
              })}
              <div className={path === "/logout" ? "selected-menu" : ""}>
                <span
                  className="flex items-center space-x-3 py-3 cursor-pointer"
                  onClick={handleLogout}
                >
                  <div className="w-12 h-12 flexCenter">
                    <img
                      src={require("../../assets/images/logout.png").default}
                      width="22"
                      alt="logout"
                    />
                  </div>
                  <span className="my-auto menu">Se déconnecter</span>
                </span>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarDoctor;
