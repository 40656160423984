import { Redirect, RouteProps } from "react-router";
import Profile from "../pages/Profile";
import MyPatients from "../pages/MyPatients";
import Agenda from "../pages/Agenda";
import MyRequests from "../pages/MyRequests";
import Consultation from "../pages/ConsultationDoctor";



const medecinResponsableRoutes: RouteProps[] = [

  {
    path: "/consultation",
    component: Consultation,
    exact: true,
  },
  {
    path: "/mes-patients",
    component: MyPatients,
    exact: true,
  },
  {
    path: "/agenda",
    component: Agenda,
    exact: true,
  },
  {
    path: "/demandes",
    component: MyRequests,
    exact: true,

  },
 
  {
    path: "/profile",
    component: Profile,
    exact: true,
  },

  { 
    render: () => <Redirect to="/mes-patients" /> },
];


export default medecinResponsableRoutes;
