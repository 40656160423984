import { useState, useRef } from "react";
import { useAtom } from "jotai";

import moment from "../../../../helper/moment";
import { userAtom } from "../../../../state/auth";

import Button from "../../../../components/ButtonUI";

import WomanDoctor from "../../../../assets/doctor.png";
import { ReactComponent as IconFolder } from "../../../../assets/folder.svg";
import { ReactComponent as IconDownload } from "../../../../assets/icons/icon-telecharger.svg";



const ChatModal = ({
  specialistDetails,
  socket,
  handlePreviewSharedFolder,
}) => {
  const [user] = useAtom<any>(userAtom);
  const [displayMessage, setDisplayMessage] = useState<any>([]);
  const messageRef = useRef<any>();
  const blockMessageRef = useRef<any>();

  const chatroomId = [specialistDetails._id, user?._id].sort().join("");

  const sendMessage = () => {
    if (socket) {
      socket.emit("chatroomMessage", {
        sentFrom: user?._id,
        sentTo: specialistDetails._id,
        type: "text",
        message: messageRef.current.value,
        createdAt: moment(),
        chatroomId,
      });
      messageRef.current.value = "";
    }
  };

  return (
    <div className="chat-box w-full h-full m-2 flex flex-col pr-3 overflow-y-auto">
      <div className="flex flex-col justify-end h-full  m-3 space-y-3">
        {displayMessage &&
          displayMessage.length > 0 &&
          displayMessage.map((el) => (
            <div className="relative">

              <hr
                className="hr-center-text flex-1"
                data-content={moment().format("dddd DD MMMM")}
              />
              {el.data.map(
                // TODO fixe new arg with schema messages
                ({ from, date, type, message, folder, avatar, ...rest }) => (
                  <div>
                    <div
                      className={`flex items-center space-x-2 my-2  ${
                        rest?.sentFrom == user?._id && "flex-row-reverse "
                      }`}
                    >
                      {rest?.sentFrom != user?._id && (
                        <span className="avatar-chat">
                          <img src={WomanDoctor} alt="doctor" />
                        </span>
                      )}
                      {type === "text" && (
                        <span
                          className={`message-chat max-w-max ${
                            rest?.sentFrom == user?._id && "text-right p-2 you"
                          }`}
                        >
                          {message}
                        </span>
                      )}
                      {type === "sharedFolder" && (
                        <div
                          className={`flex items-center ${
                            rest?.sentFrom == user?._id && "flex-row-reverse"
                          }`}
                          onClick={() =>
                            handlePreviewSharedFolder({
                              from,
                              date,
                              type,
                              message,
                              folder,
                              avatar,
                              ...rest,
                            })
                          }
                        >
                          <div className="relative">
                            <IconFolder />
                            <div className="absolute top-7 left-4">
                              <span className="block text-sm text-white font-bold">
                                {user.firstName} {user.lastName}
                              </span>
                              <span className="block rounded-lg text-white text-sm p-1 bg-gray-500 bg-opacity-10">
                                {user.speciality}
                              </span>
                            </div>
                          </div>
                          <div className="mx-3">
                            <IconDownload />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
      </div>

      <div className="flex items-center px-2 mb-4">
        <input
          type="text"
          name="chat"
          className="input-chat-box w-full mx-2 pl-5"
          style={{ height: "45px" }}
          placeholder="Tapez ici votre message..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          ref={messageRef}
        />
        <Button className="p-2" onClick={sendMessage}>
          Envoyer
        </Button>
      </div>
      <div style={{ float: "left", clear: "both" }} ref={blockMessageRef}></div>
    </div>
  );
};

export default ChatModal;
