import React from "react";
import Button from "../../../components/ButtonUI";
import "./styles.scss";
import presentationSchema from "./schemas/presentationSchema";
import { addOrModifyExpertise } from "../../../api/medecin";
import { Formik } from "formik";
import { User } from "../../../types/user";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import Input from "../../../components/Input";
import makeToast from "../../../components/Snackbar";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
}

export const AddExpertise: React.FC<Props> = ({ openModal, setOpenModal }) => {
  const [user, setUser] = useAtom(userAtom);

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd w-1/2">
        <div className="relative ">
          <div className="absolute right-0 mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div>
        <Formik
          initialValues={{
            expertise: "",
          }}
          onSubmit={async (values) => {
            try {
              if (!values.expertise)
                return makeToast("warning", "Veuillez saisir votre expertise");
              setOpenModal(!openModal);
              const newUser = await addOrModifyExpertise({
                expertises: { ...values },
              });

              setUser({
                ...user,
                ...newUser,
              } as User);
              if (newUser.id) {
                makeToast("success", "Expertise ajoutée avec succès.");
              } else {
                makeToast("error", "Expertise universitaire à échouher.");
              }
            } catch (err) {
              console.log("[error] add Patient -on Submit :", err);
            }
          }}
          validationSchema={presentationSchema}
        >
          {(props) => {
            const { values, handleChange, handleBlur, handleSubmit } = props;

            return (
              <form
                onSubmit={handleSubmit}
                className="flex-auto py-7 px-8 min-w-full	"
              >
                <div className="flex flex-wrap items-baseline">
                  <h1 className="mb-10">Ajouter un expertise</h1>
                </div>
                <div className="">
                  <Input
                    id="expertise"
                    placeholder="Ecrivez votre expertise ..."
                    value={values.expertise}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                    className="w-full mb-8"
                  />
                </div>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-left mb-4 btn-delete"
                  onClick={() => setOpenModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-right mb-4"
                >
                  Valider
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddExpertise;
