import { useEffect, useState } from "react";
import _ from "lodash";
import moment from "../../../../helper/moment";
import Spinner from "../../../../components/Spinner";
import Modal from "../../../../components/ModalUI";
import ReactHtmlParser from "react-html-parser";

const ShareFolderModal = ({ user, sharedFolderDetails }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [reports, setReports] = useState<any>([]);
  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [patientDetails, setPatientDetails] = useState<any>();
  const [images, setImages] = useState<any>();
  const [previewReportModal, setPreviewReportModal] = useState({
    status: false,
    rapport: "",
  });
  useEffect(() => {
    sharedFolderDetails &&
      sharedFolderDetails?.prescriptions &&
      sharedFolderDetails?.prescriptions.length > 0 &&
      setPrescriptions(sharedFolderDetails.prescriptions);

    sharedFolderDetails &&
      sharedFolderDetails?.reports &&
      sharedFolderDetails?.reports.length > 0 &&
      setReports(sharedFolderDetails.reports);

    sharedFolderDetails &&
      sharedFolderDetails?.images &&
      sharedFolderDetails?.images.length > 0 &&
      setImages(sharedFolderDetails.images);
  }, [sharedFolderDetails]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Modal
        showModal={previewReportModal?.status}
        closeModal={() =>
          setPreviewReportModal({
            status: false,
            rapport: "",
          })
        }
      >
        <div className="p-5">
          <p>{ReactHtmlParser(ReactHtmlParser(previewReportModal?.rapport))}</p>
        </div>
      </Modal>
      <div className="shared-folder  w-full h-full m-2 flex flex-col pr-3">
        <div className="flex flex-col justify-end h-full m-3 space-y-3">
          <div className="h-full">
            {images && images.length > 0 && (
              <div className="mt-10">
                <div className="flex justify-between">
                  <h3>IMAGES</h3>
                </div>
                <div className="flexCenter flex-wrap gap-4 mt-7">
                  {images?.map((el) => {
                    if (el.metadata && el.metadata.slice(0, 5) === "image") {
                      return (
                        <div className="relative">
                          <img
                            src={
                              process.env.REACT_APP_KATOMI_API_URL + el.filePath
                            }
                            alt="patient-folder"
                            className="rounded-lg"
                            style={{
                              width: "120px",
                              height: "120px",
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            )}
            {/* COMPTE RENDU */}
            {reports && reports.length > 0 && (
              <div className="mt-10">
                <div className="flex justify-between">
                  <h3 className="uppercase">Compte rendu</h3>
                </div>
                <div className="flex flex-wrap justify-between mt-7">
                  {reports.map((el) => (
                    <div
                      className="relative report-card shadow-md"
                      onClick={() => {
                        setPreviewReportModal({ status: true, ...el });
                      }}
                    >
                      <span className="card-title block">
                        Consultation du :
                      </span>
                      <span className="block font-normal">
                        {moment(el.dateConsultation).format("DD MMMM DDDD")}
                      </span>
                      <span className="block updated-report">
                        Consultation du : Mise à jour le{" "}
                        {moment(el.updatedAt).format("DD MMMM YYYY à HH:mm")}
                      </span>
                      <div className="box max-w-max ">{el.motif}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* PRESCRIPTION */}
            {prescriptions && prescriptions.length > 0 && (
              <div className="mt-10">
                <div className="flex justify-between">
                  <h3>Prescription</h3>
                </div>
                <div className="flex flex-wrap justify-between mt-7">
                  {prescriptions.map((el) => (
                    <div className="relative report-card shadow-md">
                      <span className="card-title block">
                        Consultation du :
                      </span>
                      <span className="block font-normal">
                        {moment(el.dateConsultation).format("DD MMMM YYYY")}
                      </span>
                      <span className="block updated-report">
                        Consultation du : Mise à jour le{" "}
                        {moment(el.updatedAt).format("DD MMMM YYYY à HH:mm")}
                      </span>
                      <div className="box max-w-max ">{el.motif}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareFolderModal;
