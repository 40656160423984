import Button from "../../../../components/ButtonUI";
import { useAtom } from "jotai";
import { colorAvatarAtom } from "../../../../state/constants";
import { ReactComponent as IconFolder } from "../../../../assets/icons/icon-piece-jointe-blanc.svg";
import { ReactComponent as IconMessage } from "../../../../assets/icons/icon-message.svg";
import { ReactComponent as IconCloseModal } from "../../../../assets/icons/icon-close.svg";

import { ReactComponent as WomanPatient } from "../../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../../assets/illustrations/illustration-homme-patient.svg";

interface Props {
  ToggleContent: any;
  closeModal: any;
  chatOrShareFolder: string;
  user: any;
  specialistDetails: any;
}

const HeaderModal = (props: Props) => {
  const {
    ToggleContent,
    closeModal,
    chatOrShareFolder,
    user,
    specialistDetails,
  } = props;
  const [colorAvatar] = useAtom<any>(colorAvatarAtom);
  return (
    <div className="flex justify-between p-1 ">
      <div className="flex flex-1 justify-between mr-5 bg-gray-100  rounded-2xl p-3">
        <div className="flex items-center space-x-5">
          {specialistDetails?.business_photo ? (
            <div className="avatarMedecinChat ">
              <img
                src={specialistDetails.pbusiness_photo}
                alt="doctor avatar"
                className="rounded-lg w-full h-full"
              />
            </div>
          ) : (
            <div
              className="avatarMedecinChat"
              style={{ backgroundColor: colorAvatar }}
            >
              <ManPatient className="-mt-2" />
            </div>
          )}

          <div className="card-title">
            <span className="card-title capitalize">
              {specialistDetails.firstName} {specialistDetails.lastName}
            </span>
            <p className="card-subtitle ">{specialistDetails.speciality}</p>
          </div>
        </div>
        {chatOrShareFolder !== "chat" ? (
          <Button status="primary" className="p-2">
            <IconMessage
              style={{
                filter:
                  "invert(97%) sepia(9%) saturate(414%) hue-rotate(66deg) brightness(118%) contrast(100%)",
              }}
              onClick={ToggleContent}
            />
          </Button>
        ) : (
          <Button status="primary" className="p-2">
            <IconFolder
              style={{
                filter:
                  "invert(97%) sepia(9%) saturate(414%) hue-rotate(66deg) brightness(118%) contrast(100%)",
              }}
              onClick={ToggleContent}
            />
          </Button>
        )}
      </div>
      <div className="bg-gray-100 flex-none rounded-2xl px-2 max-h-10">
        <IconCloseModal onClick={closeModal} />
      </div>
    </div>
  );
};

export default HeaderModal;
