import React from "react";
import classNames from "classnames";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: any;
  showModal: boolean;
  slidemodal?: boolean;
  closeModal?: any;
  noDarkBackground?: boolean;
  width?: string;
};

const Modal: React.FC<Props> = ({
  children,
  showModal,
  slidemodal,
  closeModal,
  width,
  noDarkBackground,
}) => {
  return (
    <>
      {showModal ? (
        <>
          <div
            className={`fixed inset-0 z-50 outline-none focus:outline-none  ${
              noDarkBackground ? "" : "bg-opacity-modal"
            }  ${slidemodal ? "  " : " flex justify-center items-center"}`}
            onClick={closeModal}
          >
            <div
              className={classNames(
                // `relative w-auto my-6 ml-auto `,
                "absolute  inset-y-0 right-1 z-40 patient-modal-info",
                width ? width : "max-w-3xl",
                // slidemodal ? " slide-left h-screen pr-5 pb-10" : " m-auto"
                slidemodal ? " pr-5 pb-10" : " m-auto"
              )}
            >
              {/*content*/}
              <div
                // className={`border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-2 overflow-y-auto overflow-x-hidden${
                //   slidemodal ? " h-full " : " max-h-screen-100"
                // }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {children}
              </div>
            </div>
          </div>
          {noDarkBackground ? null : (
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          )}
        </>
      ) : null}
    </>
  );
};

export default Modal;
