import React from "react";
import Button from "../../components/ButtonUI";
import "./styles.scss";

interface Props {
  setOpenModal: Function;
  action: Function;
}

export const ConfirmModal: React.FC<Props> = ({ setOpenModal, action }) => {
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute z-40 top-1/3 flexColCenter confirmAddPatient">
        <h2>Êtes-vous sûr de vouloir modifier les données du patient ?</h2>
        <div className="flexCenter mt-6 buttons">
          <Button
            status="secondary"
            width={"119px"}
            height={"50px"}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Annuler
          </Button>
          <Button
            status="secondary"
            width={"119px"}
            height={"50px"}
            onClick={() => {
              action();
            }}
          >
            Confirmer
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmModal;
