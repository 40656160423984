import { atom } from "jotai";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];
let random: string | null =
  colorAvatar[Math.floor(Math.random() * colorAvatar.length)];
if (localStorage.getItem("colorAvatar"))
  random = localStorage.getItem("colorAvatar");
else {
  localStorage.setItem("colorAvatar", random);
}
export const colorAvatarAtom = atom<string | null>(random);
