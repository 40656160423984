import React, { useEffect, useState } from "react";
import { loadImage } from "../../api/medecin";
import { IPatient } from "../../types/patient";
import Spinner from "../Spinner";
import { useAtom } from "jotai";
import { refreshAtomRequest } from "../../state/refreshRequestList";

interface IProps {
  helperCategorie: Function;
  setAddCategorieDocMenu: Function;
  addCategorieDocMenu: boolean;

  documentsList: [] | any;
  patientData: IPatient | any;
  setImageUrl: Function;
  setModalImage: Function;
  retriveDemandeMyAPI: Function;
  setDocumentsList: Function;
}

const parseImage = (specialite, userId, filePath) => ({
  original: loadImage(specialite, userId, "thumbnail", filePath),
});

const ImagesContainer: React.FC<IProps> = ({
  documentsList,
  patientData,
  setImageUrl,
  setModalImage,
  setDocumentsList,
}) => {
  const [parsedImages, setParsedImages] = useState([]);
  const [refresh] = useAtom(refreshAtomRequest);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (patientData) 
    {
      const imgs = [] as any;
      documentsList.map((img) => {
        imgs.push(parseImage(img.specialite, img.patientId, img.filePath));
      });
      setParsedImages(imgs);
    }
  }, [documentsList]);
  useEffect(() => {
    const imgs = [] as any;
    documentsList.map((img) => {
      imgs.push(parseImage(img.specialite, img.patientId, img.filePath));
    });
    setParsedImages(imgs);
  }, [refresh?.state]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="flex">
        <p className="doc-title">Images</p>
      </div>
      <div className="images flex flex-wrap min-w-full mt-2 gap-7">
        <div className="images flex flex-wrap min-w-full mt-2 gap-7">
          {parsedImages.length !== 0 ||
          patientData.status !== "WAIT_FOR_IMGS" ? (
            parsedImages.map((i: any, k: number) => {
              return (
                i && (
                  <div className="relative imagesContainer">
                    <img
                      src={i?.original}
                      className="object-cover "
                      alt="document katomi"
                      onLoad={() => <Spinner />}
                      onClick={() => {
                        setImageUrl({
                          url: i?.original,
                          type: i?.original,
                          fileInfo: documentsList[k],
                        });

                        setModalImage(true);
                      }}
                    />
                  </div>
                )
              );
            })
          ) : (
            <div className="flexCenter w-full anEmptyBox">
              <p className="card-subtitle">
                N'oublier pas de joindre les photos de votre patient afin de
                bénéficier de l'avis médical de nos experts en{" "}
                {patientData.specialite} .
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ImagesContainer;
