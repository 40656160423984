import React, { useState } from "react";
import "./styles.scss";
import langue from "../../assets/images/langue.png";
import Cursus from "./Cursus";
import Description from "./Description";
import Sites from "./Sites";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import { User } from "../../types/user";
import AddLangue from "./modal/AddLangue";
import Subscription from "./Subscription";
import { FaSyncAlt } from "react-icons/fa";
import Invoices from "./Invoices";

const Profile: React.FC = () => {
  const [user] = useAtom<User | null>(userAtom);
  const [openModalAddLangue, setOpenModalAddLangue] = useState(false);

  const rolesLabels = {
    medecinResponsable: "Médecin Référent",
    medecinTeleexpert: "Médecin Expert",
    medecin: "Médecin Requérant",
    medecinChirurgien: "Médecin Chirurgien",
  };
  const successCallback = (position) => {
    console.log(position);
  };

  const errorCallback = (error) => {
    console.log(error);
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.log("Geolocalisation API is not supported");
    }
  };

  return (
    <div className="profile-doctor">
      <div className="grid grid-cols-6 info">
        <div className="flex col-span-5">
          <div className=" flex flex-col w-full doctorNames">
            <p className="doctorTitle">
              Dr {user?.firstName}{" "}
              {user?.lastName && user?.lastName.toUpperCase()}
            </p>
            <div className="flex specailities">
              <div className="boxSpecialite flexColCenter">
                <p>{rolesLabels[user?.type || ""]}</p>
              </div>
              {/* <FaSyncAlt
                onClick={() => {
                  getLocation();
                }}
              />{" "} */}
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-5 mt-8 gap-4">
        <div className="row-span-4">
          <Description />
        </div>
        {/* <Sites /> */}

        {user?.type !== "medecinResponsable" && (
          <>
            <div className="row-span-2  blocCursusTarif">
              <Subscription userProps={user} />
            </div>
            {/* TODO : display only for non free users */}
            <div className="row-span-4">
              <Invoices />
            </div>
          </>
        )}

        <div className="langues row-span-1">
          <div className="headerContainer flex items-center relative">
            <img src={langue} className="svgClass" alt="chat" />
            <p className="header">LANGUES PARLÉES</p>
            <p
              className="absolute right-0	modifier"
              onClick={() => setOpenModalAddLangue(true)}
            >
              Modifier
            </p>
          </div>
          <div className="paiements flex flex-wrap">
            {user?.langues &&
              user?.langues.map((langue) => (
                <div className="boxMethod flexRowCenter mt-2">
                  <p>{langue}</p>
                </div>
              ))}
          </div>
        </div>

        <div className="blocCursusTarif row-span-2">
          <Cursus userProps={user} />
        </div>
        {/* <Sites /> */}
        {openModalAddLangue && (
          <AddLangue
            setOpenModal={setOpenModalAddLangue}
            openModal={openModalAddLangue}
            doctorId={user?._id}
            languesDoctor={user?.langues}
          />
        )}
      </div>
    </div>
  );
};

export default Profile;
