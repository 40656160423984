import React from "react";
import { switchDoctor } from "../../api/medecin";
import Button from "../../components/ButtonUI";
import makeToast from "../Snackbar";
import "./styles.scss";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  setOpenModalAddDoctor: Function;
  doctorData: any;
  setActive: Function;
  closeModal: Function;
}

export const ConfirmModalActivation: React.FC<Props> = ({
  openModal,
  setOpenModal,
  setOpenModalAddDoctor,
  doctorData,
  setActive,
  closeModal,
}) => {
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute z-40 top-1/3 flexColCenter confirmAddPatient">
        <h2>
          Êtes-vous sûr de vouloir modifier le status compte du Dr.{" "}
          {doctorData.lastName} ?
        </h2>
        <div className="flexCenter mt-6 buttons">
          <Button
            status="secondary"
            width={"119px"}
            height={"50px"}
            onClick={() => {
              setActive(doctorData.activated);
              setOpenModal(false);
            }}
          >
            Annuler
          </Button>
          <Button
            status="secondary"
            width={"119px"}
            height={"50px"}
            onClick={() => {
              const res = switchDoctor(doctorData);
              makeToast("success", "Changement du Status effectué avec succès");
              setOpenModal(false);

              closeModal(true);
            }}
          >
            Confirmer
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmModalActivation;
