import { useAtom } from "jotai";
import { useState } from "react";
import Icon from "../../../components/Icon";
import Button from "../../../components/ButtonUI";
import Select from "../../../components/Select";
import { userAtom } from "../../../state/auth";

export interface IProps {
  openModal: boolean;
  setOpenModal: Function;
  filerObject: object;
  setFilterObject: Function;
  search: boolean;
  setSearch;
}

const FilterModal: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  filerObject,
  setFilterObject,
  search,
  setSearch,
}) => {
  const [user, setUser] = useAtom(userAtom);

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd">
        <div className="flex justify-end p-5">
          <div
            className="bg-gray-100 p-2 rounded-2xl"
            onClick={() => setOpenModal(false)}
          >
            <Icon style={{ cursor: "pointer" }} isIcon={true} name="close" />
          </div>
        </div>
        <div className="flex flex-col item-center space-y-3  px-10 pb-5">
          {/* <div className="flexCenter space-x-5">
            <Select
              labelText="select sexe"
              className="w-60 px-3"
              labelProps={{ className: "w-40" }}
              options={[
                { label: "homme", value: "man" },
                { label: "femme", value: "woman" },
              ]}
              onChange={(e) => {
                setFilterObject({ ...filerObject, patientSexe: e.target.value });
              }}
            />
          </div> */}
          <div className="flexCenter space-x-5 ">
            <Select
              labelText="Type de médecin"
              className="w-60 px-3"
              labelProps={{ className: "w-40" }}
              style={{ cursor: "pointer" }}
              text="Sélectionner le Type de médecin"
            defaultValue={"medecinResponsable"}
              options={[
                { label: "Médecin Référent", value: "medecinResponsable" },
                { label: "Médecin Expert", value: "medecinTeleexpert" },
                { label: "Médecin Requérant", value: "medecin" },
                { label: "Médecin Chirurgien", value: "medecinChirurgien" },
              ]}
              onChange={(e) => {
                setFilterObject({
                  ...filerObject,
                  userType: e.target.value,
                });
                setSearch(true);
              }}
            />
          </div>
          {/* <div className="flexCenter space-x-5">
            <Select
              labelText="select specialité"
              className="w-60 px-3"
              labelProps={{ className: "w-40" }}
              options={[{ label: "Neurologue", value: "Neurologue" }]}
              onChange={(e) => {
                setFilterObject({ ...filerObject, doctorSpeciality: e.target.value });
              }}
            />
          </div> */}
          <Button
            status="secondary"
            height="50px"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Fermer
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
