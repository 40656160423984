const etatOptionGroup = [
  { key: "bon ", value: "bon " },
  { key: "altéré", value: "altéré" },
];
const evolution = [
  { label: "stable", value: "stable" },
  { label: "ascendante", value: "ascendante" },
  { label: "descendante", value: "descendante" },
  { label: " Médaillon initial", value: " Médaillon initial" },
  { label: "Migratrice", value: "Migratrice" },
];

const humanBody = [
  { label: "tête", value: "tête" },
  { label: "cou", value: "cou" },
  { label: "membre sup droit", value: "membre sup droit" },
  { label: "membre sup gauche", value: "membre sup gauche" },
  { label: "dos", value: "dos" },
  { label: "face antérieure du tronc", value: "face antérieure du tronc" },
  { label: "membre inférieur droit", value: "membre inférieur droit" },
  { label: "membre inférieur gauche", value: "membre inférieur gauche" },
];
const Examens = [
  { label: "Biologie", value: "biologie" },
  { label: "Imagerie", value: "imagerie" },
  { label: "biopsie cutanée", value: "biopsie cutanée" },
];
const traitements = [
  { label: "dermocorticoïdes", value: "dermocorticoïdes" },
  { label: "antifongiques", value: "antifongiques" },
  { label: "antibiotiques pers os", value: "antibiotiques pers os" },
  { label: "antibiotiques locaux", value: "antibiotiques locaux" },
];
const antecedents = [
  { label: "Carcinomes basocellulaires", value: "Carcinomes basocellulaires" },
  { label: "Carcinome épidermoïde", value: "Carcinome épidermoïde" },
  { label: "Mélanome", value: "Mélanome" },
];

const modificationOptionGroup = [
  {
    key: "Oui",
    value: "Oui",
  },

  {
    key: "Non",
    value: "Non",
  },
  {
    key: "Inconnue",
    value: "Inconnue",
  },
];

export const formConfigDermatose = [
  {
    type: "texteLibre",
    field: "Description de l’éruption motivant la téléexpertise",
    label: "Description de l’éruption motivant la téléexpertise",
    style: {
      color: "",
      margin: "",
    },
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["Obligatoire"],
      },
    ],
  },

  {
    type: "only_Creatable",
    field: "Antécédents dermatologiques",
    label: "Antécédents dermatologiques/extradermatologiques",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "text",
    field: "Mode de vie",
    label: "Mode de vie",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "only_Creatable",
    field: "Traitements habituels chroniques",
    label: "Traitements habituels chroniques",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "only_Creatable",
    field: "Traitements récemment introduits",
    label: "Traitements récemment introduits pour l’éruption",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "text",
    field: "Durée de l’éruption",
    label: "Durée de l’éruption",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "text",
    field: "Evolution de l’éruption",
    label: "Evolution de l’éruption et des symptômes depuis son apparition ",
    style: {
      color: "",
      margin: "",
    },
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["Obligatoire"],
      },
    ],
  },
  {
    type: "text",
    field: "Topographie de l’éruption actuelle ",
    label: "Topographie de l’éruption actuelle ",
    style: {
      color: "",
      margin: "",
    },
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["Obligatoire"],
      },
    ],
  },
  {
    type: "text",
    field: "Prurit",
    label: "Prurit",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "text",
    field: "Éruption dans l’entourage ",
    label: "Éruption dans l’entourage",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "only_Creatable",
    field: "Signes extra-cutanés",
    label: "Signes extra-cutanés",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "radio",
    field: "Fièvre",
    label: "Fièvre",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "radio",
    field: "Atteinte des muqueuses",
    label: "Atteinte des muqueuses",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "contionalIput",
    field: "muqueuses",
    label: "Lesquelles ?",
    style: {
      color: "",
      margin: "",
    },
    condition: "Atteinte des muqueuses",
    conditionValue: "Oui",
  },
  {
    type: "radioWithExtraCondition",
    field: "Contraception",
    label: "Contraception",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "radio",
    field: "Voyage hors de France",
    label: "Voyage hors de France durant l’année précédente",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },

  {
    type: "contionalIput",
    field: "destination voyage",
    label: "Où exactement ? ",
    style: {
      color: "",
      margin: "",
    },
    condition: "Voyage hors de France",
    conditionValue: "Oui",
  },
  {
    type: "only_Creatable",
    field: "Examens complémentaires",
    label: "Examens complémentaires",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "texteLibre",
    field: "Commentaires libres",
    label: "Commentaires libres",
    style: {
      color: "",
      margin: "",
    },
  },

  {
    type: "text",
    field: "Question posée",
    label: "Question posée",
    style: {
      color: "",
      margin: "",
    },
    validationType: "string",

    validations: [
      {
        type: "required",
        params: ["Obligatoire"],
      },
    ],
  },
];

export const formConfigTummerCutane = [
  {
    type: "radio",
    field: "Autonome",
    label: "Autonome",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },

  {
    type: "contionalIput2",
    field: "Logement",
    label: "Logement ",
    style: {
      color: "",
      margin: "",
    },
    condition: "Autonome",
    conditionValue: "Non",
  },
  {
    type: "radio",
    field: "Démence",
    label: "Démence",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "radio",
    field: "Aspirine/Antiagrégants",
    label: "Aspirine/Antiagrégants",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "radio",
    field: "Anticoagulants",
    label: "Anticoagulants",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "radio",
    field: "Antécédents de cancers cutanés connus",
    label: "Antécédents de cancers cutanés connus",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
    validationType: "string",

    validations: [
      {
        type: "required",
        params: ["Obligatoire"],
      },
    ],
  },
  {
    type: "arrayWithCondition",
    field: "Antécédents de cancers cutanés",
    options: antecedents,

    condition: "Antécédents de cancers cutanés connus",
    conditionValue: "Oui",
  },
  {
    type: "radio",
    field: "Antécédent familial de mélanome",
    label: "Antécédent familial de mélanome",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
    validationType: "string",

    validations: [
      {
        type: "required",
        params: ["Obligatoire"],
      },
    ],
  },
  {
    type: "only_Creatable",
    field: "Autres antécédents",
    label: "Autres antécédents",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "only_Creatable",
    field: "Traitements antérieurs",
    label: "Traitements antérieurs",
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "radio",
    field: "Phototype clair",
    label: "Phototype clair",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "radio",
    field: "Immunodépression",
    label: "Immunodépression",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "text",
    field: "Localisation de la lésion",
    label: "Localisation de la lésion",
    placeholder: "Sélectionner la localisation de la lésion",

    style: {
      color: "",
      margin: "",
    },
    validationType: "string",

    validations: [
      {
        type: "required",
        params: ["Obligatoire"],
      },
    ],
  },
  {
    type: "text",
    field: "Ancienneté de la lésion",
    label: "Ancienneté de la lésion",
    style: {
      color: "",
      margin: "",
    },
    validationType: "string",

    validations: [
      {
        type: "required",
        params: ["Obligatoire"],
      },
    ],
  },
  {
    type: "number",
    field: "Taille",
    label: "Taille (mm)",
    style: {
      color: "",
      margin: "",
    },
    validationType: "string",

    validations: [
      {
        type: "required",
        params: ["Obligatoire"],
      },
    ],
  },

  {
    type: "radio",
    field: "Douleur",
    label: "Douleur",
    options: modificationOptionGroup,
    style: {
      color: "",
      margin: "",
    },
  },
  {
    type: "texteLibre",
    field: "Commentaires libres",
    label: "Commentaires libres",
    style: {
      color: "",
      margin: "",
    },
  },
];
