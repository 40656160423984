import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useAtom } from "jotai";
import _ from "lodash";
import { Formik } from "formik";

import Button from "../../components/ButtonUI";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { colorAvatar, tabsR, tabsT } from "./fakeData";
import moment from "../../helper/moment";
import { userAtom } from "../../state/auth";
import { User } from "../../types/user";
import { statusLabel } from "../../helper/demandeStatus";
import { deleteDoctor, groupDoctor, modifyAdelliRpps } from "../../api/medecin";
import Historiques from "./Historiques";
import Spinner from "../../components/Spinner";
import ConfirmModalActivation from "./ConfirmModalActivion";
import SharedRequest from "../SharedRequest";
import ConfirmModalFree from "./ConfirmModalFree";
import CardSpecialist from "./CardSpecialist";
import birthday from "../../assets/images/birthday.png";
import phone from "../../assets/images/phone.png";
import mail from "../../assets/images/mail.png";
import telex from "../../assets/images/dermatologista.png";
import home from "../../assets/images/home.png";
import numAdeli from "../../assets/images/patient-doc.png";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";

import Input from "../Input";
import numAdliSchema from "./numAdli";
import { refreshAtomUsers } from "../../state/refreshUsersList";
import makeToast from "../Snackbar";
import { getAllGroups, updateGroupDoctor } from "../../api/groups";
import SelectComp from "../Select";
import Icon from "../Icon";
interface IProps {
  openModal: Boolean;
  setOpenModal: Function;
  message?: String;
  type?: String;
  url?: string;
  doctorData?: any;
  antecedentsList?: [] | any;
  antecedentsChrirugicalList?: [] | any;
  setDocumentsList?: [] | any;
  documentsList?: [] | any;
  consultaionPage?: boolean;
  colorIndex?: number;
  setUpdateList: Function;
  setEditModeSharedDoctor?: Function | any;
  setRefreshGroup?: Function | any;
  editModeSharedDoctor?: boolean;
}

const DoctorInfo: React.FC<IProps> = ({
  doctorData,
  setOpenModal,
  consultaionPage,
  colorIndex,
  setUpdateList,
  setRefreshGroup,
  editModeSharedDoctor,
  setEditModeSharedDoctor,
}) => {
  console.log(
    "🚀 ~ file: index.tsx:67 ~ editModeSharedDoctor:",
    editModeSharedDoctor
  );

  const [openTab, setOpenTab] = useState(1);
  const [display, setDisplay] = useState(true);
  const [user] = useAtom<User | any>(userAtom);
  const [active, setActive] = useState(false);
  const [free, setFree] = useState(user?.freeAccount);
  const [switcher, setSwitcher] = useState(true);
  const [switcherPay, setSwitcherPay] = useState(true);
  const [modalRequest, setModalRequest] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [openModalActivation, setOpenModalActivation] = useState(false);
  const [openModalFree, setOpenModalFree] = useState(false);
  const [refresh, setRefresh] = useAtom(refreshAtomUsers);
  const [tabs, setTabs] = useState(tabsR);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [editListGroup, setEditListGroup] = useState(false);

  useEffect(() => {
    if (doctorData?._id) {
      setActive(doctorData.activated);
      if (doctorData.type === "medecinResponsable") setSwitcher(false);
      if (doctorData.type === "medecinTeleexpert") setTabs(tabsT);
      setFree(doctorData.freeAccount);
      if (doctorData.type === "medecinResponsable") setSwitcherPay(false);
    }
  }, [doctorData]);

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      const response = await getAllGroups();
      setGroupList(response);
    }

    fetchMyAPI();
  }, []);
  const colorStatus = (status, delai) => {
    switch (status) {
      case "IN_PROGRESS":
        if (moment(delai).diff(moment(), "hours") > 1) {
          return "rgba(52, 211, 153)";
        } else {
          return "rgb(244 ,142 ,154)";
        }

      case "RESPONSE":
        return "rgba(52, 211, 153)";
      default:
        if (moment(delai).diff(moment(), "hours") > 1) {
          return "rgba(98 145 240)";
        } else {
          return "rgb(244 ,142 ,154)";
        }
    }
  };
  const activeChange = (checked) => {
    setOpenModalActivation(true);
    setActive(checked);
  };
  const freeChange = (checked) => {
    setOpenModalFree(true);
    setFree(checked);
    console.log(checked);
  };
  const softDeleteUser = async (data) => {
    const response = deleteDoctor(data);

    setOpenModal(false);
    makeToast("success", `Suppréssion du Compte Dr ${data.firstName}`);
  };
  const requrentRoles = {
    ["medecinRequrent"]: "Médecin Requérant",
    ["sageFemme"]: "Sage-femme",
    ["infirmier"]: "Infirmier(e)",
  };
  const rolesLabels = {
    medecinResponsable: "Médecin Référent",
    medecinTeleexpert: "Médecin Expert",
    medecinChirurgien: "Médecin Chirurgien",
  };
  const digitsOnly = (value) => /^\d+$/.test(value);
  const verifNumRpps = (num, numType) => {
    // switch (numType) {
    //   case "RPPS":
    //     if (num && num.length === 11 && digitsOnly(num)) return false;
    //     else return true;

    //   case "ADELI":
    //     if (num && num.length === 9) return false;
    //     else return true;
    // }
    return true 
  };
  const buttonOnSubmit = async (values) => {
    try {
      if (editModeSharedDoctor) {
        if (values.groupId === "Pas de groupe") {
          await updateGroupDoctor(doctorData._id, { groupId: null });
          makeToast(
            "success",
            "Suppression d'utilisateur du groupe effectuée avec succès"
          );
        } else {
          await updateGroupDoctor(doctorData._id, { groupId: values.groupId });
          makeToast("success", "Groupe mis à jour avec succès");
        }
        setRefreshGroup((x) => !x);
        setEditModeSharedDoctor(false);
        setEditListGroup(false);
        setOpenModal(false);
      } else {
        if (values.groupId === "Pas de groupe") {
          await updateGroupDoctor(doctorData._id, { groupId: null });
          makeToast(
            "success",
            "Suppression d'utilisateur du groupe effectuée avec succès"
          );
        } else {
          await updateGroupDoctor(doctorData._id, { groupId: values.groupId });
          makeToast("success", "Groupe mis à jour avec succès");
        }
        setEditListGroup(false);
        setOpenModal(false);
      }
    } catch (error) {
      console.log(`Error: ${JSON.stringify(error)}`);
    }
  };
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      {doctorData?._id ? (
        <div className="absolute  inset-y-0 right-0 z-40  patient-modal-info">
          <div
            className={`sub-container ${
              display ? "overflow-hidden" : "max-h-content"
            }`}
          >
            <div>
              {!consultaionPage && (
                <Button
                  status="icon-secondary"
                  onClick={() => setOpenModal(false)}
                  className="absolute top-1 right-1 "
                >
                  <Close className="absolute top-1 right-1 " />
                </Button>
              )}
            </div>
            <div className="flex header justify-between	">
              <div className="infos flex w-full justify-between mr-2 mt-3">
                <div className="flex sub-infos">
                  <div
                    className="photo"
                    style={{
                      backgroundColor:
                        colorAvatar[(colorIndex || 1) % colorAvatar.length],
                    }}
                  >
                    {doctorData?.gender === "male" ? (
                      <ManPatient />
                    ) : (
                      <WomanPatient />
                    )}
                  </div>
                  <div className="details">
                    <div className="flex justify-between">
                      <div>
                        <p className="font-bold text-lg capitalize">
                          {doctorData.firstName} {doctorData.lastName}
                        </p>
                        <div className="flex birth  mt-2">
                          <div className="sub-birth flexCenter img">
                            <img src={birthday} alt="birthday" />
                          </div>
                          <div className="">
                            <p className="text-sm  text-neutral-900">
                              {moment(doctorData.birthday).format("L")} (
                              {moment(Date.now()).year() -
                                moment(doctorData.birthday).year()}{" "}
                              ans)
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mx-6 mt-2">
                        <p className="text-xs  font-bold">
                          {doctorData.type === "medecin"
                            ? (rolesLabels["medecin"] =
                                doctorData.requrentType &&
                                requrentRoles[doctorData.requrentType]
                                  ? requrentRoles[doctorData.requrentType]
                                  : "Médecin Requérant")
                            : rolesLabels[doctorData.type]}{" "}
                        </p>
                        <div className="flex  ">
                          <div className="flexCenter img">
                            <img
                              src={numAdeli}
                              alt="home"
                              width="14.64px"
                              height="14.64px"
                            />
                          </div>
                          <div className="text-xs font-bold">
                            Numéro ADELI :{" "}
                            {doctorData.numCom ? doctorData.numCom : "N/A"}
                          </div>
                        </div>
                        <div className="flex  ">
                          <div className="flexCenter img">
                            <img
                              src={numAdeli}
                              alt="home"
                              width="14.64px"
                              height="14.64px"
                            />
                          </div>
                          <div className="text-xs font-bold">
                            Numéro RPPS :{" "}
                            {doctorData.numRPPS ? doctorData.numRPPS : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex justify-between phonemail"> */}
                    <div className="flex -mt-2">
                      <div className="flexCenter img">
                        <img
                          src={phone}
                          alt="phone"
                          width="15.5px"
                          height="15.5px"
                        />
                      </div>
                      <div>{doctorData.phone}</div>
                    </div>
                    <div className="flex ">
                      <div className="flexCenter img">
                        <img
                          src={mail}
                          alt="mail"
                          width="15.5px"
                          height="15.5px"
                        />
                      </div>
                      <div>{doctorData.email}</div>
                    </div>
                    {/* </div> */}
                    <div className="flex">
                      <div className="flex">
                        <div className="flexCenter img">
                          <img
                            src={home}
                            alt="home"
                            width="14.64px"
                            height="14.64px"
                          />
                        </div>
                        <div>{doctorData.adresse}</div>
                      </div>
                    </div>
                    <div className="flex "></div>
                  </div>
                </div>
                <div className="patient-icons flex flex-col justify-between">
                  {/* <Button status="icon-secondary">
                    <ThreeDots />
                  </Button>
                  <Button
                    status="icon-secondary"
                    className="bg-white"
                    onClick={() => {
                      setSwitchToChatModal(true);
                    }}
                  >
                    <IconMessage />
                  </Button> */}
                </div>
              </div>
            </div>

            <div className={`patient-tabs2 ${!display ? "stretch" : null}`}>
              <div className="tabs flex items-center justify-between	">
                {tabs.map((tab) => {
                  if (
                    tab.id === 6 &&
                    !(
                      verifNumRpps(doctorData.numCom, "ADELI") ||
                      verifNumRpps(doctorData.numRPPS, "RPPS")
                    )
                  ) {
                    // eslint-disable-next-line array-callback-return
                    return;
                  } else
                    return (
                      <div
                        className={
                          openTab === tab.id
                            ? "tab selected flexCenter"
                            : "tab flexCenter cursor-pointer"
                        }
                        onClick={() => {
                          setOpenTab(tab.id);
                          if (tab.id === 0 || tab.id === 3) {
                            setDisplay(true);
                          } else if (tab.id === 1 || tab.id === 2) {
                            setDisplay(true);
                          }
                        }}
                      >
                        {tab.id === 6 ? tab.name + "⚠️" : tab.name}
                      </div>
                    );
                })}
              </div>
              <div></div>
            </div>
            <div className="relative tabs-content">
              <div className="tabs-container">
                <div className="tab-content tab-space tabs-liste">
                  <div
                    className={classNames(
                      "item",
                      openTab === 1 ? "block" : "hidden"
                    )}
                    id=""
                  >
                    <div className="sub-item">
                      <div className="title-container">
                        <p className="title mb-1">
                          Activation/Désactivation du compte
                        </p>
                        <span className="card-subtitle">
                          Membre depuis :{" "}
                          {moment(doctorData.createdAt).format("L")}
                        </span>
                      </div>
                      <div className="flex values-patient">
                        <div className="flexCenter sub-values">
                          <p className="name">Status du compte :</p>

                          <p>
                            <ToggleSwitch
                              id="newsletter"
                              disabled={!switcher}
                              small
                              checked={active}
                              onChange={activeChange}
                              setOpenModalActivation={setOpenModalActivation}
                            />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="sub-item">
                      <div className="title-container">
                        <p className="title">Liste des groupes</p>
                        <span className="card-subtitle"></span>
                        <Formik
                          initialValues={{
                            groupId: doctorData?.groupId,
                          }}
                          onSubmit={async (values) => {
                            try {
                              console.log("Submited");
                            } catch (error) {
                              console.log(`Error: ${JSON.stringify(error)}`);
                            }
                          }}
                        >
                          {(props) => {
                            const {
                              values,
                              errors,
                              isSubmitting,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              isValid,
                              touched,
                            } = props;

                            return (
                              <>
                                <form onSubmit={handleSubmit}>
                                  <div className="flex flex-row gap-x-2 items-center">
                                    <SelectComp
                                      id="groupId"
                                      value={values.groupId}
                                      options={[
                                        { value: null, label: "Pas de groupe" },
                                        ...groupList?.map((e) => ({
                                          value: e._id,
                                          label: e.name,
                                        })),
                                      ]}
                                      onChange={handleChange}
                                      onBlur={() => handleBlur}
                                      text="Sélectionner un groupe"
                                      className=" flex-grow h-10 px-5 border border-gray-200 mt-2"
                                      disabled={!editListGroup}
                                      style={{ maxWidth: "450px" }}
                                    />

                                    <div className="cursor-pointer ">
                                      {editListGroup ? (
                                        <div
                                          className="flex"
                                          style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Icon
                                            isIcon={true}
                                            name="close"
                                            onClick={() =>
                                              setEditListGroup(false)
                                            }
                                          />
                                          <Icon
                                            isIcon={true}
                                            name="done"
                                            onClick={() => {
                                              buttonOnSubmit(values);
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <Icon
                                          name="pen"
                                          height={22}
                                          width={22}
                                          onClick={() => {
                                            setEditListGroup(true);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </form>
                              </>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                    <div className="sub-item">
                      <div className="title-container">
                        <p className="title">Mode de facturation</p>
                        <span className="card-subtitle"></span>
                      </div>
                      <div className="flex values-patient">
                        <div className="flexCenter sub-values">
                          <p className="name">Non payant :</p>

                          <p>
                            <ToggleSwitch
                              id="freeAccount"
                              disabled={!switcherPay}
                              small
                              checked={free}
                              onChange={freeChange}
                              setOpenModalActivation={setOpenModalFree}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "item",
                      openTab === 2 ? "block" : "hidden"
                    )}
                    id=""
                  >
                    {doctorData?.patients?.length > 0 ? (
                      <>
                        {doctorData.patients.map((el) => {
                          return (
                            <CardSpecialist
                              className=""
                              specialistDetails={el}
                              i={0}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <div className="flexCenter w-full anEmptyBox">
                        <p className="card-subtitle">
                          Ce médecin n'a aucun patient.
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    className={classNames(
                      "item",
                      openTab === 3 ? "block  " : "hidden"
                    )}
                    id=""
                  >
                    {doctorData?.dispoId &&
                    doctorData?.dispoId?.agendaConfig?.length > 0 ? (
                      <div className="flex flex-wrap gap-x-0.5 gap-y-4  text-sm">
                        {doctorData.dispoId.agendaConfig.map((d) => {
                          return (
                            <div className="mx-2  bg-white rounded-lg shadow-lg ">
                              <span
                                className="flexCenter w-20 font-bold rounded-t-lg px-1 "
                                style={{
                                  backgroundColor:
                                    colorAvatar[
                                      (colorIndex || 2) % colorAvatar?.length
                                    ],
                                }}
                              >
                                {d.day}
                              </span>

                              <p className="flexCenter mt-2 italic rounded-lg ">
                                {d.rnageOfTime.start}
                              </p>
                              <p className="flexCenter mb-2 mt-2 italic   rounded-lg ">
                                {d.rnageOfTime.end}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="flexCenter w-full anEmptyBox">
                        <p className="card-subtitle">
                          Ce médecin n'a aucune disponibilité.
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={openTab === 4 ? "block" : "hidden"} id="">
                    <div className="flex flex-col mt-8">
                      <div className="container flex flex-col mx-auto w-full  ">
                        <ul className="flex flex-col">
                          {doctorData?.demandes?.length > 0 ? (
                            <>
                              {doctorData.demandes.map((d) => (
                                <div
                                  className="card-specialist  p-3 space-x-3 max-w-max shadow relative"
                                  onClick={() => {
                                    setSelectedRequest(d);
                                    setModalRequest(true);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="flex">
                                    <div
                                      className="rounded-lg h-14 w-14 flexCenter mx-5 my-auto"
                                      style={{
                                        backgroundColor:
                                          colorAvatar[
                                            Math.floor(
                                              Math.random() * colorAvatar.length
                                            )
                                          ],
                                        opacity: 0.7,
                                      }}
                                    >
                                      {d.type === "Dermatose non tumorale" ? (
                                        <img
                                          src={telex}
                                          alt="telex"
                                          width={"45px"}
                                          height={"45px"}
                                        />
                                      ) : (
                                        <img
                                          src={telex}
                                          alt="telex"
                                          width={"45px"}
                                          height={"45px"}
                                        />
                                      )}
                                    </div>
                                    <div className="details">
                                      <div className="flex-grow">
                                        <p className="card-title">
                                          Demande de téléexpertise en
                                          {" " + d.specialite}
                                        </p>
                                        <p className="font-bold text-gray-700">
                                          {" "}
                                          Type :{" " + d.type}
                                        </p>
                                        <p className=" w-64 font-bold text-sm    ">
                                          {statusLabel(
                                            d.status,
                                            d.delaiDate,
                                            user?.type,
                                            d.priseEncharge
                                          )}
                                        </p>
                                        <span
                                          className={`rounded-full w-3 h-3 absolute right-2 top-2  `}
                                          style={{
                                            backgroundColor: colorStatus(
                                              d.status,
                                              d.delaiDate
                                            ),
                                          }}
                                        ></span>
                                        <p className="card-sub-title text-xs absolute right-2 m-2 mt-0">
                                          {" le "}
                                          {moment(d.createdAt).format(
                                            "LL à HH:mm"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex space-x-3"></div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <div className="flexCenter w-full anEmptyBox">
                              <p className="card-subtitle">
                                Ce médecin n'a aucune demande de téléexpertise.
                              </p>
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={openTab === 5 ? "block" : "hidden"} id="">
                    {doctorData ? (
                      <Historiques doctorData={doctorData} />
                    ) : (
                      <div className="flexCenter w-full anEmptyBox">
                        <p className="card-subtitle">
                          Ce médecin n'a aucun historique.
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    className={openTab === 6 ? "item block" : "hidden"}
                    id=""
                  >
                    <div className="flex values-patient p-2 mt-2  rounded-lg ">
                      {(verifNumRpps(doctorData.numCom, "ADELI") ||
                        verifNumRpps(doctorData.numRPPS, "RPPS")) && (
                        <Formik
                          initialValues={{
                            numCom: doctorData?.numCom || "",
                            numRPPS: doctorData?.numRPPS || "",
                            doctorId: doctorData?._id || "",
                          }}
                          onSubmit={async (values) => {
                            try {
                              const newUser = await modifyAdelliRpps({
                                ...values,
                              });

                              if (newUser.id) {
                                setOpenModal(false);
                                setRefresh({ state: !refresh?.state });
                                makeToast(
                                  "success",
                                  "N° RPPS / ADELI ajoutée avec succès."
                                );
                              } else {
                                makeToast(
                                  "error",
                                  "Ajout du N° RPPS / ADELI  a échouher."
                                );
                              }
                            } catch (err) {
                              console.log(
                                "[error] add Patient -on Submit :",
                                err
                              );
                            }
                          }}
                          validationSchema={numAdliSchema}
                          validateOnMount={true}
                        >
                          {(props) => {
                            const {
                              values,
                              errors,

                              handleChange,
                              handleBlur,
                              handleSubmit,
                            } = props;

                            return (
                              <div className="mt-2 sub-item">
                                <div className="title-container mb-6 ">
                                  <p className="title ">N° ADELI / RPPS</p>
                                </div>

                                <div className="flex flexCenter items-center gap-5  my-2">
                                  <span className="w-28 name">N° ADELI</span>
                                  <Input
                                    id="numCom"
                                    placeholder="N° ADELI"
                                    className="rounded-lg "
                                    value={values.numCom}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    inlineLabel={false}
                                    onfocus={true}
                                    // disabled={!errors.numCom && user?.numCom ? true :false}
                                  />
                                </div>
                                {errors.numCom && (
                                  <span className="flexCenter m-2 text-gray-400 text-sm">
                                    {" "}
                                    {errors.numCom}
                                  </span>
                                )}

                                <div className="flex flexCenter   items-center gap-5 mx-2 my-2 ">
                                  <span className="w-28 name">N° RPPS </span>
                                  <Input
                                    id="numRPPS"
                                    className="rounded-lg "
                                    placeholder="N° RPPS "
                                    value={values.numRPPS}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    inlineLabel={false}
                                    onfocus={true}
                                    // disabled={user?.numRPPS ? true :false}
                                  />
                                </div>
                                {errors.numRPPS && (
                                  <span className="flexCenter m-2 text-gray-400 text-sm">
                                    {" "}
                                    {errors.numRPPS}
                                  </span>
                                )}

                                <div className=" flex items-center  justify-end">
                                  <Button
                                    status="secondary"
                                    width="100px"
                                    height="50px"
                                    className=" my-6"
                                    onClick={() => handleSubmit()}
                                  >
                                    Valider
                                  </Button>
                                </div>

                                {/* {
              confirmModal &&     <ConfirmationModal
              openModal={confirmModal}
              setOpenModal={setConfirmModal}
              setOpenModalAddDoctor={handleSubmit}
            />
            } */}
                              </div>
                            );
                          }}
                        </Formik>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openModalActivation && (
            <ConfirmModalActivation
              openModal={openModalActivation}
              setOpenModal={setOpenModalActivation}
              setOpenModalAddDoctor={() => console.log("")}
              doctorData={doctorData}
              closeModal={setOpenModal}
              setActive={setActive}
            />
          )}
          {openModalFree && (
            <ConfirmModalFree
              openModal={openModalFree}
              setOpenModal={setOpenModalFree}
              setOpenModalAddDoctor={() => console.log("")}
              doctorData={doctorData}
              closeModal={setOpenModal}
              setActive={setFree}
              free={free}
            />
          )}

          {modalRequest && (
            <SharedRequest
              demandeData={selectedRequest}
              setOpenModal={setModalRequest}
              colorIndex={0}
              consultaionPage={false}
            />
          )}
        </div>
      ) : (
        <div className="absolute  inset-y-0 right-0 z-40  transition ease-in duration-700 patient-modal-info">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default DoctorInfo;
