import * as Yup from "yup";
const digitsOnly = (value) => /^\d+$/.test(value);

const digitsOnly2 = (value) => /^(1|2)+\d+$/.test(value);
export default Yup.object().shape({
  email: Yup.string()
    .email("Veuillez saisir une adresse mail valide 😯 ")
    .required("Champ obligatoire "),
  lastName: Yup.string().required("Champ obligatoire  "),
  firstName: Yup.string().required("Champ obligatoire  "),
  birthday: Yup.string().required("Champ obligatoire "),
  //  gender:Yup.string().required("Champ bligatoire"),
  phone: Yup.string().required(" Champ obligatoire  "),
  adresse: Yup.string().required(" Champ obligatoire  "),

  numRPPS: Yup.string()
    .test(
      "Digits only",
      "Le N° RPPS doit contenir uniquement des chiffres numériques",
      digitsOnly
    )
    .required(" ")
    .min(11, "Le N° RPPS est composé de 11 chiffres")
    .max(11, "Le N°  RPPS est composé de 11 chiffres"),

  numCom: Yup.string()
    .required(" ")
    .min(9, "Le N° ADELI est composé de 9 caractères")
    .max(9, "Le N° ADELI est composé de 9 caractères"),

  codePostale: Yup.number().required("Champ obligatoire "),
  // medecin: Yup.string(),
});
