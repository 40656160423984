import { useState } from "react";
import PropTypes from "prop-types";

import moment from "../../helper/moment";
import { statusLabel } from "../../helper/demandeStatus";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import DynamicContainer from "../DynamicForms/DynamicContainer";
import Button from "../ButtonUI";
import {
  formConfigDermatose,
  formConfigTummerCutane,
} from "../../config/formConfig";

const DraftTask = ({
  _id,
  status,
  type,
  specialite,
  createdAt,
  delaiDate,
  docExpert,
  files,
  interrogatoire,
  patientId,
  docReq,
  priseEncharge,
}) => {
  docExpert = docExpert[0];
  const [modalAdd, setModalAdd] = useState(false);
  const [user] = useAtom(userAtom);
  const formDraft:
    | {
        type: String;
        field: String;
        label: String;
        style: any;
        validations?: any[];
        value?: any;
        conditionValue: string;
        condition: string;
        options?: any[];
        validationType?: string;
        placeholder?: string;
      }[]
    | any[] = [{}];

  if (type === "Dermatose non tumorale") {
    formConfigDermatose.map((f) =>
      formDraft.push({
        type: f.type,
        field: f.field,
        label: f.label,
        style: f.style,
        validitions: f.validations,
        value: interrogatoire?.other[f.field],
        options: f.options,
        condition: f.condition,
        conditionValue: f.conditionValue,
      })
    );
  } else if (type === "Tumeurs cutanees") {
    formConfigTummerCutane.map((f) =>
      formDraft.push({
        type: f.type,
        field: f.field,
        label: f.label,
        style: f.style,
        validitions: f.validations,
        value: interrogatoire?.other[f.field],
        options: f.options,
        condition: f.condition,
        conditionValue: f.conditionValue,
      })
    );
  }
  const colorStatus = (status, delai) => {
    switch (status) {
      case "IN_PROGRESS":
        if (moment(delai).diff(moment(), "hours") > 0) {
          return "green";
        } else {
          return "red";
        }

      case "RESPONSE":
        return "green";
      default:
        if (moment(delai).diff(moment(), "hours") > 0) {
          return "";
        } else {
          return "red";
        }
    }
  };
  return (
    <div
      className="flex flex-none flex-col p-2 mt-4 mb-4 rounded-large bg-white overflow-hidden w-full"
      style={{ cursor: "pointer" }}
    >
      <span
        onClick={async () => {
          setModalAdd(true);
        }}
      >
        <div className="flex items-center">
          <span
            className={`rounded-full w-2 h-2  bg-${colorStatus(
              status,
              delaiDate
            )}-400`}
          ></span>
          <span className="ml-2 text-sm">
            {statusLabel(status, delaiDate, user?.type, priseEncharge)}
          </span>
        </div>
        {(user?.type === "medecin" || user?.type === "medecinChirurgien") && (
          <>
            <div className="flex items-center">
              <span className={`rounded-full w-4 h-4 text-xs flex m-1 gap-x-1`}>
                {" "}
                <img
                  src={require(`../../assets/images/smileSick.png`).default}
                  alt="katomi"
                />
              </span>

              <p className="text-xs">
                {" "}
                {patientId?.lastName + " " + patientId?.firstName}
              </p>
            </div>
          </>
        )}

        {user?.type === "medecinResponsable" && (
          <>
            <div className="flex items-center">
              <span className={`rounded-full w-4 h-4 text-xs flex m-1 gap-x-1`}>
                {docExpert ? (
                  <>
                    <img
                      src={
                        require(`../../assets/images/securite-sociale.png`)
                          .default
                      }
                      alt="katomi"
                    />
                    <p className="font-bold">Expert</p>{" "}
                    <p> Dr.{docExpert?.lastName}</p>
                  </>
                ) : (
                  ""
                )}
              </span>
            </div>
            <div className="flex items-center">
              <span className={`rounded-full w-4 h-4 text-xs flex m-1 gap-x-1`}>
                {" "}
                <img
                  src={require(`../../assets/images/steto.png`).default}
                  alt="katomi"
                />
                <p className="font-bold">Demandeur</p>
                <p> Dr.{docReq?.lastName}</p>
              </span>
            </div>
            <div className="flex items-center">
              <span className={`rounded-full w-4 h-4 text-xs flex m-1 gap-x-1`}>
                {" "}
                <img
                  src={require(`../../assets/images/smileSick.png`).default}
                  alt="katomi"
                />
                <p className="font-bold"> Patient</p>
                <p> {patientId?.lastName}</p>
              </span>
            </div>
          </>
        )}

        {(user?.type === "medecinTeleexpert" ||
          user?.type === "medecinChirurgien") && (
          <>
            <div className="flex items-center">
              <span className={`rounded-full  h-5 text-xs flex  gap-x-1`}>
                {" "}
                <img
                  src={require(`../../assets/images/steto.png`).default}
                  alt="katomi"
                />
                <p className="font-bold ">Demandeur</p>
                <p> Dr.{docReq[0]?.lastName + " " + docReq[0]?.firstName}</p>
              </span>
            </div>
          </>
        )}

        <div className="mt-2">
          <p className="text-sm"></p>
          <div className="h-1 relative max-w-xl rounded-full overflow-hidden mt-4">
            <div className="w-full h-full bg-gray-200 absolute"></div>
            <div
              style={{
                width: `${
                  (moment(delaiDate).diff(moment(), "hours") / 48) * 100
                }%`,
              }}
              className={`h-full rounded-full absolute  bg-green-400`}
            ></div>
          </div>
        </div>

        <div className="flex mt-3 justify-between">
          {
            <>
              <span className="flex text-xs text-gray-400 items-center">
                {moment(delaiDate).diff(moment(), "hours") > 0 ? (
                  status !== "RESPONSE" && status !== "WAIT_FOR_IMGS" ? (
                    <p>
                      {moment(delaiDate).diff(moment(), "hours") - 24 >= 0
                        ? moment(delaiDate).diff(moment(), "hours") -
                          24 +
                          " heures avant rappel"
                        : `${moment(delaiDate).diff(
                            moment(),
                            "hours"
                          )} heures avant expiration`}
                    </p>
                  ) : (
                    <p>{moment(createdAt).format("DD MMMM YYYY à HH:mm")}</p>
                  )
                ) : (
                  <p>{moment(createdAt).format("DD MMMM YYYY à HH:mm")}</p>
                )}
                {/* <Icon className="w-6 h-6" isIcon={true} name={"reglage-haut_droite"} /> */}
              </span>
              <span className="flex-warp text-xs text-gray-400 items-center boxType flexColCenter ">
                <p>{/* Demande d'avis en <strong> {specialite}</strong> */}</p>{" "}
                <p className="mx-1">
                  <strong> Motif : </strong>
                  {type}
                </p>
                {/* <Icon className="w-6 h-6" isIcon={true} name={"reglage-haut_droite"} /> */}
              </span>
            </>
          }
        </div>
      </span>

      {modalAdd && (
        <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-scroll bg-opacity-modal flexCenter">
          <div className=" absolute  inset-y-5 right-0 form-add-patient transition transition ease-in duration-700  z-40 transition transition ease-in duration-700 patient-modal-info">
            <div className="patient-icons flex flex-col justify-between">
              <div>
                <Button
                  status="icon-secondary"
                  onClick={() => setModalAdd(false)}
                  className="absolute top-1 right-1 "
                >
                  <Close className="absolute top-1 right-1 " />
                </Button>
              </div>
            </div>

            <DynamicContainer
              formConfig={formDraft}
              setOpenModal={setModalAdd}
              props={{
                status,
                demandeId: _id,
                type,
                specialite,
                createdAt,
                files,
                delaiDate,
                patientId,
                docReq,
                docExpert: docExpert?._id,
                priseEncharge,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DraftTask.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  users: PropTypes.array,
  progress: PropTypes.number,
};
export default DraftTask;
