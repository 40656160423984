import axios from "axios";
import { get, post } from "../helper/apiMethods";
import {
  PAYMENTS_CREATE_SUBSCRIPTION_URL,
  PAYMENTS_CREATE_CUSTOMER_ID,
  PAYMENTS_CANCEL_SUBSCRIPTION_URL,
  PAYMENTS_VERIFY_SUBSCRIPTION_URL,
  PAYMENTS_GET_INVOICES,
  PAYMENTS_GET_PAYMENT_METHODS,
} from "../config/api";

export async function createSubscription({
  customerId,
  paymentMethodId,
  priceId,
}) {
  try {
    const { data: subscription } = await axios.post(
      PAYMENTS_CREATE_SUBSCRIPTION_URL,
      {
        customerId,
        paymentMethodId,
        priceId,
      }
    );

    return {
      paymentMethodId,
      priceId,
      subscription,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createCustomerId({ email, lastName, firstName }) {
  try {
    const data = await axios.post(PAYMENTS_CREATE_CUSTOMER_ID, {
      email,
      lastName,
      firstName,
    });

    return data.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function cancelSubscription({ subscriptionId, userId }) {
  try {
    const response = await axios.post(PAYMENTS_CANCEL_SUBSCRIPTION_URL, {
      subscriptionId: subscriptionId,
      userId: userId,
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function verifyingSubscription({ subscriptionId, userId }) {
  try {
    const response = await axios.post(PAYMENTS_VERIFY_SUBSCRIPTION_URL, {
      subscriptionId: subscriptionId,
      userId: userId,
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getInvoices() {
  try {
    const response = await get(PAYMENTS_GET_INVOICES);

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPaymentMethods() {
  try {
    const response = await get(PAYMENTS_GET_PAYMENT_METHODS);

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getDefaultPaymentMethod() {
  try {
    const response = await get(`${PAYMENTS_GET_PAYMENT_METHODS}/default`);

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addPaymentMethod(paymentMethod) {
  try {
    const response = await post(PAYMENTS_GET_PAYMENT_METHODS, {
      paymentMethod,
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
