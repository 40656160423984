import { useEffect, useState } from "react";
import Creatable from "react-select/creatable";
import Input from "../Input";
import CreatableOnly from "./CreatableOnly";
import "./styles.scss";
import TextArea from "../TextArea";

const RecursiveContainer = ({ config, formik, genre }) => {
  const [val, setVal] = useState(formik.values);
  useEffect(() => {
    setVal(formik.values);
  }, [formik]);
  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      border: state.isFocused ? "0" : "1px solid #5270f6 ",
      boxShadow: state.isFocused ? "0 0 0 0.1rem #5270f6" : 0,
      "*": {
        boxShadow: "none !important",
      },
    }),

    multiValue: (styles, state) => ({
      ...styles,
      color: "black",
      background: "#f7f8fd",
      ":hover": {
        background: "#a6a9c8",
      },
    }),
    input: (styles, state) => ({
      ...styles,
      height: "28px",
    }),
  };

  function isPerson(val, opt): boolean {
    try {
      if (val === opt.value) return true;
      else return false;
    } catch (error) {
      return false;
    }
  }

  const builder = (individualConfig, values) => {
    switch (individualConfig.type) {
      case "text":
        return (
          <>
            <div>
              <label className="block text-base font-medium text-gray-800 mb-1">
                {individualConfig.label}:
                {individualConfig.validationType === "string" &&
                  !formik.values[individualConfig.field] && (
                    <span className="flex items-center w-max h-4  px-1 py-1 text-xs  leading-none text-gray-400  bg-red-200 rounded">
                      Obligatoire
                    </span>
                  )}
              </label>

              <div className="inline-block w-full mr-2">
                <Input
                  id={individualConfig.field}
                  //placeholder={individualConfig.label}
                  type="text"
                  error={
                    formik.touched[individualConfig.field]
                      ? formik.errors[individualConfig.field]
                      : undefined
                  }
                  value={formik.values[individualConfig.field]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full mb-1"
                  onfocus={true}
                  style={{
                    cursor: "pointer",
                    border: "1px solid #5270f6",
                    borderRadius: "4px",
                    height: "38px",
                  }}
                />
              </div>
            </div>
          </>
        );
      case "date":
        return (
          <>
            <div>
              <label className="block text-base font-medium text-gray-800">
                {individualConfig.label}:
                {individualConfig.validationType === "string" &&
                  !formik.values[individualConfig.field] && (
                    <span className="flex items-center w-max h-4  px-1 py-1 text-xs  leading-none text-gray-400  bg-red-200 rounded">
                      Obligatoire
                    </span>
                  )}
              </label>
              <Input
                id={individualConfig.field}
                placeholder="JJ/MM/AAAA"
                type="date"
                lang="fr-CA"
                error={
                  formik.touched[individualConfig.field]
                    ? formik.errors[individualConfig.field]
                    : undefined
                }
                value={formik.values[individualConfig.field]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="custom-Input w-full m-2 mb-4 text-gray-800 "
                onfocus={true}
                style={{ cursor: "pointer" }}
              />
            </div>
          </>
        );
      case "number":
        return (
          <>
            <div>
              <label className="block text-base font-medium text-gray-800">
                {individualConfig.label}:
                {individualConfig.validationType === "string" &&
                  !formik.values[individualConfig.field] && (
                    <span className="flex items-center w-max h-4  px-1 py-1 text-xs  leading-none text-gray-400  bg-red-200 rounded">
                      Obligatoire
                    </span>
                  )}
              </label>
              <Input
                id={individualConfig.field}
                //placeholder="Taille en millimètres"
                type="number"
                error={formik.errors[individualConfig.field]}
                value={formik.values[individualConfig.field]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="custom-Input w-full m-2 mb-2 text-gray-800 "
                onfocus={true}
                style={{
                  cursor: "pointer",
                  border: "1px solid #5270f6",
                  borderRadius: "4px",
                  height: "38px",
                }}
              />
            </div>
          </>
        );
      case "array":
        return (
          <RecursiveContainer
            config={individualConfig.children || []}
            formik={formik}
            genre={genre}
          />
        );
      case "arrayWithCondition":
        return (
          formik.values[individualConfig.condition] ===
            individualConfig.conditionValue && (
            <>
              {" "}
              <label className="block text-base font-medium text-gray-800">
                {individualConfig.field}:
              </label>
              <div className="relative mt-1" style={{ cursor: "pointer" }}>
                <Creatable
                  options={individualConfig.options}
                  isMulti
                  components={{
                    IndicatorsContainer: () => <></>,
                  }}
                  value={
                    formik.values[individualConfig.field]
                      ? formik.values[individualConfig.field].map((p) => {
                          return { label: p, value: p };
                        })
                      : []
                  }
                  openMenuOnClick={false}
                  noOptionsMessage={() => null}
                  className="autoComplete"
                  placeholder={individualConfig.placeholder}
                  formatCreateLabel={(userInput) => `Ajouter  ${userInput}`}
                  name={individualConfig.field}
                  onChange={(value) =>
                    formik.setFieldValue(
                      individualConfig.field,
                      value.map((e: any) => e.value)
                    )
                  }
                  styles={customStyles}
                />
              </div>
            </>
          )
        );
      case "only_Creatable":
        return (
          <>
            <label className="block text-base font-medium text-gray-800">
              {" "}
              {individualConfig.label}:
            </label>
            <div
              className="relative mt-1"
              style={{ cursor: "pointer", marginTop: "8px" }}
            >
              <CreatableOnly
                formik={formik}
                name={individualConfig.field}
                onChange={(value) =>
                  formik.setFieldValue(
                    individualConfig.field,
                    value.map((e: any) => e.value)
                  )
                }
              />
            </div>
          </>
        );
      case "radio":
        return (
          <>
            <label className="block text-base font-medium text-gray-800">
              {individualConfig.label} :{" "}
              {individualConfig.validationType === "string" &&
                !formik.values[individualConfig.field] && (
                  <span className="flex items-center w-max h-4  px-1 py-1 text-xs  leading-none text-gray-400  bg-red-200 rounded">
                    Obligatoire
                  </span>
                )}
            </label>

            {individualConfig.options.map((opt, i) => (
              <div className="relative mt-2 inline-block ml-2 mr-2" key={i}>
                <Input
                  id={individualConfig.field}
                  name={individualConfig.field}
                  type="radio"
                  onChange={(e) =>
                    formik.setFieldValue(individualConfig.field, e.target.value)
                  }
                  checked={isPerson(values[individualConfig.field], opt)}
                  labelText={opt.key}
                  className="mr-1"
                  value={opt.value}
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
          </>
        );

      case "radioWithExtraCondition":
        return (
          genre === "female" && (
            <>
              <label className="block text-base font-medium text-gray-800">
                {individualConfig.label} :{" "}
              </label>

              {individualConfig.options.map((opt) => (
                <div className="inline-block ml-2 mr-2">
                  <Input
                    id={individualConfig.field}
                    name={individualConfig.field}
                    onChange={(e) =>
                      formik.setFieldValue(
                        individualConfig.field,
                        e.target.value
                      )
                    }
                    checked={isPerson(values[individualConfig.field], opt)}
                    value={opt.value}
                    type="radio"
                    labelText={opt.key}
                    className="mr-1"
                    style={{ cursor: "pointer" }}
                  />{" "}
                </div>
              ))}
            </>
          )
        );
      case "contionalIput":
        return (
          formik.values[individualConfig.condition] ===
            individualConfig.conditionValue && (
            <>
              <div className="inline-block ml-2 mr-2">
                <Input
                  id={individualConfig.field}
                  //placeholder={individualConfig.label}
                  type="text"
                  value={formik.values[individualConfig.field]}
                  error={
                    formik.touched[individualConfig.field]
                      ? formik.errors[individualConfig.field]
                      : undefined
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="custom-Input w-full m-2 mb-4 "
                  onfocus={true}
                  style={{ cursor: "pointer" }}
                />{" "}
              </div>
            </>
          )
        );
      case "contionalIput2":
        return (
          formik.values[individualConfig.condition] ===
            individualConfig.conditionValue && (
            <>
              <label className="block text-base font-medium text-gray-800">
                {individualConfig.label} :{" "}
              </label>
              <div className="inline-block ml-2 mr-2">
                <Input
                  name={individualConfig.field}
                  type="radio"
                  onChange={(e) =>
                    formik.setFieldValue(individualConfig.field, e.target.value)
                  }
                  value={"Institution"}
                  labelText={"Institution"}
                  className="mr-1"
                  style={{ cursor: "pointer" }}
                />
              </div>{" "}
              <div className="inline-block ml-2 mr-2">
                <Input
                  name={individualConfig.field}
                  type="radio"
                  onChange={(e) =>
                    formik.setFieldValue(individualConfig.field, e.target.value)
                  }
                  value={"Domicile"}
                  labelText={"Domicile"}
                  className="mr-1"
                  style={{ cursor: "pointer" }}
                />{" "}
              </div>
            </>
          )
        );
      case "texteLibre":
        return (
          <>
            {" "}
            <label className="block text-base font-medium text-gray-800">
              {individualConfig.label} :
              {individualConfig.validationType === "string" &&
                !formik.values[individualConfig.field] && (
                  <span className="flex items-center w-max h-4 px-1 py-1 text-xs  leading-none text-gray-400  bg-red-200 rounded">
                    Obligatoire
                  </span>
                )}
            </label>
            <div className="inline-block w-full">
              <TextArea
                id={individualConfig.field}
                className="-mb-1 -mt-3"
                //placeholder={individualConfig.label}
                value={formik.values[individualConfig.field]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inlineLabel={false}
                error={
                  formik.touched[individualConfig.field]
                    ? formik.errors[individualConfig.field]
                    : undefined
                }
                onfocus={true}
                style={{ cursor: "pointer" }}
              />
            </div>
          </>
        );
      default:
        return;
    }
  };
  return (
    <>
      {config.map((c) => {
        return builder(c, formik.values);
      })}
    </>
  );
};

export default RecursiveContainer;
