import React, { useState } from "react";
import { useAtom } from "jotai";
import { Formik } from "formik";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import "./styles.scss";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/Input";
import { userAtom } from "../../../state/auth";
import { updateGroup } from "../../../api/groups";
import makeToast from "../../../components/Snackbar";
import { Link } from "react-router-dom";

interface IProps {
  openModal: Boolean;
  setOpenModal: Function;
  message?: String;
  type?: String;
  url?: string;
  setRefreshGroup: Function;
  setDoctorAdded?: boolean | any;
  setSelectedDoctor: Function | any;
  setDoctorData: Function;
  setOpenModalInfoDoctor?: Function;
}

const AddGroup: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  setDoctorAdded,
  setRefreshGroup,
  setSelectedDoctor,
  setDoctorData,
  setOpenModalInfoDoctor,
}) => {
  const [user, setUser] = useAtom(userAtom);
  const [copied, setCopied] = useState(false);

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd w-1/2">
        <div className="relative ">
          <div className="absolute right-0 mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div>
        <Formik
          initialValues={{
            name: setSelectedDoctor?.name,
            freeRegistration: setSelectedDoctor?.freeRegistration,
          }}
          onSubmit={async (values) => {
            try {
              setOpenModal(!openModal);
              const response = await updateGroup(
                {
                  ...values,
                },
                setSelectedDoctor._id
              );

              if (response.group) {
                makeToast(
                  "success",
                  "Mise à jour des données du groupe effectuée avec succès."
                );
                //setDoctorAdded(true);
                setOpenModal(!openModal);
                setRefreshGroup((x) => !x);
                //setSelectedDoctor(response.data.id);
                setDoctorData(response.doctor);
              }
            } catch (err) {
              console.log("[error] add Group -on Submit :", err);
            }
          }}
        >
          {(props) => {
            const {
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;

            return (
              <form
                onSubmit={handleSubmit}
                className="flex-auto py-7 px-8 min-w-full	"
              >
                <div className="flex flex-wrap items-baseline">
                  <h1 className="mb-10">Modifier le groupe</h1>
                </div>
                <div className="">
                  <Input
                    id="name"
                    placeholder="Ecrivez le nom du groupe ..."
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                    className="w-full mb-6"
                  />
                  {/* Invitation section */}
                  <div className="mb-6">
                    {
                      <p>
                        Lien d'invitation :
                        <Link
                          className={copied ? "ml-1 copied-link" : "ml-1"}
                          to={`/register/${setSelectedDoctor._id}`}
                          onClick={(event) => {
                            event.preventDefault();
                            const link = `${window.location.origin}/register/${setSelectedDoctor._id}`;
                            navigator.clipboard.writeText(link);
                            setCopied(true);
                          }}
                        >
                          {`${window.location.origin}/register/${setSelectedDoctor._id}`}
                        </Link>
                      </p>
                    }
                  </div>
                  <div className="mb-8 flex items-center">
                    <input
                      id="freeRegistration"
                      type="checkbox"
                      checked={values.freeRegistration}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldValue("freeRegistration", event.target.checked);
                      }}
                      className="form-checkbox h-5 w-5 text-blue-500"
                    />
                    <p className="ml-2 text-gray-700">Inscription gratuite</p>
                  </div>
                </div>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-left mb-4 btn-delete"
                  onClick={() => setOpenModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-right mb-4"
                >
                  Valider
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddGroup;
