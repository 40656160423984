

import SideBar from "../components/SideBar/SideBarResponsable";
import "./styles-layout-doctor.scss";

const LayoutResponsable = ({ children }) => {
  
  return (
    <div className= "layout-doctor flex" >
    {
      window.location.pathname.slice(1, 14) !== "consultation/" && <SideBar />}

        <div className="w-full pl-4" > { children } </div>
        </div>
  );
};

export default LayoutResponsable;
