import { GET_OLD_MESSAGES } from "../config/api";
import { get } from "../helper/apiMethods";

export const getOldMessages = async (chatroomId) => {
  try {
    const response = await get(GET_OLD_MESSAGES(chatroomId));
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
