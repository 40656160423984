import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Formik } from "formik";
import Input from "../../components/Input";
import Button from "../../components/ButtonUI";
import { userAtom } from "../../state/auth";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import "./styles.scss";
import { addGroup } from "../../api/groups";
import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import { getDoctorsNotAffected } from "../../api/medecin";
import makeToast from "../../components/Snackbar";
import Fuse from "fuse.js";
import _ from "lodash";
import {
  FaUserPlus,
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import Icon from "../../components/Icon";

interface IProps {
  openModal: Boolean;
  setOpenModal: Function;
  message?: String;
  type?: String;
  url?: string;
  setDoctorAdded: boolean | any;
  setSelectedDoctor: Function;
  setDoctorData: Function;
  setOpenModalInfoDoctor: Function;
}

const AddGroup: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  setDoctorAdded,
  setSelectedDoctor,
  setDoctorData,
  setOpenModalInfoDoctor,
}) => {
  const [user, setUser] = useAtom(userAtom);
  const [data, dataSet] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]) as any;
  const [name, setName] = useState("");
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const [strFilter, setStrFilter] = React.useState<string>("");
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = data?.slice(startIndex, endIndex);
  const rowsPerPage = 3;
  const [filteredData, setFilteredData] = useState([]) as any;
  const totalRows = data?.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const requrentRoles = {
    ["medecinRequrent"]: "Médecin Requérant",
    ["sageFemme"]: "Sage-femme",
    ["infirmier"]: "Infirmier(e)",
  };
  const rolesLabels = {
    ["medecinResponsable"]: "Médecin Référent",
    ["medecinTeleexpert"]: "Médecin Expert",
    ["medecinChirurgien"]: "Médecin Chirurgien",
  };

  const fetchMyData = async () => {
    setLoading(true);
    let response = await getDoctorsNotAffected();

    if (response) {
    } else {
      makeToast("error", "Chargement de la listes Medecins à échouher.");
    }
    dataSet(response ? response : []);
    setLoading(false);
  };
  const toggleSelection = (itemId) => {
    if (selectedUsers.includes(itemId)) {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((id) => id !== itemId)
      );
    } else {
      setSelectedUsers((prevSelected) => [...prevSelected, itemId]);
    }
  };

  const isSelected = (itemId) => {
    return selectedUsers.includes(itemId);
  };

  const combineAllKeyValues = (obj, separator = " ") => {
    separator = separator || " ";
    obj.fullName = obj.firstName + separator + obj.lastName;
    obj.InversedfullName = obj.lastName + separator + obj.firstName;
    if (obj.docExpert)
      obj.doctor.fullName =
        obj.doctor.firstName + separator + obj.doctor.lastName;
    return obj;
  };
  const handleListFiltering = () => {
    const usersObjs = data.map((s) => combineAllKeyValues(s));
    const fuse = new Fuse(usersObjs, {
      threshold: 0,

      shouldSort: true,
      keys: ["lastName", "firstName", "fullName", "InversedfullName", "email"],
    });
    const newList = fuse.search(strFilter).map((item) => {
      return item.item;
    });
    setFilteredData(newList);
  };

  React.useEffect(() => {
    if (strFilter !== "") {
      handleListFiltering();
    }
  }, [strFilter, data]);

  const handleSubmit = async (event) => {
    try {
      if (name.trim() === "") {
        makeToast("error", "Veuillez ajouter un nom du groupe");
        return;
      }
      const response = await addGroup({
        name: name,
        selectedUsers,
      });

      if (response) {
        setDoctorAdded(true);
        setOpenModal(!openModal);
        setSelectedDoctor(response.data.id);
        setDoctorData(response.doctor);
        setUser({ ...user, ...response.doctor });
      }
    } catch (err) {
      console.log("[error] add Group -on Submit :", err);
    }
  };

  useEffect(() => {
    fetchMyData();
  }, []);

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd w-1/2">
        <div className="absolute right-0 mt-2 mr-2">
          <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
            <Close />
          </Button>
        </div>
        <div className="flex flex-wrap items-baseline px-8 mt-4">
          <h1 className="">Ajouter un groupe</h1>
        </div>
        <div className="px-8 mt-6">
          <Input
            id="name"
            placeholder="Ecrivez le nom du groupe ..."
            value={name}
            onChange={(event) => setName(event.target.value)}
            inlineLabel={false}
            onfocus={true}
            className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white  outline-none focus:border-blue-500  border-blue-100 h-12 w-full pl-10"
          />
        </div>
        <div className="relative flex w-full flex-wrap px-8 mb-3 mt-2 ">
          <div className="w-full">
            <span className="z-10 h-full leading-snug font-normal  text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
              <IconSearch />
            </span>
            <Input
              type="text"
              placeholder="Rechercher par : nom, prénom ou email "
              onChange={(e) => {
                setStrFilter(e.target.value);
              }}
              onKeyPress={(event) => {}}
              className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white   outline-none focus:border-blue-500  border-blue-100 h-12 w-full pl-10"
            />
          </div>
          <div className="w-full">
            <table
              className="items-center mt-8 w-full"
              style={{
                backgroundColor: "#f5f5f9",
                borderRadius: "20px 20px 20px 20px",
              }}
            >
              <thead>
                <tr>
                  <th className="px-10 text-black align-middle border-b border-solid  py-3 text-base whitespace-nowrap  font-semibold text-center">
                    Nom
                  </th>
                  <th className="px-10 text-black align-middle border-b border-solid py-3 text-base whitespace-nowrap  font-semibold text-center">
                    Email
                  </th>
                  <th className="px-10 text-black align-middle border-b border-solid  py-3 text-base whitespace-nowrap  font-semibold text-center">
                    Rôle
                  </th>
                  <th className="px-10 text-black align-middle border-b border-solid  py-3 text-base whitespace-nowrap  font-semibold text-center">
                    Action
                  </th>
                </tr>
              </thead>
              {loading ? (
                <tbody>
                  <tr>
                    <td></td>
                    <td colSpan={3} className="flexCenter w-full h-12">
                      <p>Chargement ...</p>
                    </td>
                  </tr>
                </tbody>
              ) : !_.isEmpty(data) ? (
                <tbody>
                  {(strFilter !== "" ? filteredData : itemsToDisplay)?.map(
                    (item) => (
                      <tr key={item.id}>
                        <td className="border-b  align-middle font-light text-sm whitespace-nowrap px-10 py-4 text-center  ">
                          {" "}
                          {item.firstName} {item.lastName}
                        </td>
                        <td className="border-b  align-middle font-light text-sm whitespace-nowrap px-10 py-4 text-center  ">
                          {" "}
                          {item.email}{" "}
                        </td>
                        <td className="border-b  align-middle font-light text-sm whitespace-nowrap px-10 py-4 text-center">
                          {" "}
                          {item.type === "medecin"
                            ? (rolesLabels["medecin"] =
                                item.requrentType &&
                                requrentRoles[item.requrentType]
                                  ? requrentRoles[item.requrentType]
                                  : "Médecin Requérant")
                            : rolesLabels[item.type]}
                        </td>
                        <td className="border-b  align-middle font-light text-sm whitespace-nowrap px-10 py-4 text-center  ">
                          {isSelected(item._id) ? (
                            <FaCheck
                              className="text-green-500 cursor-pointer mx-auto"
                              onClick={() => toggleSelection(item._id)}
                              size={18}
                            />
                          ) : (
                            <FaUserPlus
                              className="text-gray-500 cursor-pointer mx-auto"
                              onClick={() => toggleSelection(item._id)}
                              size={18}
                            />
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td></td>
                    <td
                      colSpan={3}
                      className="flexCenter card-subtitle border-4 w-full h-12"
                    >
                      <p>Vous n'avez aucun utilisateur</p>
                    </td>
                  </tr>
                </tbody>
              )}
              <tfoot>
                <tr>
                  <td colSpan={4}>
                    <div className="flex justify-end gap-4 py-2 px-3">
                      <button
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        className="bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-l"
                      >
                        <FaChevronLeft />
                      </button>
                      <div>
                        <p className="text-xs w-32 flexCenter py-4  text-gray-700 px-4">
                          Page N°{" "}
                          <span className="font-medium">{currentPage}</span>/
                          <span className="font-medium">{totalPages}</span>
                        </p>
                      </div>
                      <button
                        disabled={currentPage === totalPages}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        className="bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-r"
                      >
                        <FaChevronRight />
                      </button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="relative flex w-full flex-wrap px-8 justify-between">
          <Button
            status="secondary"
            width="100px"
            height="50px"
            className="float-left mb-4 btn-delete"
            onClick={() => setOpenModal(false)}
          >
            Annuler
          </Button>
          <Button
            status="secondary"
            width="100px"
            onClick={handleSubmit}
            height="50px"
            className="float-right mb-4"
          >
            Valider
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddGroup;
