import React, { useState } from "react";
import { useAtom } from "jotai";
import ReactHtmlParser from "react-html-parser";
import Modal from "../../../components/ModalUI";
import HeaderModal from "./modal/HeaderModal";
import ChatModal from "./modal/ChatModal";
import ShareFolderModal from "./modal/ShareFolderModal";
import SharedFolderFromChat from "./modal/PreviewSharedFolder";
import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patienta.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patienta.svg";

import { userAtom } from "../../../state/auth";
import "./styles.scss";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { specialistDetails: any; i: number };

const CardSpecialist: React.FC<Props> = ({ specialistDetails, i }) => {
  const [user] = useAtom(userAtom);
  const [openModal, setopenModal] = useState(false);
  const [chatOrShareFolder, setChatOrShareFolder] = useState("chat");
  const [sharedFolderDetails, setSharedFolderDetails] = useState();
  const [previewReportModal, setPreviewReportModal] = useState({
    status: false,
    rapport: "",
  });

  const handleGenerateNewShareFolder = async (values) => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  const handlePreviewSharedFolder = async (values) => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  const ToggleContent = () => {
    chatOrShareFolder === "chat"
      ? setChatOrShareFolder("shareFolder")
      : setChatOrShareFolder("chat");
  };

  return (
    <>
      {/* Show modal */}
      <Modal
        showModal={openModal}
        slidemodal={true}
        closeModal={() => setopenModal(false)}
      >
        <>
          <HeaderModal
            specialistDetails={specialistDetails}
            chatOrShareFolder={chatOrShareFolder}
            ToggleContent={ToggleContent}
            closeModal={() => setopenModal(false)}
            user={user}
          />
          {chatOrShareFolder === "chat" ? (
            <ChatModal
              specialistDetails={specialistDetails}
              socket={undefined}
              handlePreviewSharedFolder={handlePreviewSharedFolder}
            />
          ) : chatOrShareFolder === "shareFolder" ? (
            <ShareFolderModal
              generateNewShareFolder={handleGenerateNewShareFolder}
              setPreviewReportModal={setPreviewReportModal}
              getPatientById={() => console.log("")}
              specialistDetails={specialistDetails}
              socket={undefined}
              user={user}
            />
          ) : (
            <SharedFolderFromChat
              sharedFolderDetails={sharedFolderDetails}
              user={user}
            />
          )}
        </>
      </Modal>
      <Modal
        showModal={previewReportModal?.status}
        closeModal={() =>
          setPreviewReportModal({
            status: false,
            rapport: "",
          })
        }
      >
        <div className="p-5">
          <p>{ReactHtmlParser(ReactHtmlParser(previewReportModal?.rapport))}</p>
        </div>
      </Modal>
      <div className="card-specialist  p-3 space-x-3  shadow-md">
        <div className="flex">
          {specialistDetails?.business_photo && (
            <div className="avatar-doctor ">
              <img
                src={specialistDetails?.business_photo}
                alt="doctor avatar"
                className="rounded-lg w-full h-full"
              />
            </div>
          )}

          <div
            className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
            style={{
              backgroundColor:
                colorAvatar[Math.floor(Math.random() * colorAvatar.length)],
            }}
          >
            {specialistDetails.gender === "male" ? (
              <ManPatient />
            ) : (
              <WomanPatient />
            )}
          </div>
          <div className="details">
            <div className="flex-grow">
              <p className="card-title">
                {specialistDetails?.firstName} {specialistDetails?.lastName}
              </p>
              <p className="card-subtitle ">
                {specialistDetails?.demandes?.length > 1
                  ? specialistDetails?.demandes?.length +
                    " Demandes de téléexpertise"
                  : specialistDetails?.demandes?.length +
                    " Demande de téléexpertise"}
                {}
              </p>
            </div>
          </div>
        </div>

        <div className="flex space-x-3"></div>
      </div>
    </>
  );
};

export default CardSpecialist;
