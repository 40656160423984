import React, { useState } from "react";

import moment from "moment";

interface Props {
  doctorData: any;
}

const Historiques: React.FC<Props> = ({ doctorData }) => {
  const [showDetails] = useState(false);
  const [idSelected] = useState("");

  return (
    <div className="historiques mt-10">
      {doctorData.authLogs && doctorData?.authLogs?.length !== 0 ? (
        doctorData?.authLogs?.map((c) => {
          return (
            <>
              <div
                className="bg-white col-start-4 col-end-12 p-4 rounded-xl my-4 mr-auto shadow-md w-full"
                key={c._id}
              >
                <h3>Action : {c.action}</h3>
                <div className="flex w-full justify-between	">
                  <h3 className="font-semibold text-black text-base mb-1 capitalize"></h3>
                  <p className="leading-tight text-justify ">
                    {moment(c.createdAt).format("DD/MM/YYYY à HH:mm")}
                  </p>
                </div>
                {showDetails && idSelected === c._id && (
                  <div
                    className="ml-8"
                    id={c._id}
                    style={{ display: showDetails ? "" : "none" }}
                  >
                    {c?.rapport && (
                      <p className="leading-tight text-justify w-full">
                        Rapport : {c.rapport}
                      </p>
                    )}
                    {c?.motif && (
                      <p className="leading-tight text-justify w-full">
                        Motifs : {c.motif}
                      </p>
                    )}
                    {c?.consultation?.prescriptions &&
                    c?.consultation?.prescriptions?.length !== 0 ? (
                      <>
                        {" "}
                        <p className="leading-tight text-justify w-full">
                          Precriptions :
                        </p>
                        <div className="ml-8">
                          {c?.consultation?.prescriptions.map((p: any) => {
                            return (
                              <div className="mb-4">
                                <p className="leading-tight text-justify w-full">
                                  Medicament : {p.medicament}
                                </p>
                                <p className="leading-tight text-justify w-full">
                                  Dosage : {p.dosage}
                                </p>
                                {p.duree && (
                                  <p className="leading-tight text-justify w-full">
                                    Durée : {p.duree}
                                  </p>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : null}
                    {c?.consultation?.biologiques?.length !== 0 && (
                      <>
                        <p className="leading-tight text-justify w-full">
                          Biologiques documents :
                        </p>
                        <div className="grid grid-flow-col auto-cols-max">
                          {c?.consultation?.biologiques.map((p) => {
                            return (
                              <div className="mb-4 mr-4">
                                <img
                                  src={
                                    process.env.REACT_APP_KATOMI_API_URL +
                                    p.bioDoc
                                  }
                                  alt="..."
                                  className="shadow-lg w-auto max-h-16 mt-2 align-middle border-none"
                                  onClick={() => {}}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                    {c?.consultation?.radiologiques?.length !== 0 && (
                      <>
                        <p className="leading-tight text-justify w-full">
                          Radiologiques documents :
                        </p>
                        <div className="grid grid-flow-col auto-cols-max">
                          {c.consultation.radiologiques.map((p) => {
                            return (
                              <div className="mb-4 mr-4">
                                <img
                                  src={
                                    process.env.REACT_APP_KATOMI_API_URL +
                                    p.radioDoc
                                  }
                                  alt="..."
                                  className="shadow-lg w-auto max-h-16 mt-2 align-middle border-none"
                                  onClick={() => {}}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                    {c.consultation.autres.length !== 0 && (
                      <>
                        <p className="leading-tight text-justify w-full">
                          Autres documents :
                        </p>
                        <div className="grid grid-flow-col auto-cols-max">
                          {c.consultation.autres.map((p) => {
                            return (
                              <div className="mb-4 mr-4">
                                <img
                                  src={
                                    process.env.REACT_APP_KATOMI_API_URL +
                                    p.autreDoc
                                  }
                                  alt="..."
                                  className="shadow-lg w-auto max-h-16 mt-2 align-middle border-none"
                                  onClick={() => {}}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          );
        })
      ) : (
        <div className="flexCenter w-full anEmptyBox">
          <p className="card-subtitle">Ce médecin n'a aucun historique.</p>
        </div>
      )}
    </div>
  );
};

export default Historiques;
