import SideBar from "../components/SideBar/SideBarMedecin";
import "./styles-layout-doctor.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const LayoutMedecin = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const location = useLocation();
  
  useEffect(() => {
    if (
      location.pathname.slice(0, 13) === "/consultation" ||
      location.pathname.slice(0, 17) === "/teleconsultation"
    )
      setShowSideBar(false);
    else setShowSideBar(true);
  }, [location.pathname]);

  return (
    <div className="layout-doctor flex">
      {showSideBar && <SideBar />}
      <div className="w-full pl-4">{children}</div>
    </div>
  );
};

export default LayoutMedecin;
