import * as Yup from "yup";
const digitsOnly = (value) => /^\d+$/.test(value);

export default Yup.object().shape({
  email: Yup.string()
    .email("Veuillez saisir une adresse mail valide 😯 ")
    .required("Champ obligatoire 🚧"),
  lastName: Yup.string().required("Champ obligatoire 🚧 "),
  firstName: Yup.string().required("Champ obligatoire 🚧 "),
  speciality: Yup.string().required("Champ obligatoire 🚧 "),
  country: Yup.string().required("Champ obligatoire 🚧 "),
  gender: Yup.string().required("Champ bligatoire"),
  phone: Yup.string()
    .required(" Champ obligatoire 🚧 ")
    .min(8, "Minimum 8 caractères ")
    .max(12, "Minimum 12 caractères "),
  adresse: Yup.string().required(" Champ obligatoire 🚧 "),
  numRPPS: Yup.string()
    .test(
      "Digits only",
      "Le N° RPPS ne doit comporter que des chiffres",
      digitsOnly
    )
    .required(" ")
    .min(11, "Le N° RPPS doit comporter exactement 11 chiffres")
    .max(11, "Le N°  RPPS doit comporter exactement  11 chiffres"),
  numCom: Yup.string()
    .required(" ")
    .min(9, "Le N° ADELI doit comporter exactement 9 caractères")
    .max(9, "Le N° ADELI doit comporter exactement 9 caractères"),
  codePostale: Yup.string().required("Champ obligatoire 🚧 "),
  type: Yup.string().required("Champ obligatoire 🚧 "),
  birthday: Yup.string().required("Champ obligatoire 🚧 "),
  password: Yup.string()

    .required("Votre mot de passe est obligatoire.")
    .min(8, "Minimum 8 caractères ")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Votre mot de passe doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial."
    ),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Mot de passe doit étre identique!"
  ),
});
