import React, { useState, useEffect } from "react";
// import { MDBBtn } from "mdbreact";
import { ReactComponent as Hopital } from "../../../assets/illustrations/illustration-hospital.svg";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";

import "./styles.scss";

import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import { User } from "../../../types/user";
import Tooltip from "../../../components/toolTip";
import Button from "../../../components/ButtonUI";
import ConfirmModal from "./ConfirmModalDelete";
import { deleteGroup } from "../../../api/groups";
import makeToast from "../../../components/Snackbar";
import AddGroup from "./addGroup";
import DoctorInfo from "../../../components/SharedDoctorDetails";
import { retriveDoctor } from "../../../api/medecin";
import { FaPencilAlt } from "react-icons/fa";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

interface IDoctorDetails {
  updatedAt: Date;
  createdAt: Date;
  firstName: string;
  lastName: string;
  _id: string;
  date: Date;
  type: string;
  gender: string;
  activated: Boolean;
}

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  doctorDetails: any;
  openModal: boolean;
  setOpenModal: Function;
  setSelectedDoctor: Function;
  selectedDoctor: string;
  setRefreshGroup: Function;
  setDoctorData: Function;

  setColorIndex: Function;
  i: number;
};

const CardGroup: React.FC<Props> = ({
  doctorDetails,
  openModal,
  setOpenModal,
  setSelectedDoctor,
  selectedDoctor,
  setColorIndex,
  setRefreshGroup,
  setDoctorData,
  i,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [user, setUser] = useAtom<User | any>(userAtom);
  const [isExpanded, setIsExpanded] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modal, setModal] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [userData, setUserData] = useState<User>();
  const [selectedUserData, setSelectedUserData] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [editModeSharedDoctor, setEditModeSharedDoctor] = useState(false);

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      let response: any = await retriveDoctor(selectedUserData);
      const doctor = response;
      if (!response) {
        makeToast("error", "Chargement de médecin  à échouher.");
      }
      setDoctorData({ ...doctor });
    }
    if (selectedUserData) {
      fetchMyAPI();
      setModal(true);
    }
  }, [selectedUserData]);

  function handleExpand() {
    setIsExpanded(!isExpanded);
  }
  const handleDelete = async (id) => {
    try {
      const response = await deleteGroup(id);
      makeToast("success", "suppression du groupe effectuée avec succès.");
      setRefreshGroup((x) => !x);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className={`card-patient justify-between relative bg-white ${
          selectedDoctor === doctorDetails?._id
        }`}
        style={{ cursor: "pointer" }}
        // onClick={(e) => {
        //   setSelectedDoctor(doctorDetails?._id);
        //   setModalAdd(true);
        // }}
      >
        <div className="cursor-pointer flex flex-col items-start">
          <div className="flex patient-info ">
            <div
              className="rounded-full h-14 w-14 flexCenter mx-5 my-auto"
              style={{
                backgroundColor: colorAvatar[i % colorAvatar.length],
                cursor: "pointer",
              }}
            >
              <Hopital />
            </div>
            <div className="details" style={{ cursor: "pointer" }}>
              <div className="flex-grow ">
                <p
                  className="card-title capitalize"
                  style={{ cursor: "pointer" }}
                >
                  {doctorDetails.name}
                </p>
              </div>
              <div className="flex">
                <p className="card-subtitle">
                  {doctorDetails.doctorId.length === 0
                    ? "0 utilisateur appartient à ce groupe"
                    : `(${doctorDetails.doctorId.length}) utilisateurs`}
                </p>
                <svg
                  className={`w-5 h-5 ml-4 text-gray-600 ${
                    !(doctorDetails.doctorId.length > 0) && "hidden"
                  }`}
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleExpand();
                  }}
                >
                  {isExpanded ? (
                    <path d="M19 9l-7 7-7-7"></path>
                  ) : (
                    <path d="M9 5l7 7-7 7"></path>
                  )}
                </svg>
              </div>
            </div>
          </div>
          {isExpanded && (
            <ul
              className="py-2 flex flex-row flex-wrap text-xs justify-start gap-0.5 ml-4 "
              style={{ maxWidth: "23vw" }}
            >
              {doctorDetails?.doctorId?.map((person) => (
                <Tooltip tooltipText={person.email} position={"top"}>
                  {" "}
                  <li
                    key={person.id}
                    className="text-gray-700 box truncate w-36"
                    onClick={() => {
                      setSelectedUserData(person._id);
                      setUserInfo(person);
                      setEditModeSharedDoctor(true);
                      //setModal(true);
                    }}
                  >
                    {person.email}
                  </li>
                </Tooltip>
              ))}
            </ul>
          )}
        </div>
        <div className="absolute right-2 top-1">
          <Button
            status="icon-secondary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setDeleteConfirmModal(true);
            }}
            style={{ height: "40px", width: "40px", padding: 0 }}
          >
            <Close />
          </Button>
        </div>
        <div className="absolute right-2 top-16 mr-2">
          <FaPencilAlt
            onClick={(e) => {
              setSelectedDoctor(doctorDetails?._id);
              setModalAdd(true);
            }}
          />
        </div>
      </div>
      {deleteConfirmModal && (
        <ConfirmModal
          action={() => {
            handleDelete(doctorDetails?._id);
          }}
          setOpenModal={setDeleteConfirmModal}
        />
      )}
      {modalAdd && (
        <AddGroup
          openModal={modalAdd}
          setOpenModal={setModalAdd}
          //setDoctorAdded={setDoctorAdded}
          setRefreshGroup={setRefreshGroup}
          setSelectedDoctor={doctorDetails}
          setDoctorData={setDoctorData}
          //setOpenModalInfoDoctor={setModal}
        />
      )}
      {modal && (
        <DoctorInfo
          openModal={modal}
          setOpenModal={() => {
            setModal(false);
            setSelectedDoctor("");
            setDoctorData({} as any);
          }}
          setUpdateList={setUpdateList}
          doctorData={userInfo}
          setRefreshGroup={setRefreshGroup}
          setEditModeSharedDoctor={setEditModeSharedDoctor}
          editModeSharedDoctor={editModeSharedDoctor}

          //colorIndex={colorIndex}
        />
      )}
    </>
  );
};

export default CardGroup;
