import React, { useState, useEffect } from "react";
import Spinner from "../Spinner";
import classNames from "classnames";
import moment from "../../helper/moment";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/na.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/na.svg";
import { ReactComponent as WomanPatient1 } from "../../assets/illustrations/illustration-femme-patienta.svg";
import { ReactComponent as ManPatient1 } from "../../assets/illustrations/illustration-homme-patienta.svg";
import { ReactComponent as WomanPatient2 } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient2 } from "../../assets/illustrations/illustration-homme-patient.svg";
import { ReactComponent as SpecIcon } from "../../assets/icons/icon-union.svg";
import { ReactComponent as Reponse } from "../../assets/icons/icons8-nouvelles.svg";
import { ReactComponent as Ruban } from "../../assets/icons/icons8-ruban-marque-page.svg";
import { ReactComponent as Urgence } from "../../assets/icons/icon-danger.svg";
import { ReactComponent as PriseCharge } from "../../assets/illustrations/priseEnCharge.svg";
import time from "../../assets/images/time.png";
import statusProgress from "../../assets/images/work-process.png";
import statusWaiting from "../../assets/images/exclamation-mark.png";
import statusDone from "../../assets/images/checked.png";
import Button from "../ButtonUI";
import ImagesContainer from "./imagesContainer";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import { User } from "../../types/user";
import Modal from "../ModalUI";
import ImageModal from "../ImageModal";
import {
  assignDemande,
  assignDemandeForce,
  assignDemandeToChirurgien,
  getAllChirurgiens,
  getAllExperts,
  retriveDemande,
  retrivePatient,
  seenByChirurgien,
} from "../../api/medecin";
import telex from "../../assets/images/dermatologista.png";
import Select from "../Select";
import { useFormik } from "formik";
import * as Yup from "yup";
import makeToast from "../Snackbar";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { refreshAtomRequest } from "../../state/refreshRequestList";
import UploadDemandeImages from "../DynamicForms/UploadDemandeImages";
import { statusLabel } from "../../helper/demandeStatus";
import PdfDocument from "../Pdf/PdfDocument";
import TestDocument from "../Pdf/TestDocument";
import { IPatient } from "../../types/patient";
import PriseEnCharge from "./PriseEnChargeModal";
import io from "socket.io-client";
import { socketAtom } from "../../state/socket";
import Chat from "../SharedChat";

import { ReactComponent as IconMessage } from "../../assets/icons/icon-message.svg";

interface IProps {
  demandeData?: any;
  setOpenModal: Function;
  colorIndex: number;
  consultaionPage: boolean;
  //setSwitchToChatModal: any;
}

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];
const requrentRoles = {
  ["medecinRequrent"]: "Médecin Requérant",
  ["sageFemme"]: "Sage-femme",
  ["infirmier"]: "Infirmier(e)",
};
const rolesLabels = {
  medecinResponsable: "Médecin Référent",
  medecinTeleexpert: "Médecin Expert",
  medecinChirurgien: "Médecin Chirurgien",
};

const KatomiEndPoint =
  process.env.REACT_APP_KATOMI_API_URL || "http://localhost:3009";

const SharedRequest: React.FC<IProps> = ({
  demandeData,
  setOpenModal,
  colorIndex,
  consultaionPage,
  //setSwitchToChatModal,
}) => {
  const tabsR = [
    {
      id: 1,
      name: "Demande ",
    },
    {
      id: 2,
      name: " Résultat de l'interrogatoire ",
    },
  ];
  const tabsCh = [
    {
      id: 1,
      name: "Demande ",
    },
    {
      id: 2,
      name: " Résultat de l'interrogatoire ",
    },
    {
      id: 3,
      name: "Informations patient",
    },
  ];

  const [display, setDisplay] = useState(true);
  const [documentsList, setDocumentsList] = useState([]);
  const [user] = useAtom<User | any>(userAtom);
  const [refresh, setRefresh] = useAtom(refreshAtomRequest);
  const [openTab, setOpenTab] = useState(1);
  const [tabs, setTabs] = useState(
    user?.type === "medecinChirurgien" ? tabsCh : tabsR
  );
  const [addCategorieDocMenu, setAddCategorieDocMenu] = useState(false);
  const [demandeInfo, setDemandeInfo] = useState<any>();
  const [patientData, setPatientData] = useState<any>();
  const history = useHistory();
  const [teleexpertList, setTeleexpertList] = useState<any[]>([]);
  const [chirurgienList, setChirurgienList] = useState<any[]>([]);
  const [modalImage, setModalImage] = useState(false);
  const [modalPriseEncharge, setModalPriseEncharge] = useState(false);
  const [modalAssginChg, setModalAssginChg] = useState(false);
  const [modalUserLimite, setModalUserLimite] = useState(false);
  const [expertLimit, setExpertLimit] = useState("");
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [chatModal, setChatModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const setupSocket = () => {
    const newSocket = io(KatomiEndPoint, {
      query: { userId: user?._id },
    });
    newSocket.on("disconnect", () => {
      setSocket(null);
    });
    newSocket.on("connection", () => {
      makeToast("success", "Connected!");
    });

    setSocket(newSocket);
  };

  useEffect(() => {
    if (!socket) {
      setupSocket();
    }
  }, []);
  console.log("socket", socket);
  console.log("idDemande", demandeData._id);

  const validationSchema = Yup.object().shape({
    datePriseEnCharge: Yup.date().required("Champ  obligatoire"),
    lieuPriseEnCharge: Yup.string().required("Champ  obligatoire"),
  });
  function splitString(stringToSplit, separator) {
    var arrayOfStrings = stringToSplit.split(separator);

    return arrayOfStrings;
  }
  const formik = useFormik({
    initialValues: {
      expertId: "",
    },
    onSubmit: async (values) => {
      const response = await assignDemande(values, demandeData._id);

      if (response.status === "Success") {
        setDemandeInfo(response.data.demandeToUpdate);
        setRefresh({ state: !refresh?.state });

        makeToast("success", response.message);
      } else if (response.status === "Limite") {
        makeToast(
          "info",
          "Atteinte de limite de demandes autorisées par l'abonnement."
        );
        setExpertLimit(values.expertId);
        setModalUserLimite(true);
      } else {
        makeToast("error", response.message);
      }
    },
  });

  const formikAssignForce = useFormik({
    initialValues: {
      expertId: expertLimit,
    },
    onSubmit: async (values) => {
      values.expertId = expertLimit;
      const response = await assignDemandeForce(values, demandeData._id);

      if (response.status === "Success") {
        setDemandeInfo(response.data.demandeToUpdate);
        setRefresh({ state: !refresh?.state });

        makeToast("success", response.message);
      } else {
        makeToast("error", response.message);
      }
    },
  });
  const formikAssignChirurgien = useFormik({
    initialValues: {
      chirurgienId: "",
    },
    onSubmit: async (values) => {
      const response = await assignDemandeToChirurgien(values, demandeData._id);

      if (response.status === "Success") {
        setDemandeInfo(response.data.demandeToUpdate);
        setRefresh({ state: !refresh?.state });
        setModalAssginChg(false);

        makeToast("success", response.message);
      } else {
        makeToast("error", response.message);
      }
    },
  });
  const formikAcceptPriseEnCharge = useFormik({
    initialValues: {
      lieuPriseEnCharge: "",
      datePriseEnCharge: "",
    },

    onSubmit: async (values) => {
      const response = await seenByChirurgien(values, demandeData._id);

      if (response.status === "Success") {
        setDemandeInfo(response.data.demandeToUpdate);
        setRefresh({ state: !refresh?.state });

        makeToast("success", response.message);
      } else {
        makeToast("error", response.message);
      }
    },
    validationSchema,
  });
  const [imageUrl, setImageUrl] = useState({
    url: "",
    type: "",
    fileInfo: {},
  });

  const imgStatuts = () => {
    let imgSrc = "";
    if (
      demandeInfo?.status === "IN_PROGRESS" ||
      demandeInfo?.status === "SEEN_BY_EXPERT"
    ) {
      imgSrc = statusProgress;
    } else if (
      demandeInfo?.status === "RESPONSE" ||
      demandeInfo?.status === "SEEN_BY_REQURANT"
    ) {
      imgSrc = statusDone;
    } else {
      imgSrc = statusWaiting;
    }
    return imgSrc;
  };

  async function retriveDemandeMyAPI() {
    setLoading(true);
    const res = await retriveDemande(demandeData?._id);
    setDemandeInfo(res?.data?.demande);
    setDocumentsList(res?.data?.demande?.files);
    setLoading(false);
    return await res;
  }
  useEffect(() => {
    retriveDemandeMyAPI();
  }, []);
  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      if (
        user.type === "medecinResponsable" &&
        (demandeInfo?.status === "NOT_ASSIGNED" ||
          demandeInfo?.status === "DELAI_EXPIRE")
      ) {
        const listTeleExpertDispo = await getAllExperts();
        if (listTeleExpertDispo.length !== 0)
          setTeleexpertList(listTeleExpertDispo);
      } else if (
        user._id === demandeInfo?.docReq._id &&
        demandeInfo?.Reponse !== null &&
        demandeInfo?.status === "RESPONSE" &&
        demandeInfo?.docChirurgien.length == 0
      ) {
        const listChirurgientDispo = await getAllChirurgiens();
        if (listChirurgientDispo.length !== 0)
          setChirurgienList(listChirurgientDispo);
      }
    }

    fetchMyAPI();
  }, [demandeInfo]);

  const dataToMap = () => {
    const data = propertyNames.map((e) => {
      if (e[1] === "undefined") {
        return { label: e[0], value: "Inconnue" };
      }
      if (e[1].indexOf(",") > 0)
        return { label: e[0], value: splitString(e[1], ",") };
      else return { label: e[0], value: e[1] };
    });

    return data;
  };
  const propertyNames: [string, string][] = Object.entries(
    demandeData.interrogatoire && demandeData.interrogatoire.other
      ? demandeData.interrogatoire.other
      : {}
  );

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      let response: IPatient = await retrivePatient(
        demandeData?.patientId?._id,
        user?._id
      );
      if (response) setPatientData(response);
    }
    if (user.type === "medecinChirurgien") {
      fetchMyAPI();
    }
  }, [openTab == 3]);

  return (
    <div
      className={`${
        consultaionPage
          ? "ml-12 mb-12"
          : "fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-scroll bg-opacity-modal flexCenter"
      }`}
    >
      {demandeInfo?._id ? (
        <div
          className={`${
            consultaionPage
              ? `  inset-y-0 right-0 z-40  ${
                  openTab === 2 ? "h-screen" : " h-98 "
                } relative w-full  request-modal-info`
              : "absolute  inset-y-0 right-0 z-40  patient-modal-info"
          }`}
        >
          <div
            className={`sub-container ${
              display ? "overflow-hidden" : "max-h-content"
            }`}
          >
            <div className="flex header justify-between	">
              <div className="infos flex w-full justify-between mr-2 mt-3">
                <div className="flex sub-infos">
                  {" "}
                  <div
                    className="photo"
                    style={{
                      backgroundColor:
                        colorAvatar[(colorIndex || 1) % colorAvatar.length],
                    }}
                  >
                    {demandeInfo?.type === "Dermatose non tumorale" ? (
                      <ManPatient />
                    ) : (
                      <WomanPatient />
                    )}
                  </div>
                  <div className="details">
                    <div className="flex justify-between">
                      <div className="">
                        <p className="name capitalize">{demandeInfo?.type}</p>
                      </div>
                    </div>

                    <div className="flex birth mt-3">
                      <div className="sub-birth flexCenter img">
                        <img src={time} alt="date" />
                      </div>
                      <div className="dateBirth">
                        <p>{moment(demandeInfo?.createdAt).format("L")}</p>
                      </div>
                      <div className="flex mail ">
                        <div className="flexCenter img ml-14">
                          <SpecIcon />
                        </div>
                        <div>{demandeInfo?.specialite}</div>
                      </div>
                    </div>
                    <div className="flex birth">
                      <div className="sub-birth flexCenter">
                        <div className="flexCenter ">
                          <img
                            src={imgStatuts()}
                            alt="statusIcon"
                            width="50px"
                            height="15.5px"
                          />
                        </div>
                        <span className="ml-2 text-sm">
                          {statusLabel(
                            demandeInfo?.status,
                            demandeInfo?.delaiDate,
                            user?.type,
                            demandeInfo?.priseEncharge
                          )}
                        </span>
                      </div>

                      {demandeInfo?.Reponse !== null &&
                        (demandeInfo?.status === "RESPONSE" ||
                          demandeInfo?.status === "SEEN_BY_REQURANT") && (
                          <Button
                            status="secondary"
                            className="bg-white p-2 mx-4"
                            onClick={() => {}}
                          >
                            <PdfDocument
                              title={`${
                                demandeInfo?.patientId?.lastName +
                                "_" +
                                demandeInfo?.patientId?.firstName +
                                "-" +
                                demandeInfo?.patientId?.numeroSecurite
                              }`}
                              document={<TestDocument data={demandeInfo} />}
                            />
                          </Button>
                        )}
                    </div>
                  </div>
                </div>

                <div className="patient-icons flex flex-col justify-between items-center">
                  {(user?.type === "medecin" ||
                    user?.type === "medecinTeleexpert") &&
                    (demandeInfo?.status === "IN_PROGRESS" ||
                      demandeInfo?.status === "SEEN_BY_EXPERT" ||
                      demandeInfo?.status === "RESPONSE") && (
                      <Button
                        status="icon-secondary"
                        className="bg-white"
                        onClick={() => {
                          setChatModal(true);
                        }}
                      >
                        <IconMessage />
                      </Button>
                    )}
                  <div>
                    {!consultaionPage && (
                      <Button
                        status="icon-secondary"
                        onClick={() => setOpenModal(false)}
                        className="absolute top-1 right-1 "
                      >
                        <Close className="absolute top-1 right-1 " />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {user?.type === "medecinResponsable" &&
              demandeInfo?.status === "NOT_ASSIGNED" && (
                <div className="">
                  <div className="flex justify-between items-center pr-2">
                    <div className=" flex inline-flex items-center m-1 p-2 leading-none capitalize  ">
                      <p className="text-lg uppercase  text-gray-500">
                        Tâche(s) en attente
                      </p>
                    </div>
                  </div>
                  <div className="inline-flex items-center text-md text-gray-500 m-1 p-2 leading-none ">
                    Transférer la demande :
                  </div>
                  <div className="inline-flex items-center text-md text-gray-500 m-1 p-2 leading-none ">
                    <form onSubmit={formik.handleSubmit}>
                      <div className=" ">
                        <Select
                          value={formik.values.expertId}
                          onChange={formik.handleChange}
                          id="expertId"
                          text={"Sélectionner  un expert"}
                          className="relative mb-2 mt-2 mx-3"
                          options={teleexpertList?.map((e) => ({
                            value: e._id,
                            label: e.lastName + " " + e.firstName,
                          }))}
                          required={true}
                        ></Select>
                      </div>
                      {formik.values.expertId !== "" && (
                        <div className="relative mt-2  ml-2 mr-2 ">
                          <Button
                            status="primary"
                            className="float-right flexCenter mb-5"
                            width="200px"
                            height="50px"
                            type="submit"
                            disabled={!formik.isValid}
                          >
                            {!formik.isValid ? (
                              <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                            ) : (
                              "Transférer"
                            )}
                          </Button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              )}
            {user?.type === "medecinChirurgien" &&
              demandeInfo?.status === "RESPONSE" &&
              !demandeInfo?.priseEncharge && (
                <div className="">
                  <div className="flex justify-between items-center pr-2">
                    <div className=" flex inline-flex items-center m-1 p-2 leading-none capitalize  ">
                      <p className="text-lg uppercase  text-gray-500">
                        Prise en charge de la demande
                      </p>
                    </div>
                  </div>

                  <div className="relative flexCenter mb-2 ">
                    <Button
                      status="primary"
                      className=""
                      width="200px"
                      height="50px"
                      type="submit"
                      onClick={() => setModalPriseEncharge(true)}
                    >
                      Prendre en charge
                    </Button>
                  </div>
                </div>
              )}

            {user._id === demandeInfo?.docReq._id &&
              demandeInfo?.Reponse !== null &&
              demandeInfo?.status === "RESPONSE" &&
              demandeInfo?.docChirurgien.length == 0 && (
                <div className="">
                  <div className="flex justify-between items-center pr-2">
                    <div className=" flex inline-flex items-center m-1 p-2 leading-none capitalize  ">
                      <p className="text-lg uppercase  text-gray-500">
                        Prise en charge de la demande
                      </p>
                    </div>
                  </div>

                  <div className="relative flexCenter mb-2 ">
                    <Button
                      status="primary"
                      className=""
                      width="200px"
                      height="50px"
                      type="submit"
                      onClick={() => setModalAssginChg(true)}
                    >
                      Prise en charge
                    </Button>
                  </div>
                </div>
              )}
            {demandeInfo?.docExpert !== null &&
              demandeInfo?.docExpert._id &&
              user._id === demandeInfo?.docExpert?._id &&
              (demandeInfo?.status === "IN_PROGRESS" ||
                demandeInfo?.status === "SEEN_BY_EXPERT") &&
              !consultaionPage && (
                <div className="flex justify-between items-center pr-2">
                  <div className="relative mt-2  ml-2 mr-2 ">
                    <Button
                      status="primary"
                      className="float-right flexCenter mb-5"
                      width="200px"
                      height="50px"
                      type="submit"
                      onClick={() => {
                        history.push(`/consultation/${demandeInfo._id}`);
                      }}
                    >
                      Répondre
                    </Button>
                  </div>
                </div>
              )}

            <div className={`patient-tabs ${!display ? "stretch" : null}`}>
              <div className="tabs flex items-center justify-between	">
                {tabs.map((tab) => {
                  return (
                    <div
                      className={
                        openTab === tab.id
                          ? "tab selected flexCenter"
                          : "tab flexCenter cursor-pointer"
                      }
                      onClick={() => {
                        setOpenTab(tab.id);
                        if (tab.id === 0 || tab.id === 3) {
                          setDisplay(true);
                        } else if (tab.id === 1 || tab.id === 2) {
                          setDisplay(true);
                        }
                      }}
                    >
                      {tab.name}
                    </div>
                  );
                })}
              </div>
              <div></div>
            </div>

            <div className=" tabs-content mt-5">
              {" "}
              <div className="tabs-container">
                <div className="tab-content tab-space tabs-liste">
                  <div
                    className={classNames(
                      "item",
                      openTab === 1 ? "block" : "hidden"
                    )}
                    id=""
                  >
                    <div className="sub-documents">
                      {demandeInfo?.priseEncharge && (
                        <>
                          <div className="flex justify-between title-doc my-2">
                            <div className="sub-titleReq "></div>
                          </div>
                          <div className="bg-white rounded-lg p-2 w-full bg-white mb-4">
                            <h3 className="text-md flex items-center ">
                              <div className="rounded-full h-12 w-12 flexCenter  my-auto">
                                <PriseCharge />
                              </div>
                              <span className="mx-2">
                                Prise en charge de la demande
                              </span>
                            </h3>
                            <p className=" mx-12 w-98 text-justify text-sm ">
                              <p>
                                {" "}
                                Prise en charge de la demande pour le{" "}
                                {moment(demandeInfo?.datePriseEnCharge).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                              </p>

                              <p className="text-xs ">
                                Dr Chirurgien :{" "}
                                {demandeInfo?.docChirurgien[0]?.lastName +
                                  " " +
                                  demandeInfo?.docChirurgien[0]?.firstName}
                              </p>
                              <p className="text-xs ">
                                Lieu : {demandeInfo?.lieuPriseEnCharge}
                              </p>
                            </p>
                          </div>
                        </>
                      )}

                      <ImagesContainer
                        helperCategorie={() => console.log()}
                        setAddCategorieDocMenu={() => <div></div>}
                        addCategorieDocMenu={addCategorieDocMenu}
                        documentsList={demandeInfo?.files}
                        patientData={demandeInfo}
                        setImageUrl={setImageUrl}
                        retriveDemandeMyAPI={() => retriveDemandeMyAPI()}
                        setModalImage={setModalImage}
                        setDocumentsList={setDocumentsList}
                      />

                      {user.type === "medecin" &&
                        (demandeInfo?.status === "WAIT_FOR_IMGS" ||
                          demandeInfo?.files.length == 0) &&
                        (demandeInfo?.docReq._id === user?._id ||
                          demandeInfo?.docReq === user?._id) && (
                          <UploadDemandeImages
                            setDemandeInfo={setDemandeInfo}
                            demandeId={demandeInfo._id}
                          />
                        )}
                      {user.type === "medecinResponsable" &&
                        (demandeInfo?.status === "WAIT_FOR_IMGS" ||
                          demandeInfo?.files.length == 0) &&
                        demandeInfo?.docReq._id === user?._id && (
                          <UploadDemandeImages
                            setDemandeInfo={setDemandeInfo}
                            demandeId={demandeInfo._id}
                          />
                        )}

                      {demandeInfo?.Reponse !== null &&
                        (demandeInfo?.status === "RESPONSE" ||
                          demandeInfo?.status === "SEEN_BY_REQURANT") && (
                          <>
                            <div className="flex justify-between title-doc mt-2">
                              <div className="sub-title">
                                <p>Avis de l'Expert</p>
                              </div>
                            </div>{" "}
                            <div className=" bg-white p-2 shadow-lg rounded-2xl m-2 ">
                              {demandeInfo?.Reponse.urgence && (
                                <div className="bg-white rounded-lg p-2 w-full bg-white mb-4">
                                  <h3 className="text-md flex items-center ">
                                    <div className="rounded-full h-12 w-12 flexCenter  my-auto">
                                      <Urgence />
                                    </div>
                                    <span className="mx-2">Urgence</span>
                                  </h3>
                                  <p className=" mt-1 py-1 mx-12 w-98 text-justify text-sm ">
                                    {" "}
                                    {demandeInfo?.type ===
                                    "Dermatose non tumorale"
                                      ? "Une biopsie est nécessaire."
                                      : "La prise en charge est urgente."}
                                  </p>
                                </div>
                              )}
                              {demandeInfo?.Reponse?.recommendation &&
                              demandeInfo?.Reponse?.recommendation !== "" ? (
                                <div className="bg-white  rounded-lg p-2 w-full bg-white mb-4 ">
                                  {" "}
                                  <h3 className="text-md flex items-center ">
                                    <div className="rounded-full  h-12 w-12 flexCenter  my-auto">
                                      <Ruban />
                                    </div>
                                    <span className="mx-2">Recommandation</span>
                                  </h3>
                                  <p className=" mt-1 py-1 mx-12 w-98 text-justify text-sm">
                                    {" "}
                                    {demandeInfo?.Reponse?.recommendation
                                      ? demandeInfo?.Reponse?.recommendation.c +
                                        " " +
                                        (demandeInfo?.Reponse?.recommendation
                                          .nbrJrs.length > 0
                                          ? "dans un délai de " +
                                            demandeInfo?.Reponse?.recommendation
                                              .nbrJrs +
                                            " jours."
                                          : "")
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <div className="bg-white  rounded-lg p-2 w-full bg-white mb-4">
                                  <h3 className="text-md flex items-center ">
                                    <div className="rounded-full  h-12 w-12 flexCenter  my-auto">
                                      <Ruban />
                                    </div>
                                    <span className="mx-2">Recommandation</span>
                                  </h3>
                                  <p className=" mt-1 py-1 mx-12 w-98 text-justify text-gray-400 text-sm">
                                    Le médecin expert n'a prescrit aucune
                                    recommandation .{" "}
                                  </p>
                                </div>
                              )}
                              <div className="bg-white rounded-lg   p-2 w-full mt-2 mb-4 ">
                                <h3 className="text-md flex items-center ">
                                  <div className="rounded-full  h-12 w-12 flexCenter  my-auto">
                                    <Reponse />
                                  </div>

                                  <span className="mx-2">Réponse</span>
                                </h3>

                                <p className="  mt-1 py-1 mx-12 w-98 text-justify  text-sm">
                                  {demandeInfo?.Reponse?.response
                                    ? ReactHtmlParser(
                                        demandeInfo?.Reponse?.response
                                      )
                                    : ReactHtmlParser(demandeInfo?.Reponse)}
                                </p>
                              </div>
                            </div>
                          </>
                        )}

                      <>
                        {user.type !== "medecin" && (
                          <div className="flex justify-between title-doc mt-2">
                            <div className="sub-title">
                              <p>Informations médecin</p>
                            </div>
                          </div>
                        )}

                        {user.type !== "medecin" && (
                          <div className="grid grid-flow-row card-specialist mt-2 p-2 shadow-md  auto-rows-max">
                            <div
                              className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
                              style={{
                                backgroundColor:
                                  colorAvatar[
                                    Math.floor(
                                      Math.random() * colorAvatar.length
                                    )
                                  ],
                              }}
                            >
                              {demandeInfo?.docReq?.gender === "male" ? (
                                <ManPatient2 />
                              ) : (
                                <WomanPatient2 />
                              )}
                            </div>

                            <div className="flex flex-col">
                              <div className="card-title">
                                {demandeInfo?.docReq?.lastName +
                                  " " +
                                  demandeInfo?.docReq?.firstName}
                              </div>
                              <p className="card-subtitle ">
                                {"🩺 "}
                                {demandeInfo?.docReq?.expertises?.length > 0
                                  ? demandeInfo?.docReq?.expertises[0]
                                      ?.expertise
                                  : (rolesLabels["medecin"] =
                                      demandeInfo?.docReq?.requrentType &&
                                      requrentRoles[
                                        demandeInfo?.docReq?.requrentType
                                      ]
                                        ? requrentRoles[
                                            demandeInfo?.docReq?.requrentType
                                          ]
                                        : "Médecin Requérant")}
                              </p>
                              <p className="card-subtitle ">
                                {"📱 "}
                                {demandeInfo?.docReq?.phone}
                              </p>
                              <p className=" card-subtitle  ">
                                {"📫 "}
                                {demandeInfo?.docReq?.email}
                              </p>
                            </div>
                          </div>
                        )}
                        {user.type !== "medecinChirurgien" ? (
                          <>
                            <div className="flex justify-between title-doc mt-2">
                              <div className="sub-title">
                                <p>
                                  Informations{" "}
                                  {user.type === "medecin"
                                    ? "patient"
                                    : "patient"}
                                </p>
                              </div>
                            </div>
                            <div className="grid grid-flow-row card-specialist mt-2 p-2 shadow-md  auto-rows-max">
                              <div
                                className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
                                style={{
                                  backgroundColor:
                                    colorAvatar[
                                      Math.floor(
                                        Math.random() * colorAvatar.length
                                      )
                                    ],
                                }}
                              >
                                {demandeInfo?.patientId?.gender === "male" ? (
                                  <ManPatient1 />
                                ) : (
                                  <WomanPatient1 />
                                )}
                              </div>

                              <div className="flex flex-col">
                                <div className="card-title">
                                  {(demandeInfo?.patientId?.gender === "male"
                                    ? "Mr "
                                    : "Mme ") +
                                    demandeInfo?.patientId?.lastName +
                                    " " +
                                    demandeInfo?.patientId?.firstName}
                                </div>
                                <p className="card-subtitle ">
                                  {"📅 "}
                                  {moment(Date.now()).year() -
                                    moment(
                                      demandeInfo?.patientId?.birthday
                                    ).year()}{" "}
                                  ans
                                </p>
                                <p className="card-subtitle ">
                                  {"📱 "}
                                  {demandeInfo?.patientId?.phone}
                                </p>
                                <p className=" card-subtitle  ">
                                  {"📫 "}
                                  {demandeInfo?.patientId?.email}
                                </p>
                              </div>
                            </div>
                            {user.type === "medecin" &&
                              demandeInfo.status !== "NOT_ASSIGNED" &&
                              demandeInfo.status !== "WAIT_FOR_IMGS" && (
                                <>
                                  {loading ? (
                                    <div
                                      role="status"
                                      className="max-w-sm p-4 border card-specialist border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
                                    >
                                      <div className="flex items-center space-x-3">
                                        <svg
                                          className="text-gray-200 w-14 h-14 dark:text-gray-700"
                                          aria-hidden="true"
                                          fill="currentColor"
                                          viewBox="0 0 20 20"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                            clip-rule="evenodd"
                                          ></path>
                                        </svg>
                                        <div>
                                          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                                          <div className="w-14 h-2 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
                                          <div className="w-20 h-2 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
                                          <div className="w-40 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                        </div>
                                      </div>
                                      <span className="sr-only">
                                        Chargement en cours...
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="flex justify-between title-doc mt-2">
                                        <div className="sub-title">
                                          <p>Informations médecin Expert</p>
                                        </div>
                                      </div>
                                      <div className="grid grid-flow-row card-specialist mt-2 p-2 shadow-md  auto-rows-max">
                                        <div
                                          className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
                                          style={{
                                            backgroundColor:
                                              colorAvatar[
                                                Math.floor(
                                                  Math.random() *
                                                    colorAvatar.length
                                                )
                                              ],
                                          }}
                                        >
                                          {demandeInfo?.docExpert?.gender ===
                                          "male" ? (
                                            <ManPatient1 />
                                          ) : (
                                            <WomanPatient1 />
                                          )}
                                        </div>
                                        <div className="flex flex-col">
                                          <div className="card-title">
                                            {(demandeInfo?.docExpert?.gender ===
                                            "male"
                                              ? "Mr "
                                              : "Mme ") +
                                              demandeInfo?.docExpert?.lastName +
                                              " " +
                                              demandeInfo?.docExpert?.firstName}
                                          </div>
                                          <p className="card-subtitle ">
                                            {"📅 "}
                                            {moment(Date.now()).year() -
                                              moment(
                                                demandeInfo?.docExpert?.birthday
                                              ).year()}{" "}
                                            ans
                                          </p>
                                          <p className="card-subtitle ">
                                            {"📱 "}
                                            {demandeInfo?.docExpert?.phone}
                                          </p>
                                          <p className=" card-subtitle  ">
                                            {"📫 "}
                                            {demandeInfo?.docExpert?.email}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                          </>
                        ) : (
                          <>
                            <div className="flex justify-between title-doc mt-2">
                              <div className="sub-title">
                                <p>Informations médecin Expert</p>
                              </div>
                            </div>
                            <div className="grid grid-flow-row card-specialist mt-2 p-2 shadow-md  auto-rows-max">
                              <div
                                className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
                                style={{
                                  backgroundColor:
                                    colorAvatar[
                                      Math.floor(
                                        Math.random() * colorAvatar.length
                                      )
                                    ],
                                }}
                              >
                                {demandeInfo?.docExpert?.gender === "male" ? (
                                  <ManPatient1 />
                                ) : (
                                  <WomanPatient1 />
                                )}
                              </div>

                              <div className="flex flex-col">
                                <div className="card-title">
                                  {(demandeInfo?.docExpert?.gender === "male"
                                    ? "Mr "
                                    : "Mme ") +
                                    demandeInfo?.docExpert?.lastName +
                                    " " +
                                    demandeInfo?.docExpert?.firstName}
                                </div>
                                <p className="card-subtitle ">
                                  {"📅 "}
                                  {moment(Date.now()).year() -
                                    moment(
                                      demandeInfo?.docExpert?.birthday
                                    ).year()}{" "}
                                  ans
                                </p>
                                <p className="card-subtitle ">
                                  {"📱 "}
                                  {demandeInfo?.docExpert?.phone}
                                </p>
                                <p className=" card-subtitle  ">
                                  {"📫 "}
                                  {demandeInfo?.docExpert?.email}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      "item",
                      openTab === 2 ? "block" : "hidden"
                    )}
                    id=""
                  >
                    <div className="  sub-item h-full  overflow-y">
                      <div className="form-response rounded-xl shadow-sm mt-2">
                        <div className="sub-documents mb-8">
                          <ImagesContainer
                            helperCategorie={() => console.log()}
                            setAddCategorieDocMenu={() => <div></div>}
                            addCategorieDocMenu={addCategorieDocMenu}
                            documentsList={demandeInfo?.files}
                            patientData={demandeInfo}
                            setImageUrl={setImageUrl}
                            retriveDemandeMyAPI={() => retriveDemandeMyAPI()}
                            setModalImage={setModalImage}
                            setDocumentsList={setDocumentsList}
                          />
                        </div>
                        <div className="flex justify-between title-doc my-2">
                          <div className="sub-title2 ">
                            <p>résultat de l'interrogatoire</p>
                          </div>
                        </div>
                        <dl className=" rounded-xl shadow-sm bg-white">
                          {dataToMap().map((e: any) => {
                            return (
                              <div className="px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6    ">
                                <dt className="inline-flex items-center text-md font-bold text-gray-500 m-1 p-2 leading-none capitalize  ">
                                  {e.label}
                                </dt>
                                {Array.isArray(e.value) ? (
                                  <dd>
                                    {e.value.map((x) => (
                                      <span className=" w-full inline-flex items-center justify-center m-1 p-2 text-sm font-bold leading-none text-slate-500 bg-gray-200 rounded-md capitalize ">
                                        {x}
                                      </span>
                                    ))}
                                  </dd>
                                ) : (
                                  <dd className="inline-flex items-center justify-center m-1 p-2 text-sm font-bold leading-none text-slate-500 bg-gray-200 rounded-md capitalize  ">
                                    {e.value}
                                  </dd>
                                )}
                              </div>
                            );
                          })}
                        </dl>
                      </div>
                    </div>
                  </div>
                  {patientData && patientData.demandes && (
                    <div
                      className={classNames(
                        "item",
                        openTab === 3 ? "block" : "hidden"
                      )}
                      id=""
                    >
                      <div className="sub-documents">
                        <>
                          <div className="grid grid-flow-row card-specialist mt-2 p-2 shadow-md  auto-rows-max">
                            <div
                              className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
                              style={{
                                backgroundColor:
                                  colorAvatar[
                                    Math.floor(
                                      Math.random() * colorAvatar.length
                                    )
                                  ],
                              }}
                            >
                              {demandeInfo?.patientId?.gender === "male" ? (
                                <ManPatient1 width={42} height={42} />
                              ) : (
                                <WomanPatient1 width={42} height={42} />
                              )}
                            </div>

                            <div className="flex flex-col">
                              <div className="card-title">
                                {(demandeInfo?.patientId?.gender === "male"
                                  ? "Mr "
                                  : "Mme ") +
                                  demandeInfo?.patientId?.lastName +
                                  " " +
                                  demandeInfo?.patientId?.firstName}
                              </div>
                              <p className="card-subtitle ">
                                {"📅 "}
                                {moment(Date.now()).year() -
                                  moment(
                                    demandeInfo?.patientId?.birthday
                                  ).year()}{" "}
                                ans
                              </p>
                              <p className="card-subtitle ">
                                {"📱 "}
                                {demandeInfo?.patientId?.phone}
                              </p>
                              <p className=" card-subtitle  ">
                                {"📫 "}
                                {demandeInfo?.patientId?.email}
                              </p>
                              <p className=" card-subtitle  ">
                                {"💳"}
                                {patientData.numeroSecurite}
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between title-doc mt-2">
                            <div className="sub-title">
                              <p>Historique de téléexpertise </p>
                            </div>
                          </div>
                          {patientData.demandes.map((d) => (
                            <div
                              className="card-specialist mt-2  p-3 space-x-3 max-w-max shadow relative"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="flex">
                                <div
                                  className="rounded-lg h-14 w-14 flexCenter mx-5 my-auto"
                                  style={{
                                    backgroundColor:
                                      colorAvatar[
                                        Math.floor(
                                          Math.random() * colorAvatar.length
                                        )
                                      ],
                                    opacity: 0.7,
                                  }}
                                >
                                  {d.type === "Dermatose non tumorale" ? (
                                    <img
                                      src={telex}
                                      alt="telex"
                                      width={"45px"}
                                      height={"45px"}
                                    />
                                  ) : (
                                    <img
                                      src={telex}
                                      alt="telex"
                                      width={"45px"}
                                      height={"45px"}
                                    />
                                  )}
                                </div>
                                <div className="details">
                                  <div className="flex-grow">
                                    <p className="card-title">
                                      Demande de téléexpertise en
                                      {" " + d.specialite}
                                    </p>
                                    <p className="font-bold text-gray-700">
                                      {" "}
                                      Type :{" " + d.type}
                                    </p>

                                    <span
                                      className={`rounded-full w-3 h-3 absolute right-2 top-2  `}
                                    ></span>
                                    <p className="card-sub-title text-xs absolute right-2 m-2 ">
                                      {" le "}
                                      {moment(d.createdAt).format("LL à HH:mm")}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="flex space-x-3"></div>
                            </div>
                          ))}
                        </>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {user.type === "medecinChirurgien" &&
            demandeInfo?.status === "RESPONSE" &&
            !demandeInfo?.priseEncharge &&
            modalPriseEncharge && (
              <Modal
                showModal={true}
                closeModal={() => setModalPriseEncharge(false)}
              >
                <PriseEnCharge
                  closeModal={() => setModalPriseEncharge(false)}
                  openPrescriptionModal={undefined}
                  seenByExpert={undefined}
                  demandId={""}
                  quit={false}
                  formikAcceptPriseEnCharge={formikAcceptPriseEnCharge}
                />
              </Modal>
            )}
          <Modal
            showModal={modalAssginChg}
            closeModal={() => setModalAssginChg(false)}
          >
            <div className="">
              <div className="flex justify-between items-center pr-2">
                <div className=" flex inline-flex items-center m-1 p-2 leading-none capitalize  ">
                  <p className="text-lg uppercase  text-gray-500">
                    Tâche(s) en attente
                  </p>
                </div>
              </div>
              <div className="inline-flex items-center text-md text-gray-500 m-1 p-2 leading-none ">
                Transférer la demande :
              </div>
              <div className="inline-flex items-center text-md text-gray-500 m-1 p-2 leading-none ">
                <form onSubmit={formikAssignChirurgien.handleSubmit}>
                  <div className=" ">
                    <Select
                      value={formikAssignChirurgien.values.chirurgienId}
                      onChange={formikAssignChirurgien.handleChange}
                      id="chirurgienId"
                      text={"Sélectionner un Chirurgie"}
                      className="relative mb-2 mt-2 mx-3"
                      options={chirurgienList?.map((e) => ({
                        value: e._id,
                        label: e.lastName + " " + e.firstName,
                      }))}
                      required={true}
                    ></Select>
                  </div>
                  {formikAssignChirurgien.values.chirurgienId !== "" && (
                    <div className="relative mt-2  ml-2 mr-2 ">
                      <Button
                        status="primary"
                        className="float-right flexCenter mb-5"
                        width="200px"
                        height="50px"
                        type="submit"
                        onClick={() => formikAssignChirurgien.handleSubmit()}
                        disabled={!formikAssignChirurgien.isValid}
                      >
                        {!formikAssignChirurgien.isValid ? (
                          <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                        ) : (
                          "Transférer"
                        )}
                      </Button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Modal>

          <Modal showModal={modalImage} closeModal={() => setModalImage(false)}>
            <ImageModal
              closeModal={() => setModalImage(false)}
              patientId={demandeInfo?._id}
              imageData={imageUrl}
            />
          </Modal>

          <Modal
            showModal={modalUserLimite}
            closeModal={() => setModalUserLimite(false)}
          >
            <div className="p-3 ">
              <h2 className="flexCenter">
                Ce spécialiste atteint sa limite de demandes autorisées par son
                abonnement. <br></br>{" "}
              </h2>
              <span className="flexCenter">
                Êtes-vous sûr de vouloir attribuer cette demande à ce médecin ?
              </span>
              <div className="flexCenter mt-6 buttons gap-x-6">
                <Button
                  status="secondary"
                  width={"119px"}
                  height={"50px"}
                  onClick={() => setModalUserLimite(false)}
                >
                  Annuler
                </Button>
                <Button
                  status="secondary"
                  width={"119px"}
                  height={"50px"}
                  onClick={() => {
                    formikAssignForce.handleSubmit();
                    setModalUserLimite(false);
                  }}
                >
                  Confirmer
                </Button>
              </div>
            </div>
          </Modal>
          <Chat
            chatWithId={demandeData._id}
            showModal={chatModal}
            closeChatModal={() => setChatModal(false)}
          />
        </div>
      ) : (
        <div className="absolute  inset-y-0 right-0 z-40 transition transition ease-in duration-700 patient-modal-info">
          <Spinner />
        </div>
      )}
    </div>
  );
};
export default SharedRequest;
