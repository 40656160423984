import ICalendarEvent from "../../../types/ICalendarEvent";

type props = {
  timeText: string;
  event: {
    extendedProps: Partial<ICalendarEvent>;
    [property: string]: any;
  };
  [property: string]: any;
};

const CalendarEvent: React.FC<props> = (props) => {
  return (
    <>
      {props.timeText !== "0:01" ? (
        <div
          className="fc-custom-event-content relative "
          style={{
            borderColor: props.event.extendedProps.testIndisponibility
              ? "red"
              : "#4565f6",
            backgroundColor: props.event.extendedProps.testIndisponibility
              ? "rgb(254, 209, 209)"
              : "#FFFFFF",
          }}
        >
          {props.event.extendedProps.testIndisponibility ? (
            <p className="fc-custom-event-time"> En congé </p>
          ) : (
            <div>
              <p className="fc-custom-event-time">
                Disponibilité pour téléexpertise
              </p>
              <p className="fc-custom-event-patient">{props.timeText}</p>
            </div>
          )}
        </div>
      ) : (
        <div
          className="fc-custom-event-content relative "
          style={{
            borderColor: "#FF775A",
          }}
        >
          <p className="fc-custom-event-time">Aucune disponibilité</p>
        </div>
      )}
    </>
  );
};

export default CalendarEvent;
