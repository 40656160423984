import PropTypes from "prop-types";
import Task from "./Task";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

const TasksContainer = ({
  title,
  titleBorder = false,
  tasks,
  setUpdate,
  userType,
  fetchMoreData,
  hasMore,
  id,
  totalCounts,
}) => {
  const titleClass = classNames("p-1", "rounded-sm", {
    "border-b-4": titleBorder,
    "border-indigo-500": titleBorder,
  });
  return (
    <div
      className={`flex mt-10   gap-2 flex-col ${
        userType === "medecinChirurgien" ? "w-1/2" : "w-1/3"
      }  h-full`}
    >
      <div className="flex items-center ">
        <h4 className={titleClass}>{title}</h4>
        <span className="p-1 text-gray-500">
          ({totalCounts || tasks.length})
        </span>
      </div>

      <div
        className="p-2 overflow-y-scroll h-full "
        id={id}
        style={{ height: "100%", overflowY: "scroll" }}
      >
        <InfiniteScroll
          dataLength={tasks.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={hasMore ? <h4>Chargement en cours...</h4> : null}
          scrollableTarget={id}
        >
          <div className="flex flex-col p-2">
            {tasks && tasks.length > 0
              ? tasks.map((task, i) => <Task key={i} {...task} />)
              : !hasMore && <p>Aucune demande trouvée</p>}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

TasksContainer.propTypes = {
  title: PropTypes.string,
  titleBorder: PropTypes.bool,
  tasks: PropTypes.array,
};
export default TasksContainer;
