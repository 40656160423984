import { Redirect, RouteProps } from "react-router";
import Agenda from "../pages/Agenda";
import Consultation from "../pages/ConsultationDoctor";

import MyRequests from "../pages/MyRequests";
import Profile from "../pages/Profile";



const medecinResponsableRoutes: RouteProps[] = [
 

  {
    path: "/disponibilités",
    component: Agenda,
    exact: true,
  },
  {
    path: "/demandes",
    component: MyRequests,
    exact: true,

  },
  {
    path: "/consultation/:idDemande",
    component: Consultation,
    exact: true,
  },
 
  {
    path: "/profile",
    component: Profile,
    exact: true,
  },

  { render: () => <Redirect to="/demandes" /> },
];

export default medecinResponsableRoutes;
