import React from "react";
import Button from "../../../components/ButtonUI";
import "./styles.scss";
import { addOrModifyExpertise, addOrModifyLangues } from "../../../api/medecin";
import { Formik } from "formik";
import { User } from "../../../types/user";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import Input from "../../../components/Input";
import makeToast from "../../../components/Snackbar";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
  languesDoctor?: string[];
}

export const AddLangue: React.FC<Props> = ({
  openModal,
  setOpenModal,
  languesDoctor,
}) => {
  const [user, setUser] = useAtom(userAtom);
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd w-1/2">
        <Formik
          initialValues={{
            langues: user?.langues || [],
          }}
          onSubmit={async (values) => {
            try {
              setOpenModal(!openModal);
              const newUser = await addOrModifyLangues({ ...values });
              setUser({ ...user, ...newUser });
              if (newUser.id) {
                makeToast("success", "Langues modifiées avec succès.");
              } else {
                makeToast("error", "Ajout langues à échouher.");
              }
            } catch (err) {
              console.log("[error] add Patient -on Submit :", err);
            }
          }}
        >
          {(props) => {
            const {
              values,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form
                onSubmit={handleSubmit}
                className="flex-auto py-7 px-8 min-w-full	"
              >
                <div className="flex flex-wrap items-baseline">
                  <h1 className="mb-10">Ajouter une langue</h1>
                </div>
                <div className="flex items-center flexCenter ">
                  <div className="ml-8 mb-8">
                    <Input
                      name="langues"
                      type="checkbox"
                      onChange={handleChange}
                      value="Français"
                      labelText="Français"
                      labelProps={{ className: "check" }}
                      checked={
                        values.langues.find((e) => e === "Français")
                          ? true
                          : false
                      }
                      className="mt-4 mb-4"
                    />
                    <Input
                      name="langues"
                      type="checkbox"
                      onChange={handleChange}
                      value="Anglais"
                      labelText="Anglais"
                      labelProps={{ className: "check" }}
                      checked={
                        values.langues.find((e) => e === "Anglais")
                          ? true
                          : false
                      }
                    />
                    <Input
                      name="langues"
                      type="checkbox"
                      onChange={handleChange}
                      value="Allemand"
                      labelText="Allemand"
                      labelProps={{ className: "check" }}
                      checked={
                        values.langues.find((e) => e === "Allemand")
                          ? true
                          : false
                      }
                      className="mt-4 mb-4"
                      stylesDiv={"inputTest"}
                    />
                  </div>
                  <div className="ml-8 mb-8">
                    <Input
                      name="langues"
                      type="checkbox"
                      onChange={handleChange}
                      value="Espagnol"
                      labelText="Espagnol"
                      labelProps={{ className: "check" }}
                      className="mt-4 mb-4"
                      checked={
                        values.langues.find((e) => e === "Espagnol")
                          ? true
                          : false
                      }
                   
                    
                    />{" "}
                    <Input
                      name="langues"
                      type="checkbox"
                      onChange={handleChange}
                      value="Italien"
                      labelText="Italien"
                      labelProps={{ className: "check" }}
                      checked={
                        values.langues.find((e) => e === "Italien")
                          ? true
                          : false
                      }
                
                    />
                    <Input
                      name="langues"
                      type="checkbox"
                      onChange={handleChange}
                      value="Arabe"
                      labelText="Arabe"
                      labelProps={{ className: "check" }}
                      checked={
                        values.langues.find((e) => e === "Arabe") ? true : false
                      }
                      className="mt-4 mb-4"
                      stylesDiv={"inputTest"}
                   
                    />
                  </div>
                  <div className="ml-8 mb-8 ">
                    <Input
                      name="langues"
                      type="checkbox"
                      onChange={handleChange}
                      value="Portugais"
                      labelText="Portugais"
                      labelProps={{ className: "check" }}
                      checked={
                        values.langues.find((e) => e === "Portugais")
                          ? true
                          : false
                      }
                      className="mt-4 mb-4"
                      stylesDiv={"inputTest"}
                    />

                    <Input
                      name="langues"
                      type="checkbox"
                      onChange={handleChange}
                      value="Russe"
                      labelText="Russe"
                      labelProps={{ className: "check" }}
                      checked={
                        values.langues.find((e) => e === "Russe") ? true : false
                      }
                    />
                    <Input
                      name="langues"
                      type="checkbox"
                      onChange={handleChange}
                      value="Japonais"
                      labelText="Japonais"
                      labelProps={{ className: "check" }}
                      className="mt-4 mb-4"
                      checked={
                        values.langues.find((e) => e === "Japonais")
                          ? true
                          : false
                      }
                    
                      stylesDiv={"inputTest"}
                    />
                  </div>
                </div>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-left mb-4 btn-delete"
                  onClick={() => setOpenModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-right mb-4"
                >
                  Valider
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddLangue;
