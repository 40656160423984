import React, {
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
} from "react";

import CreatableSelect from "react-select/creatable";
import { MultiValue, OnChangeValue } from "react-select";

const components = {
  DropdownIndicator: null,
};
interface Props {
  name: string;
  onChange: Function;
  formik: any;
}
interface Option {
  label: string;
  value: string;
}

const CreatableInputOnly: React.FC<Props> = ({ name, formik, onChange }) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [values, setVal] = useState<MultiValue<Option>>((formik.values[name]  ) ? formik.values[name].map((p)=>{return {label : p , value : p }}) : []  );
  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      border: state.isFocused ? "0" : "1px solid #5270f6 ",
      boxShadow: state.isFocused ? "0 0 0 0.1rem #5270f6" : 0,
      "*": {
        boxShadow: "none !important",
      },
    }),

    multiValue: (styles, state) => ({
      ...styles,
      color: "black",
      background: "#f7f8fd",
      ":hover": {
        background: "#a6a9c8",
      },
    }),
    input: (styles, state) => ({
      ...styles,
      height: "28px",
    }),
  };
  const createOption = (label: string) => {
    return {
      label,
      value: label,
    };
  };
  const handleChange = (value: OnChangeValue<Option, true>) => {
   try {
    setVal(value);


    formik.setFieldValue(name, value);
    if (values.length < 2) {
      formik.setErrors(name, "Champ obligatoire !");
    }
 
   } catch (error) {
     Promise.reject(error)
     console.log(error)

   }
  }
  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  };
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    let x = false;
    if (!inputValue) return;
    if (event.type === "blur") {
      values.map((v) => {
        if (v.label === inputValue) x = true;
      });
      if (!x) {
        setVal([...values, createOption(inputValue)]);
        setInputValue("");
        formik.setFieldValue(
          name,
          [...values, createOption(inputValue)].map((el) => el.value)
        );
      }
      event.preventDefault();
    }
    switch (event.key) {
      case "Enter":
      case "Tab":
        values.map((v) => {
          if (v.label === inputValue) x = true;
        });
        if (!x) {
          setVal([...values, createOption(inputValue)]);
          setInputValue("");
          formik.setFieldValue(
            name,
            [...values, createOption(inputValue)].map((el) => el.value)
          );
        }
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      name={name}

      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onBlur={(e: any) => handleKeyDown(e)}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder=""
      styles={customStyles}
      value={values   }
    />
  );
};
export default CreatableInputOnly;
