import React from "react";
import Button from "../../../components/ButtonUI";
import TextArea from "../../../components/TextArea";
import "./styles.scss";
import presentationSchema from "./schemas/presentationSchema";
import { addOrModifyDescription } from "../../../api/medecin";
import { Formik } from "formik";
import { User } from "../../../types/user";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import makeToast from "../../../components/Snackbar";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
}

export const AddPresentation: React.FC<Props> = ({
  openModal,
  setOpenModal,
}) => {
  const [user, setUser] = useAtom(userAtom);

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd">
        <Formik
          initialValues={{
            description: "" || user?.description,
          }}
          onSubmit={async (values) => {
            try {
              const newUser = await addOrModifyDescription({ ...values });
              const { description } = newUser;

              setUser({
                ...user,
                description,
              } as User);
              setOpenModal(!openModal);
              if (newUser.id) {
                makeToast("success", "Présentation ajoutée avec succès.");
              } else {
                makeToast("error", "Présentation universitaire à échouher.");
              }
            } catch (err) {
              console.log("[error] add Patient -on Submit :", err);
            }
          }}
          validationSchema={presentationSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form
                onSubmit={handleSubmit}
                className="flex-auto py-7 px-8 min-w-full	"
              >
                <div className="flex flex-wrap items-baseline">
                  <h1 className="mb-10">Présentation</h1>
                </div>
                <div className="">
                  <TextArea
                    id="description"
                    placeholder="Ecrivez votre description ..."
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                  />
                </div>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-left mb-4 btn-delete"
                  onClick={() => setOpenModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-right mb-4"
                >
                  Valider
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddPresentation;
