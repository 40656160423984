import React from "react";
import Button from "../ButtonUI";
import moment from "../../helper/moment";

import { ReactComponent as ThreeDots } from "../../assets/icons/icon-troisPoints.svg";
import { ReactComponent as IconMessage } from "../../assets/icons/icon-message.svg";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/na.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/na.svg";
import { ReactComponent as IconCloseModal } from "../../assets/icons/icon-close.svg";
import { ReactComponent as SpecIcon } from "../../assets/icons/icon-union.svg";

import birthday from "../../assets/images/birthday.png";
import phone from "../../assets/images/phone.png";
import mail from "../../assets/images/mail.png";
import home from "../../assets/images/home.png";
import time from "../../assets/images/time.png";
import statusProgress from "../../assets/images/work-process.png";
import statusWaiting from "../../assets/images/exclamation-mark.png";
import statusDone from "../../assets/images/checked.png";
import { statusLabel } from "../../helper/demandeStatus";
import PdfDocument from "../Pdf/PdfDocument";
import TestDocument from "../Pdf/TestDocument";
//import { loadImage } from "../../api/documents";

const HeaderChat = ({ chatWithData, closeChatModal }) => {
  const [demandData, setDemandData] = React.useState() as any;
  React.useEffect(() => {
    setDemandData({
      // phone: chatWithData?.phoneNumber,
      // email: chatWithData?.email,
      // address: chatWithData?.address,
      // role: "DOCTOR",
      type: chatWithData?.type,
      specialite: chatWithData?.specialite,
      files: chatWithData?.files,
      patientId: chatWithData?.patientId,
      createdAt: chatWithData?.createdAt,
    });
  }, [chatWithData]);
  const colorAvatar = [
    "#ccd2ff",
    "#d5f0f7",
    "#ffecba",
    "#fff7ab",
    "#ffccd2",
    "#ffe2c8",
  ];
  const imgStatuts = () => {
    let imgSrc = "";
    if (
      demandData?.status === "IN_PROGRESS" ||
      demandData?.status === "SEEN_BY_EXPERT"
    ) {
      imgSrc = statusProgress;
    } else if (
      demandData?.status === "RESPONSE" ||
      demandData?.status === "SEEN_BY_REQURANT"
    ) {
      imgSrc = statusDone;
    } else {
      imgSrc = statusWaiting;
    }
    return imgSrc;
  };
  return (
    <div className="sub-container overflow-hidden">
      <div className="flex header justify-between	">
        <div className="infos flex w-full justify-between mr-2 mt-3">
          <div className="flex sub-infos">
            <div
              className="photo"
              style={{ backgroundColor: "rgb(213, 240, 247)" }}
            >
              {" "}
              {demandData?.type === "Dermatose non tumorale" ? (
                <ManPatient />
              ) : (
                <WomanPatient />
              )}
            </div>

            <div className="details">
              <div className="flex justify-between">
                <div className="">
                  <p className="name capitalize">{demandData?.type}</p>
                </div>
              </div>
              <div className="flex justify-between mt-3">
                <div className="">
                  <p className="name capitalize">
                    {" "}
                    Patient : {demandData?.patientId?.firstName}{" "}
                    {demandData?.patientId?.lastName}
                  </p>
                </div>
              </div>

              <div className="flex birth mt-3">
                <div className="sub-birth flexCenter img">
                  <img src={time} alt="date" />
                </div>
                <div className="dateBirth">
                  <p>{moment(demandData?.createdAt).format("L")}</p>
                </div>
                <div className="flex mail ">
                  <div className="flexCenter img ml-14">
                    <SpecIcon />
                  </div>
                  <div>{demandData?.specialite}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="patient-icons flex flex-col justify-between items-center">
            <div>
              <Button
                status="icon-secondary"
                onClick={closeChatModal}
                className="absolute top-1 right-1  "
              >
                <Close className="absolute top-1 right-1 " />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderChat;
