export const KATOMI_API_ENDPOINT = `${process.env.REACT_APP_KATOMI_API_URL}/v${process.env.REACT_APP_KATOMI_API_VERSION}`;
const DOCTOR_AUTH_ENDPOINT = `${KATOMI_API_ENDPOINT}/superAdmin`;
export const DOCTOR_LOGIN_URL = `${DOCTOR_AUTH_ENDPOINT}/auth/login`;
export const DOCTOR_FORGET_PASSWORD_URL = `${DOCTOR_AUTH_ENDPOINT}/auth/forgot-password`;
export const DOCTOR_FORGET_PASSWORD_URL_VERIFY = `${DOCTOR_AUTH_ENDPOINT}/auth/verifyResetToken`;
export const DOCTOR_LOGOUT_URL = `${DOCTOR_AUTH_ENDPOINT}/auth/logout`;
export const DOCTOR_VERIFY_URL = `${DOCTOR_AUTH_ENDPOINT}/admin/auth`;
export const DOCTOR_UPDATE_PROFILE_URL = `${DOCTOR_AUTH_ENDPOINT}/admin`;
export const PAYMENTS_ENDPOINT = `${KATOMI_API_ENDPOINT}/payments`;
export const PAYMENTS_CREATE_SUBSCRIPTION_URL = `${PAYMENTS_ENDPOINT}/subscription`;
export const PAYMENTS_CREATE_CUSTOMER_ID = `${PAYMENTS_ENDPOINT}/createCustomerId`;
export const PAYMENTS_CANCEL_SUBSCRIPTION_URL = `${PAYMENTS_ENDPOINT}/cancelSubscription`;
export const PAYMENTS_VERIFY_SUBSCRIPTION_URL = `${PAYMENTS_ENDPOINT}/verifyingSubscription`;
export const PAYMENTS_GET_INVOICES = `${PAYMENTS_ENDPOINT}/invoices`;
export const PAYMENTS_GET_PAYMENT_METHODS = `${PAYMENTS_ENDPOINT}/payment-methods`;
export const DOCTOR_UPDATE_URL = `${KATOMI_API_ENDPOINT}/superAdmin/admin/update`;

// CHAT ENDPOINTS
export const GET_OLD_MESSAGES = (chatroomId) =>
  `${KATOMI_API_ENDPOINT}/message/getAll?chatroom=${chatroomId}`;
export const GET_UNREAD_MSG = `${KATOMI_API_ENDPOINT}/message/unreadMsg`;
export const MARK_AS_REPLIED_MSG = `${KATOMI_API_ENDPOINT}/message/markAsReplied`;
