import React, { useState } from "react";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import AddPresentation from "./modal/AddPresentation";
import AddExpertise from "./modal/AddExpertise";
import AddDepartement from "./modal/AddDepartement";
import Icon from "../../components/Icon";
import AddNumAdeli from "./modal/AddNumAdeli";
import _ from "lodash";

const Description: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [openModalExpertise, setOpenModalExpertise] = useState(false);
  const [openModalDepartement, setOpenModalDepartement] = useState(false);
  const [openModalNumAdli, setOpenModalNumAdli] = useState(false);

  return (
    <div>
      <div className="expertises mb-4">
        <div className="">
          <div className="headerContainer flex items-center relative">
            <Icon name="steto" isIcon={true} className="svgClass" />
            <p className="header">N° ADELI / RPPS</p>
            {user?.numCom ? (
              ""
            ) : (
              <p
                className="absolute right-0	modifier"
                onClick={() => {
                  setOpenModalNumAdli(true);
                }}
              >
                Modifier
              </p>
            )}
            {user?.numRPPS ? (
              ""
            ) : (
              <p
                className="absolute right-0	modifier"
                onClick={() => {
                  setOpenModalNumAdli(true);
                }}
              >
                Modifier
              </p>
            )}
          </div>
          <div className="grid grid-cols-2  gap-2 specailities pb-2">
            <div className="relative boxSpecialiteX p-2  flexColCenter ">
              <p>
                <div className=" flexColCenter">
                  <p className="font-bold">
                    {" "}
                    N° ADELI - {user?.numCom ? user.numCom : "N/A"}
                  </p>
                </div>
              </p>
            </div>
            <div className="relative boxSpecialiteX p-2  flexColCenter ">
              <p>
                <div className=" flexColCenter">
                  <p className="font-bold">
                    N° RPPS - {user?.numRPPS ? user.numRPPS : "N/A"}
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="PresentationBox mb-4">
        <div className="headerContainer flex items-center relative">
          <Icon isIcon={true} name="nurse" className="svgClass" />
          <p className="header">Présentation</p>
          <p
            className="absolute right-0	modifier"
            onClick={() => setOpenModal(true)}
          >
            Modifier
          </p>
        </div>
        <div className="textClassContainer">
          <p className={`textClass ${user?.description ? "" : "flexCenter"}`}>
            {user?.description ? (
              user.description
            ) : (
              <span className="card-subtitle">
                Vous n'avez pas encore ajouté une présentation.
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="expertises mb-4">
        <div className="">
          <div className="headerContainer flex items-center relative">
            <Icon name="adn" isIcon={true} className="svgClass" />
            <p className="header">EXPERTISES</p>
            <p
              className="absolute right-0	modifier"
              onClick={() => {
                setOpenModalExpertise(true);
              }}
            >
              Modifier
            </p>
          </div>
          <div className="grid grid-cols-3  gap-2 specailities pb-2">
            {user?.expertises &&
              user.expertises.map((expertise) => {
                return (
                  <div className="relative boxSpecialite flexColCenter">
                    <p>{expertise.expertise}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="expertises">
        <div className="">
          <div className="headerContainer flex items-center relative">
            <Icon name="location" isIcon={true} className="svgClass" />
            <p className="header">Département</p>
            <p
              className="absolute right-0	modifier"
              onClick={() => {
                setOpenModalDepartement(true);
              }}
            >
              Modifier
            </p>
          </div>
          <div className="textClassContainer">
            <div className="grid grid-cols-3  gap-2 specailities pb-2">
              {user?.departement && (
                <div className="relative boxSpecialite flexColCenter">
                  <p>{user.departement} </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <AddPresentation openModal={openModal} setOpenModal={setOpenModal} />
      )}
      {openModalExpertise && (
        <AddExpertise
          openModal={openModalExpertise}
          setOpenModal={setOpenModalExpertise}
        />
      )}
      {openModalDepartement && (
        <AddDepartement
          openModal={openModalDepartement}
          setOpenModal={setOpenModalDepartement}
        />
      )}
      {openModalNumAdli && (
        <AddNumAdeli
          openModal={openModalNumAdli}
          setOpenModal={setOpenModalNumAdli}
        />
      )}
    </div>
  );
};

export default Description;
