import * as Yup from "yup";
const digitsOnly = (value) => /^\d+$/.test(value)



export default Yup.object().shape({
  sexe: Yup.array().required("At least one checkbox is required").min(1),
  email: Yup.string().email("Veuillez utiliser une adresse mail valide."),
  lastName: Yup.string().required("Champ  obligatoire"),
  firstName: Yup.string().required("Champ  obligatoire"),
  birthday: Yup.string().required("Champ  obligatoire"),
  phone: Yup.string().required("Champ  obligatoire"),
  numeroSecurite: Yup.string().test('Digits only', 'The field should have digits only', digitsOnly).required("Champ  obligatoire"),
  codePostale: Yup.string().test('Digits only', 'The field should have digits only', digitsOnly).required("Champ  obligatoire"),

});
