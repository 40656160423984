import React, {
  DetailedHTMLProps,
  SelectHTMLAttributes,
  LabelHTMLAttributes,
} from "react";
import classNames from "classnames";

import "./styles.scss";

interface option {
  label: string;
  value: string;
}
type Props = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  error?: string;
  labelText?: string;
  labelProps?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  options: option[];
  text?: String;
  setText?: Boolean;
  isMulti?:Boolean;
};

const SelectComp: React.FC<Props> = ({
  error,
  labelText,
  labelProps,
  text,
  setText,
  options,
  isMulti,
  ...props
}) => {
  const { className: labelClassName, ...restOfLabelProps } = labelProps!;
  const { className: inputClassName, ...inputProps } = props;
  const yes = isMulti ? true : false 
  return (
    <>
      {labelText ? (
        <label
          className={classNames(
            "pl-2 h-12 flex items-center",
            labelClassName,
            error ? "text-red-500" : null
          )}
          htmlFor={inputProps.id}
          {...restOfLabelProps}
        >
          {labelText}
        </label>
      ) : null}
      <select
        className={classNames(
          "h-12 border bg-white rounded  outline-none",
          error ? "text-red-500 border-red-300" : null,
          inputClassName
        )}
        {...inputProps}
        multiple={yes}
      >
        {setText && (
          <option key={null} value="">
            {text ? text : "Please Select an option"}
          </option>
        )}
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </>
  );
};
SelectComp.defaultProps = {
  labelProps: {},
  setText: true,
};
export default SelectComp;
