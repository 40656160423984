import React, { useState } from "react";
import Button from "../../components/ButtonUI";
import Input from "../../components/Input";
import "./styles.scss";

interface Props {
  setOpenModal: Function;
  action: (hasConsent: boolean) => void;
}

export const ConfirmModalRedirection: React.FC<Props> = ({
  setOpenModal,
  action,
}) => {
  const [hasConsent, setHasConsent] = useState(false);

  const handleCheckboxChange = () => {
    setHasConsent(!hasConsent);
  };
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute z-40 top-1/3 flexColCenter confirmAddPatient">
        <h2>
          Ce patient existe déjà sur AvisDoc, souhaitez-vous consulter sa fiche
          ?{" "}
        </h2>
        <div className="flex  flex-row-reverse items-center justify-center max-w-max py-4">
          <label className="flex items-center text-base check">
            J'ai bien reçu le consentement du patient qui m'autorise à accéder
            aux demandes passées
          </label>
          <input
            className=" flex-grow flexCenter checkbox border border-gray-200 mt-4 mb-4"
            type="checkbox"
            onChange={handleCheckboxChange}
            checked={hasConsent}
          />
        </div>
        <div className="flexCenter mt-2 buttons">
          <Button
            status="secondary"
            width={"119px"}
            height={"50px"}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Annuler
          </Button>
          <Button
            status="secondary"
            width={"119px"}
            height={"50px"}
            onClick={() => {
              action(hasConsent);
            }}
          >
            Confirmer
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmModalRedirection;
