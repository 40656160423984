import React, { useState } from "react";
import Button from "../../ButtonUI";
import { deleteLeave, getAllMyLeaves } from "../../../api/medecin";
import AddLeave from "../../AddLeave";
import moment from "moment";
import { ILeave } from "../../../types/ILeave";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import makeToast from "../../../components/Snackbar";
import ConfirmModal from "./ConfirmModalDelete";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import _ from "lodash";

interface Props {
  closeModal: any;
}

const LeaveForm: React.FC<Props> = ({ closeModal }) => {
  const [leave, setLeave] = React.useState([]) as any;
  const [show, setShow] = useState(false);
  //const [hide, setHide] = useState(false);
  const [leaveAdded, setLeaveAdded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState() as any;
  const [selectedId, setSelectedId] = useState(null);
  const [selectedLeave, setSelectedLeave] = useState("");
  const [refreshLeave, setRefreshLeave] = useState(false);
  const [leaveData, setLeaveData] = useState<ILeave>();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const [user] = useAtom(userAtom);

  const handleDelete = async (id) => {
    try {
      const response = await deleteLeave(id);
      makeToast("success", "suppression du congé effectuée avec succès.");
      setRefreshLeave((x) => !x);
    } catch (error) {
      console.log(error);
    }
  };

  const _getAllMyLeave = async () => {
    try {
      setLoading(true);
      const response = await getAllMyLeaves({
        doctorId: user?._id,
      });
      if (response && response.data) {
        console.log("response", response);

        setTimeout(() => {
          setLeave(response?.data.leave);

          setLoading(false);
        }, 1000);
      } else {
        makeToast("error", "Chargement a échoué.");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    _getAllMyLeave();
  }, [refreshLeave]);
  return (
    <div className="leave-config-form flex flex-col items-center w-full">
      {show ? (
        <Button
          status="secondary"
          onClick={() => {
            setShow(false);
            setEditMode(false);
            //setHide(false);
          }}
          className="h-10"
          style={{ marginLeft: "500px", width: "146px" }}
        >
          Retour
        </Button>
      ) : (
        <>
          <Button
            status="secondary"
            onClick={() => {
              setShow(true);
              //setHide(true);
            }}
            className="h-10"
            style={{ marginLeft: "500px", width: "146px" }}
          >
            + Ajouter un congé
          </Button>

          <div className={`mt-8 w-full`}>
            <table
              className="items-center mx-8"
              style={{
                backgroundColor: "#f5f5f9",
                borderRadius: "20px 20px 20px 20px",
                width: "90%",
              }}
            >
              <thead>
                <tr>
                  <th className="px-10 text-black align-middle border-b border-solid  py-3 text-base whitespace-nowrap  font-semibold text-center">
                    Date de début
                  </th>
                  <th className="px-10 text-black align-middle border-b border-solid py-3 text-base whitespace-nowrap  font-semibold text-center">
                    Date de fin
                  </th>
                  <th className="px-10 text-black align-middle border-b border-solid  py-3 text-base whitespace-nowrap  font-semibold text-center">
                    Actions
                  </th>
                </tr>
              </thead>

              {loading ? (
                <tbody>
                  <tr>
                    <td></td>
                    <td
                      colSpan={3}
                      className="flexCenter card-subtitle border-4 w-full h-12"
                    >
                      <p>Chargement ...</p>
                    </td>
                  </tr>
                </tbody>
              ) : !_.isEmpty(leave) ? (
                <tbody>
                  {leave &&
                    leave
                      .sort(
                        (a, b) =>
                          +new Date(b.createdAt) - +new Date(a.createdAt)
                      )
                      .map((item) => (
                        <tr>
                          <td className="border-b  align-middle font-light text-sm whitespace-nowrap px-10 py-4 text-center  ">
                            {moment(item?.startDate).format("DD/MM/YYYY")}
                          </td>
                          <td className="border-b  align-middle font-light text-sm whitespace-nowrap px-10 py-4 text-center  ">
                            {moment(item?.endDate).format("DD/MM/YYYY")}
                          </td>
                          <td className="flex flex-col md:flex-row space-x-2 mt-4 ml-14 ">
                            <FaPencilAlt
                              values={item}
                              onClick={() => {
                                setEditMode(true);
                                console.log("leave", item);
                                setSelectedItem(item);
                                setShow(true);
                                //setHide(true);
                              }}
                            />
                            &nbsp;
                            <FaTrashAlt
                              values={item?._id}
                              onClick={() => {
                                setSelectedId(item?._id);
                                console.log("ItemIdàsupprimer", item?._id);
                                setConfirmDeleteModal(true);
                                //handleDelete(item?._id);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td></td>
                    <td
                      colSpan={3}
                      className="flexCenter card-subtitle border-4 w-full h-12"
                    >
                      <p>Vous n'avez aucun congé</p>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </>
      )}

      {/* <div className={`mt-8 w-max ${hide && "max-h-52"} overflow-y-auto`}>
        <table
          className="items-center border-collapse"
          style={{
            backgroundColor: "#f5f5f9",
            borderRadius: "20px 20px 20px 20px",
            width: "560px",
          }}
        >
          <thead>
            <tr>
              <th className="px-10 text-black align-middle border-b border-solid  py-3 text-sm whitespace-nowrap  font-semibold text-center">
                Date de début
              </th>
              <th className="px-10 text-black align-middle border-b border-solid py-3 text-sm whitespace-nowrap  font-semibold text-center">
                Date de fin
              </th>
              <th className="px-10 text-black align-middle border-b border-solid  py-3 text-sm whitespace-nowrap  font-semibold text-center">
                Actions
              </th>
            </tr>
          </thead>
          {!_.isEmpty(leave) ? (
            <tbody>
              {leave &&
                leave.map((item) => (
                  <tr>
                    <td className="border-b  align-middle font-light text-sm whitespace-nowrap px-10 py-4 text-center  ">
                      {moment(item?.startDate).format("DD/MM/YYYY")}
                    </td>
                    <td className="border-b  align-middle font-light text-sm whitespace-nowrap px-10 py-4 text-center  ">
                      {moment(item?.endDate).format("DD/MM/YYYY")}
                    </td>
                    <td className="flex flex-col md:flex-row px-10 space-x-2 mt-4  ">
                      <FaPencilAlt
                        values={item}
                        onClick={() => {
                          setEditMode(true);
                          console.log("leave", item);
                          setSelectedItem(item);
                          setShow(true);
                          setHide(true);
                        }}
                      />
                      &nbsp;
                      <FaTrashAlt
                        values={item?._id}
                        onClick={() => {
                          setSelectedId(item?._id);
                          console.log("ItemIdàsupprimer", item?._id);
                          setConfirmDeleteModal(true);
                          //handleDelete(item?._id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td></td>
                <td
                  colSpan={3}
                  className="flexCenter
                  card-subtitle border-4 w-full h-12"
                >
                  <p> Ce médecin n'a aucun congé</p>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div> */}

      {show && (
        <AddLeave
          setShow={setShow}
          editMode={editMode}
          setLeaveAdded={setLeaveAdded}
          setSelectedLeave={setSelectedLeave}
          setLeaveData={setLeaveData}
          selectedItem={selectedItem}
          setRefreshLeave={setRefreshLeave}
          leaveData={{
            _id: "",
            startDate: "",
            endDate: "",
            notAgreedToReceiveDemand: true,
          }}
          setEditMode={setEditMode}
        />
      )}
      {confirmDeleteModal && (
        <ConfirmModal
          action={() => handleDelete(selectedId)}
          setOpenModal={setConfirmDeleteModal}
        />
      )}
    </div>
  );
};

export default LeaveForm;
