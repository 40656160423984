import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import Fuse from "fuse.js";

import { refreshAtom } from "../../state/refreshPatientInfo";
import { userAtom } from "../../state/auth";
import { IPatient } from "../../types/patient";

import CardPatient from "./CardPatient";
import ConfirmAddPatient from "./ConfirmModalAddPatient";
import AddPatient from "./addPatient";
import Button from "../../components/ButtonUI";
import PatientInfo from "../../components/SharedPatientDetails";
import makeToast from "../../components/Snackbar";
import Pagination from "../../components/pagination";
import Input from "../../components/Input";

import { getAllMyPatients, retrivePatient } from "../../api/medecin";
import { verify } from "../../api/auth";
import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import { ReactComponent as Download } from "../../assets/illustrations/6421887411543238876.svg";
import "./styles.scss";

const MyPatients: React.FC = () => {
  const [user, setUser] = useAtom(userAtom);
  const [refreshPatientInfo] = useAtom(refreshAtom);
  const [isResponsable, setIsResponsable] = useState(
    user?.type === "medecinResponsable" ? true : false
  );
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [strFilter, setStrFilter] = React.useState<string>("");
  const [modalAddConfirm, setModalAddConfirm] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [patientAdded, setPatientAdded] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientData, setPatientData] = useState<IPatient>();
  const [modal, setModal] = useState(false);
  const [listPatients, setListPatients] = useState([]);
  const [updatedPatient, setUpdatedPatient] = useState(false);
  const [colorIndex, setColorIndex] = useState(1);

  const fetchMyData = async () => {
    const response =
      user?.type === "medecinResponsable"
        ? await getAllMyPatients()
        : await verify();
    try {
      if (!isResponsable) {
        setUser({ ...response });
      } else {
        setListPatients(response);
      }
    } catch (error) {}
  };
  const combineAllKeyValues = (obj, separator = " ") => {
    separator = separator || " ";
    obj.fullName = obj.firstName + separator + obj.lastName;
    obj.InversedfullName = obj.lastName + separator + obj.firstName;
    if (obj.docExpert)
      obj.doctor.fullName =
        obj.doctor.firstName + separator + obj.doctor.lastName;
    return obj;
  };
  const handleListFiltering = () => {
    const patientsObj = isResponsable
      ? listPatients.map((s) => combineAllKeyValues(s))
      : user?.patients.map((s) => combineAllKeyValues(s));
    const fuse = new Fuse(patientsObj, {
      threshold: 0,

      shouldSort: true,
      keys: ["lastName", "firstName", "fullName", "InversedfullName", "motifs"],
    });
    const newList = fuse.search(strFilter).map((item) => {
      return item.item;
    });
    setFilteredData(newList);
  };
  useEffect(() => {
    if (patientAdded) {
      fetchMyData();
      setPatientAdded(!patientAdded);
    }
  }, [patientAdded]);
  useEffect(() => {
    if (isResponsable) {
      fetchMyData();
    }
  }, []);
  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      let response: IPatient = await retrivePatient(selectedPatient, user?._id);
      const patient = response;
      if (!response) {
        makeToast("error", "Chargement de patient  à échouher.");
      }
      setPatientData({ ...patient });
    }

    if (selectedPatient) {
      fetchMyAPI();
      setModal(true);
      setPatientAdded(false);
      setUpdatedPatient(false);
    }
  }, [selectedPatient, updatedPatient, refreshPatientInfo?.state]);
  React.useEffect(() => {
    if (strFilter !== "") {
      handleListFiltering();
    }
  }, [strFilter, listPatients]);
  return (
    <>
      <div className="my-patients">
        <header className="flex justify-between items-center w-full">
          <div className="title-container">
            <h1 className="pageTitle">Mes patients</h1>
            {/* */}
          </div>
        </header>
        <div className="relative w-full flex items-center py-2">
          <div className=" flex w-1/2 flex-wrap items-stretch   ">
            <span className="z-10 h-full leading-snug font-normal  text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
              <IconSearch />
            </span>
            <Input
              type="text"
              placeholder="Rechercher patient"
              width="w-full"
              onChange={(e) => setStrFilter(e.target.value)}
              onKeyPress={(event) => {}}
              className="rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white  text-sm outline-none focus:border-blue-500  border-blue-100 w-full pl-10"
            />
          </div>
          <div className="absolute right-2  flex  space-x-2   ">
            <div className=" justify-center content-center ">
              <Button
                status="secondary"
                style={{ width: "182px", height: "50px" }}
                className="pl-2 btnAddPatient"
              >
                {" "}
                <Link
                  className="flex  text-indigo-600    text-sm  "
                  to={"./Formulaireconsentement.docx"}
                  target="_parent"
                  download="Formulaireconsentement.docx"
                >
                  <Download className="w-4 text-indigo-600 ml-6" />
                  <p className="ml-2"> Consentement</p>
                </Link>
              </Button>
            </div>
            <div className="relative">
              <Button
                status="secondary"
                onClick={() => setModalAddConfirm(true)}
                className="btnAddPatient text-sm  text-indigo-600 "
              >
                + Nouveau patient
              </Button>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center w-full mt-5"></div>
        <div className={`patient-list flex flex-wrap justify-between w-full`}>
          {user?.patients.length !== 0 || listPatients.length !== 0 ? (
            <Pagination
              data={
                isResponsable
                  ? strFilter === ""
                    ? listPatients
                    : filteredData
                  : strFilter === ""
                  ? user?.patients
                  : filteredData
              }
              RenderComponent={CardPatient}
              pageLimit={50}
              Pages={() => {
                if (user?.type === "medecinResponsable") {
                  return Math.ceil(
                    strFilter === ""
                      ? listPatients.length / 10
                      : filteredData.length / 10
                  );
                } else if (user?.patients.length > 0) {
                  return Math.ceil(
                    strFilter === ""
                      ? user?.patients.length / 10
                      : filteredData.length / 10
                  );
                } else {
                  return 0;
                }
              }}
              dataLimit={10}
              modal={modal}
              setModal={setModal}
              setSelectedPatient={setSelectedPatient}
              selectedPatient={selectedPatient}
              setPages={strFilter === ""}
            />
          ) : (
            <div className="w-full flexCenter mt-24">
              <h3>Aucun patient trouvé</h3>
            </div>
          )}
        </div>
      </div>

      {modalAdd && (
        <AddPatient
          openModal={modalAdd}
          setOpenModal={setModalAdd}
          setPatientAdded={setPatientAdded}
          setSelectedPatient={setSelectedPatient}
          setPatientData={setPatientData}
          setOpenModalInfoPatient={setModal}
          editMode={false}
          patientData={{
            _id: "",
            email: "",
            password: "",
            lastName: "",
            firstName: "",
            birthDate: "",
            birthday: "",
            sexe: [],
            gender: "",
            antecedents: [],
            phone: "",
            adresse: "",
            genez: "",
            numeroSecurite: "",
            medecin: "",
            codePostale: "",
          }}
        />
      )}
      {modal && (
        <PatientInfo
          setOpenModal={() => {
            setModal(false);
            setSelectedPatient("");
            setPatientData({} as any);
          }}
          setPatientAdded={setPatientAdded}
          setSelectedPatient={setSelectedPatient}
          setPatientData={setPatientData}
          setOpenModalInfoPatient={setModal}
          updatePatientData={setUpdatedPatient}
          patientData={patientData}
          colorIndex={colorIndex}
          openModal={modal}
        />
      )}

      {modalAddConfirm && (
        <ConfirmAddPatient
          openModal={modalAddConfirm}
          setOpenModal={setModalAddConfirm}
          setOpenModalAddPatient={setModalAdd}
        />
      )}
    </>
  );
};
export default MyPatients;
