import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { remove } from "lodash";
import { useDropzone } from "react-dropzone";
import Compressor from "compressorjs";
import { useAtom } from "jotai";

import {
  formConfigDermatose,
  formConfigTummerCutane,
} from "../../config/formConfig";
import {
  dispatchOrUpdateDraft,
  getAllAvailableExperts,
  loadImage,
} from "../../api/medecin";
import { getYupSchemaFromMetaData } from "./yupSchemaCreator";
import alert from "../../assets/images/alert.png";

import RecursiveContainer from "./RecursiveContainer";
import Button from "../ButtonUI";
import Spinner from "../Spinner";
import Select from "../Select";
import makeToast from "../Snackbar";

import { refreshAtomRequest } from "../../state/refreshRequestList";
import { userAtom } from "../../state/auth";

import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import _ from "lodash";
import { retriveGroup } from "../../api/groups";

const specialites = [{ label: "Dermatologie", value: "dermatologie" }];

const category = [
  { label: "Dermatose non tumorale", value: "Dermatose non tumorale" },
  { label: "Tumeurs cutanees", value: "Tumeurs cutanees" },
];
const parseImage = (specialite, userId, filePath, id) => ({
  original: loadImage(specialite, userId, "thumbnail", filePath),
  id: id,
});
const DynamicContainer: React.FC<any> = ({
  setOpenModal,
  gender,
  formConfig,
  props,
}) => {
  const [parsedImages, setParsedImages] = useState([]) as any[];
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [oldImgs, setOldImgs] = useState([]) as any[];
  const [refresh, setRefresh] = useAtom(refreshAtomRequest);
  const [filesX, setFilesx] = useState<any>([]);
  const [progress, setProgress] = useState(0);
  const [uploadToast, setUploadToast] = useState(false);
  const [teleexpertList, setTeleexpertList] = useState<any[]>([]);
  const [previousDoctor, setPreviousDoctor] = useState<any[]>([]);
  const [groupData, setGroupData] = useState("") as any;
  const [dispatch, setDispatch] = useState(true);
  const [withPics, setWithPics] = useState(
    props.files.length > 0 ? false : true
  );
  const [loadingCompressor, setLoadingCompressor] = useState(false);

  const format = ({ size }: Blob) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const calculate = (t: number) => {
    return (
      Math.round((performance.now() - t + Number.EPSILON) * 100) / 100 + "ms"
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/jpeg,image/jpg,image/webp",
    onDropAccepted: () => {
      console.log("compressorjs");
      setLoadingCompressor(true);
    },
    onDrop: (acceptedFiles) => {
      let pics: any[] = [];
      acceptedFiles.map((file, i) => {
        const t0 = performance.now();
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 2048,
          maxHeight: 2048,

          success(blob) {
            console.log("compressorjs", format(blob), calculate(t0));
            pics.push(
              Object.assign(blob, {
                preview: URL.createObjectURL(blob),
              })
            );
            setFilesx([...pics]);
            if (i + 1 === acceptedFiles.length) {
              console.log("compressorjs xx");
              setLoadingCompressor(false);
            }
          },
        });
      }) as any;
    },
  });
  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      const response = await getAllAvailableExperts({
        userId: user?._id,
        patientId: props.patientId._id,
      });
      setTeleexpertList(response?.filterListOfAvailableDoctor);
      setPreviousDoctor(
        response?.ListPreviousDoctors
          ? response?.ListPreviousDoctors.map((el) => ({
              ...el,
              label:
                el.lastName +
                " " +
                el.firstName +
                " " +
                "(" +
                "a précédemment traité une demande sur ce même patient" +
                ")",
              value: el._id,
            }))
          : []
      );
    }

    fetchMyAPI();
  }, []);
  async function retriveGroupMyAPI() {
    setLoading(true);
    const res = await retriveGroup(user?.groupId);
    setGroupData(res?.response);
    setLoading(false);
    return await res;
  }
  useEffect(() => {
    retriveGroupMyAPI();
  }, []);

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      const response = await getAllAvailableExperts({
        userId: user?._id,
        patientId: props.patientId._id,
      });
      setTeleexpertList(response?.filterListOfAvailableDoctor);
      const uniquePreviousDoctorIds: string[] = [];
      setPreviousDoctor(
        (response?.ListPreviousDoctors || [])
          .map((el) => {
            if (!uniquePreviousDoctorIds.includes(el._id)) {
              uniquePreviousDoctorIds.push(el._id);
              return {
                ...el,
                label:
                  el.lastName +
                  " " +
                  el.firstName +
                  " " +
                  "(" +
                  "a précédémment traité une demande sur ce même patient" +
                  ")",
                value: el._id,
              };
            }
            return null;
          })
          .filter(Boolean)
      );
      setLoading(false);
    }

    fetchMyAPI();
  }, []);

  useEffect(() => {
    const imgs = [] as any;
    props?.files?.map((img) => {
      imgs.push(
        parseImage(img.specialite, img.patientId, img.filePath, img._id)
      );
    });
    setParsedImages(imgs);
  }, [props.files]);
  useEffect(() => {
    setOldImgs(parsedImages.map((p) => p.id));
  }, [parsedImages]);

  const thumbs = filesX.map((file) => (
    <div className="thumb" key={file?.preview as any}>
      <div className="thumbInner">
        <span
          className="absolute icon-secondary2"
          onClick={() => {
            setFilesx(
              remove([...filesX], function (n) {
                return n.preview !== file.preview;
              })
            );
          }}
        >
          <Close />
        </span>
        <img src={file.preview} alt="img drop" className="img" />
      </div>
    </div>
  ));
  const intialVal: any = {};
  intialVal["demandeId"] = props.demandeId;
  intialVal["type"] = props.type;
  intialVal["withPhotos"] = withPics;
  intialVal["constId"] = "6203ded2169b5137f453363d";
  intialVal["status"] = props.status;
  intialVal["specialite"] = props.specialite;
  intialVal["patientId"] = props.patientId._id;
  intialVal["docReq"] = user?._id;
  intialVal["doctor_id"] = user?._id;
  intialVal["priseEncharge"] = props.priseEncharge;
  intialVal["docExpert"] = props.docExpert;

  formConfig.map((f) => {
    if (f.value && f.value !== "undefined") {
      let readyData = [];
      if (f.type === "only_Creatable" || f.type === "arrayWithCondition") {
        const arr =
          f.value && f.value !== "undefined" ? f.value.split(",") : [];
        readyData = arr.map((a) => {
          return a;
        });
        intialVal[`${f.field}`] = readyData;
      } else {
        intialVal[`${f.field}`] = f.value;
      }
    }
  });
  useEffect(
    () => () => {
      filesX.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [filesX]
  );

  const formik = useFormik({
    initialValues: intialVal,
    onSubmit: async (values) => {
      try {
        let formConfig = {};

        if (values.type === "Dermatose non tumorale") {
          formConfig = formConfigDermatose;
        } else if (values.type === "Tumeurs cutanees") {
          formConfig = formConfigTummerCutane;
        }
        console.log("values.files", values.files);
        console.log("values.dispatch", values.dispatch);
        console.log("values.oldImgs", values.oldImgs);

        values.files = filesX;

        values.dispatch = dispatch;

        values.oldImgs = oldImgs;

        setLoading(true);
        if (withPics) {
          setUploadToast(true);
        }

        const response = await dispatchOrUpdateDraft(
          values,
          formConfig,
          (event: { loaded: number; total: number }) => {
            setProgress(Math.round((100 * event.loaded) / event.total));

            return event.loaded;
          }
        );
        setOpenModal(false);

        if (response) {
          setRefresh({ state: !refresh?.state });
          makeToast("success", response?.message);
          setLoading(false);
        } else {
          setLoading(false);
          makeToast("error", response?.message);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        makeToast(
          "error",
          "Vous n'êtes pas autorisé à transmettre cette demande de téléexpertise! "
        );
        setOpenModal(false);
      }
    },

    validationSchema:
      dispatch &&
      getYupSchemaFromMetaData(
        props.type === "Dermatose non tumorale"
          ? formConfigDermatose
          : formConfigTummerCutane,
        [],
        []
      ),
  });

  return !uploadToast || filesX.length == 0 ? (
    <>
      <h1 className=" title flexCenter text-center">
        Brouillon de la demande{" "}
      </h1>
      <div className="flexCenter">
        {loading ? (
          <Spinner />
        ) : (
          <form
            className="w-3/4 max-w-lg mt-4 space-y-4"
            onSubmit={formik.handleSubmit}
          >
            <div className="flex flex-col w-full">
              Sélectionner la spécialité correspondant à votre demande :
              <Select
                value={formik.values.specialite}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                id="specialite"
                text={"Sélectionner  une spécialité"}
                className="relative mb-2 mt-2"
                options={specialites}
                required={true}
                style={{ cursor: "not-allowed" }}
                disabled
              ></Select>
              {formik.values.specialite === "dermatologie" && (
                <>
                  Sélectionner le type de lésion correspondant :
                  <Select
                    value={formik.values.type}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    id="type"
                    text={"Type de lésion"}
                    className="relative mb-2 mt-2"
                    options={category}
                    required={true}
                    style={{ cursor: "not-allowed" }}
                    disabled
                  ></Select>
                </>
              )}
            </div>

            <>
              <RecursiveContainer
                genre={gender}
                config={
                  props.type === "Dermatose non tumorale"
                    ? formConfigDermatose
                    : formConfigTummerCutane
                }
                formik={formik}
              />

              {parsedImages.length !== 0 ? (
                <div className="sub-documents images  block ">
                  <div className="flex">
                    <p className=" text-sm font-normal text-gray-700 ">
                      Images :{" "}
                    </p>
                  </div>
                  <div className="images flex flex-wrap min-w-full mt-2 gap-7">
                    <div className="images flex flex-wrap min-w-full mt-2 gap-7">
                      {parsedImages.map((i: any, k: number) => {
                        return (
                          i && (
                            <div className="relative  imagesContainer">
                              <span
                                className="absolute icon-secondary2"
                                onClick={() => {
                                  setParsedImages(
                                    remove(
                                      [...parsedImages],
                                      function (n: any) {
                                        return n.original !== i.original;
                                      }
                                    )
                                  );
                                }}
                              >
                                <Close />
                              </span>
                              <img
                                src={i?.original}
                                className="object-cover"
                                alt="document katomi"
                                width={"120px"}
                                height={"120px"}
                                onLoad={() => <Spinner />}
                              />
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flexCenter w-full anEmptyBox">
                  <p className="card-subtitle">
                    N'oublier pas de joindre les photos de votre patient afin de
                    bénéficier de l'avis médical de nos experts en{" "}
                    {props.specialite} .
                  </p>
                </div>
              )}
              <section
                style={{ cursor: "pointer" }}
                className="container addDocs"
              >
                <div
                  {...getRootProps({
                    className: "dropzone flexCenter",
                  })}
                >
                  <input {...getInputProps()} />
                  {loadingCompressor ? (
                    <p>Chargement d'images...</p>
                  ) : (
                    <p>
                      Glissez et déposez les images ou cliquez ici.<br></br>
                      <span className="text-sm text-gray-400">
                        Formats acceptés (.png , .jpg , jpeg , .webp)
                      </span>
                    </p>
                  )}
                </div>
                <aside className="thumbsContainer gap-4 mb-5">{thumbs}</aside>
              </section>
              {_.isEmpty(teleexpertList) ? (
                <>
                  <img
                    src={alert}
                    alt="alerte"
                    className="absolute top-35 left-8"
                    width="40"
                  />
                  <p
                    style={{
                      fontSize: "17px",
                      color: "#4565f6",
                      textAlign: "justify",
                    }}
                  >
                    {user?.groupId ? (
                      <>
                        {" "}
                        Aucun expert n'est disponible dans votre groupe, votre
                        demande sera transmise à un médecin expert de notre
                        réseau.
                      </>
                    ) : (
                      <>
                        {" "}
                        Aucun expert n'est disponible dans votre département,
                        votre demande sera transmise à un médecin expert de
                        notre réseau.
                      </>
                    )}
                  </p>
                </>
              ) : (
                <div className="flex flex-col w-full">
                  <Select
                    id="expertId"
                    value={formik?.values?.docExpert}
                    onChange={(selectedOption) => {
                      formik.setFieldValue(
                        "docExpert",
                        selectedOption.target.value
                      );
                    }}
                    //onChange={formik.handleChange}
                    text={"Sélectionner un téléexpert"}
                    className="relative mb-2 mt-2"
                    //disabled={_.isEmpty(teleexpertList)}
                    options={(_.isEmpty(previousDoctor)
                      ? teleexpertList?.map((e) => ({
                          value: e._id,
                          label:
                            e.lastName +
                            " " +
                            e.firstName +
                            " (" +
                            e.departement +
                            ")",
                        }))
                      : teleexpertList
                          ?.map((e) => ({
                            value: e._id,
                            label:
                              e.lastName +
                              " " +
                              e.firstName +
                              " (" +
                              e.departement +
                              ")",
                          }))
                          .concat(previousDoctor)
                    ).filter((option) =>
                      teleexpertList.some(
                        (doctor) => doctor._id === option.value
                      )
                    )}
                  ></Select>

                  {formik?.values?.docExpert ? null : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={alert} alt="alerte" width="40" />
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#4565f6",
                          textAlign: "justify",
                        }}
                      >
                        {user?.groupId ? (
                          groupData?.doctorId?.length === 1 ? (
                            <>
                              Aucun expert de votre département n'a été
                              sélectionné, votre demande sera transmise à un
                              médecin expert de notre réseau national.{" "}
                            </>
                          ) : (
                            <>
                              Aucun expert de votre groupe n'a été sélectionné,
                              votre demande sera transmise à un médecin expert
                              de notre réseau national.
                            </>
                          )
                        ) : (
                          <>
                            Aucun expert de votre département n'a été
                            sélectionné, votre demande sera transmise à un
                            médecin expert de notre réseau national.
                          </>
                        )}
                      </p>
                    </div>
                  )}
                </div>
              )}
              <div className="relative mt-2  ml-2 mr-2 ">
                <Button
                  status="primary"
                  className="float-right flexCenter mb-5"
                  width="200px"
                  height="50px"
                  onClick={() => {
                    setDispatch(true);
                  }}
                  disabled={loading || (dispatch && !!!formik.isValid)}
                >
                  {loading ? (
                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                  ) : (
                    "Envoi de la demande"
                  )}
                </Button>
                <Button
                  status="secondary"
                  className=" flexCenter mb-5"
                  width="200px"
                  height="50px"
                  type="submit"
                  disabled={loading}
                  onClick={() => {
                    setDispatch(false);
                  }}
                >
                  {loading ? (
                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                  ) : (
                    "Sauvegarder"
                  )}
                </Button>
              </div>
            </>
          </form>
        )}
      </div>
    </>
  ) : (
    <div className="w-full px-6  ">
      <div className="font-bold text-gray-700 ">
        Téléchargement d'images en cours...
      </div>{" "}
      <div className="w-full  bg-gray-300 rounded-full ">
        <div
          className={`${
            progress == 0 ? "" : "bg-Blue-Primary"
          } text-xs font-medium text-white text-center px-0.5 leading-none rounded-full`}
          style={{ width: `${progress}%` }}
        >
          {" "}
          {progress}%
        </div>
      </div>{" "}
    </div>
  );
};

export default DynamicContainer;
