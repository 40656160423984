import axios from "axios";
import { values } from "lodash";
import { DOCTOR_UPDATE_PROFILE_URL } from "../config/api";
import { get, update, post, remove, post2 } from "../helper/apiMethods";
import { KATOMI_API_ENDPOINT } from "../config/api";
import makeToast from "../components/Snackbar";

export const addOrModifyProfileInfo = async (values: any) => {
  try {
    const response = await update(DOCTOR_UPDATE_PROFILE_URL, { ...values });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDemandesByStatus = async (params1: any, params2: string) => {
  try {
    const res = await post("/demandes/getDemandeByStatus", {
      doctorId: params1,
      status: params2,
    });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDemandeByDoctors = async () => {
  try {
    const res = await get("/demandes/getDemandeByDoctors");
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDemandeByDoctorsPaginated = async (
  page,
  limit,
  status,
  filter
) => {
  try {
    const res = await get(
      `/demandes/getDemandeByDoctorsPaginated/?page=${page}&limit=${limit}&status=${status}&filter=${filter}`
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDemandeByChirugienPaginated = async (
  page,
  limit,
  priseEncharge,
  filter
) => {
  try {
    const res = await get(
      `/demandes/getDemandeByChirugienPaginated/?page=${page}&limit=${limit}&priseEncharge=${priseEncharge}&filter=${filter}`
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDemandeByTeleexpertPaginated = async (
  page,
  limit,
  status,
  filter
) => {
  try {
    const res = await get(
      `/demandes/getDemandeByTeleexpertPaginated/?page=${page}&limit=${limit}&status=${status}&filter=${filter}`
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDemandeByRequrentPaginated = async (
  page,
  limit,
  status,
  filter
) => {
  try {
    const res = await get(
      `/demandes/getDemandeByRequrentPaginated/?page=${page}&limit=${limit}&status=${status}&filter=${filter}`
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const addOrModifyDescription = async (values) => {
  try {
    const response = await update("/superAdmin/admin", { ...values });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const addOrModifyNumAdli = async (values) => {
  try {
    const response = await update("/superAdmin/admin", { ...values });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const loadImage = (specialite, patientId, quality, filename) =>
  `${KATOMI_API_ENDPOINT}/demandes/load/${specialite}/${patientId}/${quality}/${filename}`;
export const loadImageConverted = (specialite, patientId, quality, filename) =>
  `${KATOMI_API_ENDPOINT}/demandes/loadCovertImage/${specialite}/${patientId}/${quality}/${filename}`;

export const addOrModifySites = async (values) => {
  try {
    const response = await update("/superAdmin/admin", { ...values });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addOrModifyExpertise = async (values) => {
  try {
    const response = await update("/superAdmin/admin", { ...values });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const addOrModifyDepartement = async (values) => {
  try {
    const response = await update("/superAdmin/admin", { ...values });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addOrModifyLangues = async (values) => {
  try {
    const response = await update("/superAdmin/admin", { ...values });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addCursus = async (values) => {
  try {
    const response = await update("/superAdmin/admin", { ...values });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllDoctors = async () => {
  try {
    const res = await get("/superAdmin/admin/get-admins/");

    return res.data ? res.data : false;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDoctorsNotAffected = async () => {
  try {
    const res = await get("/superAdmin/admin/get-userswithoutgrp/");

    return res.data ? res.data : false;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retriveDoctor = async (patientId) => {
  try {
    const res = await get(`/superAdmin/admin/${patientId}`);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addDoctor = async (values) => {
  try {
    const res = await post("/superAdmin/admin/registerResponsable", values);
    if (!res) {
      makeToast("error", "Ce médecin existe déjà.");
    } else {
      makeToast("success", "Médecin créé avec succès.");
    }

    return res;
  } catch (error) {
    console.log("error_AddDoctor", error);
    return Promise.reject(error);
  }
};
export const inscriptionDoctor = async (values) => {
  try {
    const res = await post("/superAdmin/admin/register", values);
    if (!res) {
      makeToast("error", "Ce médecin existe déjà.");
    } else {
      makeToast("success", "Médecin créé avec succès.");
    }

    return res;
  } catch (error) {
    console.log("error_inscriptionDoctor", error);
    return Promise.reject(error);
  }
};

export const switchDoctor = async (payload) => {
  try {
    const response = await update(`/superAdmin/admin/${payload._id}`, {
      activated: !payload.activated,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const groupDoctor = async (payload) => {
  try {
    const response = await update(`/superAdmin/admin/${payload._id}`, {
      groupId: payload.groupId,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const freehDoctor = async (payload) => {
  try {
    const response = await update(`/superAdmin/admin/${payload._id}`, {
      freeAccount: !payload.freeAccount,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const modifyAdelliRpps = async (payload) => {
  try {
    const response = await update(`/superAdmin/admin/${payload.doctorId}`, {
      numRPPS: payload.numRPPS,
      numCom: payload.numCom,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteDoctor = async (payload) => {
  try {
    const response = await remove(`/users/${payload._id}`);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addPatient = async (values) => {
  try {
    const res = await post("/patients/addPatient", values);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExistPatient = async (values, id) => {
  try {
    const res = await post(`/patients/getExistPatient/${id}`, values);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDoctorPatients = async (id) => {
  try {
    const res = await get(`/patients/getPatientsByDoctor/${id}`);
    console.log("🚀 ~ file: medecin.ts:222 ~ getDoctorPatients ~ res:", res);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePatient = async (values, id) => {
  try {
    const res = update(`/patients/update/${id}`, values);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retrivePatient = async (patientId, doctorId) => {
  try {
    const res = await get(`/patients/${patientId}`);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const retriveDemande = (demandeId) => {
  try {
    const res = get(`demandes/retrive/${demandeId}`);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const retriveDemande2 = (demandeId) => {
  try {
    const res = get(`demandes/retrive/${demandeId}`);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const sendResponse = (demandeId, payload) => {
  try {
    const res = update(`demandes/response/${demandeId}`, payload);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const seenByExpert = (demandeId: any, docId: any) => {
  try {
    const res = update(`demandes/see/${demandeId}`, docId);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const seenByChirurgien = (values: any, demandeId: any) => {
  try {
    const res = update(`demandes/seenByChirurgien/${demandeId}`, values);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPatientConsta = async (values) => {
  try {
    const res = await post("/patients/consta", values);

    return res.data ? res.data : false;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifPatientConsta = async (values) => {
  try {
    const res = await post("/patients/verfiyConsta", values);

    return res ? res.data : { data: { err: "No" } };
  } catch (error) {
    return Promise.reject(error);
  }
};
export const changePasword = async (values) => {
  try {
    const res = await post("/superAdmin/admin/changePswd", values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllMyPatients = async () => {
  try {
    const res = await get("/patients/");
    return res.data ? res.data : false;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllExperts = async () => {
  try {
    const res = await get("/superAdmin/admin/medecins-experts");

    return res.data ? res.data : false;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllChirurgiens = async () => {
  try {
    const res = await get("/superAdmin/admin/medecins-chirurgiens");
    return res.data ? res.data : false;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setAgendaConfiguration = async (payload) => {
  try {
    const res = await post("superAdmin/admin/dispo", payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllAvailableExperts = async ({ userId, patientId }) => {
  try {
    const res = await get(
      `superAdmin/admin/list-experts/${userId}/${patientId}`
    );
    return res.data ? res.data : false;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAgendaConfig = async (userId) => {
  try {
    const res = await get(`superAdmin/admin/dispo/${userId}`);
    return res.dispoDoctor ? res.dispoDoctor : {};
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getGlobalAgendaConfig = async () => {
  try {
    const res = await get(`/dispos/`);
    return res.data ? res.data : {};
  } catch (error) {
    return Promise.reject(error);
  }
};
export const dispatchReq = async (payload) => {
  try {
    const res = await post(`demandes/dispatchReq`, payload);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const assignDemande = async (payload, demandeId) => {
  try {
    const res = await update(`demandes/assignDemande/${demandeId}`, payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const assignDemandeForce = async (payload, demandeId) => {
  try {
    const res = await update(
      `demandes/assignDemandeForce/${demandeId}`,
      payload
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/** Affectation des demandes par médecin réqurant*/
export const assignDemandeToExpert = async (payload) => {
  try {
    const res = await update("demandes/ForceAssignedDemande", payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addDemandByReqDoctor = async (values, formConfig) => {
  try {
    let formData = new FormData();

    const {
      constId,
      expertId,
      files,
      specialite,
      withPhotos,
      doctor_id,
      priseEncharge,
      patientId,
      forceIt,
      type,
      isDraft,
      withConsentement,
    } = values;

    files.map((f) => {
      formData.append("docs[]", f);
    });

    if (specialite === "dermatologie") {
      formData.append("constId", constId);
      formData.append("specialite", specialite);
      formData.append("forceIt", forceIt);
      formData.append("doctor_id", doctor_id);
      formData.append("patientId", patientId);
      formData.append("withPhotos", withPhotos);
      formData.append("isDraft", isDraft);
      formData.append("withConsentement", withConsentement);
      formData.append("type", type);
      formData.append("expertId", expertId);

      formConfig.map((conf) => {
        formData.append(`${conf.field}`, values[conf.field]);
      });
    }
    const res = await post("demandes/ForceAssignedDemande", formData);

    files.map(() => {
      formData.delete("docs[]");
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const assignDemandeToChirurgien = async (payload, demandeId) => {
  try {
    const res = await update(
      `demandes/assignToChirurgien/${demandeId}`,
      payload
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addDocsByPatient = async (values, formConfig) => {
  try {
    let formData = new FormData();

    const {
      constId,

      files,
      specialite,
      withPhotos,
      doctor_id,
      priseEncharge,
      patientId,
      type,
    } = values;

    files.map((f) => {
      formData.append("docs[]", f);
    });

    if (specialite === "dermatologie") {
      formData.append("constId", constId);
      formData.append("specialite", specialite);

      formData.append("doctor_id", doctor_id);
      formData.append("patientId", patientId);
      formData.append("withPhotos", withPhotos);

      formData.append("type", type);
      formConfig.map((conf) => {
        formData.append(`${conf.field}`, values[conf.field]);
      });
    }

    const res = await post("demandes/dispatchReq", formData);

    files.map(() => {
      formData.delete("docs[]");
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const addDocsByPatient2 = async (
  values,
  formConfig,
  onUploadProgress
) => {
  try {
    let formData = new FormData();

    const {
      constId,
      files,
      specialite,
      withPhotos,
      doctor_id,
      priseEncharge,
      patientId,
      forceIt,
      withConsentement,
      type,
      isDraft,
    } = values;

    files.map((f) => {
      formData.append("docs[]", f);
    });

    if (specialite === "dermatologie") {
      formData.append("constId", constId);
      formData.append("specialite", specialite);
      formData.append("forceIt", forceIt);
      formData.append("doctor_id", doctor_id);
      formData.append("patientId", patientId);
      formData.append("withPhotos", withPhotos);
      formData.append("isDraft", isDraft);
      formData.append("withConsentement", withConsentement);
      formData.append("type", type);
      formConfig.map((conf) => {
        formData.append(`${conf.field}`, values[conf.field]);
      });
    }
    const res = await post2("demandes/dispatchReq", formData, onUploadProgress);

    files.map(() => {
      formData.delete("docs[]");
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const saveAsDraft = async (values, formConfig, onUploadProgress) => {
  try {
    let formData = new FormData();

    const {
      constId,

      files,
      specialite,
      withPhotos,
      doctor_id,
      priseEncharge,
      patientId,
      type,
    } = values;

    files.map((f) => {
      formData.append("docs[]", f);
    });

    if (specialite === "dermatologie") {
      formData.append("constId", constId);
      formData.append("specialite", specialite);

      formData.append("doctor_id", doctor_id);
      formData.append("patientId", patientId);
      formData.append("withPhotos", withPhotos);
      formData.append("type", type);
      formConfig.map((conf) => {
        formData.append(`${conf.field}`, values[conf.field]);
      });
    }
    const res = await post2("demandes/dispatchReq", formData, onUploadProgress);

    files.map(() => {
      formData.delete("docs[]");
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const dispatchOrUpdateDraft = async (
  values,
  formConfig,
  onUploadProgress
) => {
  try {
    let formData = new FormData();
    let pics = "false";

    const {
      constId,
      files,
      specialite,
      withPhotos,
      doctor_id,
      priseEncharge,
      patientId,
      type,
      isDraft,
      dispatch,
      demandeId,
      docExpert,
      oldImgs,
    } = values;

    if (files && files.length > 0) {
      pics = "true";
    }

    files.map((f) => {
      formData.append("docs[]", f);
    });
    oldImgs.map((i, k) => formData.append(`oldImgs`, i));

    if (specialite === "dermatologie") {
      formData.append("constId", constId);
      formData.append("specialite", specialite);
      formData.append("dispatch", dispatch);
      formData.append("pics", pics);
      formData.append("demandeId", demandeId);
      formData.append("doctor_id", doctor_id);
      formData.append("patientId", patientId);
      formData.append("withPhotos", withPhotos);
      formData.append("docExpert", docExpert);
      formData.append("type", type);
      formConfig.map((conf) => {
        formData.append(`${conf.field}`, values[conf.field]);
      });
    }
    const res = await post2(
      "demandes/dispatchOrUpdateDraft",
      formData,
      onUploadProgress
    );

    files.map(() => {
      formData.delete("docs[]");
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addPhotos = async (values, onUploadProgress) => {
  try {
    const { files, demandeId, doctor_id } = values;
    let formData = new FormData();
    files.forEach((element) => {
      formData.append("docs[]", element);
    });
    formData.append("doctor_id", doctor_id);
    formData.append("demandeId", demandeId);

    const res = await post2(
      "demandes/addImagesFromWeb",
      formData,
      onUploadProgress
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addLeave = async (values) => {
  try {
    const res = await post("/leave/addLeave", values);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateLeave = async (values, id) => {
  try {
    const res = update(`/leave/update/${id}`, values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllLeaves = async () => {
  try {
    const res = await get("/leave/ListofLeaves");
    return res.data ? res.data : false;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllMyLeaves = async ({ doctorId }) => {
  try {
    const res = get(`/leave/getLeaves/${doctorId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteLeave = async (id) => {
  try {
    const response = await remove(`/leave/delete/${id}`);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
