import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import Fuse from "fuse.js";

import { getAllDoctors, retriveDoctor } from "../../api/medecin";
import { User } from "../../types/user";
import { userAtom } from "../../state/auth";

import Button from "../../components/ButtonUI";
import DoctorInfo from "../../components/SharedDoctorDetails";
import Spinner from "../../components/Spinner";
import makeToast from "../../components/Snackbar";
import Input from "../../components/Input";
import PaginationUsers from "../../components/paginationUsers";
import CardDoctor from "./CardDoctor";
import AddDoctor from "./addDoctor";
import ConfirmAddDoctor from "./ConfirmModalAddDoctor";
import FilterModal from "./modal/filterModal";

import "./styles.scss";
import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import { refreshAtomUsers } from "../../state/refreshUsersList";

const MyUsers = () => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useAtom(refreshAtomUsers);
  const [modalAdd, setModalAdd] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [modalAddConfirm, setModalAddConfirm] = useState(false);
  const [data, dataSet] = useState<any>(null);
  const [user] = useAtom(userAtom);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [modalFilter, setModalFilter] = useState(false);
  const [search, setSearch] = useState(false);
  const [doctorData, setDoctorData] = useState<User>();
  const [filerObject, setFilterObject] = useState<any>({
    patientCity: "",
    name: "",
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [doctorAdded, setDoctorAdded] = useState(false);
  const [colorIndex, setColorIndex] = useState(1);
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [strFilter, setStrFilter] = React.useState<string>("");

  const fetchMyData = async () => {
    setLoading(true);
    let response = await getAllDoctors();
    if (response) {
    } else {
      makeToast("error", "Chargement de la listes Medecins à échouher.");
    }
    dataSet(response ? response : []);
    if (search) {
      setTotalResult(
        data?.filter((iot) => iot.type === filerObject.userType).length
      );
    }
    setLoading(false);
  };
  const rolesLabels = {
    medecinResponsable: "Médecin Référent",
    medecinTeleexpert: "Médecin Expert",
    medecin: "Médecin Requérant",
    medecinChirurgien: "Médecin Chirurgien",
  };
  const combineAllKeyValues = (obj, separator = " ") => {
    separator = separator || " ";
    obj.fullName = obj.firstName + separator + obj.lastName;
    obj.InversedfullName = obj.lastName + separator + obj.firstName;
    if (obj.docExpert)
      obj.doctor.fullName =
        obj.doctor.firstName + separator + obj.doctor.lastName;
    return obj;
  };
  const handleListFiltering = () => {
    const usersObjs = data.map((s) => combineAllKeyValues(s));
    const fuse = new Fuse(usersObjs, {
      threshold: 0,

      shouldSort: true,
      keys: [
        "lastName",
        "firstName",
        "fullName",
        "InversedfullName",
        "codePostale",
      ],
    });
    const newList = fuse.search(strFilter).map((item) => {
      return item.item;
    });
    setFilteredData(newList);
  };
  React.useEffect(() => {
    if (strFilter !== "") {
      handleListFiltering();
    }
  }, [strFilter, data]);

  useEffect(() => {
    if (search || doctorAdded || updateList || page) {
      fetchMyData();
    }

    if (doctorAdded) {
      setDoctorAdded(false);
      makeToast("success", "Un nouvel utilisateur est ajouté avec succès.");
      setFilterObject({
        userType: "",
        name: "",
      });
      setModal(true);
    }
  }, [search, doctorAdded, page, updateList]);

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      let response: any = await retriveDoctor(selectedDoctor);
      const doctor = response;
      if (!response) {
        makeToast("error", "Chargement de médecin  à échouher.");
      }
      setDoctorData({ ...doctor });
    }
    if (selectedDoctor) {
      fetchMyAPI();
      setModal(true);
      setDoctorAdded(false);
    }
  }, [selectedDoctor]);

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  useEffect(() => {
    fetchMyData();
  }, [refresh?.state]);
  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="mx-16 my-patients">
        <header className="flex justify-between items-center w-full flex-wrap">
          <div className="title-container">
            <h1 className="pageTitle">Utilisateurs</h1>
            <h3 className="card-subtitle"></h3>
          </div>
          <div className="flex max-w-max items-center space-x-2 ">
            <div className="relative">
              <Button
                status="secondary"
                onClick={() => setModalAddConfirm(true)}
                className="btnAddPatient"
              >
                + Nouvel utilisateur
              </Button>
            </div>
          </div>
        </header>

        <div className="flex justify-between items-center w-full mt-5 filtres">
          <div className="relative flex w-full flex-wrap  mb-3 mx-6 mt-2 ">
            <span className="z-10 h-full leading-snug font-normal  text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
              <IconSearch />
            </span>
            <Input
              type="text"
              placeholder="Rechercher par : nom, prénom ou code postal "
              style={{ width: 361 }}
              onChange={(e) => setStrFilter(e.target.value)}
              onKeyPress={(event) => {}}
              className="rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white  text-sm outline-none focus:border-blue-500  border-blue-100 h-12 w-full pl-10"
            />
            <Button
              status="secondary"
              onClick={() => {
                setModalFilter(true);
                setSearch(false);
              }}
              className="mx-4 h-12"
              width={"146px"}
            >
              + Filtres
            </Button>
            <div className="flex">
              {filerObject &&
                Object.values(filerObject).length > 0 &&
                Object.values(filerObject)
                  .filter((el) => el !== null && el !== undefined && el !== "")
                  .map((el) => (
                    <span className="box mx-2 flexCenter">
                      {rolesLabels[`${el}`]}
                      <button
                        className="cros"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const key = getKeyByValue(filerObject, el) || "";
                          setFilterObject({
                            ...filerObject,
                            [key]: undefined,
                          });
                          setSearch(false);
                        }}
                      >
                        X
                      </button>
                    </span>
                  ))}
            </div>
          </div>

          {totalResult && search ? (
            <div>{totalResult} résultats trouvés</div>
          ) : (
            ""
          )}
        </div>

        <div className={`patient-list flex flex-wrap justify-between w-full"`}>
          {data && data.length !== 0 ? (
            <PaginationUsers
              data={
                search
                  ? data.filter((iot) => iot.type === filerObject.userType)
                  : strFilter === ""
                  ? data
                  : filteredData
              }
              RenderComponent={CardDoctor}
              pageLimit={50}
              dataLimit={10}
              modal={modal}
              setModal={setModal}
              setPages={strFilter === ""}
              setSelectedPatient={setSelectedDoctor}
              selectedPatient={selectedDoctor}
              Pages={() => {
                return Math.ceil(
                  strFilter === "" ? data.length / 10 : filteredData.length / 10
                );
              }}
              setColorIndex={setColorIndex}
            ></PaginationUsers>
          ) : (
            <div className="w-full flexCenter mt-24">
              <h3>Aucun médecin trouvé</h3>
            </div>
          )}
        </div>
        {modal && (
          <DoctorInfo
            openModal={modal}
            setOpenModal={() => {
              setModal(false);
              setSelectedDoctor("");

              setDoctorData({} as any);
            }}
            setUpdateList={setUpdateList}
            doctorData={doctorData}
            colorIndex={colorIndex}
          />
        )}
        {modalAdd && (
          <AddDoctor
            openModal={modalAdd}
            setOpenModal={setModalAdd}
            setDoctorAdded={setDoctorAdded}
            setSelectedDoctor={setSelectedDoctor}
            setDoctorData={setDoctorData}
            setOpenModalInfoDoctor={setModal}
          />
        )}
        {modalAddConfirm && (
          <ConfirmAddDoctor
            openModal={modalAddConfirm}
            setOpenModal={setModalAddConfirm}
            setOpenModalAddDoctor={setModalAdd}
          />
        )}

        {modalFilter && (
          <FilterModal
            openModal={modalFilter}
            setOpenModal={setModalFilter}
            filerObject={filerObject}
            setFilterObject={setFilterObject}
            search={search}
            setSearch={setSearch}
          />
        )}
      </div>
    </>
  );
};
export default MyUsers;
