import React from "react";
import Button from "../../components/ButtonUI";
import "./styles.scss";


interface Props {
  openModal: boolean;
  setOpenModal: Function;
  setOpenModalAddDoctor : Function;
}

export const ConfirmAddPatient: React.FC<Props> = ({ openModal, setOpenModal, setOpenModalAddDoctor }) => {

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute z-40 top-1/3 flexColCenter confirmAddPatient">
        <h2>Êtes-vous sûr de vouloir créer un nouveau compte utilisateur ?</h2>
        <div className="flexCenter mt-6 buttons">
            <Button status="secondary" width={"119px"} height={"50px"} onClick={()=>setOpenModal(!openModal)}>
                Annuler
            </Button>
            <Button status="secondary" width={"119px"} height={"50px"} onClick={()=>{
                setOpenModal(false)
                setOpenModalAddDoctor(true)
            }}>
                + Créer
            </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAddPatient;
