import { useState } from "react";
import PropTypes from "prop-types";

import moment from "../../helper/moment";
import { statusLabel } from "../../helper/demandeStatus";
import SharedRequest from "../SharedRequest";

import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";

const Task = ({
  _id,
  status,
  type,
  specialite,
  createdAt,
  motif,
  delaiDate,
  description,
  docExpert,
  files,
  interrogatoire,
  Reponse,
  patientId,
  docReq,
  priseEncharge,
}) => {
  const [modalAdd, setModalAdd] = useState(false);
  const [user] = useAtom(userAtom);

  const colorStatus = (status, delai) => {
    switch (status) {
      case "IN_PROGRESS":
        if (moment(delai).diff(moment(), "hours") > 0) {
          return "green";
        } else {
          return "red";
        }

      case "RESPONSE":
        return "green";
      default:
        if (moment(delai).diff(moment(), "hours") > 0) {
          return "";
        } else {
          return "red";
        }
    }
  };
  return (
    <div
      className="flex flex-none flex-col p-2 mt-4 mb-4 rounded-large bg-white overflow-hidden w-full"
      style={{ cursor: "pointer" }}
    >
      <span
        onClick={async () => {
          setModalAdd(true);
        }}
      >
        <div className="flex items-center">
          <span
            className={`rounded-full w-2 h-2  bg-${colorStatus(
              status,
              delaiDate
            )}-400`}
          ></span>
          <span className="ml-2 text-sm">
            {statusLabel(status, delaiDate, user?.type, priseEncharge)}
          </span>
        </div>
        {(user?.type === "medecin" ||
          user?.type === "medecinChirurgien" ||
          user?.type === "medecinTeleexpert") && (
          <>
            <div className="flex items-center">
              <span className={`rounded-full w-4 h-4 text-xs flex m-1 gap-x-1`}>
                {" "}
                <img
                  src={require(`../../assets/images/smileSick.png`).default}
                  alt="katomi"
                />
              </span>

              <p className="text-xs">
                {" "}
                {patientId?.lastName + " " + patientId?.firstName}
              </p>
            </div>
          </>
        )}

        {user?.type === "medecinResponsable" && (
          <>
            <div className="flex items-center">
              <span className={`rounded-full w-4 h-4 text-xs flex m-1 gap-x-1`}>
                {docExpert ? (
                  <>
                    <img
                      src={
                        require(`../../assets/images/securite-sociale.png`)
                          .default
                      }
                      alt="katomi"
                    />
                    <p className="font-bold">Expert</p>{" "}
                    <p style={{ flex: "none" }}> Dr.{docExpert?.lastName}</p>
                  </>
                ) : (
                  ""
                )}
              </span>
            </div>
            <div className="flex items-center">
              <span className={`rounded-full w-4 h-4 text-xs flex m-1 gap-x-1`}>
                {" "}
                <img
                  src={require(`../../assets/images/steto.png`).default}
                  alt="katomi"
                />
                <p className="font-bold">Demandeur</p>
                <p style={{ flex: "none" }}> Dr.{docReq?.lastName}</p>
              </span>
            </div>
            <div className="flex items-center">
              <span className={`rounded-full w-4 h-4 text-xs flex m-1 gap-x-1`}>
                {" "}
                <img
                  src={require(`../../assets/images/smileSick.png`).default}
                  alt="katomi"
                />
                <p className="font-bold"> Patient</p>
                <p> {patientId?.lastName}</p>
              </span>
            </div>
          </>
        )}

        {(user?.type === "medecinTeleexpert" ||
          user?.type === "medecinChirurgien") && (
          <>
            <div className="flex items-center">
              <span className={`rounded-full  h-5 text-xs flex  gap-x-1`}>
                {" "}
                <img
                  src={require(`../../assets/images/steto.png`).default}
                  alt="katomi"
                />
                <p className="font-bold ">Demandeur</p>
                <p style={{ flex: "none" }}>
                  {" "}
                  Dr.{docReq?.lastName + " " + docReq?.firstName}
                </p>
              </span>
            </div>
          </>
        )}

        <div className="mt-2">
          <p className="text-sm"></p>
          <div className="h-1 relative max-w-xl rounded-full overflow-hidden mt-4">
            <div className="w-full h-full bg-gray-200 absolute"></div>
            <div
              style={{
                width: `${
                  (moment(delaiDate).diff(moment(), "hours") / 48) * 100
                }%`,
              }}
              className={`h-full rounded-full absolute  bg-green-400`}
            ></div>
          </div>
        </div>

        <div className="flex mt-3 justify-between">
          {
            <>
              <span className="flex text-xs text-gray-400 items-center">
                {moment(delaiDate).diff(moment(), "hours") > 0 ? (
                  status !== "RESPONSE" && status !== "WAIT_FOR_IMGS" ? (
                    <p>
                      {moment(delaiDate).diff(moment(), "hours") - 24 >= 0
                        ? moment(delaiDate).diff(moment(), "hours") -
                          24 +
                          " heures avant rappel"
                        : `${moment(delaiDate).diff(
                            moment(),
                            "hours"
                          )} heures avant expiration`}
                    </p>
                  ) : (
                    <p>{moment(createdAt).format("DD MMMM YYYY à HH:mm")}</p>
                  )
                ) : (
                  <p>{moment(createdAt).format("DD MMMM YYYY à HH:mm")}</p>
                )}
                {/* <Icon className="w-6 h-6" isIcon={true} name={"reglage-haut_droite"} /> */}
              </span>
              <span className="flex-warp text-xs text-gray-400 items-center boxType flexColCenter ">
                <p>{/* Demande d'avis en <strong> {specialite}</strong> */}</p>{" "}
                <p className="mx-1">
                  <strong> Motif : </strong>
                  {type}
                </p>
                {/* <Icon className="w-6 h-6" isIcon={true} name={"reglage-haut_droite"} /> */}
              </span>
            </>
          }
        </div>
      </span>
      {modalAdd && (
        <SharedRequest
          colorIndex={1}
          demandeData={{
            _id,
            type,
            specialite,
            createdAt,
            status,
            motif,
            delaiDate,
            description,
            docExpert,
            patientId,
            docReq,
            files,
            interrogatoire,
            Reponse,
          }}
          consultaionPage={false}
          setOpenModal={setModalAdd}
        />
      )}
    </div>
  );
};

Task.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  users: PropTypes.array,
  progress: PropTypes.number,
};
export default Task;
