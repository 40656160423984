import React, { useEffect, useState } from "react";
import Button from "../../../../components/ButtonUI";
import moment from "../../../../helper/moment";
import Icon from "../../../../components/Icon";


interface Props {}

const Antecedents = ({ patientData }) => {
  const [typeObservation, setTypeObservation] = useState({
    typeObservation: "",
    idObservation: "",
  });
  const [observationModal, setObservationModal] = useState(false);
  const [showObservationsModal, setShowObservationsModal] = useState(false);
  const [selectedObservationId, setSelectedObservationId] = useState("");
  const [typeOfAddObservation, setTypeOfAddObservation] = useState("");
  const [antecedentsList, setAntecedentsList] = useState([] as any);
  const [antecedentsChrirugicalList, setAntecedentsChrirugicalList] = useState(
    [] as any
  );

  useEffect(() => {
    setAntecedentsList(patientData.antecedents);
    setAntecedentsChrirugicalList(patientData.antecedentsChirugical);
  }, []);

  return (
    <div className="share-folder-antecedent">
      <div className="flex justify-between title-taches">
        <div className="sub-title mb-2">
          <h3>Antécédents médicaux</h3>
        </div>
      </div>
      <div className="flex flex-wrap gap-5">
        {antecedentsList && antecedentsList.length > 0 ? (
          antecedentsList.map((med) => (
            <div className="card-antecedent">
              <div className="flex justify-between">
                <div>
                  <p className="card-title">{med.nature}</p>
                  <span className="block">{med.type}</span>
                </div>

                <Icon name="troisPoints" isIcon={true} />
              </div>
              <p className="subTitle">{med.subTitle}</p>
              <p className="card-subtitle">
                Mise à jour le{" "}
                <span className="font-bold">
                  {moment(med.date).format("DD MMMM YYYY à HH:mm")}
                </span>
              </p>
              <div className="flex gap-2 my-2">
                {med["traitements"] &&
                  med["traitements"].map((medic) => (
                    <div className="box max-w-max flex items-center space-x-2">
                      <Icon
                        name="capsule"
                        isIcon={false}
                        width={14}
                        height={16}
                      />
                      <p>{medic.traitement}</p>
                    </div>
                  ))}
              </div>
              {med.to ? (
                <p className="addObservation menu flexCenter">
                  {"+  Ajouter une observation"}
                </p>
              ) : (
                <div className="flexCenter">
                  <div
                    className="menu addObservation cursor-pointer blue-text"
                    onClick={() => {
                      setTypeObservation({
                        typeObservation: "antecedents",
                        idObservation: med._id,
                      });
                      setShowObservationsModal(true);
                    }}
                  >
                    {med.observationsDoctor.length} observations
                  </div>
                  <div
                    className="menu ml-6 addObservation cursor-pointer blue-text"
                    onClick={() => {
                      setSelectedObservationId(med._id);
                      setObservationModal(true);
                      setTypeOfAddObservation("antecedents");
                    }}
                  >
                    Ajouter
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">
              Ce patient n'as aucun ancien antécédents médicaux.
            </p>
          </div>
        )}
      </div>
      <div className="flex justify-between mt-5">
        <div className="sub-title">
          <h3>Antécédents Chirurgicaux</h3>
        </div>
      </div>
      <div className="flex flex-wrap gap-5 mt-3">
        {antecedentsChrirugicalList && antecedentsChrirugicalList.length > 0 ? (
          antecedentsChrirugicalList.map((med) => (
            <div className="card-antecedent">
              <div className="flex justify-between">
                <div>
                  <p className="card-title">{med.nature}</p>
                  <span className="block">{med.type}</span>
                </div>

                <Icon name="troisPoints" isIcon={true} />
              </div>
              <p className="subTitle">{med.subTitle}</p>
              <p className="card-subtitle">
                Mise à jour le{" "}
                <span className="font-bold">
                  {moment(med.date).format("DD MMMM YYYY à HH:mm")}
                </span>
              </p>
              {med.to ? (
                <p className="addObservation menu flexCenter blue-text">
                  {"+  Ajouter une observation"}
                </p>
              ) : (
                <div className="flexCenter">
                  <div
                    className="menu addObservation cursor-pointer blue-text"
                    onClick={() => {
                      setTypeObservation({
                        typeObservation: "antecedentChirurgicaux",
                        idObservation: med._id,
                      });
                      setShowObservationsModal(true);
                    }}
                  >
                    {med.observationsDoctor.length} observations
                  </div>
                  <div
                    className="menu ml-6 addObservation cursor-pointer blue-text"
                    onClick={() => {
                      setSelectedObservationId(med._id);
                      setObservationModal(true);
                      setTypeOfAddObservation("antecedentsChirugical");
                    }}
                  >
                    Ajouter
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="flexCenter w-full anEmptyBox">
            <p className="card-subtitle">
              Ce patient n'as aucun ancien antécédents chirurgicaux.
            </p>
          </div>
        )}
      </div>

    </div>
  );
};

export default Antecedents;
