import {
  Document,
  Font,
  Page,
  Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import axios from "axios";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { loadImageConverted } from "../../api/medecin";

export interface DocumentProps {
  data: any;
}

export const styles = StyleSheet.create({
  font: { fontFamily: "Oswald" },
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 13,
    margin: 2,
    fontFamily: "Oswald",
    marginBottom: 4,
  },
  textsubTitle: {
    fontSize: 10,
    margin: 2,
    fontFamily: "Oswald",
  },
  textsubTitleInt: {
    fontSize: 9,
    margin: 2,
    fontFamily: "Oswald",
  },
  text: {
    margin: 2,
    fontSize: 11,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text2: {
    margin: 2,
    fontSize: 11,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    color: "red",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  imageX: {
    border: "1px bold black ",
    margin: 10,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  header2: {
    fontSize: 14,
    margin: 20,
    fontStyle: "italic",
    fontWeight: "bold",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
const fontSrc =
  "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf";
Font.register({ family: "Oswald", src: fontSrc });
Font.register({ family: "ubuntu, sans-serif", src: fontSrc });
const parseImage = (specialite, userId, filePath) => ({
  original: loadImageConverted(specialite, userId, "preview", filePath),
});

const TestDocument: React.FC<DocumentProps> = ({ data }) => {
  const [parsedImages, setParsedImages] = useState([]);

  const propertyNames: [string, string][] = Object.entries(
    data.interrogatoire.other
  );
  useEffect(() => {
    if (data.files.length > 0) {
      const imgs = [] as any;
      data.files.map((img) => {
        imgs.push(parseImage(img.specialite, img.patientId, img.filePath));
      });
      setParsedImages(imgs);
    }
  }, []);

  function splitString(stringToSplit, separator) {
    var arrayOfStrings = stringToSplit.split(separator);

    return arrayOfStrings;
  }
  const dataToMap = () => {
    const data = propertyNames.map((e) => {
      if (e[1] === "undefined") {
        return { label: e[0], value: "Inconnue" };
      }
      if (e[1].indexOf(",") > 0)
        return { label: e[0], value: splitString(e[1], ",") };
      else return { label: e[0], value: e[1] };
    });

    return data;
  };

  if (data) {
    return (
      <Document>
        <Page style={styles.body}>
          <Text style={styles.header} fixed>
            Extrait depuis la plateforme AvisDoc
          </Text>
          <Text style={styles.title}>Demande de téléexpertise</Text>
          <Text style={styles.author}>Id : {data._id} </Text>
          <Text style={styles.subtitle}>Spécialité : {data.specialite}</Text>
          <Text style={styles.subtitle}>Motif : {data.type}</Text>
          <Text style={styles.subtitle}>
            Date de création : {moment(data.createdAt).format("L")}
          </Text>
          <Text style={styles.subtitle}>
            Nombre des Images : {data.files.length}
          </Text>
          <Text style={styles.subtitle}>Résultat de l'interrogatoire :</Text>
          {dataToMap().map((e) => {
            return (
              <>
                <Text style={styles.textsubTitleInt}>
                  {`${
                    e.label +
                    " : " +
                    (Array.isArray(e.value)
                      ? e.value.map((el) => el + " , ")
                      : e.value)
                  }`}{" "}
                </Text>
              </>
            );
          })}
          <Text style={styles.subtitle}>
            Réponse et recommandations du médecin téléexpert :{" "}
          </Text>
          {data.Reponse.urgence && (
            <Text style={styles.text2}>
              {" "}
              Cette demande nécessite une intervention urgente .
            </Text>
          )}
          <Text style={styles.textsubTitle}> Avis : </Text>
          <Text style={styles.text}>
            {" "}
            {data.Reponse.response
              ? ReactHtmlParser(data.Reponse.response)
              : ReactHtmlParser(data.Reponse)}
          </Text>
          <Text style={styles.textsubTitle}> Recommandation : </Text>
          <Text style={styles.text}>
            {" "}
            {data.Reponse.recommendation
              ? data.Reponse.recommendation.c +
                " " +
                (data.Reponse.recommendation.nbrJrs.length > 0
                  ? "dans un délai de " +
                    data.Reponse.recommendation.nbrJrs +
                    " jours."
                  : "")
              : ""}
          </Text>
          <Text> </Text>
          <Text> </Text>
          <Text> </Text>
          <Text style={styles.header2}>Informations patient : </Text>
          <Text style={styles.subtitle}>
            Numéro de sécurité sociale du patient :{" "}
            {data.patientId.numeroSecurite}
          </Text>
          <Text style={styles.subtitle}>
            Organisme Payeur:{" "}
            {data.patientId.organismeSecuSociale
              ? data.patientId.organismeSecuSociale
              : "---"}
          </Text>
          <Text style={styles.subtitle}>
            Nom et Prénom :{" "}
            {data.patientId.lastName + " " + data.patientId.firstName}
          </Text>
          <Text style={styles.subtitle}>
            Genre : {data.patientId.gender === "male" ? "Homme" : "Femme"}
          </Text>
          <Text style={styles.subtitle}>
            Numéro de téléphone : {data.patientId.phone}
          </Text>
          <Text style={styles.subtitle}>
            Adresse postale :{" "}
            {data.patientId.adresse + " " + data.patientId.codePostale}
          </Text>
          <Text style={styles.subtitle}>
            Date de naissance : {moment(data.patientId.birthday).format("L")}
          </Text>
          <Text style={styles.header2}>Docteur demandeur</Text>
          <Text style={styles.subtitle}>
            Docteur demandeur : Dr{" "}
            {data.docReq.lastName + " " + data.docReq.firstName}
          </Text>
          <Text style={styles.subtitle}>
            Contacts du docteur demandeur :{" "}
            {data.docReq.phone + " --- " + data.docReq.email}
          </Text>
          <Text style={styles.subtitle}>
            N° ADELI: {data.docReq.numCom ? data.docReq.numCom : "N/A"}
          </Text>
          <Text style={styles.subtitle}>
            N° RPPS : {data.docReq.numRPPS ? data.docReq.numRPPS : "N/A"}
          </Text>
          <Text style={styles.header2}>Docteur expert</Text>
          <Text style={styles.subtitle}>
            Docteur expert : Dr{" "}
            {data?.docExpert?.lastName + " " + data?.docExpert?.firstName}
          </Text>
          <Text style={styles.subtitle}>
            Contacts du docteur expert :{" "}
            {data?.docExpert?.phone + " --- " + data?.docExpert?.email}
          </Text>
          <Text style={styles.subtitle}>
            N° ADELI :{" "}
            {data?.docExpert?.numCom ? data?.docExpert?.numCom : "N/A"}
          </Text>
          <Text style={styles.subtitle}>
            N° RPPS :{" "}
            {data?.docExpert?.numRPPS ? data?.docExpert?.numRPPS : "N/A"}
          </Text>
        </Page>
        <Page wrap>
          <Text style={styles.header2}>Photos de la lésion</Text>
          {parsedImages.map((i: any, k: number) => {
            return (
              <Image
                style={styles.imageX}
                src={{
                  uri: i?.original,
                  method: "GET",
                  headers: {},
                  body: "",
                }}
              />
            );
          })}
        </Page>
      </Document>
    );
  } else
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>Le Pdf n'a pas pu être généré</Text>
          </View>
        </Page>
      </Document>
    );
};

export default TestDocument;
