import { useState } from "react";
import { Formik } from "formik";

import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import Modal from "../../components/ModalUI";
import makeToast from "../../components/Snackbar";

import history from "../../history";
import validationSchema from "./validation";
import { forgetPassword } from "../../api/auth";

import "./styles.scss";
import logo from "../../assets/Logo.png";

const ForgetPassword: React.FC = () => {
  const [openModal, setopenModal] = useState(false);

  return (
    <div className="login min-h-screen flex justify-center forgetPassword">
      <div className="auth-login-Container mt-4">
        <h1 className="h1 title flexRowCenter">Mot de passe oublié</h1>
        <div className="formLogin">
          <div className="">
            <Formik
              initialValues={{ email: "" }}
              onSubmit={async (values) => {
                const res = await forgetPassword(values);
                if (res) {
                  switch (res) {
                    case "DISABLED":
                      setopenModal(true);
                      break;
                    case "OK":
                      makeToast(
                        "success",
                        "Votre mot de passe a été bien réinitialisé. Vérifiez votre e-mail!"
                      );
                      history.push("/login");
                      break;
                    case "ERROR":
                      makeToast(
                        "error",
                        "La réinitialisation du mot de passe a échoué!"
                      );
                      break;
                  }
                } else {
                  makeToast(
                    "error",
                    "La réinitialisation du mot de passe a échoué!"
                  );
                }
              }}
              validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;

                return (
                  <>
                    <div className="logo-bloc flexRowCenter">
                      <img src={logo} alt="logo" />
                    </div>
                    <br />
                    <form onSubmit={handleSubmit} className="form-login">
                      <Input
                        id="email"
                        placeholder="email"
                        labelText="email"
                        type="text"
                        error={errors.email}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inlineLabel={false}
                        onfocus={true}
                      />

                      <div className="flexRowCenter">
                        <Button
                          type="submit"
                          disabled={
                            isSubmitting || Object.keys(errors).length > 0
                          }
                          status="primary"
                          className="btn"
                        >
                          Envoyer
                        </Button>
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="create-Account flexColCenter">
          <p
            className="create cursor-pointer"
            onClick={() => history.push("/login")}
          >
            Se connecter{}
          </p>
        </div>
      </div>
      <Modal
        showModal={openModal}
        slidemodal={false}
        closeModal={() => setopenModal(false)}
      >
        <div className="formLogin p-2 text-center ">
          <>
            <span className="item block   text-lg  py-2  overflow-y-auto  h-auto">
              Votre compte est désactivé, veuillez contacter l'administrateur de
              la plateforme via contact@avisdoc.co
            </span>
          </>
        </div>
      </Modal>
    </div>
  );
};

export default ForgetPassword;
