import React from "react";
import Button from "../../../components/ButtonUI";
import "./styles.scss";
import departementSchema from "./schemas/presentationSchema";
import { addOrModifyDepartement } from "../../../api/medecin";
import { Formik } from "formik";
import { User } from "../../../types/user";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import Input from "../../../components/Input";
import makeToast from "../../../components/Snackbar";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import ListDepartement from "../../../config/constants/departement.json";
import SelectComp from "../../../components/Select";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
}

export const AddDepartement: React.FC<Props> = ({
  openModal,
  setOpenModal,
}) => {
  const [user, setUser] = useAtom(userAtom);

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd w-1/2">
        <div className="relative ">
          <div className="absolute right-0 mt-2 mr-2">
            <Button status="icon-secondary" onClick={() => setOpenModal(false)}>
              <Close />
            </Button>
          </div>
        </div>
        <Formik
          initialValues={{
            departement: "" || user?.departement,
          }}
          onSubmit={async (values) => {
            try {
              setOpenModal(!openModal);
              const newUser = await addOrModifyDepartement({ ...values });
              const { departement } = newUser;
              setUser({
                ...user,
                departement: values.departement,
              } as User);
              if (newUser.id) {
                makeToast("success", "Département ajouté avec succès.");
              } else {
                makeToast("error", "Département à échouher.");
              }
            } catch (err) {
              console.log(err);
            }
          }}
          validationSchema={departementSchema}
        >
          {(props) => {
            const {
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;

            return (
              <form
                onSubmit={handleSubmit}
                className="flex-auto py-7 px-8 min-w-full	"
              >
                <div className="flex flex-wrap items-baseline">
                  <h1 className="mb-10">Sélectionner votre département</h1>
                </div>
                <div className="">
                  <SelectComp
                    id="departement"
                    value={values.departement}
                    options={ListDepartement}
                    onChange={(e) =>
                      setFieldValue("departement", e.target.value)
                    }
                    onBlur={() => handleBlur}
                    text="Saisissez votre département"
                    className="w-full mb-8"
                  />
                  {/* <Input
                    id="departement"
                    placeholder="Ecrivez votre département ..."
                    value={values.departement}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                    className="w-full mb-8"
                  /> */}
                </div>
                <div className="flexCenterBtn">
                  <Button
                    status="secondary"
                    width="100px"
                    height="50px"
                    className=" mb-4 btn-delete"
                    onClick={() => setOpenModal(false)}
                  >
                    Annuler
                  </Button>
                  <Button
                    status="secondary"
                    width="100px"
                    height="50px"
                    className=" mb-4"
                  >
                    Valider
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddDepartement;
