import { FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/FloatInput";
import "./styles.scss";
import cursusSchema from "./schemas/cursusSchema";
import { IStudies } from "../../../types/user";
import { addCursus } from "../../../api/medecin";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import makeToast from "../../../components/Snackbar";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId: string;
}

const emptyCursus = { diplome: "", university: "", year: "" };

const AddCursus: React.FC<Props> = ({ openModal, setOpenModal, doctorId }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useAtom(userAtom);
  const [newUserCursus, setNewUserCursus] = useState([]);
  useEffect(() => {
    if (user) {
      const cursusDoctor = user?.studiesHistory;
      const newArray = cursusDoctor.map((cd) => {
        return {
          diplome: cd.diplome,
          university: cd.university,
          year: cd.year,
        };
      });
      setNewUserCursus(newArray as any);
    }
    setLoading(false);
  }, [user]);

  return loading ? (
    <h1>Loading </h1>
  ) : (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute bg-white z-40 top-1/3 flexCente cursusAdd">
        <Formik
          initialValues={{
            cursus: newUserCursus.length !== 0 ? newUserCursus : [emptyCursus],
          }}
          onSubmit={async (values) => {
            try {
              setOpenModal(!openModal);
              let fixArr = {studiesHistory : values.cursus}
              const newUser = await addCursus({ ...fixArr});
              setUser({ ...user, ...newUser });
              if (newUser.id) {
                makeToast(
                  "success",
                  "Cursus universitaire ajoutée avec succès."
                );
              } else {
                makeToast("error", "Ajout cursus universitaire à échouher.");
              }
            } catch (err) {
              console.log("[error] add Patient -on Submit :", err);
            }
          }}
          validationSchema={cursusSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;

            return (
              <form
                onSubmit={handleSubmit}
                className="flex-auto py-7 px-8 min-w-full	"
              >
                <div className="flex flex-wrap items-baseline">
                  <h1 className="mb-10">Cursus universitaire</h1>
                </div>
                <div className="flex w-full">
                  <FieldArray name="cursus">
                    {({ push, remove }) => (
                      <div>
                        {values.cursus.map((diploma: IStudies, index) => (
                          <div className="flex form-cursus">
                            <Input
                              id={`cursus.${index}.diplome`}
                              placeholder="Nom du diplôme"
                              labelText="Nom du diplôme"
                              type="text"
                              value={values.cursus[index].diplome}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inlineLabel={false}
                              onfocus={true}
                            />

                            <Input
                              id={`cursus.${index}.university`}
                              placeholder="Nom de l'université"
                              labelText="Nom de l'université"
                              type="text"
                              value={values.cursus[index].university}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inlineLabel={false}
                              onfocus={true}
                            />
                            <Input
                              id={`cursus.${index}.year`}
                              placeholder="Année universitaire"
                              labelText="Année universitaire"
                              type="text"
                              value={values.cursus[index].year}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inlineLabel={false}
                              onfocus={true}
                            />
                            <div className="buttons flexCenter">
                              <Button
                                disabled={isSubmitting}
                                onClick={() => remove(index)}
                                status="secondary"
                              >
                                -
                              </Button>
                              <Button
                                disabled={isSubmitting}
                                onClick={() => push(emptyCursus)}
                                status="secondary"
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                </div>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-left mb-4 btn-delete"
                  onClick={() => setOpenModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-right mb-4"
                >
                  Valider
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddCursus;
