import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Button from "../ButtonUI";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import { useDropzone } from "react-dropzone";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import { remove } from "lodash";
import makeToast from "../Snackbar";
import { addPhotos } from "../../api/medecin";
import { refreshAtom } from "../../state/refreshPatientInfo";
import { refreshAtomRequest } from "../../state/refreshRequestList";
import Compressor from "compressorjs";

const UploadDemandeImages: React.FC<any> = ({ setDemandeInfo, demandeId }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [refresh, setRefresh] = useAtom(refreshAtom);
  const [progress, setProgress] = useState(0);
  const [uploadToast, setUploadToast] = useState(false);
  const [refreshRequestList, setRefreshRequestList] =
    useAtom(refreshAtomRequest);
  const [files, setFiles] = useState<any>([]);


  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/jpeg,image/jpg,image/webp",
    onDrop: (acceptedFiles) => {
      let pics: any[] = [];
      acceptedFiles.map((file) => {
        // const t0 = performance.now();
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 2048,
          maxHeight: 2048,

          success(blob) {
            pics.push(
              Object.assign(blob, {
                preview: URL.createObjectURL(blob),
              })
            );
            setFiles([...pics]);
          },
        });
      }) as any;
    },
  });

  const thumbs = files.map((file) => (
    <div className="thumb" key={file?.preview as any}>
      <div className="thumbInner">
        <button
          className="absolute icon-secondary2"
          onClick={() => {
            setFiles(
              remove([...files], function (n) {
                return n.preview !== file.preview;
              })
            );
          }}
        >
          <Close />
        </button>
        <img src={file.preview} alt="img drop" className="img" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const formik = useFormik({
    initialValues: {
      demandeId: demandeId,
      doctor_id: user?._id,
      files: files,
    },
    onSubmit: async (values) => {
      try {
        values.files = files;
        setLoading(true);
        setUploadToast(true);
        const response = await addPhotos(values,(event: { loaded: number; total: number }) => {
          setProgress(Math.round((100 * event.loaded) / event.total));

          return event.loaded;
        });
        if (response.status === "Success") {
          setLoading(false);
          setUploadToast(false);
          setDemandeInfo(response.data.updatedDemande);
          setRefresh({ state: !refresh?.state });
          setRefreshRequestList({ state: !refreshRequestList?.state });

          makeToast("success", response.message);
        } else {
          setLoading(false);
          setUploadToast(false);
          makeToast("error", response.message);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        setUploadToast(false);
        makeToast("error", "Vous etes pas autorisé de passer cette demande ");
      }
    },
  });

  return !uploadToast ? (
    <form
      className="w-3/4  mb-8 max-w-lg mt-4 space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <>
        <section className="addDocs" style={{ cursor: "pointer" }}>
          <div
            {...getRootProps({
              className: "dropzone flexCenter",
            })}
          >
            <input {...getInputProps()} />
            <p>
              Glissez et déposez les images ou cliquez ici.<br></br>
              <span className="text-sm text-gray-400">
                Formats acceptés (.png , .jpg , jpeg , .webp)
              </span>
            </p>
          </div>
          <aside className="thumbsContainer gap-4 mb-4">{thumbs} </aside>{" "}
          {files.length > 0 && (
            <aside>
              <Button
                status="primary"
                className="float-right flexCenter mb-5"
                width="200px"
                height="50px"
                type="submit"
                disabled={!formik.isValid || files.length == 0}
              >
                {!formik.isValid || files.length == 0 || loading ? (
                  <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500"></div>
                ) : (
                  ` Joindre ${files.length} photos`
                )}
              </Button>
            </aside>
          )}
        </section>
      </>
    </form>
  ):(
    <div className="w-full px-6  ">
    <div className="font-bold text-gray-700 ">
      Téléchargement d'images en cours...
    </div>{" "}
    <div className="w-full  bg-gray-300 rounded-full ">
      <div
        className={`${
          progress == 0 ? "" : "bg-Blue-Primary"
        } text-xs font-medium text-white text-center px-0.5 leading-none rounded-full`}
        style={{ width: `${progress}%` }}
      >
        {" "}
        {progress}%
      </div>
    </div>{" "}
  </div>
  )
};

export default UploadDemandeImages;
