import { Redirect, RouteProps } from "react-router";
import Profile from "../pages/Profile";
import MyUsers from "../pages/MyUsers";
import MyPatients from "../pages/MyPatients";
import MyRequests from "../pages/MyRequests";
import Groups from "../pages/Groups";
import Agenda from "../pages/Agenda";

const medecinResponsableRoutes: RouteProps[] = [
  {
    path: "/mes-patients",
    component: MyPatients,
    exact: true,
  },

  {
    path: "/groupes",
    component: Groups,
    exact: true,
  },
  {
    path: "/users",
    component: MyUsers,
    exact: true,
  },
  {
    path: "/demandes",
    component: MyRequests,
    exact: true,
  },
  {
    path: "/profile",
    component: Profile,
    exact: true,
  },

  {
    path: "/disponibilités",
    component: Agenda,
    exact: true,
  },

  { render: () => <Redirect to="/users" /> },
];

export default medecinResponsableRoutes;
