import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from "moment";
import { Formik } from "formik";

import Button from "../../components/ButtonUI";
import Input from "../../components/Input";
import makeToast from "../../components/Snackbar";
import ConfirmModal from "./ConfirmModalEdit";
import ConfirmModalRedirection from "./ConfirmModalRedirection";
import validationSchema from "./validateSchema";
import {
  addPatient,
  getDoctorPatients,
  getExistPatient,
  updatePatient,
} from "../../api/medecin";
import { userAtom } from "../../state/auth";
import CardPatient from "./CardPatient";
import cake from "../../assets/images/cake.png";
import info from "../../assets/icons/icon-patient.svg";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import fr from "date-fns/locale/fr";
import "./styles.scss";
import ConfirmModalExistPatient from "./ConfirmModalExistPatient";

const intialValueForm = {
  email: "",
  password: "",
  lastName: "",
  firstName: "",
  birthDate: "",
  birthday: "",
  sexe: [],
  gender: "",
  antecedents: [],
  phone: "",
  adresse: "",
  genez: "",
  numeroSecurite: "",
  medecin: "",
  codePostale: "",
  organismeSecuSociale: "",
};

registerLocale("fr", fr);
setDefaultLocale("fr");
interface IProps {
  openModal: Boolean;
  setOpenModal: Function;
  message?: String;
  type?: String;
  url?: string;
  setPatientAdded: boolean | any;
  setSelectedPatient: Function;
  setPatientData: Function;
  setOpenModalInfoPatient: Function;
  editMode: boolean;
  patientData: {
    _id: string;
    email: string;
    password: string;
    lastName: string;
    firstName: string;
    birthDate: string;
    birthday: string;
    sexe: any[];
    gender: string;
    antecedents: any[];
    phone: string;
    adresse: string;
    genez: string;
    numeroSecurite: string;
    medecin: string;
    codePostale: string;
    organismeSecuSociale?: string;
    authorizedDoctors?: any;
  };
}

const AddPatient: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  setPatientAdded,
  setSelectedPatient,
  setPatientData,
  editMode,
  setOpenModalInfoPatient,
  patientData,
}) => {
  const [user] = useAtom(userAtom);
  const [confirmEditModal, setConfirmEditModal] = useState(false);
  const [confirmRedirectionModal, setConfirmRedirectionModal] = useState(false);
  const [confirmRedirectionModalPatient, setConfirmRedirectionModalPatient] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [intialVal, setIntialVal] = useState(
    editMode ? patientData : intialValueForm
  );
  const [response, setResponse] = useState("");

  const handleAddPatient = async (values) => {
    try {
      const {
        lastName,
        firstName,
        birthday,
        gender,
        phone,
        numeroSecurite,
        codePostale,
      } = values;

      if (
        lastName === "" ||
        firstName === "" ||
        gender === "" ||
        birthday === "" ||
        phone === "" ||
        numeroSecurite === "" ||
        codePostale === ""
      ) {
        makeToast("error", "Veuillez remplir les champs  obligatoires");
      } else {
        const response = await addPatient({
          ...values,
          doctorId: user?._id,
        });
        //console.log("response", response.data);
        setResponse(response.data.patient);

        if (response.request.status === 203) {
          try {
            const doctorPatients = await getDoctorPatients(user?._id);
            console.log(
              "🚀 ~ file: addPatient.tsx:150 ~ handleAddPatienttttttt ~ doctorPatients:",
              doctorPatients
            );
            const existingPatient = doctorPatients.patients.find(
              (patient) => patient._id === response.data.patient._id
            );
            console.log(
              "🚀 ~ file: addPatient.tsx:151 ~ handleAddPatient ~ existingPatient:",
              existingPatient
            );

            if (existingPatient) {
              setConfirmRedirectionModalPatient(true);
              setSelectedUser(existingPatient._id);
            } else {
              setConfirmRedirectionModal(true);
              setSelectedUser(response.data.patient._id);
            }
          } catch (error) {
            console.log(error);
          }
        } else if (response.request.status === 200) {
          setPatientAdded(true);
          setOpenModal(!openModal);
          setSelectedPatient(response.data.patient._id);
          setPatientData(response.data.patient);

          makeToast("success", "Patient créé avec succès.");
        } else if (response.request.status === 202) {
          makeToast("error", response.data.response);
        }
      }
    } catch (error) {
      //setConfirmRedirectionModal(true);
      console.log(error);
    }
  };
  const handleEditPatient = async (values) => {
    try {
      const {
        lastName,
        firstName,
        birthday,
        gender,
        phone,
        numeroSecurite,
        codePostale,
      } = values;

      if (
        lastName === "" ||
        firstName === "" ||
        gender === "" ||
        birthday === "" ||
        phone === "" ||
        numeroSecurite === "" ||
        codePostale === ""
      ) {
        makeToast("error", "Veuillez remplir les champs obligatoires");
      } else {
        const response = await updatePatient(
          {
            ...values,
            doctorId: user?._id,
          },
          patientData._id
        );
        if (response.patient) {
          setPatientAdded(true);
          setOpenModal(!openModal);
          setSelectedPatient(response.patient._id);
          setPatientData(response.patient);

          makeToast(
            "success",
            "Mise à jour des données du patient effectuée avec succès."
          );
          setOpenModalInfoPatient(true);
        }
      }
    } catch (error) {
      makeToast("error", "Ce Patient existe déjà.");
      console.log(error);
    }
  };
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute  inset-y-0 right-0  form-add-patient  z-40 transition transition ease-in duration-700 patient-modal-info">
        <Button
          status="icon-secondary"
          className="absolute top-1 right-1"
          onClick={() => setOpenModal(!openModal)}
        >
          <Close />
        </Button>
        <h1 className="title flexCenter text-center">
          {editMode ? "Édition de la fiche patient" : "Nouveau patient"}
        </h1>
        <div className="form-container flexCenter">
          <Formik
            initialValues={intialVal}
            onSubmit={async (values) => {
              try {
                console.log("Submited");
              } catch (error) {
                console.log("error", error);
              }
            }}
            validationSchema={validationSchema}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                touched,
              } = props;

              return (
                <>
                  <form
                    onSubmit={handleSubmit}
                    className="form-login flexCenter flex-col fixingColor"
                  >
                    <div className="relative">
                      <Input
                        id="lastName"
                        placeholder="Nom"
                        type="text"
                        error={touched.lastName ? errors.lastName : undefined}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="custom-Input mb-2.5"
                        inlineLabel={false}
                        onfocus={true}
                        icon="user"
                        iconColor="white"
                        isIcon={true}
                      />
                      {values.lastName === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="relative">
                      <Input
                        id="firstName"
                        placeholder="Prénom"
                        type="text"
                        error={touched.firstName ? errors.firstName : undefined}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="custom-Input mb-2.5"
                        inlineLabel={false}
                        onfocus={true}
                        icon="user"
                        iconColor="white"
                        isIcon={true}
                      />
                      {values.firstName === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>

                    <div
                      className={`inputax  relative h-12 border ${
                        touched.phone && !values.gender
                          ? "ring-2 ring-red-100"
                          : ""
                      }   border border-gray-200  w-full flex gap-x-5  mb-2.5`}
                    >
                      <img
                        src={info}
                        alt="info"
                        className=" relative left-3 top-3 "
                        width="20"
                        style={{ height: "24px" }}
                      />{" "}
                      <p
                        className={
                          values.gender
                            ? `relative py-3 text-sm `
                            : `relative py-3 text-sm text-gray-400`
                        }
                      >
                        Sexe
                      </p>
                      {values.gender === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                      <Input
                        name="gender"
                        type="checkbox"
                        onChange={() => {
                          setFieldValue("gender", "female");
                        }}
                        value="female"
                        labelText="Féminin"
                        checked={values.gender === "female"}
                        labelProps={{
                          className: "check text-sm text-gray-400 m-2",
                        }}
                        stylesDiv={""}
                      />
                      <Input
                        name="gender"
                        type="checkbox"
                        onChange={() => {
                          setFieldValue("gender", "male");
                        }}
                        value="male"
                        labelText="Masculin"
                        labelProps={{
                          className: "check text-sm text-gray-400 m-2",
                        }}
                        checked={values.gender === "male"}
                        stylesDiv={""}
                      />
                    </div>

                    <div className="relative mb-8 mt-5">
                      <label className="absolute -top-5 text-gray-400 text-sm ">
                        Date de naissance :
                      </label>
                      <Input
                        id="birthday"
                        type="date"
                        error={touched.birthday ? errors.birthday : undefined}
                        value={
                          editMode
                            ? moment(values.birthday).format("YYYY-MM-DD")
                            : values.birthday
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="custom-Input mb-2"
                        inlineLabel={false}
                        onfocus={true}
                        image=""
                      />

                      <img
                        src={cake}
                        alt="cake"
                        className="absolute top-3 left-3"
                        width="18"
                      />

                      {values.birthday === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="relative">
                      <Input
                        id="phone"
                        placeholder="Numéro de téléphone"
                        type="text"
                        error={touched.phone ? errors.phone : undefined}
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2.5"
                        image="phone-white"
                      />
                      {values.phone === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>

                    <div className="relative">
                      <Input
                        id="email"
                        placeholder="Adresse mail"
                        type="email"
                        error={touched.email ? errors.email : undefined}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2"
                        image="mail-white"
                      />
                      {values.email === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded"></span>
                        </span>
                      )}
                    </div>
                    <div className="relative">
                      <Input
                        id="adresse"
                        placeholder="Adresse postale"
                        type="text"
                        error={touched.adresse ? errors.adresse : undefined}
                        value={values.adresse}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2.5"
                        icon="home"
                        iconColor="white"
                        isIcon={true}
                      />
                      {values.adresse === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded"></span>
                        </span>
                      )}
                    </div>

                    <div className="relative">
                      <Input
                        id="codePostale"
                        placeholder="Code postal"
                        type="text"
                        error={
                          touched.codePostale || touched.codePostale
                            ? errors.codePostale
                            : undefined
                        }
                        value={values.codePostale}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2.5"
                        icon="home"
                        iconColor="white"
                        isIcon={true}
                      />
                      {(values.codePostale === "" || errors.codePostale) && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="relative">
                      <Input
                        id="numeroSecurite"
                        placeholder="Numéro de sécurité sociale"
                        type="text"
                        error={
                          touched.numeroSecurite
                            ? errors.numeroSecurite
                            : undefined
                        }
                        value={values.numeroSecurite}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2.5"
                        image="securite-sociale"
                      />

                      {values.numeroSecurite === "" && (
                        <span className="absolute top-0 bottom-0 right-0">
                          <span className="flex items-center w-max h-4 subpixel-antialiased px-1 py-1 text-xs  leading-none text-gray-400  bg-gray-100 rounded">
                            ✯
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="relative">
                      <Input
                        id="organismeSecuSociale"
                        placeholder="Organisme Payeur"
                        type="text"
                        error={
                          touched.organismeSecuSociale
                            ? errors.organismeSecuSociale
                            : undefined
                        }
                        value={values.organismeSecuSociale}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2.5"
                        image="securite-sociale"
                      />
                    </div>

                    <div className="flex flex-row gap-x-8 mb-12"></div>

                    <div className="flexCenter">
                      <Button
                        status="primary"
                        className="btn mb-2.5"
                        onClick={() =>
                          editMode
                            ? setConfirmEditModal(true)
                            : handleAddPatient(values)
                        }
                      >
                        Enregistrer
                      </Button>
                    </div>
                    {editMode && confirmEditModal && (
                      <ConfirmModal
                        action={() => handleEditPatient(values)}
                        setOpenModal={setConfirmEditModal}
                      />
                    )}
                    {confirmRedirectionModal && (
                      <ConfirmModalRedirection
                        action={async (hasConsent) => {
                          const response = await getExistPatient(
                            {
                              doctorId: user?._id,
                              hasConsent,
                            },
                            selectedUser
                          );
                          setSelectedPatient(response.data.patient._id);
                          setPatientData(response.data.patient);
                          setOpenModal(!openModal);
                          setOpenModalInfoPatient(true);
                          setPatientAdded(true);
                        }}
                        setOpenModal={setConfirmRedirectionModal}
                      />
                    )}

                    {confirmRedirectionModalPatient && (
                      <ConfirmModalExistPatient
                        action={() => {
                          setSelectedPatient(response);
                          setPatientData(response);
                          setOpenModal(!openModal);
                          setOpenModalInfoPatient(true);
                        }}
                        setOpenModal={setConfirmRedirectionModal}
                      />
                    )}
                  </form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddPatient;
