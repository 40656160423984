export enum UserRole {
  MEDECIN = "medecin",
  RESPONSABLE = "medecinResponsable",
  TELEEXPERT = "medecinTeleexpert",
  CHIRURGIEN = "medecinChirurgien",
  Guest = "GUEST",
}
export enum RequrentRole {
  MEDECINREQURENT = "medecinRequrent",
  MIDWIFE = "sageFemme",
  NURSE = "infirmier",
}
export enum Speciality {
  DERMATO = "Dermatologie",
  GENERAL = "medecine Generale",
  AUTRE = "Autre",
}

export interface User {
  type: UserRole;
  requrentType: RequrentRole;
  activated: boolean;
  deleted: boolean;
  adresse: string;
  departement: string;
  demandes: [];
  leaves: [];
  createdAt: Date;
  email: string;
  firstName: string;
  imagesNumber: number;
  gender: string;
  lastName: string;
  numCom: string;
  numRPPS: string;
  phone: string;
  speciality: Speciality;
  updatedAt: Date;
  token: string;
  _id: string;
  __v?: number;
  studiesHistory: IStudies[];
  description: string;
  picture: string;
  linkedin: string;
  newPassword: boolean;
  agreedToCGU: boolean;
  freeAccount: boolean;
  stripeCustomerId: string;
  facebook: string;
  instagram: string;
  website: string;
  expertises: IExpertises[];
  patients: any;
  birthday: Date;
  langues: string[];
  stripeSubscriptionId: string;
  groupId: string;
}

export interface IExpertises {
  _id: string;
  expertise: string;
}

export interface IStudies {
  _id?: string;
  diplome: string;
  university: string;
  year: string;
}

interface IBookingDays {
  active: number;
  business_id: number;
  created_at: Date;
  day: string;
  end_time: string;
  id: number;
  member_id: number;
  start_time: string;
  updated_at: Date;
}

export interface CurrentUser {
  liberrexData: any;
  patient: any;
  role: UserRole;
  token: string;
  [key: string]: string | any;
}
