import PropTypes from "prop-types";
import React from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";

type Props = React.DetailedHTMLProps<
  React.SVGAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & { isIcon?: boolean; groundColor?: string; position?: string };

const Icon: React.FC<Props> = ({
  color,
  width,
  height,
  transform,
  name,
  isIcon,
  groundColor,
  position,
  ...props
}) => {
  const StyledSVGIcon = styled(ReactSVG)`
    svg {
      fill: black;
      ${({ width, height }) =>
        width &&
        height &&
        css`
          width: ${width}px !important;
          height: ${height}px !important;
        `}
      ${({ transform }) =>
        transform &&
        css`
          transform: ${transform};
        `}
      ${({ groundColor }) =>
        groundColor &&
        css`
          background-color: ${groundColor};
        `}
      ${({ position }) =>
        position &&
        css`
          position: ${position};
        `}
    path {
        ${({ color }) =>
          color &&
          groundColor &&
          css`
            background-color: ${groundColor};
          `}
        path {
          ${({ color }) =>
            color &&
            css`
              fill: ${color};
            `}
        }
      }
    }
  `;
  return (
    <StyledSVGIcon
      src={
        isIcon
          ? require(`../../assets/icons/icon-${name}.svg`).default
          
          : require(`../../assets/emoji/emoji-${name}.svg`).default
      }
      color={color}
      width={width}
      height={height}
      transform={transform}
      position={position}
      groundcolor={groundColor}
      {...props}
    />
  );
};

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  position: PropTypes.string,
  transform: PropTypes.string,
  isIcon: PropTypes.bool.isRequired,
  groundColor: PropTypes.string,
};

export default Icon;
