import { useAtom } from "jotai";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import PricingCard from "./pricingCard";
import { createSubscription } from "../../api/stripe";
import { userAtom } from "../../state/auth";

import "./styles.scss";
import { useState } from "react";
import makeToast from "../Snackbar";

const StripePaymentMethodForm = ({
  setFieldValue,
  auth,
  values,
  handleSubmitGlobal,
  
 
  
}) => {
  const [user] = useAtom<any>(userAtom);
  const stripe = useStripe();
  const elements = useElements();
  const [sendLoad, setSendLoad] = useState(false);
  const [PriceId, setPriceId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { type } = values;

 

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    try {
      // if (!user.stripeCustomerId)
      //   throw new Error("missing field 'stripeCustomerId' in user data.");
      setSendLoad(true);
      setDisabled(true);
      const { error, paymentMethod } = await stripe!.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)!,
      });

      if (error) throw error;
      console.log("[debug] paymentMethod -", {
        paymentMethod,
        user,
        priceId: PriceId,
      });
      const subscriptionResult = await createSubscription({
        customerId: auth ? user.stripeCustomerId : values.stripeCustomerId,
        paymentMethodId: paymentMethod!.id,
        priceId: PriceId,
      });
   
      setFieldValue("subscriptionId", subscriptionResult.subscription.id);
      setSendLoad(false);
      makeToast("success", "Vous êtes maintenant abonné. ");
      if (!auth) {
        handleSubmitGlobal();
      }
      if(handleSubmitGlobal !== undefined && auth){
        handleSubmitGlobal();
      }
    } catch (error) {
      console.error(error);
      makeToast("error", "Veuillez vérifier vos informations. ");
      setSendLoad(false);
      setDisabled(false);
    }
  };

  return (
    <div id="stripe-payment-method">
      <PricingCard userType={type}  setPriceId={setPriceId}>
        {" "}
      </PricingCard>
      {PriceId !== "" && (
        <>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  color: "#424770",
                  fontFamily: '"Ubuntu", sans-serif',
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          <button
            className="buttonPay"
            type="submit"
            disabled={!stripe || !elements || disabled}
            onClick={handleSubmit}
          >
            {" "}
            {!sendLoad ? (
              "Acheter"
            ) : (
              <p className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 self-center border-purple-500 mx-auto"></p>
            )}
          </button>
        </>
      )}
    </div>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!, {
  locale: "fr",
});

const StripePaymentMethod = ({
  setFieldValue,
  auth,
  values,
  handleSubmitGlobal,
  
  
}) => (
  <Elements stripe={stripePromise}>
    <StripePaymentMethodForm
      setFieldValue={setFieldValue}
      auth={auth}
      values={values}
      handleSubmitGlobal={handleSubmitGlobal}     />
  </Elements>
);

export default StripePaymentMethod;
