import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import Button from "../Button";


interface IProps {
  data: any;
  RenderComponent: any;
  pageLimit: number;
  dataLimit: number;
  modal: any;
  setModal: any;
  setPages: any;
  setSelectedPatient: Function;
  selectedPatient: any;
  Pages: any;
}

const Pagination: React.FC<IProps> = (props: IProps) => {
  const {
    data,
    RenderComponent,
    pageLimit,
    dataLimit,
    setPages,
    modal,
    selectedPatient,
    setSelectedPatient,
    setModal,
    Pages,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);

  function goToNextPage() {
    if (currentPage < Pages()) setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    if (currentPage > 1) setCurrentPage((page) => page - 1);
  }
useEffect(()=>{
  if (!setPages){
    setCurrentPage(1)
  }
})

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }
  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };
  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / data.length) * data.length;
    return new Array(data.length)
      .fill(pageLimit)
      .map((_, idx) => start + idx + 1);
  };

  return (
    <>
      {getPaginatedData().map((d, idx) => (
        <RenderComponent
          patientDetails={d}
          setOpenModal={setModal}
          setChatModal={() => console.log()}
          openModal={modal}
          key={idx}
          setSelectedPatient={setSelectedPatient}
          selectedPatient={selectedPatient}
          i={idx}
          setColorIndex={() => console.log()}
        />
      ))}
      <div className="  relative bg-white w-full h-16 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-2xl mt-4">
        <div className="absolute right-2 justify-between">
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <Button
              onClick={() => goToPreviousPage()}
              status="none"
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              disabled={!(currentPage > 1)}
            >
              <span className="sr-only">Previous</span>
              <Icon isIcon={true} name="direction-gauche" />
            </Button>
            <div>
              <p className="text-xs w-32 flexCenter py-4  text-gray-700 px-4">
                Page N°
                <span className="font-medium"> {currentPage} </span>/
                <span className="font-medium"> {Pages()}</span>
              </p>
            </div>
            {/* {totalPage && getNumberCels(totalPage)} */}

            <Button
              onClick={() => goToNextPage()}
              status="none"
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              disabled={!(currentPage < Pages())}
            >
              <span className="sr-only">Next</span>

              <Icon isIcon={true} name="direction droite" />
            </Button>
          </nav>

          <div></div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
