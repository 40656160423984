import * as Yup from "yup";
const digitsOnly = (value) => /^\d+$/.test(value);
export default Yup.object().shape({
  numRPPS: Yup.string()
    .test(
      "Digits only",
      "Le N° RPPS doit contenir uniquement des chiffres numériques",
      digitsOnly
    )
    .required(" ")
    .min(11, "Le N° RPPS est composé de 11 chiffres")
    .max(11, "Le N°  RPPS est composé de 11 chiffres"),

  numCom: Yup.string()
    .required(" ")
    .min(9, "Le N° ADELI est composé de 9 caractères")
    .max(9, "Le N° ADELI est composé de 9 caractères"),
});
