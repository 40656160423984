import { Formik } from "formik";
import { useAtom } from "jotai";
import validationSchema from "./validation";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import { login } from "../../api/auth";
import { userAtom } from "../../state/auth";
import "./stylesSignin.scss";
import logo from "../../assets/Logo.png";
import history from "../../history";
import makeToast from "../../components/Snackbar";

const Signin: React.FC = () => {
  const [user, setUser] = useAtom(userAtom);

  const onSubmit = async (values: { email: any; password: any }) => {
    const { email, password } = values;
    try {
      const response = await login({ email, password });
      if (response.token.accessToken) {
        makeToast("success", "Vous êtes maintenant connecté");
      }

      setUser(response.user);
    } catch (error) {
      makeToast("error", " Merci, de vérifier vos informations.");
      console.error("[error] Signin - onSubmit :", error);
    }
  };

  return (
    <div className="login min-h-screen flex justify-center">
      <div className="auth-login-Container mt-20 mb-20">
        <div className="flexRowCenter title-wrapper">
          <h1 className="h1 title sm:block hidden">Se connecter</h1>
        </div>
        <div className="flexCenter">
          <div className="formLogin ">
            {/* start */}
            <div
              className="icon cursor-pointer"
              onClick={() => history.push("/check/user")}
            ></div>
            <div className="logo-bloc flexRowCenter">
              <img src={logo} alt="logo" />
            </div>
            <div className="title-container flexColCenter">
              <h1 className="h1 flexRowCenter">Bienvenue</h1>
            </div>
            <div className="">
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(props) => {
                  const {
                    values,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;

                  return (
                    <>
                      <form onSubmit={handleSubmit} className="form-login">
                        <Input
                          id="email"
                          placeholder="Email"
                          labelText="Email"
                          type="text"
                          error={errors.email}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inlineLabel={false}
                          onfocus={true}
                        />
                        <Input
                          id="password"
                          placeholder="Mot de passe"
                          labelText="Mot de passe"
                          type="password"
                          error={errors.password}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="flexRowCenter">
                          <Button
                            type="submit"
                            disabled={
                              isSubmitting || Object.keys(errors).length > 0
                            }
                            status="primary"
                            className="btn"
                          >
                            Se connecter
                          </Button>
                        </div>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </div>
            <div className="create-Account flexColCenter">
              <p
                className="create cursor-pointer"
                onClick={() => history.push("/register")}
              >
                S’inscrire
              </p>
              <p
                className="create cursor-pointer"
                onClick={() => history.push("/forgetPassword")}
              >
                Mot de passe oublié?
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
