import axios from "axios";
import {
  DOCTOR_LOGIN_URL,
  DOCTOR_FORGET_PASSWORD_URL,
  DOCTOR_LOGOUT_URL,
  DOCTOR_VERIFY_URL,
  DOCTOR_FORGET_PASSWORD_URL_VERIFY,
  DOCTOR_UPDATE_URL,
} from "../config/api";
import { config, update } from "../helper/apiMethods";

export async function login(userCred: { email: string; password: string }) {
  try {
    const response = await axios.post(DOCTOR_LOGIN_URL, userCred);
    if (response.data.data.token.accessToken) {
      localStorage.setItem("token", response.data.data.token.accessToken);
    }

    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function verify() {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await axios.get(DOCTOR_VERIFY_URL, config(token));
      return response.data;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export const updatePosition = async ({ userId }, values) => {
  try {
    const res = update(`${DOCTOR_UPDATE_URL}/${userId}`, values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export async function logout(user) {
  try {
    const response = await axios.post(DOCTOR_LOGOUT_URL, user);
    window.location.href = "/";
    localStorage.clear();
  } catch (error) {
    window.location.href = "/";
    localStorage.clear();
    console.error(error);
  }
}
export async function forgetPassword(values) {
  try {
    const response = await axios.post(DOCTOR_FORGET_PASSWORD_URL, values);
    if (response) {
      if (response.data && response.data?.data) {
        let status = response.data.data?.status;
        let result = "";
        status === "OK"
          ? (result = "OK")
          : status === "DISABLED"
          ? (result = "DISABLED")
          : (result = "ERROR");
        return result;
      } else return "ERROR";
    } else {
      return "ERROR";
    }
  } catch (error) {
    console.log(error);
    return Promise.reject(error).then();
  }
}
export async function verifyResetToken(values) {
  try {
    const res = await axios.post(DOCTOR_FORGET_PASSWORD_URL_VERIFY, values);
    if (res.data.data.token.accessToken) {
      localStorage.setItem("token", res.data.data.token.accessToken);
    }

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
