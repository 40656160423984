import React from "react";
import Chat from "./Chat";
import Spinner from "../Spinner";
import Header from "./Header";
import "./style.scss";
import { retriveDemande } from "../../api/medecin";
import Modal from "../ModalChat";

interface Props {
  chatWithId: string | undefined;
  closeChatModal: Function;
  showModal: boolean;
}

const Index: React.FC<Props> = ({ showModal, chatWithId, closeChatModal }) => {
  const [loading, setLoading] = React.useState(false);
  const [chatWithData, setChatWithData] = React.useState() as any;
  const _getDemandById = async () => {
    try {
      setLoading(true);
      const response = await retriveDemande(chatWithId);
      setChatWithData(response?.data?.demande);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (chatWithId) _getDemandById();
  }, [chatWithId]);
  return (
    <Modal
      // width="max-w-lg"
      showModal={showModal}
      slidemodal={true}
      closeModal={closeChatModal}
      //noDarkBackground
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Header chatWithData={chatWithData} closeChatModal={closeChatModal} />
          <Chat chatWithId={chatWithData} />
        </>
      )}
    </Modal>
  );
};

export default Index;
