import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useAtom } from "jotai";
import { Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { userAtom } from "./state/auth";
import { UserRole } from "./types/user";
import Navigator from "./components/Navigator";
import Spinner from "./components/Spinner";
import { verify } from "./api/auth";
import history from "./history";
import "./theme.scss";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { verifyingSubscription } from "./api/stripe";

const App: React.FC = () => {
  const [loading, setlLading] = useState(true);

  console.log(
    `%c[info] app running in %c${process.env.NODE_ENV} mode.`,
    null,
    "color: #3B82F6; font-weight: bold;"
  );

  const [user, setUser] = useAtom(userAtom);
  const [subId, setSubId] = useState();

  useEffect(() => {
    setlLading(true);
    verify()
      .then((res) => {
        setUser(res);
        setlLading(false);
      })
      .catch(() => {
        setlLading(false);
      });
  }, [setUser, subId]);
  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      const sub = await verifyingSubscription({
        subscriptionId: user?.stripeSubscriptionId,
        userId: user?._id,
      });

      if (sub) {
        setSubId(sub.planId !== "canceled" ? sub.planId : "canceled");
      }
    }
    if (user && user?._id && !user?.freeAccount) {
      fetchMyAPI();
    }
  });

  return loading ? (
    <Spinner />
  ) : (
    <Router history={history}>
      <Navigator
        subscription={
          (subId === "canceled" && !user?.freeAccount) ||
          (!user?.newPassword &&
            user?.stripeSubscriptionId === null &&
            !user?.freeAccount) ||
          (user?.stripeSubscriptionId === "" && !user?.freeAccount)
        }
        newPassword={user?.newPassword || !user?.stripeSubscriptionId == null}
        role={user?.type || UserRole.Guest}
      />
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
