import React from "react";
import Button from "../../components/ButtonUI";
import Input from "../../components/Input";
import "./stylesSignin.scss";
import * as Yup from "yup";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import { addOrModifyProfileInfo, changePasword } from "../../api/medecin";
import makeToast from "../../components/Snackbar";
import { FormikWizard } from "../../components/FormikWizard";
import StripePaymentMethod from "../../components/StripePaymentMethod";
import { logout } from "../../api/auth";
import { Link } from "react-router-dom";
import { ReactComponent as Download } from "../../assets/illustrations/6421887411543238876.svg";
import { ReactComponent as Pay } from "../../assets/illustrations/pay.svg";
interface Props {
  openModal: boolean;
  setOpenModal: Function;
  setOpenModalAddPatient: Function;
}

export const ChangePass: React.FC<Props> = ({ setOpenModal }) => {
  const [user] = useAtom(userAtom);

  const ProgressBar = (progress: number) => {
    const progressPercentage = (100 / 3) * (progress + 1);
    return (
      <div className=" h-2 w-full bg-gray-300 border rounded-md">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={`h-full ${
            progressPercentage < 75 ? "bg-indigo-500" : "bg-green-300"
          }`}
        ></div>
      </div>
    );
  };
  //comment Global function
  const handleLogout = async () => {
    try {
      await logout(user);
      window.location.href = "/";
      localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      localStorage.clear();
      console.error(error);
    }
  };
  //comment steps ==> components
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden  bg-opacity-modal flexCenter ">
      <div className="absolute z-40 top-8 flexColCenter firstModal ">
        <h2>Bienvenue sur AvisDoc 👋 </h2>
        <div className="m-2 w-full">
          <ul className="stepper" data-mdb-stepper="stepper">
            <FormikWizard
              initialValues={{
                politique: "",
                QZQP001: "",
                condition: "",
                subscriptionId: "",
                email: user?.email,
                password: "",
                passwordConfirmation: "",
              }}
              onSubmit={async (values) => {
                const {
                  condition,
                  email,
                  password,
                  passwordConfirmation,
                  politique,
                  QZQP001,
                  subscriptionId,
                } = values;
                if (!user?.agreedToCGU && user?.type !== "medecinResponsable") {
                  const updateProfile = await addOrModifyProfileInfo({
                    stripeSubscriptionId: subscriptionId,
                    agreedToCGU: condition === "oui",
                    agreedToConfidentialityPolicy: politique === "oui",
                    agreedToDermatoscopyPolicy: QZQP001 === "oui",
                  });
                }

                const resPwd = await changePasword({
                  email,
                  password,
                  passwordConfirmation,
                });

                if (resPwd) {
                  setOpenModal(false);

                  makeToast("success", "Mot de passe  modifié avec succès ! ");
                } else {
                  makeToast("error", "Mot de passe  invalide ! ");
                }
              }}
              validateOnNext
              activeStepIndex={0}
              steps={
                !user?.agreedToCGU && user?.type !== "medecinResponsable"
                  ? [
                      {
                        component: ({ values, setFieldValue }) => (
                          <>
                            <div className="stepper-content text-justify text-sm mb-4 ">
                              En vous inscrivant, vous reconnaissez avoir pris
                              connaissance des Conditions Générales
                              d’Utilisation et de la Politique de protection des
                              données personnelles de Avisdoc et en accepter les
                              termes.<br></br> Avisdoc, responsable du
                              traitement, a besoin de traiter vos données pour
                              créer et administrer votre compte dans le cadre de
                              votre usage de sa plateforme de téléexpertise,
                              pour assurer son bon fonctionnement et sa
                              sécurité, pour réaliser des enquêtes de
                              satisfaction, des statistiques, des études et
                              autres actions de promotion sur l’utilisation de
                              ses services et pour répondre à vos demandes. Tous
                              les champs doivent obligatoirement être remplis,
                              faute de quoi nous ne serions pas en mesure de
                              satisfaire votre demande. La création de votre
                              compte et l’utilisation de la plateforme sont
                              basées sur votre consentement, ou sur les intérêts
                              légitimes d’Avisdoc ou sur le respect de ses
                              obligations légales. Selon les conditions
                              applicables, vous pouvez modifier et accéder à vos
                              données, demander leur effacement ou leur
                              portabilité, exercer votre droit d’opposition ou
                              de limitation du traitement, et définir des
                              directives quant au sort de vos données,
                              directement grâce aux paramètres de votre compte
                              ou par courriel à{" "}
                              <i className="font-bold">contact@avisdoc.co</i>.
                              <br></br> Lorsqu’il est la base légale applicable,
                              vous pouvez retirer à tout moment votre
                              consentement au traitement de vos données. Pour
                              plus d’information, nous vous invitons à consulter
                              notre Politique de protection des données
                              personnelles.
                            </div>
                            <div className="flex flexCenter space-x-5  my-2 mx-4 ">
                              <Link
                                className="flex  text-indigo-500 space-x-1   text-xs  "
                                to={"./avisdocConditions.pdf"}
                                target="_parent"
                                download="avisdocConditions.pdf"
                              >
                                <Download className="w-4 text-indigo-600 " />
                                <p>Conditions générales d’utilisation</p>
                              </Link>

                              <Link
                                className=" flex space-x-1 text-indigo-500  text-xs  "
                                to={"./avisdocPolitique.pdf"}
                                target="_blank"
                                download="avisdocPolitique.pdf"
                              >
                                <Download className="w-4 text-indigo-600   " />
                                <p>
                                  {" "}
                                  Politique de protection des données
                                  personnelles
                                </p>
                              </Link>
                            </div>
                            <Input
                              name="politique"
                              type="checkbox"
                              onChange={(e) => {
                                if (values.politique === "oui") {
                                  setFieldValue("politique", "");
                                } else {
                                  setFieldValue("politique", e.target.value);
                                }
                              }}
                              value="oui"
                              labelText="Je déclare avoir pris connaissance de la Politique de protection des données personnelles et accepte que mes données  soient collectées et traitées par Avisdoc selon ses termes. "
                              checked={values.politique === "oui"}
                              labelProps={{
                                className: "check2 text-sm m-2",
                              }}
                              stylesDiv={""}
                            />
                            <Input
                              name="condition"
                              type="checkbox"
                              onChange={(e) => {
                                if (values.condition === "oui") {
                                  setFieldValue("condition", "");
                                } else {
                                  setFieldValue("condition", e.target.value);
                                }
                              }}
                              value="oui"
                              labelText="Je déclare avoir pris connaissance et accepter les Conditions Générales d’Utilisation. "
                              checked={values.condition === "oui"}
                              labelProps={{
                                className: "check2 text-sm  m-2 mb-3",
                              }}
                              stylesDiv={""}
                            />
                            <Input
                              name="QZQP001"
                              type="checkbox"
                              onChange={(e) => {
                                if (values.QZQP001 === "oui") {
                                  setFieldValue("QZQP001", "");
                                } else {
                                  setFieldValue("QZQP001", e.target.value);
                                }
                              }}
                              value="oui"
                              labelText="Je m'engage à ne pas utiliser la téléexpertise pour surcôter mes actes en utilisant de manière indue le code QZQP001 de dermatoscopie."
                              checked={values.QZQP001 === "oui"}
                              labelProps={{
                                className: "check2 text-sm  m-2 mb-3",
                              }}
                              stylesDiv={""}
                            />
                          </>
                        ),
                        validationSchema: Yup.object().shape({
                          condition: Yup.string().required("required"),
                          politique: Yup.string().required("required"),
                          QZQP001: Yup.string().required("required"),
                        }),
                      },

                      {
                        component: ({ values, setFieldValue }) =>
                          values.subscriptionId === "" ? (
                            !user?.freeAccount ? (
                              <>
                                <StripePaymentMethod
                                  setFieldValue={setFieldValue}
                                  auth={true}
                                  values={values}
                                  handleSubmitGlobal={undefined}
                                />
                              </>
                            ) : (
                              <>
                                {" "}
                                <Pay
                                  className="border-4 border-green-300 bg-gray-100 rounded-full p-5 mx-auto my-2"
                                  width={200}
                                  height={200}
                                />
                                <p className="flexCenter font-bold text-lg text-green-500 ">
                                  Dispense de paiement{" "}
                                </p>
                              </>
                            )
                          ) : (
                            <div>
                              <Pay
                                className="border-4 border-green-300 bg-gray-100 rounded-full p-5 mx-auto my-2"
                                width={200}
                                height={200}
                              />
                              <p className="flexCenter font-bold text-lg text-green-500 ">
                                Paiement réussi
                              </p>
                            </div>
                          ),
                        validationSchema:
                          !user?.freeAccount &&
                          Yup.object().shape({
                            subscriptionId: Yup.string().required("required"),
                          }),
                      },
                      {
                        component: ({
                          errors,
                          values,
                          handleChange,
                          handleBlur,
                        }) => (
                          <div className="stepper-content flex flex-col flexCenter ">
                            <Input
                              id="password"
                              placeholder="Mot de passe"
                              type="password"
                              className="border m-2 rounded-md "
                              error={
                                errors.password ? errors.password[0] : undefined
                              }
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              labelProps={{
                                className: " text-md",
                              }}
                            />

                            <Input
                              id="passwordConfirmation"
                              placeholder="Confirmer votre mot de passe"
                              type="password"
                              className="border m-2 rounded-md "
                              error={
                                errors.passwordConfirmation
                                  ? errors.passwordConfirmation[0]
                                  : undefined
                              }
                              value={values.passwordConfirmation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              labelProps={{
                                className: " text-sm  ",
                              }}
                            />
                            <i className="text-xs w-64 ">{errors.password}</i>
                          </div>
                        ),
                        validationSchema: Yup.object({
                          password: Yup.string()
                            .required(
                              "Le changement de votre mot de passe est obligatoire."
                            )
                            .min(8, "Minimum 8 caractères ")
                            .matches(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                              "Votre mot de passe doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial."
                            ),
                          passwordConfirmation: Yup.string().oneOf(
                            [Yup.ref("password"), null],
                            "Mot de passe doit étre identique!"
                          ),
                        }),
                      },
                    ]
                  : [
                      {
                        component: ({
                          errors,
                          values,
                          handleChange,
                          handleBlur,
                        }) => (
                          <div className="stepper-content flex flex-col flexCenter ">
                            <Input
                              id="password"
                              placeholder="Mot de passe"
                              type="password"
                              className="border m-2 rounded-md "
                              error={
                                errors.password ? errors.password[0] : undefined
                              }
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              labelProps={{
                                className: " text-md",
                              }}
                            />

                            <Input
                              id="passwordConfirmation"
                              placeholder="Confirmer votre mot de passe"
                              type="password"
                              className="border m-2 rounded-md "
                              error={
                                errors.passwordConfirmation
                                  ? errors.passwordConfirmation[0]
                                  : undefined
                              }
                              value={values.passwordConfirmation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              labelProps={{
                                className: " text-sm  ",
                              }}
                            />
                            <i className="text-xs w-64 ">{errors.password}</i>
                          </div>
                        ),
                        validationSchema: Yup.object({
                          password: Yup.string()
                            .required(
                              "Le changement de votre mot de passe est obligatoire."
                            )
                            .min(8, "Minimum 8 caractères ")
                            .matches(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                              "Votre mot de passe doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial."
                            ),
                          passwordConfirmation: Yup.string().oneOf(
                            [Yup.ref("password"), null],
                            "Mot de passe doit étre identique!"
                          ),
                        }),
                      },
                    ]
              }
            >
              {({
                renderComponent,
                handlePrev,
                handleNext,
                isNextDisabled,
                isPrevDisabled,
                isLastStep,
                currentStepIndex,
                values,
              }) => (
                <>
                  {!user?.agreedToCGU && user?.type !== "medecinResponsable" ? (
                    <div className="wrapper option-1 option-1-1">
                      {currentStepIndex
                        ? ProgressBar(currentStepIndex)
                        : ProgressBar(0)}
                      {}
                      <ol className="c-stepper">
                        <li className="c-stepper__item">
                          <h3
                            className={`c-stepper__title ${
                              currentStepIndex == 0
                                ? "text-indigo-500"
                                : "text-gray-300"
                            }`}
                          >
                            CGU et RGPD
                          </h3>
                          <p className="c-stepper__desc mb-3"></p>
                        </li>
                        <li className="c-stepper__item">
                          <h3
                            className={`c-stepper__title ${
                              currentStepIndex == 1
                                ? "text-indigo-500"
                                : "text-gray-300"
                            }`}
                          >
                            Abonnement
                          </h3>
                          <p className="c-stepper__desc mb-3"></p>
                        </li>
                        <li className="c-stepper__item">
                          <h3
                            className={`c-stepper__title ${
                              currentStepIndex == 2
                                ? "text-indigo-500"
                                : "text-gray-300"
                            }`}
                          >
                            Mot de passe
                          </h3>
                          <p className="c-stepper__desc"></p>
                        </li>
                      </ol>
                      {renderComponent()}
                    </div>
                  ) : (
                    <div className="wrapper option-1 option-1-1">
                      <ol className="c-stepper">
                        <li className="c-stepper__item">
                          <h3
                            className={`c-stepper__title ${
                              currentStepIndex == 2
                                ? "text-indigo-500"
                                : "text-gray-300"
                            }`}
                          >
                            Mot de passe
                          </h3>
                          <p className="c-stepper__desc"></p>
                        </li>
                      </ol>
                      {renderComponent()}
                    </div>
                  )}
                  <Button
                    status="primary"
                    className="float-right flexCenter mx-5 my-5"
                    width="100px"
                    height="50px"
                    type="button"
                    onClick={handleNext}
                    disabled={
                      isNextDisabled ||
                      (isLastStep &&
                        (values.password === "" ||
                          values.passwordConfirmation === ""))
                    }
                  >
                    {" "}
                    {isLastStep ? "Terminer" : "Suivant "}{" "}
                  </Button>

                  {!isPrevDisabled && (
                    <Button
                      status="primary"
                      className="float-right flexCenter mx- 5 my-5"
                      width="100px"
                      height="50px"
                      type="button"
                      onClick={handlePrev}
                      hidden={isPrevDisabled}
                    >
                      Précédent
                    </Button>
                  )}

                  <Button
                    status="secondary"
                    className="float-left flexCenter my-5"
                    width="100px"
                    height="50px"
                    type="button"
                    onClick={handleLogout}
                  >
                    Annuler
                  </Button>
                </>
              )}
            </FormikWizard>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChangePass;
