import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import Fuse from "fuse.js";

import { User } from "../../types/user";
import { userAtom } from "../../state/auth";

import Button from "../../components/ButtonUI";
import Spinner from "../../components/Spinner";
import makeToast from "../../components/Snackbar";
import Input from "../../components/Input";
import CardGroup from "./CardGroup";
import AddGroup from "./addGroup";
import ConfirmAddGroup from "./ConfirmModalAddGroup";

import "./styles.scss";
import { ReactComponent as IconSearch } from "../../assets/icons/icon-search.svg";
import { refreshAtomGroups } from "../../state/refreshGroupList";

import { getAllGroups, retriveGroup } from "../../api/groups";
import PaginationGroups from "../../components/paginationGroups";
import GroupInfo from "../../components/SharedGroupDetails";

const Groups = () => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useAtom(refreshAtomGroups);
  const [modalAdd, setModalAdd] = useState(false);
  const [listGroups, setListGroups] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [modalAddConfirm, setModalAddConfirm] = useState(false);
  const [data, dataSet] = useState<any>(null);
  const [user] = useAtom(userAtom);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [search, setSearch] = useState(false);
  const [doctorData, setDoctorData] = useState<User>();
  const [isResponsable, setIsResponsable] = useState(
    user?.type === "medecinResponsable" ? true : false
  );
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [doctorAdded, setDoctorAdded] = useState(false);
  const [colorIndex, setColorIndex] = useState(1);
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [strFilter, setStrFilter] = React.useState<string>("");
  const [refreshGroup, setRefreshGroup] = useState(false);

  const fetchMyData = async () => {
    setLoading(true);
    let response = await getAllGroups();
    if (response) {
      setListGroups(response);
    } else {
      makeToast("error", "Chargement de la listes des groupes à échouher.");
    }
    dataSet(response ? response : []);
    setLoading(false);
  };

  const handleListFiltering = () => {
    const patientsObj = isResponsable
      ? listGroups.map((s) => s)
      : data?.map((s) => s);
    const fuse = new Fuse(patientsObj, {
      threshold: 0.3,
      shouldSort: true,
      keys: ["name"],
    });
    const newList = fuse.search(strFilter).map((item) => {
      return item.item;
    });
    setFilteredData(newList);
  };

  useEffect(() => {
    if (search || doctorAdded || updateList || page) {
      fetchMyData();
    }
    if (doctorAdded) {
      setDoctorAdded(false);
      setModalAdd(true);
      makeToast("success", "Un nouveau groupe est ajouté avec succès.");
    }
  }, [search, doctorAdded, page, updateList]);

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      let response: any = await retriveGroup(selectedDoctor);
      const doctor = response;
      if (!response) {
        makeToast("error", "Chargement de groupe à échouher.");
      }
      setDoctorData({ ...doctor });
    }
    if (selectedDoctor) {
      fetchMyAPI();
      setModal(true);
      setDoctorAdded(false);
    }
  }, [selectedDoctor]);

  React.useEffect(() => {
    if (strFilter !== "") {
      handleListFiltering();
    }
  }, [strFilter, listGroups]);

  useEffect(() => {
    fetchMyData();
  }, [refresh?.state, refreshGroup]);
  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="mx-16 my-patients">
        <header className="flex justify-between items-center w-full flex-wrap">
          <div className="title-container">
            <h1 className="pageTitle">Liste des groupes</h1>
            <h3 className="card-subtitle"></h3>
          </div>
          <div className="flex max-w-max items-center space-x-2 ">
            <div className="relative">
              <Button
                status="secondary"
                onClick={() => setModalAddConfirm(true)}
                className="btnAddPatient"
              >
                + Créer un groupe
              </Button>
            </div>
          </div>
        </header>

        <div className="flex justify-between items-center w-full mt-5 filtres">
          <div className="relative flex w-full flex-wrap  mb-3 mx-6 mt-2 ">
            <span className="z-10 h-full leading-snug font-normal  text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
              <IconSearch />
            </span>
            <Input
              type="text"
              placeholder="Rechercher par : nom"
              style={{ width: 361 }}
              onChange={(e) => setStrFilter(e.target.value)}
              onKeyPress={(event) => {}}
              className="rounded-xl px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white  text-sm outline-none focus:border-blue-500  border-blue-100 h-12 w-full pl-10"
            />

            <div className="flex">
              {/* {filerObject &&
                Object.values(filerObject).length > 0 &&
                Object.values(filerObject)
                  .filter((el) => el !== null && el !== undefined && el !== "")
                  .map((el) => (
                    <span className="box mx-2 flexCenter">
                      <button
                        className="cros"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const key = getKeyByValue(filerObject, el) || "";
                          setFilterObject({
                            ...filerObject,
                            [key]: undefined,
                          });
                          setSearch(false);
                        }}
                      >
                        X
                      </button>
                    </span>
                  ))} */}
            </div>
          </div>

          {totalResult && search ? (
            <div>{totalResult} résultats trouvés</div>
          ) : (
            ""
          )}
        </div>

        <div className={`patient-list flex flex-wrap justify-between w-full"`}>
          {data && data.length !== 0 ? (
            <PaginationGroups
              // data={
              //   search
              //     ? data.filter((iot) => iot.type === filerObject.userType)
              //     : strFilter === ""
              //     ? data
              //     : filteredData
              // }
              data={
                isResponsable
                  ? strFilter === ""
                    ? listGroups
                    : filteredData
                  : strFilter === ""
                  ? data
                  : filteredData
              }
              RenderComponent={CardGroup}
              pageLimit={50}
              dataLimit={10}
              modal={modal}
              setModal={setModal}
              setPages={strFilter === ""}
              setSelectedPatient={setSelectedDoctor}
              selectedPatient={selectedDoctor}
              setRefreshGroup={setRefreshGroup}
              setDoctorData={setDoctorData}
              Pages={() => {
                return Math.ceil(
                  strFilter === "" ? data.length / 10 : filteredData.length / 10
                );
              }}
              setColorIndex={setColorIndex}
            ></PaginationGroups>
          ) : (
            <div className="w-full flexCenter mt-24">
              <h3>Aucun groupe trouvé</h3>
            </div>
          )}
        </div>

        {/* {modal && (
          <GroupInfo
            openModal={modal}
            setOpenModal={() => {
              setModal(false);
              setSelectedDoctor("");

              setDoctorData({} as any);
            }}
            setUpdateList={setUpdateList}
            doctorData={doctorData}
            colorIndex={colorIndex}
          />
        )} */}
        {modalAdd && (
          <AddGroup
            openModal={modalAdd}
            setOpenModal={setModalAdd}
            setDoctorAdded={setDoctorAdded}
            setSelectedDoctor={setSelectedDoctor}
            setDoctorData={setDoctorData}
            setOpenModalInfoDoctor={setModal}
          />
        )}
        {modalAddConfirm && (
          <ConfirmAddGroup
            openModal={modalAddConfirm}
            setOpenModal={setModalAddConfirm}
            setOpenModalAddDoctor={setModalAdd}
          />
        )}
      </div>
    </>
  );
};
export default Groups;
